import { IconRight } from "@arco-design/web-react/icon";
import { Table, type TableColumnProps } from "@arco-design/web-react";
import { OrderService } from "@api/http_pms/order/order_srv";
import { useRequest } from "ahooks";
import { useMemo, useState } from "react";
import { type OtaPlatformKind } from "@api/http_pms/const/const_enum";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useColumns } from "../Order/useColumns";
import { initialOrderColumns } from "../Order/openColumnsManager";
import { WorkbenchItemCard } from "./components/WorkbenchItemCard";
import { getCurrentTimeZoneDate } from "@/utils/orderStatus";

enum QueryTab {
  New, // 新单
  CheckIn, // 入住
  InLive, // 在住
  CheckOut, // 退房
}

const queryTabOptions = [
  { label: "新单", value: QueryTab.New },
  { label: "入住", value: QueryTab.CheckIn },
  { label: "在住", value: QueryTab.InLive },
  { label: "退房", value: QueryTab.CheckOut },
];

export interface ListByFilterReq {
  keys: string[];
  roomIds: string[];
  otaPlatformKinds: OtaPlatformKind[];
  tagIds: string[];
  orderId?: string;
  orderSourceIds: string[];
  current: number;
  pageSize: number;
  currentTab: QueryTab;
}

const orderColumns = initialOrderColumns
  .map((column) => column.databaseKey)
  .flat();

const getTabQuery = (query: ListByFilterReq) => {
  const today = getCurrentTimeZoneDate();
  if (query.currentTab === QueryTab.New) {
    return {
      bookAt: {
        start: today.startOf("day").unixStr(),
        end: today.endOf("day").unixStr(),
      },
    };
  }
  // 入住
  if (query.currentTab === QueryTab.CheckIn) {
    return {
      dateStart: {
        start: today.dateStr(),
        end: today.dateStr(),
      },
    };
  }
  // 退房
  if (query.currentTab === QueryTab.CheckOut) {
    return {
      dateEnd: {
        start: today.dateStr(),
        end: today.dateStr(),
      },
    };
  }
  // 在住
  if (query.currentTab === QueryTab.InLive) {
    return {
      dateStart: {
        end: today.dateStr(),
      },
      dateEnd: {
        start: today.add(1, "day").dateStr(),
      },
    };
  }
};

export const OrderListCard = () => {
  const columns = useColumns({ queryColumnKeys: orderColumns });
  const navigate = useNavigate();
  const [query, setQuery] = useState<ListByFilterReq>({
    keys: initialOrderColumns.map((column) => column.databaseKey).flat(),
    current: 1,
    pageSize: 20,
    roomIds: [],
    otaPlatformKinds: [],
    tagIds: [],
    orderSourceIds: [],
    currentTab: QueryTab.New,
  });

  const { data, loading } = useRequest(
    () =>
      OrderService.ListByFilter({
        ...query,
        ...getTabQuery(query),
        cancelledAt: { empty: true },
      }),
    {
      refreshDeps: [query],
    },
  );

  const finalColumns = useMemo(
    () =>
      [
        ...[
          "roomName",
          "tenant",
          "dateStart",
          "roomFee",
          "orderSource",
          "tagIds",
          "remark",
          "bookAt",
          "op",
        ]
          .map((dataIndex) =>
            columns.find((column) => column.dataIndex === dataIndex),
          )
          .filter((item) => item),
      ] as TableColumnProps[],
    [columns],
  );

  return (
    <WorkbenchItemCard
      className="flex flex-col px-6 py-4"
      css={`
        max-height: calc(100vh - 116px);
      `}
    >
      <div className="border-color-border-1 flex justify-between border-b">
        <div className="text-color-text-2 flex gap-12 text-base">
          {queryTabOptions.map((option) => (
            <div
              key={option.value}
              className={clsx("h-8 cursor-pointer", {
                "border-b-2 border-color-primary-6":
                  option.value === query.currentTab,
              })}
              onClick={() =>
                setQuery((_query) => ({ ..._query, currentTab: option.value }))
              }
            >
              {option.label}
            </div>
          ))}
        </div>
        <div
          className="text-color-text-3 cursor-pointer"
          onClick={() => {
            navigate("/order");
          }}
        >
          查看所有订单 <IconRight />
        </div>
      </div>
      <Table
        rowKey="id"
        loading={loading}
        columns={finalColumns}
        data={data?.orders}
        className="arco-table-auto-scroll mt-6 min-h-0 w-full flex-auto"
        border={false}
        pagination={{
          total: data?.total,
          pageSize: data?.pageSize,
          current: data?.current,
          sizeCanChange: true,
          showTotal: true,
        }}
        onChange={({ current, pageSize }) => {
          if (current && pageSize) {
            setQuery({ ...query, current, pageSize });
          }
        }}
        scroll={{ x: true, y: true }}
        css={`
          .arco-table-td {
            padding: 17px 16px;
          }
          .arco-table-th-item {
            padding: 17px 16px;
          }
          .arco-spin-children {
            display: flex;
            flex-direction: column;
          }
          .arco-pagination {
            margin-top: 20px;
          }
        `}
      />
    </WorkbenchItemCard>
  );
};
