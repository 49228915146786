import { OrderService } from "@api/http_pms/order/order_srv";
import {
  Alert,
  Form,
  Message,
  Modal,
  Select,
  Table,
} from "@arco-design/web-react";
import { type ColumnProps } from "@arco-design/web-react/es/Table";
import { useMemo } from "react";
import { useRequest } from "ahooks";
import { createNiceModal } from "@/utils/nicemodel";
import { getMoneyText } from "@/utils/money";
import { useWallet } from "@/store/useWallet";
import { AsyncButton } from "@/comps/AsyncButton";
import { MoneyCurrencyInput } from "@/common/Inputer/MoneyCurrencyInput";
import { Loading } from "@/comps/Loading";
import { MoneyColorShow } from "@/comps/MoneyColorShow";

enum FeeType {
  RoomFee,
  OtaFee,
}

export const openCancelOrder = createNiceModal<
  {
    id: string;
    afterSubmit: () => Promise<any>;
  }, // 调用的入参参数
  {} // 调用的出参参数
>(({ _modal, id, afterSubmit }) => {
  const [form] = Form.useForm();
  const { walletOptions, walletMap } = useWallet();

  const { data: order, loading } = useRequest(async () => {
    const orderRes = await OrderService.Get({
      id,
    });
    return orderRes.order;
  });

  const columns = useMemo<ColumnProps[]>(
    () => [
      {
        title: "订单收入",
        width: 100,
        render: (_, record) => {
          if (record.type === FeeType.RoomFee && order) {
            return (
              <div>
                <div className="text-color-text-2">
                  房费-{walletMap[order.roomFeeWalletId]?.name}
                </div>
                <MoneyColorShow
                  moneyText={getMoneyText(
                    order.roomFee,
                    order.roomFeeCurrency,
                    {
                      hide00Dec: true,
                      hidePlusSign: true,
                    },
                  )}
                />
              </div>
            );
          }
          if (record.type === FeeType.OtaFee && order) {
            return (
              <div>
                <div className="text-color-text-2">
                  佣金-
                  {order?.otaFeeWalletId
                    ? walletMap[order.otaFeeWalletId]?.name
                    : ""}
                </div>
                <MoneyColorShow
                  moneyText={getMoneyText(order.otaFee, order.roomFeeCurrency, {
                    hide00Dec: true,
                    hidePlusSign: true,
                  })}
                />
              </div>
            );
          }
        },
      },
      {
        title: "退还金额",
        width: 180,
        render: (_, record) => {
          if (record.type === FeeType.RoomFee) {
            return (
              <Form.Item field="refundRoomFee" className="!mb-0">
                <MoneyCurrencyInput
                  size="large"
                  beforeInputProps={{ placeholder: "请输入" }}
                />
              </Form.Item>
            );
          }
          if (record.type === FeeType.OtaFee) {
            return (
              <Form.Item field="refundOtaFee" className="!mb-0">
                <MoneyCurrencyInput
                  size="large"
                  beforeInputProps={{ placeholder: "请输入" }}
                />
              </Form.Item>
            );
          }
        },
      },
      {
        title: "退还钱包",
        width: 130,
        render: (_, record) => {
          if (record.type === FeeType.RoomFee) {
            return (
              <Form.Item field="walletId" className="!mb-0">
                <Select size="large" options={walletOptions} />
              </Form.Item>
            );
          }
          if (record.type === FeeType.OtaFee) {
            return (
              <Form.Item field="otaFeeWalletId" className="!mb-0">
                <Select size="large" options={walletOptions} />
              </Form.Item>
            );
          }
        },
      },
    ],
    [order],
  );

  return (
    <Modal
      title="取消订单"
      {..._modal.props} // 需要解构给Modal组件
      footer={
        <div className="flex w-full items-center justify-end">
          <AsyncButton
            type="primary"
            className="ml-3"
            onClick={async () => {
              const values = await form.validate();
              if (order) {
                await OrderService.Cancel({
                  id: order.id,
                  roomFeeBill: {
                    walletId: values.walletId,
                    refundAmount: values.refundRoomFee,
                  },
                  otaFeeBill: {
                    walletId: values.otaFeeWalletId,
                    refundAmount: values.refundOtaFee,
                  },
                });
                await afterSubmit();
                Message.success("取消订单成功");
                _modal.resolve({});
              }
            }}
          >
            确认取消订单
          </AsyncButton>
        </div>
      }
      className="w-[640px]"
      css={`
        .arco-modal-content {
          padding: 0px 0px;
        }
      `}
    >
      <Alert
        type="warning"
        content="请确认退款金额，订单取消后不可恢复，请谨慎操作。"
      />
      <Loading loading={loading} isEmpty={!order}>
        <Form
          form={form}
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            refundRoomFee: {
              currency: order?.roomFeeCurrency,
              amount: order?.roomFee,
            },
            refundOtaFee: {
              currency: order?.roomFeeCurrency,
              amount: order?.otaFee,
            },
            walletId: order?.roomFeeWalletId,
            otaFeeWalletId: order?.otaFeeWalletId,
          }}
          className="px-6 pb-6 pt-3"
        >
          <Table
            rowKey="id"
            columns={columns}
            data={
              order?.otaFeeWalletExist
                ? [{ type: FeeType.RoomFee }, { type: FeeType.OtaFee }]
                : [{ type: FeeType.RoomFee }]
            }
            className="arco-table-auto-scroll w-full flex-auto"
            border={true}
            pagination={false}
            scroll={{ x: true, y: true }}
            borderCell={true}
            css={`
              .arco-table-td {
                padding: 9px 16px;
              }
              .arco-table-th-item {
                padding: 9px 16px;
              }
              .arco-spin-children {
                display: flex;
                flex-direction: column;
              }
              .arco-table-td:not(.arco-table-col-fixed-left):not(
                  .arco-table-col-fixed-right
                ) {
                background-color: #ffffff !important;
              }
              .arco-table-th {
                border-left: 1px solid transparent;
              }
            `}
          />
        </Form>
      </Loading>
    </Modal>
  );
});
