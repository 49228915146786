/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import {
  type RoomDayActivity,
  type RoomDayClose,
  type RoomDayDetail,
  type RoomDayPrice,
  type Activity,
  type RoomDayMinNights,
} from "./room_day_type";
import { type OrderPureWithTenant } from "../order/order_type";
import { type Currency } from "../const/const_enum";
import { type CurrencyAmount } from "../common/common_type";

export namespace RoomDayService {
  // RoomDay
  // 应用活动到房天
  export function BatchApplyRoomDayActivity(
    req: BatchApplyRoomDayActivityReq,
    config?: any,
  ) {
    return webapi.post<BatchApplyRoomDayActivityResp>(
      "/api/v1/RoomDayService_BatchApplyRoomDayActivity",
      req,
      config,
    );
  }

  // 手动设置房态打开/关闭
  export function BatchSetRoomDayClose(
    req: BatchSetRoomDayCloseReq,
    config?: any,
  ) {
    return webapi.post<BatchSetRoomDayCloseResp>(
      "/api/v1/RoomDayService_BatchSetRoomDayClose",
      req,
      config,
    );
  }

  // 批量设置最小天数
  export function BatchSetRoomDayMinNights(
    req: BatchSetRoomDayMinNightsReq,
    config?: any,
  ) {
    return webapi.post<BatchSetRoomDayMinNightsResp>(
      "/api/v1/RoomDayService_BatchSetRoomDayMinNights",
      req,
      config,
    );
  }

  // 查询房态
  export function ListDetail(req: ListDetailReq, config?: any) {
    return webapi.post<ListDetailResp>(
      "/api/v1/RoomDayService_ListDetail",
      req,
      config,
    );
  }

  // 查询房价
  export function ListRoomDayPrice(req: ListRoomDayPriceReq, config?: any) {
    return webapi.post<ListRoomDayPriceResp>(
      "/api/v1/RoomDayService_ListRoomDayPrice",
      req,
      config,
    );
  }

  // 查询报价
  export function ListQuotedPrice(req: ListQuotedPriceReq, config?: any) {
    return webapi.post<ListQuotedPriceResp>(
      "/api/v1/RoomDayService_ListQuotedPrice",
      req,
      config,
    );
  }

  // 批量设置房价
  export function BatchSetRoomDayPrice(
    req: BatchSetRoomDayPriceReq,
    config?: any,
  ) {
    return webapi.post<BatchSetRoomDayPriceResp>(
      "/api/v1/RoomDayService_BatchSetRoomDayPrice",
      req,
      config,
    );
  }

  // 根据分组和日期范围 获取房间空房数量列表
  export function ListOpenRoomCountByGroupId(
    req: ListOpenRoomCountByGroupIdReq,
    config?: any,
  ) {
    return webapi.post<ListOpenRoomCountByGroupIdResp>(
      "/api/v1/RoomDayService_ListOpenRoomCountByGroupId",
      req,
      config,
    );
  }

  // Activity
  // 获取所有活动
  export function AllActivity(req: AllActivityReq, config?: any) {
    return webapi.post<AllActivityResp>(
      "/api/v1/RoomDayService_AllActivity",
      req,
      config,
    );
  }

  // 批量创建
  export function BatchCreateActivity(
    req: BatchCreateActivityReq,
    config?: any,
  ) {
    return webapi.post<BatchCreateActivityResp>(
      "/api/v1/RoomDayService_BatchCreateActivity",
      req,
      config,
    );
  }

  // 批量删除
  export function BatchDelActivity(req: BatchDelActivityReq, config?: any) {
    return webapi.post<BatchDelActivityResp>(
      "/api/v1/RoomDayService_BatchDelActivity",
      req,
      config,
    );
  }

  // 修改活动信息
  export function UpdateActivity(req: UpdateActivityReq, config?: any) {
    return webapi.post<UpdateActivityResp>(
      "/api/v1/RoomDayService_UpdateActivity",
      req,
      config,
    );
  }
}
// ApplyActivity
export interface BatchApplyRoomDayActivityReq {
  // 房间id列表
  roomIds: string[];
  // 应用活动日期起始日 e.g., 2006-01-02
  dateStart: string;
  // 应用活动日期结束日 e.g., 2006-01-02
  dateEnd: string;
  // 活动id（为空就是这些房间 这段日期不应用任何活动）
  activityId?: string;
}

export interface BatchApplyRoomDayActivityResp {
  roomDayActivities: RoomDayActivity[];
}

// BatchSetRoomDayClose
export interface BatchSetRoomDayCloseReq {
  // 房间id列表
  roomIds: string[];
  // 设置房间打开/关闭状态 起始日 e.g., 2006-01-02
  dateStart: string;
  // 设置房间打开/关闭状态 结束日 e.g., 2006-01-02
  dateEnd: string;
  // 设置的状态（true关闭,false打开） @bool
  isClose: boolean;
}

export interface BatchSetRoomDayCloseResp {
  roomDayCloses: RoomDayClose[];
}

// List
export interface ListDetailReq {
  // 房间id列表
  roomIds: string[];
  // 查询房态日期范围的起始日
  dateStart: string;
  // 查询房态日期范围的结束日
  dateEnd: string;
}

export interface ListDetailResp {
  roomDayDetail: RoomDayDetail[];
  // todo 这里为什么带出来订单?
  relatedOrders: OrderPureWithTenant[];
}

// ListRoomDayPrice
export interface ListRoomDayPriceReq {
  // 房间id列表
  roomIds: string[];
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
}

export interface ListRoomDayPriceResp {
  roomDayPrices: RoomDayPrice[];
}

// ListQuotedPrice
export interface ListQuotedPriceReq {
  // 房间id
  roomId: string;
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
  // 房客数量 @uint32
  tenantCount: number;
  // 币种
  currency: Currency;
}

// This the module of ListQuotedPriceResp
export namespace ListQuotedPriceResp {
  export interface OrderSourceQuotePrice {
    // 订单来源id
    orderSourceId: string;
    // 基础价格
    basePrice: CurrencyAmount;
    // 周租优惠 0不显示
    weeklyDiscount: CurrencyAmount;
    // 月租优惠 0不显示
    monthlyDiscount: CurrencyAmount;
    // 清扫费 0不显示
    cleaningFee: CurrencyAmount;
    // 额外人头费 0不显示
    extraPersonPrice: CurrencyAmount;
    // 实际金额
    actualPrice: CurrencyAmount;
  }
}
export interface ListQuotedPriceResp {
  // 报价
  pmsQuotedPrice: CurrencyAmount;
  // 订单来源报价
  orderSourceQuotePrices: ListQuotedPriceResp.OrderSourceQuotePrice[];
}

// BatchSetRoomDayPrice
export interface BatchSetRoomDayPriceReq {
  // 房间id列表
  roomIds: string[];
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
  // 价格 @uint32
  price?: number;
}

export interface BatchSetRoomDayPriceResp {
  roomDayPrices: RoomDayPrice[];
}

// ListOpenRoomCountByGroupId
export interface ListOpenRoomCountByGroupIdReq {
  // 分组id列表
  groupIds: string[];
  // 查询空房数量时间范围的起始日
  dateStart: string;
  // 查询空房数量时间范围的结束日
  dateEnd: string;
}

// This the module of ListOpenRoomCountByGroupIdResp
export namespace ListOpenRoomCountByGroupIdResp {
  export interface GroupOpenRoomItem {
    // 分组id
    groupId: string;
    // 日期
    date: string;
    // 空房数量 @uint32
    count: number;
  }
}
export interface ListOpenRoomCountByGroupIdResp {
  groupOpenRoomItems: ListOpenRoomCountByGroupIdResp.GroupOpenRoomItem[];
}

// AllActivity
export interface AllActivityReq {}

export interface AllActivityResp {
  // 活动列表
  activities: Activity[];
}

// CreateActivity
export interface BatchCreateActivityReq {
  // 活动列表
  activities: Activity[];
}

export interface BatchCreateActivityResp {
  // 创建成功后返回对应的活动列表
  activities: Activity[];
}

// BatchDelActivity
export interface BatchDelActivityReq {
  // 活动id列表
  activityIds: string[];
}

export interface BatchDelActivityResp {
  //  @uint32
  rows: number;
}

// UpdateActivity
export interface UpdateActivityReq {
  activity: Activity;
}

export interface UpdateActivityResp {
  activity: Activity;
}

// BatchSetRoomDayMinNights
export interface BatchSetRoomDayMinNightsReq {
  // 房间id列表
  roomIds: string[];
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
  // 最低起订间夜数 @uint32
  minNights?: number;
}

export interface BatchSetRoomDayMinNightsResp {
  roomDayMinNights: RoomDayMinNights[];
}
