/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type OrderForList } from "../order/order_type";
import { type OrderSourceQuotePrice } from "./im_type";

export namespace ImService {
  // IM鉴权
  // pms把鉴权数据发给im，im 将token放在redis 并返回给pms
  // 前端拿着这个token访问im，前端请求的时候，token放在header
  // 每次收到token的pub 刷新过期时间5mins
  // 如果token过期返401 前端重新获取token
  export function GetToken(req: GetTokenReq, config?: any) {
    return webapi.post<GetTokenResp>("/api/v1/ImService_GetToken", req, config);
  }

  // 聊天侧边栏订单信息
  export function GetConvOrder(req: GetConvOrderReq, config?: any) {
    return webapi.post<GetConvOrderResp>(
      "/api/v1/ImService_GetConvOrder",
      req,
      config,
    );
  }
}
export interface GetTokenReq {}

export interface GetTokenResp {
  token: string;
}

// GetConvOrder
export interface GetConvOrderReq {
  // 会话id
  convId: string;
}

export interface GetConvOrderResp {
  // 备注
  remark: string;
  // 房间
  roomId: string;
  order?: OrderForList;
  quotePrice?: OrderSourceQuotePrice;
}
