import ReactEChartsCore from "echarts-for-react/lib/core";
import { type LineSeriesOption } from "echarts/charts";
import * as echarts from "echarts/core";
import { isNil } from "lodash-es";
import dayjs from "dayjs";
import { useMemo, type FC } from "react";
import { WorkbenchItemCard } from "./components/WorkbenchItemCard";
import { WorkbenchItemTitle } from "./components/WorkbenchItemTitle";

import { lineChartConst } from "./const";
import { useOccupancyRate } from "./hooks";
import { type OccupancyRateLineChartData } from "./interface";

interface OccupancyRateLineChartProps {
  occupancyRateData: OccupancyRateLineChartData[];
  todayDataIndex: number;
  loading?: boolean;
}

const OccupancyRateLineChart: FC<OccupancyRateLineChartProps> = ({
  occupancyRateData,
  todayDataIndex,
  loading,
}) => {
  const option = useMemo(() => {
    // 今日标记线的位置：0为起点，markLineEnd为终点
    const markLineStart = 0;
    // 获取y轴最大值来显示今日标记线的位置
    const markLineEnd = Math.max(
      ...(occupancyRateData?.map((item) =>
        Math.round(Number(item.value) || 1),
      ) || []),
    );

    const opt: echarts.ComposeOption<LineSeriesOption> = {
      // chart内边距
      grid: {
        left: 40,
        right: 20,
        top: 30,
        bottom: 30,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          lineStyle: {
            color: lineChartConst.axisPointerLineColor,
            width: 2,
            type: "dashed",
          },
        },
        borderRadius: 8,
        formatter: (arg: Array<{ dataIndex: number; value: string }>) => {
          const params = arg[0];
          if (isNil(params)) {
            return "";
          }

          return `
        <div
          style="
            padding-left: 8px;
            padding-right: 16px;
            display: flex;
            flex-direction: column;
            color: ${lineChartConst.tooltipTextColor};
          "
        >
          <div style="color: ${lineChartConst.tooltipTitleColor}; margin-bottom: 16px">${occupancyRateData[params.dataIndex]?.date}</div>
          <div style="margin-bottom: 8px">入住率&nbsp;&nbsp;${Number(params?.value) || 0}%</div>
          <div style="margin-bottom: 8px">入住房间&nbsp;&nbsp;${occupancyRateData[params.dataIndex]?.orderCount || 0}</div>
          <div>总房间数&nbsp;&nbsp;${occupancyRateData[params.dataIndex]?.roomCount || 0}</div>
        </div>
          `;
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false, // 数值起始和结束两端空白策略
        // x 轴分割线
        splitLine: {
          show: true, // 显示 x 轴分隔线
          interval: (index: number, _: string) => {
            if (index % 3 === 0) {
              return true;
            }
            return false;
          },
          lineStyle: {
            color: lineChartConst.splitLineColor, // 分隔线颜色
            width: 1, // 分隔线宽度
            type: "solid", // 分隔线类型
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: lineChartConst.axisLineColor, // 设置 x 轴坐标轴线的颜色
            width: 1, // 设置 x 轴坐标轴线的宽度（可选）
          },
        },
        axisLabel: {
          interval: (index: number) => {
            // x轴坐标每隔 3 个标签显示一次（从 0 开始计数）
            if (
              index % 3 === 0 ||
              occupancyRateData.length - 1 === index ||
              index === todayDataIndex
            ) {
              return true;
            }
            return false;
          },
          color: function (value: string) {
            // 今日坐标显示主色
            if (value === dayjs(new Date()).format("MM/DD")) {
              return lineChartConst.primaryColor;
            }
            return lineChartConst.axisLabelColor;
          },
        },
        data: occupancyRateData.map((item) => item.label),
      },
      yAxis: {
        type: "value",
        // y 轴分割线
        splitLine: {
          show: true,
          lineStyle: {
            color: lineChartConst.splitLineColor,
            width: 1,
            type: "dashed",
          },
        },
        axisLabel: {
          color: lineChartConst.axisLabelColor,
          formatter: function (value: number) {
            if (value === 0) {
              return value;
            }
            return `${value}%`;
          },
        },
      },
      series: [
        {
          data: occupancyRateData.map((item) => item.value),
          type: "line",
          lineStyle: {
            color: lineChartConst.primaryColor,
            width: 4,
          },
          // 折线图上的小圆点
          symbol: `image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIuMDAwMDAwIiBoZWlnaHQ9IjEyLjAwMDAwMCIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KCTxkZXNjPgoJCQlDcmVhdGVkIHdpdGggUGl4c28uCgk8L2Rlc2M+Cgk8ZGVmcy8+Cgk8Y2lyY2xlIGlkPSLmpK3lnIYgNCIgY3g9IjYuMDAwMDAwIiBjeT0iNi4wMDAwMDAiIHI9IjYuMDAwMDAwIiBmaWxsPSIjNkY5RjBBIiBmaWxsLW9wYWNpdHk9IjEuMDAwMDAwIi8+Cgk8Y2lyY2xlIGlkPSLmpK3lnIYgNCIgY3g9IjYuMDAwMDAwIiBjeT0iNi4wMDAwMDAiIHI9IjUuNTAwMDAwIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS1vcGFjaXR5PSIxLjAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIxLjAwMDAwMCIvPgo8L3N2Zz4K`,
          symbolSize: 12,
          showSymbol: false,
          smooth: true,
          // 今日标记点
          markPoint: {
            symbol: `image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOC4wMDAwMDAiIGhlaWdodD0iOC4wMDAwMDAiIHZpZXdCb3g9IjAgMCA4IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgoJPGRlc2M+CgkJCUNyZWF0ZWQgd2l0aCBQaXhzby4KCTwvZGVzYz4KCTxkZWZzLz4KCTxjaXJjbGUgaWQ9IuakreWchiA1IiBjeD0iNC4wMDAwMDAiIGN5PSI0LjAwMDAwMCIgcj0iNC4wMDAwMDAiIGZpbGw9IiM2RjlGMEEiIGZpbGwtb3BhY2l0eT0iMS4wMDAwMDAiLz4KCTxjaXJjbGUgaWQ9IuakreWchiA1IiBjeD0iNC4wMDAwMDAiIGN5PSI0LjAwMDAwMCIgcj0iMy41MDAwMDAiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLW9wYWNpdHk9IjEuMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEuMDAwMDAwIi8+Cjwvc3ZnPgo=`,
            symbolSize: 8,
            data: [
              {
                name: "当日入住率",
                coord: [
                  occupancyRateData[todayDataIndex]?.label ?? "",
                  `${occupancyRateData[todayDataIndex]?.value}`,
                ],
              },
            ],
          },
          // 今日标记线
          markLine: {
            lineStyle: {
              color: lineChartConst.primaryColor,
              width: 2,
              type: "solid",
            },
            symbol: "none",
            data: [
              [
                {
                  coord: [todayDataIndex, markLineStart],
                },
                {
                  coord: [todayDataIndex, markLineEnd],
                },
              ],
            ],
          },
          // 折线图渐变
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: lineChartConst.linearGradientStartColor,
              },
              {
                offset: 1,
                color: lineChartConst.linearGradientEndColor,
              },
            ]),
          },
        },
      ],
    };

    return opt;
  }, [occupancyRateData, todayDataIndex]);

  if (!occupancyRateData.length && !loading) {
    return <div className="grid h-[250px] place-content-center">暂无数据</div>;
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      showLoading={loading}
      loadingOption={{
        color: lineChartConst.primaryColor,
      }}
      lazyUpdate={true}
      style={{ height: "250px", width: "100%" }}
    />
  );
};

export const OccupancyRateCard = () => {
  const { todayDataIndex, occupancyRateData, loading } = useOccupancyRate();

  return (
    <WorkbenchItemCard className="w-0 flex-1">
      <WorkbenchItemTitle>入住率</WorkbenchItemTitle>
      <OccupancyRateLineChart
        occupancyRateData={occupancyRateData}
        todayDataIndex={todayDataIndex}
        loading={loading}
      />
    </WorkbenchItemCard>
  );
};
