import { DatePicker, type FormInstance } from "@arco-design/web-react";
import dayjs from "dayjs";
import { type FC } from "react";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { required } from "@/utils/validator";
import { FastSaveFormItem } from "@/comps/FastSaveFormItem";
import { OrderSourceInput } from "../BaseEditor/OrderSourceInput";
import { orderUpdateFastSave } from "../utils";
import { OrderRoomFeeInput } from "./OrderRoomFeeInput";
import { MoneyCurrencyInput } from "@/common/Inputer/MoneyCurrencyInput";
import { permissionHas } from "@/store/useUserInfo";
import { CompactFormItem } from "@/common/CompactFormItem";

export const BookEditor: FC<{
  isEdit: boolean;
  orderId?: string;
  form: FormInstance;
  otaOutOrderId?: string;
}> = ({ isEdit, orderId, form, otaOutOrderId }) => (
  <>
    <FastSaveFormItem
      field="orderSource"
      label="渠道来源"
      onSubmit={(val) =>
        orderUpdateFastSave(
          {
            order: {
              orderSourceId: val.kind,
              orderSourceKey: val.key,
            },
          },
          orderId,
        )
      }
      debounceWait="fast"
      fastSaveMode={isEdit}
      form={form}
    >
      <OrderSourceInput otaOutOrderId={otaOutOrderId} />
    </FastSaveFormItem>

    {permissionHas(ApiPermission.OrderAdvancedView) ? (
      <FastSaveFormItem
        field="roomFeeGroup"
        label="订单金额"
        onSubmit={(val) =>
          orderUpdateFastSave(
            {
              order: {
                roomFeeCurrency: val.currency,
                roomFee: val.roomFeeAmount,
                roomFeeWalletId: val.roomFeeWalletId,
                otaFee: val.otaFeeAmount,
                otaFeeWalletId: val.otaFeeWalletId,
                otaFeeWalletExist: val.otaFeeWalletId !== undefined,
                handlingFee: val.handlingFee,
              },
            },
            orderId,
          )
        }
        debounceWait="fast"
        fastSaveMode={isEdit}
        form={form}
      >
        <OrderRoomFeeInput />
      </FastSaveFormItem>
    ) : (
      <CompactFormItem
        form={form}
        fieldMap={{
          amount: "roomFeeGroup.roomFeeAmount",
          currency: "roomFeeGroup.currency",
        }}
        field="_roomFeeGroup_roomFeeAmountCurrency"
        label="房费"
        disabled={true}
      >
        <MoneyCurrencyInput />
      </CompactFormItem>
    )}

    <FastSaveFormItem
      field="bookAt"
      label="订房日期"
      rules={[required("订房日期")]}
      formatter={(value) =>
        value ? dayjs.unixStr(value as string).dateStr() : undefined
      }
      normalize={(value) => dayjs.dateStr(value as string).unixStr()}
      onSubmit={(val) =>
        orderUpdateFastSave(
          {
            order: {
              bookAt: val,
            },
          },
          orderId,
        )
      }
      debounceWait="fast"
      fastSaveMode={isEdit}
      form={form}
    >
      <DatePicker
        className="w-full"
        format="YYYY-MM-DD"
        allowClear={false}
        triggerProps={{
          containerScrollToClose: true,
        }}
      />
    </FastSaveFormItem>
  </>
);
