/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type OtaPlatformKind } from "../http_pms/const/const_enum";

export interface ImConv {
  id: string;
  // 如果是ota消息的话是roomId, admin消息就是组织id
  authId: string;
  // 如果是ota的会话 outId=accountId+"/"+外部会话id
  outId: string;
  // 扩展信息
  extra: ImConvExtra;
  // pms参与方 组织信息
  pmsEntrant: ImUser;
  // 创建人 ota用户信息
  outEntrant: ImUser;
  // 最后一条消息
  lastMsg: ImMsg;
  // 非pms消息ids,上报的消息id在这里存的话 删除小于等于上报消息id的id
  pmsUnreadMsgIds: string[];
  // pms侧收藏标记 @bool
  pmsMark: boolean;
  // 创建时间 @uint64
  createdAt: string;
  //  @uint64
  lastSentAt: string;
}

// 扩展信息中的订单信息 通过ota发送mq更新
export interface ImConvExtra {
  // ota平台
  otaPlatformKind?: OtaPlatformKind;
  // 账号id
  accountId?: string;
  // 房间id
  roomId?: string;
  // 订单id
  orderId?: string;
  // 入住日期
  startDate?: string;
  // 退房日期
  endDate?: string;
  // 房客数量 @uint32
  tenantCount?: number;
  // 订单更新时间 @uint64
  orderUpdateAt?: string;
  // 语言
  lang?: string;
}

// This the module of ImUser
export namespace ImUser {
  export enum Kind {
    // 系统消息
    System = 0,
    Pms = 1,
    OtaGuest = 2,
    Admin = 3,
    OtaHost = 4,
  }
}
export interface ImUser {
  // id
  id: string;
  // 昵称
  nickname: string;
  // 头像
  avatar?: string;
  // 用户来源 放在header上 ota只监听 kind=0的数据
  kind: ImUser.Kind;
}

// This the module of ImEvent
export namespace ImEvent {
  export enum Kind {
    Unknown = 0,
    // 新消息   convId msg (PMS覆盖sender、sentAt、id，id在pms用mongo生成，不信任前端传入)
    NewMsg = 1,
    // 已读上报 convId msgId
    Read = 2,
    // 收藏会话 convId isPmsMark
    PmsMark = 3,
    // 更新会话 convId (需要通过convId更新会话信息)
    UpdateConv = 4,
    // 更新消息 convId msg
    UpdateMsg = 5,
  }
} // Event
export interface ImEvent {
  // 发送方创建traceId，
  traceId: string;
  kind: ImEvent.Kind;
  // 会话id
  convId?: string;
  // 消息id
  msgId?: string;
  // 消息
  msg?: ImMsg;
  // pms收藏 @bool
  isPmsMark?: boolean;
}

// This the module of ImMsg
export namespace ImMsg {
  export enum Kind {
    // 文本消息
    Text = 0,
    // 图片消息（网址，http开头是外部图片，不是http开头的就是内部图片从storage取，发给ota的话从storage走rpc接口取）
    Image = 1,
    // 订单预定消息
    OrderBook = 2,
    // 订单取消消息
    OrderCancel = 3,
    // 订单更变信息
    OrderUpdate = 4,
    // 已处理
    Dealed = 101,
  }
}
export interface ImMsg {
  id: string;
  outId: string;
  // 消息内容
  content: string;
  // 消息类型
  kind: ImMsg.Kind;
  // 发送者
  sender: ImUser;
  // 发送时间 @uint64
  sentAt: string;
  // 送达时间 null-发送未果；0-发送失败；时间戳-发送成功实践 @uint64
  deliverAt?: string;
}
