/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type BillKind, type ShareBill, type SubBill } from "./bill_type";
import {
  type DateRange,
  type TimeRange,
  type CurrencyAmount,
  type RemarkContent,
  type Image,
} from "../common/common_type";
import { type Currency } from "../const/const_enum";

export namespace BillService {
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/BillService_Get", req, config);
  }

  export function ListByCond(req: ListByCondReq, config?: any) {
    return webapi.post<ListByCondResp>(
      "/api/v1/BillService_ListByCond",
      req,
      config,
    );
  }

  export function ListTotalAmountByCond(req: ListByCondReq, config?: any) {
    return webapi.post<ListTotalAmountByCondResp>(
      "/api/v1/BillService_ListTotalAmountByCond",
      req,
      config,
    );
  }

  export function CountBillIdsByCond(req: CountBillIdsByCondReq, config?: any) {
    return webapi.post<CountBillIdsByCondResp>(
      "/api/v1/BillService_CountBillIdsByCond",
      req,
      config,
    );
  }

  export function Create(req: CreateReq, config?: any) {
    return webapi.post<CreateResp>("/api/v1/BillService_Create", req, config);
  }

  export function Update(req: UpdateReq, config?: any) {
    return webapi.post<UpdateResp>("/api/v1/BillService_Update", req, config);
  }

  export function BatchDel(req: BatchDelReq, config?: any) {
    return webapi.post<BatchDelResp>(
      "/api/v1/BillService_BatchDel",
      req,
      config,
    );
  }

  export function BatchConfirm(req: BatchConfirmReq, config?: any) {
    return webapi.post<BatchConfirmResp>(
      "/api/v1/BillService_BatchConfirm",
      req,
      config,
    );
  }

  export function ListDepositByRoomId(
    req: ListDepositByRoomIdReq,
    config?: any,
  ) {
    return webapi.post<ListDepositByRoomIdResp>(
      "/api/v1/BillService_ListDepositByRoomId",
      req,
      config,
    );
  }
}
// 未确认账单：confirmAt时间为空且receiptDate小于等于所选结算月份的账单 （确认操作之后只更新未确认账单item、刷新追加账单tab）
// 追加账单：所有未结算的账单（settleMonth为空）并且 confirmAt 不为空 并且receiptDate小于等于所选结算月份的上个月底账单
// ListByCond
export interface ListByCondReq {
  kinds: BillKind[];
  // 找出shareId后，再查
  roomIds: string[];
  orderIds: string[];
  walletIds: string[];
  orderKey?: string;
  // 费用产生日期区间
  receiptDate?: DateRange;
  // 入账日期
  postingDate?: DateRange;
  // 确认日期区间
  confirmAt?: TimeRange;
  // 录入日期区间
  createdAt?: TimeRange;
  // 结算月份
  settleMonth?: DateRange;
  // 当前页码 @uint32
  current?: number;
  // 每页数量 @uint32
  pageSize?: number;
}

export interface ListByCondResp {
  bills: ShareBill[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
  // 记录总数 @uint32
  total: number;
}

export interface CountBillIdsByCondReq {
  kinds: BillKind[];
  // 找出billId
  roomIds: string[];
  // 费用产生日期区间
  receiptDate: DateRange;
  // 确认日期区间
  confirmAt: TimeRange;
}

export interface CountBillIdsByCondResp {
  //  @uint32
  count: number;
}

export interface ListDepositByRoomIdReq {
  roomIds: string[];
  //  @bool
  onlyUnRefundCount?: boolean;
  // 当前页码 @uint32
  current?: number;
  // 每页数量 @uint32
  pageSize?: number;
}

export interface ListDepositByRoomIdResp {
  bill: ShareBill[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
  // 记录总数 @uint32
  total: number;
}

export interface ListTotalAmountByCondResp {
  totalAmount: CurrencyAmount[];
}

// Get
export interface GetReq {
  shareId: string;
}

export interface GetResp {
  bill: ShareBill;
}

// Create
export interface CreateReq {
  bill: ShareBill;
}

export interface CreateResp {
  bill: ShareBill;
}

// Update
export interface UpdateReq {
  shareId: string;
  // 订单页面的帐单tab，如果subBills>1个，则不能编辑删除操作
  subBills: SubBill[];
  billKind?: BillKind;
  walletId?: string;
  currency?: Currency;
  receiptDate?: string;
  postingDate?: string;
  remark?: RemarkContent;
  images: Image[];
}

export interface UpdateResp {
  bill: ShareBill;
}

// BatchDel
export interface BatchDelReq {
  shareIds: string[];
}

export interface BatchDelResp {
  //  @uint32
  rows: number;
}

// BatchConfirm
export interface BatchConfirmReq {
  shareIds: string[];
  //  @bool
  isCancel: boolean;
}

export interface BatchConfirmResp {
  bill: ShareBill[];
}
