import { create } from "zustand";

import { RoomService } from "@api/http_pms/room/room_srv";
import { type RoomSimple } from "@api/http_pms/room/room_type";
import { groupBy } from "lodash-es";

interface RoomInfoStore {
  // rooms、roomsMapGroupId 只有未删除房间
  rooms: RoomSimple[];
  roomsMapGroupId: Record<string, RoomSimple[]>;
  // 包含删除的房间
  roomMapId: Record<string, RoomSimple>;
  roomListWithClosed: RoomSimple[];
  roomsWithClosedMapGroupId: Record<string, RoomSimple[]>;
  // 某些房间修改后，更新数据。（房间删除后，传入id同样适用）
  refreshById: (...ids: string[]) => Promise<void>;
  // 某些分组后，更新数据
  refreshByGroupId: (...groupIds: string[]) => Promise<void>;
  // 更新全部房间数据
  refreshAll: () => Promise<void>;
}

export const useRoomInfo = create<RoomInfoStore>((set) => ({
  rooms: [],
  roomListWithClosed: [],
  roomsMapGroupId: {},
  roomMapId: {},
  roomsWithClosedMapGroupId: {},
  refreshById: async (...ids) => {
    const { rooms: _rooms } = await RoomService.List({ ids, groupIds: [] });
    set(({ roomListWithClosed }) => {
      const idSet = new Set(ids);
      const newRooms = roomListWithClosed.filter((r) => !idSet.has(r.id));
      newRooms.push(..._rooms);
      newRooms.sort((a, b) =>
        b.rank === a.rank ? a.id.localeCompare(b.id) : b.rank - a.rank,
      );
      const noDeleteRooms = newRooms.filter((r) => !r.deletedAt);
      return {
        rooms: noDeleteRooms,
        roomsMapGroupId: groupBy(noDeleteRooms, "groupId"),
        roomMapId: Object.fromEntries(newRooms.map((r) => [r.id, r])),
        roomListWithClosed: newRooms,
        roomsWithClosedMapGroupId: groupBy(newRooms, "groupId"),
      };
    });
  },

  refreshByGroupId: async (...groupIds) => {
    const { rooms: _rooms } = await RoomService.List({
      ids: [],
      groupIds,
    });
    set(({ roomListWithClosed }) => {
      const groupIdSet = new Set(groupIds);
      const newRooms = roomListWithClosed.filter(
        (r) => !groupIdSet.has(r.groupId),
      );
      newRooms.push(..._rooms);
      newRooms.sort((a, b) =>
        b.rank === a.rank ? a.id.localeCompare(b.id) : b.rank - a.rank,
      );
      const noDeleteRooms = newRooms.filter((r) => !r.deletedAt);
      return {
        rooms: noDeleteRooms,
        roomsMapGroupId: groupBy(noDeleteRooms, "groupId"),
        roomMapId: Object.fromEntries(newRooms.map((r) => [r.id, r])),
        roomListWithClosed: newRooms,
        roomsWithClosedMapGroupId: groupBy(newRooms, "groupId"),
      };
    });
  },

  refreshAll: async () => {
    const { rooms: newRooms } = await RoomService.List({
      ids: [],
      groupIds: [],
      all: true,
    });
    set(() => {
      newRooms.sort((a, b) =>
        b.rank === a.rank ? a.id.localeCompare(b.id) : b.rank - a.rank,
      );
      const noDeleteRooms = newRooms.filter((r) => !r.deletedAt);
      return {
        rooms: noDeleteRooms,
        roomsMapGroupId: groupBy(noDeleteRooms, "groupId"),
        roomMapId: Object.fromEntries(newRooms.map((r) => [r.id, r])),
        roomListWithClosed: newRooms,
        roomsWithClosedMapGroupId: groupBy(newRooms, "groupId"),
      };
    });
  },
}));
