import React from "react";
import clsx from "clsx";

export const WorkbenchItemCard = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx("rounded-xl bg-white p-6", className)} {...props}>
    {children}
  </div>
);
