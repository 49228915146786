import dayjs, { type PluginFunc } from "dayjs";

declare module "dayjs" {
  export function unixStr(time: string): Dayjs;
  export function dateStr(time: string): Dayjs;
  interface Dayjs {
    unixStr: () => string;
    dateStr: (
      /** 加上小时分钟信息 */
      hasTime?: boolean,
      /** 忽略年份信息（仅和系统时间同年份） */
      ignoreYear?: boolean,
      /** 忽略日期信息（仅和系统时间同日期） */
      ignoreDate?: boolean,
    ) => string;
    mapUntil: <T>(
      until: Dayjs,
      type: ManipulateType,
      fn: (d: Dayjs) => T,
    ) => T[];
  }
}

export const customDayjsPlugin: PluginFunc = (_, dayjsClass, dayjsFactory) => {
  dayjsFactory.unixStr = (time: string) => dayjsFactory.unix(parseInt(time));
  dayjsClass.prototype.unixStr = function () {
    return this.unix().toString();
  };
  dayjsClass.prototype.mapUntil = function (until, type, fn) {
    const res = [];
    for (let cur = this.clone(); !cur.isAfter(until); cur = cur.add(1, type)) {
      res.push(fn(cur));
    }
    return res;
  };

  dayjsFactory.dateStr = (time: string) => {
    if (/^\d{4}/.test(time)) {
      dayjsFactory(time);
    } else {
      dayjsFactory(`${dayjs().year()}-${time}`);
    }
    return dayjsFactory(time);
  };
  dayjsClass.prototype.dateStr = function (
    hasTime = false,
    ignoreYear = false,
    ignoreDate = false,
  ) {
    const format = hasTime ? ["HH:mm"] : [];
    if (!dayjs().isSame(this, "y") || !ignoreYear) {
      format.unshift("YYYY-MM-DD");
    } else if (!dayjs().isSame(this, "date") || !ignoreDate) {
      format.unshift("MM-DD");
    }
    return this.format(format.join(" "));
  };
};

// export const mapDayRange = (startDay: Dayjs, endDay: Dayjs) => {};
