import { type Image as ApiImage } from "@api/http_pms/common/common_type";
import { TenantService } from "@api/http_pms/tenant/tenant_srv";
import {
  type Nationality as ApiNationality,
  type Tenant,
} from "@api/http_pms/tenant/tenant_type";
import { Button, Form, Message, Modal } from "@arco-design/web-react";
import { useRef } from "react";
import { createNiceModal } from "@/utils/nicemodel";
import { AsyncButton } from "@/comps/AsyncButton";
import { DeletePopconfirm } from "@/comps/DeletePopconfirm";
import { EditFormItem } from "./EditFormItem";
import { IsFileUploading } from "@/common/FileUploadList";

export interface TenantEditor {
  id: string;
  orderId: string;
  name: string;
  email: string;
  sex: Tenant.Sex;
  birthday: string;
  profession: string;
  nationality: ApiNationality;
  address: string;
  tel: {
    countryCode: string;
    phoneNumber: string;
  };
  images: ApiImage[];
}

export const openEditor = createNiceModal<
  {
    orderId?: string;
    tenant?: Tenant;
  }, // 调用的入参参数
  {
    tenant?: Tenant;
  } // 调用的出参参数
>(({ _modal, orderId, tenant }) => {
  const isAdd = !tenant;
  const [form] = Form.useForm();

  const fileUploading = useRef(false);

  return (
    <Modal
      title={`${isAdd ? "添加" : "编辑"}房客信息`}
      {..._modal.props} // 需要解构给Modal组件
      maskClosable={!isAdd}
      footer={
        <div className="flex w-full items-center justify-end">
          {!isAdd && (
            <DeletePopconfirm
              title="确定删除此房客吗？"
              onOk={async () => {
                if (tenant && orderId) {
                  await TenantService.BatchDel({
                    ids: [tenant.id],
                  });
                  Message.success("删除成功");
                }
                // 删除配置
                _modal.resolve({});
              }}
            >
              <Button type="outline" status="danger">
                删除
              </Button>
            </DeletePopconfirm>
          )}

          <AsyncButton
            type="primary"
            onClick={async () => {
              const values: TenantEditor = await form.validate();
              if (fileUploading.current) {
                Message.error("附件未上传完成");
                return;
              }
              const finalValues: Tenant = {
                ...values,
                countryCode: values?.tel?.countryCode,
                phoneNumber: values?.tel?.phoneNumber,
              };
              if (orderId) {
                // 添加
                if (isAdd) {
                  const res = await TenantService.BatchCreate({
                    tenants: [
                      {
                        ...finalValues,
                        id: "",
                        orderId,
                      },
                    ],
                  });
                  Message.success("创建成功");
                  if (res.tenants[0]) {
                    _modal.resolve({ tenant: res.tenants[0] });
                  }
                } else {
                  // 编辑
                  const res = await TenantService.Update({
                    tenant: finalValues,
                  });
                  Message.success("编辑成功");
                  if (res) {
                    _modal.resolve({ tenant: res.tenant });
                  }
                }
              } else {
                _modal.resolve({ tenant: finalValues });
              }

              _modal.resolve({});
            }}
            className="ml-3"
          >
            保存
          </AsyncButton>
        </div>
      }
      className="w-[640px]"
    >
      <IsFileUploading onUploadingChange={(s) => (fileUploading.current = s)}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            isAdd
              ? {}
              : {
                  ...tenant,
                  tel: {
                    countryCode: tenant.countryCode,
                    phoneNumber: tenant.phoneNumber,
                  },
                }
          }
          size="large"
          className="max-h-[60vh] overflow-y-auto overflow-x-hidden"
        >
          <EditFormItem />
        </Form>
      </IsFileUploading>
    </Modal>
  );
});
