import { Link, Select, type SelectProps } from "@arco-design/web-react";
import { type FC, useMemo } from "react";
import { type OtaPlatformKind as ApiOtaPlatformKind } from "@api/http_pms/const/const_enum";
import { IconPlus } from "@arco-design/web-react/icon";
import { OtaPlatformKind } from "@/common/const";
import { useOrderSource } from "@/store/useOrderSource";
import { openOrderSourceCreator } from "@/pages/Setting/RightSettingForm/openOrderSourceCreator";
import { toLowerCaseSearch } from "@/utils/toLowerCaseSearch";

export const OrderSourceKindSelector: FC<
  SelectProps & {
    type?: "search" | "input";
    limitOtaPlatformKinds?: Set<ApiOtaPlatformKind>;
    onChange?: (v: string) => void;
  }
> = ({ value, onChange, type = "input", limitOtaPlatformKinds, ...props }) => {
  const { orderSourceList, orderSourceMapId, refresh } =
    useOrderSource.getState();

  const finalOptions = useMemo(() => {
    const finalOrderSourceList = [orderSourceMapId[""]!, ...orderSourceList];
    if (type === "search" || value === "reservation") {
      finalOrderSourceList.splice(1, 0, orderSourceMapId.reservation!);
    }
    return finalOrderSourceList.map((item) => ({
      value: item.id,
      label: (
        <div className="flex items-center">
          <img
            src={OtaPlatformKind[item.otaPlatformKind].iconUrlSquare}
            className="mr-2 size-5"
          />
          {item.name}
        </div>
      ),
      disabled:
        limitOtaPlatformKinds &&
        !limitOtaPlatformKinds?.has(item.otaPlatformKind),
    }));
  }, [orderSourceList, orderSourceMapId, type]);

  return (
    <Select
      placeholder="请选择"
      options={finalOptions}
      value={value}
      onChange={onChange}
      showSearch={true}
      filterOption={(inputValue, option) =>
        toLowerCaseSearch(String(option.props.children), inputValue)
      }
      triggerProps={{
        style: { minWidth: 160 },
        position: "bl",
        containerScrollToClose: true,
      }}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <div className="flex w-full justify-center py-2">
            <Link
              icon={<IconPlus />}
              onClick={() => {
                void openOrderSourceCreator({
                  afterSubmit: async (config) => {
                    await refresh();
                    if (config?.id) {
                      onChange?.(config.id);
                    }
                  },
                  limitOtaPlatformKinds,
                });
              }}
            >
              新增自定义渠道
            </Link>
          </div>
        </div>
      )}
      {...props}
    />
  );
};
