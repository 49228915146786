import { create } from "zustand";

import { WalletService } from "@api/http_pms/wallet/wallet_srv";
import { type WalletPure } from "@api/http_pms/wallet/wallet_type";
import { type Option } from "@/common/const/interface";

interface WalletStore {
  walletOptions: Option[];
  walletMap: Record<string, WalletPure>;
  walletRefresh: () => Promise<void>;
  walletSet: (wallets: WalletPure[]) => void;
  walletFetch: () => Promise<WalletPure[]>;
}

export const useWallet = create<WalletStore>((set) => {
  const walletFetch = async () => {
    const res = await WalletService.AllPure({});
    return res.wallets;
  };

  const walletSet = (wallets: WalletPure[]) => {
    const newWallets = wallets.map((wallet) => ({
      ...wallet,
      name: wallet.id ? wallet.name : "默认钱包",
    }));

    set({
      walletMap: Object.fromEntries(
        newWallets.map((wallet) => [wallet.id, wallet]),
      ),
      walletOptions: newWallets.map((wallet) => ({
        label: wallet.name,
        value: wallet.id,
      })),
    });
  };

  return {
    walletList: [],
    walletMap: {},
    walletOptions: [],
    walletSet,
    walletFetch,
    walletRefresh: async () => {
      const wallets = await walletFetch();
      walletSet(wallets);
    },
  };
});
