import { UserService } from "@api/http_pms/user/user_srv";
import { Popconfirm, Trigger } from "@arco-design/web-react";
import { IconPoweroff, IconRight } from "@arco-design/web-react/icon";
import clsx from "clsx";
import { type FC, type ReactNode } from "react";
import { useUserInfo } from "@/store/useUserInfo";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
import { IconFont } from "@/common/IconFont";
import { Avatar } from "@/comps/Avatar";
import { openUserCenterModal } from "./UserCenter/openUserCenterModal";

export const User: FC = () => {
  const { userInfo } = useUserInfo();

  return (
    <Trigger
      trigger="click"
      popupAlign={{
        bottom: [0, 5],
      }}
      unmountOnExit={true}
      popup={() => (
        <div className="w-40 rounded-lg bg-white py-3 shadow-[0_2px_8px_0px_rgba(0,0,0,0.15)]">
          <div className="mb-2 flex flex-col items-center px-3">
            <Avatar
              className="size-[36px] text-base"
              name={userInfo.nickname}
            />
            <div className="mt-3 font-medium">{userInfo.nickname}</div>
            <div className="text-color-text-2 mt-2">
              <OverflowTooltip>{userInfo.accountName}</OverflowTooltip>
            </div>
          </div>
          <EasMenuItem
            onClick={() => {
              void openUserCenterModal({});
            }}
          >
            <div>
              <IconFont type="icon-user" className="mr-3" />
              个人中心
            </div>
            <IconRight />
          </EasMenuItem>
          <Popconfirm
            title="确定要退出登录？"
            onOk={async () => {
              await UserService.SignOut({});
            }}
            okText="退出"
            position="left"
          >
            <EasMenuItem className="text-color-danger-6">
              <div>
                <IconPoweroff className="mr-3" />
                退出登录
              </div>
            </EasMenuItem>
          </Popconfirm>
        </div>
      )}
      position="br"
    >
      <div className="flex cursor-pointer items-center">
        <Avatar className="size-[36px] text-base" name={userInfo.nickname} />
        <div className="ml-2 mr-[7px]">{userInfo.nickname}</div>
        <IconRight className={clsx("w-5 rotate-90 cursor-pointer")} />
      </div>
    </Trigger>
  );
};

const EasMenuItem: FC<{
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}> = ({ children, onClick, className }) => (
  <div
    className={clsx(
      "hover:bg-color-fill-2 px-4",
      {
        "cursor-pointer": Boolean(onClick),
      },
      className,
    )}
    onClick={() => {
      onClick?.();
    }}
  >
    <div className={clsx("flex h-12 items-center justify-between")}>
      {children}
    </div>
  </div>
);
