import { create } from "zustand";

import { CommonService } from "@api/http_pms/common/common_srv";
import { type Tag } from "@api/http_pms/common/common_type";
import { type EntityKind } from "@api/http_pms/const/const_enum";
import { useCallback } from "react";
import { useCreation } from "ahooks";
import { type Option } from "@/common/const/interface";

export interface TagStore {
  tagsData: {
    [k in EntityKind]?: {
      tagList: Tag[];
      tagMap: Record<string, Tag>;
      tagOptions: Option[];
    };
  };
  /** 传 null 代表更新所有 TagKind (不用 undefined 是为了防止漏传) */
  tagRefresh: (entityKind: EntityKind | null) => Promise<void>;
}

const newEmptyTagData = (): Exclude<
  TagStore["tagsData"][EntityKind],
  undefined
> => ({
  tagList: [],
  tagOptions: [],
  tagMap: {},
});

export const useAllTag = create<TagStore>((set) => ({
  tagsData: {},
  tagRefresh: async (entityKind) => {
    const { tags } = await CommonService.AllTag({
      entityKind: entityKind ?? undefined,
    });

    const entityKinds = new Set(tags.map((t) => t.entityKind));
    entityKind != null && entityKinds.add(entityKind);
    const newTagMap: TagStore["tagsData"] = Object.fromEntries(
      [...entityKinds].map((k) => [k, newEmptyTagData()]),
    );

    tags.forEach((tag) => {
      newTagMap[tag.entityKind]!.tagList.push(tag);
      newTagMap[tag.entityKind]!.tagOptions.push({
        label: tag.name,
        value: tag.id,
      });
      newTagMap[tag.entityKind]!.tagMap[tag.id] = tag;
    });

    set((s) => ({
      tagsData: {
        ...(entityKind === null ? {} : s.tagsData),
        ...newTagMap,
      },
    }));
  },
}));

export const useTag = (entityKind: EntityKind) => {
  const emptyTagData = useCreation(newEmptyTagData, []);
  const { tag, originTagRefresh } = useAllTag((t) => ({
    tag: t.tagsData[entityKind] || emptyTagData,
    originTagRefresh: t.tagRefresh,
  }));
  const tagRefresh = useCallback(() => originTagRefresh(entityKind), []);
  return { ...tag, tagRefresh };
};
