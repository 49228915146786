import { Grid } from "@arco-design/web-react";
import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import clsx from "clsx";
import { tagShowRule } from "./showRule";

const { Row } = Grid;
const { Col } = Grid;

export const TagChange = ({
  fieldContents,
}: {
  fieldContents: OperateLog.FieldContent[];
}) => {
  const finalShowRules = tagShowRule.filter(
    (rule) =>
      fieldContents.findIndex((content) => content.field === rule.key) !== -1,
  );
  return (
    <>
      {finalShowRules.map((rule) => {
        const content = fieldContents.find(
          (_content) => _content.field === rule.key,
        )!;
        return (
          <TagChangeItem
            key={rule.key}
            field={rule.key}
            title={rule.title}
            current={content.current}
          />
        );
      })}
    </>
  );
};

export const TagChangeItem = ({
  field,
  title,
  current,
}: {
  field: string;
  title: string;
  current?: string;
}) => (
  <Row>
    <Col span={5} className="text-color-text-2 text-right">
      {title}：
    </Col>
    <Col span={19} className={clsx({ "line-through": field === "DeleteTags" })}>
      {current}
    </Col>
  </Row>
);
