import { Button, Grid, Typography } from "@arco-design/web-react";
import { type FC } from "react";
import { CheckInKind, type Order } from "@api/http_pms/order/order_type";
import { OrderService } from "@api/http_pms/order/order_srv";
import clickPage from "@/assets/system/clickPage.svg";
import success from "@/assets/system/success.svg";
import { AsyncButton } from "@/comps/AsyncButton";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
import { getCheckinUrl, getQrUrl } from "@/utils/url";

const Row = Grid.Row;
const Col = Grid.Col;

export const OnlineRegistration: FC<{
  mutateOrderData?: (data: Order) => void;
  initOrderData: Order;
}> = ({ mutateOrderData, initOrderData }) => {
  const url = getCheckinUrl(initOrderData.signedId);
  return (
    <div
      className="flex w-[320px] flex-col items-center rounded-xl bg-white p-4"
      css={`
        box-shadow: 0px 2px 20px 0px rgba(39, 44, 62, 0.16);
      `}
    >
      {initOrderData.checkInKind === CheckInKind.CheckInDefault ? (
        <div className="mt-7 flex flex-col items-center">
          <img src={clickPage} className="w-[136px]" />
          <span className="text-color-text-2 mt-4">点击生成在线登记网页</span>
          <AsyncButton
            type="primary"
            className="mb-7 mt-4"
            onClick={async () => {
              if (!initOrderData.id) return;
              const res = await OrderService.Update({
                order: {
                  checkInKind: CheckInKind.CheckInView,
                  id: initOrderData.id,
                },
              });
              mutateOrderData?.({
                ...initOrderData,
                checkInKind: res.order.checkInKind,
              });
            }}
          >
            生成登记网页
          </AsyncButton>
        </div>
      ) : initOrderData.checkInKind === CheckInKind.CheckInView ? (
        <Row gutter={[12, 16]}>
          <Col span={6}>
            <div className="flex h-8 items-center justify-end">登记地址</div>
          </Col>
          <Col span={18}>
            <div className="bg-color-fill-1 flex h-8 items-center rounded-lg px-2">
              <Typography.Paragraph
                copyable={true}
                css={`
                  &.arco-typography {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                  }
                `}
              >
                <OverflowTooltip>{url}</OverflowTooltip>
              </Typography.Paragraph>
            </div>
          </Col>
          <Col span={6}>
            <div className="flex h-8 items-center justify-end">扫码登记</div>
          </Col>
          <Col span={18}>
            <img
              src={getQrUrl(url, 108)}
              className="block size-[108px] rounded-lg"
            />
            <span className="text-color-text-3 mt-1 text-xs">
              右键点击复制二维码
            </span>
          </Col>
          <Col span={6} />
          <Col span={18}>
            <AsyncButton
              type="primary"
              onClick={async () => {
                if (!initOrderData.id) return;
                const res = await OrderService.Update({
                  order: {
                    checkInKind: CheckInKind.CheckInDefault,
                    id: initOrderData.id,
                  },
                });
                mutateOrderData?.({
                  ...initOrderData,
                  checkInKind: res.order.checkInKind,
                });
              }}
            >
              停用登记网页
            </AsyncButton>
          </Col>
        </Row>
      ) : (
        <div className="mt-7 flex flex-col items-center">
          <img src={success} className="w-[136px]" />
          <span className="text-color-text-2 mt-4">房客填写完成</span>
          <Button
            type="primary"
            className="mb-7 mt-4"
            onClick={async () => {
              if (!initOrderData.id) return;
              const res = await OrderService.Update({
                order: {
                  checkInKind: CheckInKind.CheckInView,
                  id: initOrderData.id,
                },
              });
              mutateOrderData?.({
                ...initOrderData,
                checkInKind: res.order.checkInKind,
              });
            }}
          >
            重新开放登记网页
          </Button>
        </div>
      )}
    </div>
  );
};
