import { Popconfirm, type PopconfirmProps } from "@arco-design/web-react";
import { type ReactElement, type FC } from "react";

export const DeletePopconfirm: FC<
  PopconfirmProps & { children: ReactElement }
> = ({ children, ...props }) => (
  <Popconfirm
    {...props}
    okText="删除"
    okButtonProps={{ status: "danger", type: "outline" }}
    css={`
      .arco-icon-exclamation-circle-fill {
        color: rgb(var(--danger-6));
      }
    `}
  >
    {children}
  </Popconfirm>
);
