import ReactDOM from "react-dom/client";
import { subscribe } from "pubsub-js";
import dayjsDuration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "./main.less";
import { Message } from "@arco-design/web-react";
import { enableMapSet } from "immer";
import * as echarts from "echarts/core";
import {
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  TitleComponent,
  DatasetComponent,
  MarkPointComponent,
  MarkLineComponent,
} from "echarts/components";
import { PieChart, LineChart } from "echarts/charts";
import { SVGRenderer } from "echarts/renderers";

import { UniversalTransition } from "echarts/features";
import App from "./pages/App";
import { customDayjsPlugin } from "./utils/dayjsUtils";
import { blockFindDOMNodeWarm } from "./utils/blockFindDOMNodeWarm";

blockFindDOMNodeWarm();
enableMapSet();

// echarts 初始化
echarts.use([
  // Components
  TitleComponent,
  TooltipComponent,
  AxisPointerComponent,
  DatasetComponent,
  GridComponent,
  MarkPointComponent,
  MarkLineComponent,

  // Transitions
  UniversalTransition,

  // Charts
  PieChart,
  LineChart,

  // Renderer
  SVGRenderer,
]);

dayjs.extend(dayjsDuration);
dayjs.extend(customDayjsPlugin);
dayjs.extend(utc);
dayjs.extend(timezone);

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

subscribe("webapi_net_err", (_, error: string) => {
  Message.error(error);
});
subscribe("webapi_status_401", () => {
  Message.error("登录状态已失效，请重新登录");
  setTimeout(() => {
    window.location.href = "/login";
  }, 1000);
});
subscribe("webapi_status_403", () => {
  Message.error("无权限，请联系管理员");
});

export default App;
