import { Image } from "@arco-design/web-react";
import { useMemo, useState } from "react";
import { makeImageUrl } from "@/utils/fileUpload";
import { createNiceModal } from "@/utils/nicemodel";

export const openImagePreview = createNiceModal<
  {
    imgUrls: string[];
    currentIndex: number;
  }, // 调用的入参参数
  {} // 调用的出参参数
>(
  ({
    _modal, // 注入的控制器，其中的props属性需要丢给实际使用的Modal组件，还有resolve、reject需要手动调用
    imgUrls,
    currentIndex,
  }) => {
    const [current, setCurrent] = useState(currentIndex);

    const srcList = useMemo(
      () => imgUrls.map((url) => makeImageUrl(url, 1440, 900, "l")),
      [imgUrls],
    );

    return (
      <Image.PreviewGroup
        {..._modal.props} // 需要解构给Modal组件
        srcList={srcList}
        onVisibleChange={(visible) => {
          if (!visible) {
            _modal.props.afterClose();
          }
        }}
        current={current}
        onChange={(val) => setCurrent(val)}
        className="z-[9999]"
      />
    );
  },
);
