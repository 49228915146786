import { Button, Message } from "@arco-design/web-react";
import { type FC } from "react";
import { IconPlus } from "@arco-design/web-react/icon";
import { useRequest } from "ahooks";
import { BillService } from "@api/http_pms/bill/bill_srv";
import { type ShareBill } from "@api/http_pms/bill/bill_type";
import { BillKind as ApiBillKind } from "@api/http_pms/bill/bill_type";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { useOrgInfo } from "@/store/useOrgInfo";
import { permissionHas } from "@/store/useUserInfo";
import { checkIsAddId, genAddId } from "@/utils/dealWithId";
import { BillItem } from "./BillItem";

export const BillList: FC<{ orderId: string }> = ({ orderId }) => {
  const { data, mutate } = useRequest(
    async () => {
      const res = await BillService.ListByCond({
        kinds: [],
        roomIds: [],
        orderIds: [orderId],
        walletIds: [],
        current: 1,
        pageSize: 100,
      });
      return res.bills;
    },
    {
      refreshDeps: [orderId],
    },
  );

  return (
    <>
      {permissionHas(ApiPermission.BillUpdate) && (
        <Button
          type="primary"
          icon={<IconPlus />}
          className="mb-4"
          onClick={() => {
            mutate([
              {
                shareId: genAddId(),
                walletId: "",
                subBills: [],
                billKind: ApiBillKind.Unknown,
                currency: useOrgInfo.getState().orgInfo.currency,
                receiptDate: "",
                images: [],
                postingDate: "",
              },
              ...(data || []),
            ]);
          }}
        >
          添加条目
        </Button>
      )}

      {data?.map((item, index) => (
        <BillItem
          key={item.shareId}
          item={item}
          orderId={orderId}
          onOk={async (bill) => {
            let newShareBill: ShareBill;
            // 添加
            if (checkIsAddId(item.shareId)) {
              const res = await BillService.Create({ bill });
              newShareBill = res.bill;
              Message.success("新建成功");
            } else {
              const res = await BillService.Update(bill);
              newShareBill = res.bill;
              Message.success("操作成功");
            }
            const newList = [...(data || [])];
            newList.splice(index, 1, newShareBill);
            mutate(newList);
          }}
          onRemove={async () => {
            if (!checkIsAddId(item.shareId)) {
              await BillService.BatchDel({ shareIds: [item.shareId] });
              Message.success("删除成功");
            }
            const newList = [...(data || [])];
            newList.splice(index, 1);
            mutate(newList);
          }}
        />
      ))}
    </>
  );
};
