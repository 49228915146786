const InCompleteFlag = Symbol("InCompleteFlag");
export const checkImCompletedFlag = (input: any) =>
  input instanceof Object && InCompleteFlag in input;
const setImCompletedFlag = (obj: Object, on: boolean) => {
  if (on) {
    Object.defineProperty(obj, InCompleteFlag, {
      value: true,
      enumerable: false,
      // 不可枚举
      configurable: true, // 可配置
    });
  } else {
    // @ts-expect-error 验证过没问题
    delete obj[InCompleteFlag];
  }
};
const checkRequiredKeys = (
  value: Record<PropertyKey, any>,
  requiredKeys: PropertyKey[],
) =>
  value instanceof Object &&
  requiredKeys.every((key) => value[key] !== undefined);

export const usePartialControllable = <T extends Record<PropertyKey, any>>(
  props: {
    value?: Partial<T>;
    onChange?: ((v?: Partial<T>) => void) | undefined;
  },
  requiredKeys: (keyof T)[],
) => {
  const { value, onChange } = props;
  const currentValue: Partial<T> = value || {};

  const handleCurrentValueChange = (
    type: keyof T,
    val: T[typeof type] | undefined,
  ) => {
    const newValue = {
      ...currentValue,
      [type]: val,
    };
    setImCompletedFlag(newValue, !checkRequiredKeys(newValue, requiredKeys));
    onChange?.(newValue);
  };
  const handleMultiValueAssign = (t: Partial<T>) => {
    const newValue = {
      ...currentValue,
      ...t,
    };
    setImCompletedFlag(newValue, !checkRequiredKeys(newValue, requiredKeys));
    onChange?.(newValue);
  };
  return {
    currentValue,
    handleCurrentValueChange,
    handleMultiValueAssign,
  };
};
