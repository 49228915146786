/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type WalletPure, type Wallet } from "./wallet_type";

export namespace WalletService {
  // 获取所有钱包
  export function AllPure(req: AllPureReq, config?: any) {
    return webapi.post<AllPureResp>(
      "/api/v1/WalletService_AllPure",
      req,
      config,
    );
  }

  // 获取所有钱包
  export function All(req: AllReq, config?: any) {
    return webapi.post<AllResp>("/api/v1/WalletService_All", req, config);
  }

  // 创建钱包
  export function Create(req: CreateReq, config?: any) {
    return webapi.post<CreateResp>("/api/v1/WalletService_Create", req, config);
  }

  // 批量删除
  export function BatchDel(req: BatchDelReq, config?: any) {
    return webapi.post<BatchDelResp>(
      "/api/v1/WalletService_BatchDel",
      req,
      config,
    );
  }

  // 修改钱包
  export function Update(req: UpdateReq, config?: any) {
    return webapi.post<UpdateResp>("/api/v1/WalletService_Update", req, config);
  }
}
// All
export interface AllPureReq {}

export interface AllPureResp {
  wallets: WalletPure[];
}

// All
export interface AllReq {}

export interface AllResp {
  wallets: Wallet[];
}

// Create
export interface CreateReq {
  wallet: Wallet;
}

export interface CreateResp {
  wallet: Wallet;
}

// BatchDel 校验只能删除bill中没有使用到wallet
export interface BatchDelReq {
  // 钱包id
  ids: string[];
}

export interface BatchDelResp {
  //  @uint32
  rows: number;
}

// Update
export interface UpdateReq {
  wallet: Wallet;
}

export interface UpdateResp {
  wallet: Wallet;
}
