import { OrderService } from "@api/http_pms/order/order_srv";
import { CheckInKind, type Order } from "@api/http_pms/order/order_type";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import {
  Collapse,
  Empty,
  Form,
  Link,
  Trigger,
  type FormInstance,
} from "@arco-design/web-react";
import { isEmpty } from "lodash-es";
import { useState, type FC } from "react";
import { permissionHas } from "@/store/useUserInfo";
import { BaseEditor } from "./BaseEditor";
import { BookEditor } from "./BookEditor";
import { TagEditor } from "../../../../common/TagEditor";
import { TenantEditor } from "./TenantEditor";
import { type orderRawToForm } from "./utils";
import { OnlineRegistration } from "./OnlineRegistration";
import { IconFont } from "@/common/IconFont";

export const BaseInfoContent: FC<{
  mutateOrderData?: (data: Order) => void;
  form: FormInstance;
  initFormData?: ReturnType<typeof orderRawToForm>;
  initOrderData?: Order;
}> = ({ mutateOrderData, form, initFormData, initOrderData }) => {
  const orderId = initOrderData?.id;
  const isAdd = !initFormData?.id;
  const [collapseActivityKeys, setCollapseActivityKeys] = useState([
    "1",
    "2",
    "3",
    "4",
  ]);

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={initFormData}
      size="large"
      disabled={!permissionHas(ApiPermission.OrderUpdate)}
    >
      <Collapse
        bordered={false}
        activeKey={collapseActivityKeys}
        triggerRegion="header"
        onChange={(_, keys) => {
          setCollapseActivityKeys(keys);
        }}
      >
        <Collapse.Item name="1" header="基本信息">
          <BaseEditor
            form={form}
            isEdit={!isAdd}
            orderId={orderId}
            mutateOrderData={mutateOrderData}
          />
        </Collapse.Item>

        <Collapse.Item name="2" header="预定信息">
          <BookEditor
            form={form}
            isEdit={!isAdd}
            orderId={orderId}
            otaOutOrderId={initOrderData?.otaOutOrderId}
          />
        </Collapse.Item>

        {permissionHas(ApiPermission.OrderAdvancedView) && (
          <Collapse.Item header="标签信息" name="3">
            {isEmpty(initFormData?.tagIds) &&
              !permissionHas(ApiPermission.OrderSetRemarkAndTag) && (
                <Empty description="暂无标签信息" />
              )}
            <Form.Item
              field="tagIds"
              wrapperCol={{ span: 24 }}
              className="!mb-3"
            >
              <TagEditor
                onSubmit={async (tagIds) => {
                  // 修改
                  if (orderId) {
                    const res = await OrderService.ApplyTag({
                      id: orderId,
                      tagIds,
                    });
                    return res.tagIds;
                  }
                  // 新建
                  return tagIds;
                }}
              />
            </Form.Item>
          </Collapse.Item>
        )}

        <Collapse.Item
          name="4"
          header="房客信息"
          extra={
            initOrderData ? (
              <Trigger
                popup={() => (
                  <OnlineRegistration
                    initOrderData={initOrderData}
                    mutateOrderData={mutateOrderData}
                  />
                )}
                position="br"
                showArrow={true}
              >
                <Link>
                  在线登记
                  {initOrderData.checkInKind === CheckInKind.CheckInView && (
                    <IconFont type="icon-in-progress" className="ml-1" />
                  )}
                  {initOrderData.checkInKind === CheckInKind.CheckInSuper && (
                    <IconFont type="icon-solid-success" className="ml-1" />
                  )}
                </Link>
              </Trigger>
            ) : undefined
          }
        >
          {isEmpty(initFormData?.tenants) &&
            !permissionHas(ApiPermission.OrderUpdate) && (
              <Empty description="暂无房客信息" />
            )}
          <Form.Item field="tenants" wrapperCol={{ span: 24 }}>
            <TenantEditor orderId={orderId} />
          </Form.Item>
        </Collapse.Item>
      </Collapse>
    </Form>
  );
};
