import clsx from "clsx";
import { type TodayOccupancyResp } from "@api/http_pms/dashboard/dashboard";
import { type FC, useMemo } from "react";
import { getTimeFrame } from "./utils";
import { type StatusItem } from "./interface";
import bannerBg from "@/assets/workbench/bannerBg.svg";
import { WorkbenchItemCard } from "./components/WorkbenchItemCard";
import { WorkbenchItemTitle } from "./components/WorkbenchItemTitle";
import { useUserInfo } from "@/store/useUserInfo";
import { todayStatusListSkeletonColumn } from "./const";

interface TodayStatusListProps {
  items: StatusItem[];
}

const TodayStatusList: FC<TodayStatusListProps> = ({ items }) => (
  <div className="flex flex-1 items-center">
    {items.map((item, idx) => (
      <div
        key={item.label}
        className={clsx("relative flex flex-1 flex-col items-center", {
          "border-l border-color-border-1": idx > 0,
        })}
      >
        <div className="text-color-text-1 text-2xl font-medium leading-[34px]">
          {item.value}
        </div>
        <div className="text-color-text-3 text-sm leading-[28px]">
          {item.label}
        </div>
      </div>
    ))}
  </div>
);

interface TodayStatusListSkeletonProps {
  columnNum?: number;
}

const TodayStatusListSkeleton: FC<TodayStatusListSkeletonProps> = ({
  columnNum = todayStatusListSkeletonColumn,
}) => (
  <div className="flex flex-1 items-center gap-12">
    {Array.from({ length: columnNum }).map((_, idx) => (
      <div key={idx} className={clsx("flex flex-1 flex-col px-4")}>
        <div className="bg-color-fill-2 mb-3 h-7 rounded" />
        <div className="bg-color-fill-2 h-5 rounded" />
      </div>
    ))}
  </div>
);

interface Props {
  statusData?: TodayOccupancyResp;
  loading?: boolean;
}

export const TodayStatusCard: FC<Props> = ({ statusData, loading }) => {
  const { userInfo } = useUserInfo();

  const hello = userInfo?.nickname
    ? `${getTimeFrame()}，${userInfo?.nickname} ~`
    : `${getTimeFrame()} ~`;

  const statusItems = useMemo<StatusItem[]>(
    () => [
      {
        label: "入住",
        value: statusData?.todayCheckInCount ?? 0,
      },
      {
        label: "退房",
        value: statusData?.todayCheckOutCount ?? 0,
      },
      {
        label: "换客",
        value: statusData?.todayChangeTenantCount ?? 0,
      },
      {
        label: "空房",
        value: statusData?.todayRemainingCount ?? 0,
      },
      {
        label: "在住",
        value: statusData?.todayStayCount ?? 0,
      },
      {
        label: "预定",
        value: statusData?.todayBookCount ?? 0,
      },
      {
        label: "取消",
        value: statusData?.todayCancelCount ?? 0,
      },
    ],
    [statusData],
  );

  return (
    <WorkbenchItemCard className="relative flex flex-1 flex-col overflow-hidden bg-none">
      <div className="bg-color-primary-6 pointer-events-none absolute inset-0 opacity-10" />
      <img
        src={bannerBg}
        className="absolute -bottom-16 -right-12 h-[200px] object-scale-down"
      />
      <div className="text-color-warning-6 mb-4 text-2xl font-medium">
        {hello}
      </div>
      <WorkbenchItemCard
        className="relative z-[1] flex flex-1 flex-col rounded-2xl bg-white/90 !p-4 backdrop-blur-lg"
        style={{ maxWidth: 168 * statusItems.length }}
      >
        <WorkbenchItemTitle>今日状态</WorkbenchItemTitle>
        {loading ? (
          <TodayStatusListSkeleton />
        ) : (
          <TodayStatusList items={statusItems} />
        )}
      </WorkbenchItemCard>
    </WorkbenchItemCard>
  );
};
