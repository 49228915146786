import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { useMemo } from "react";
import { DashboardService } from "@api/http_pms/dashboard/dashboard";
import { orderBy } from "lodash-es";
import { type OccupancyRateLineChartData } from "./interface";
import { notFoundIndex } from "./const";

export const useOccupancyRate = () => {
  const { data, loading } = useRequest(() =>
    DashboardService.OccupancyRate({}),
  );

  const occupancyRateData = useMemo(
    () =>
      data?.dailyOccupancyRates.map((record) => {
        // Math.max(record.roomCount, 1) 防止除数为0
        const rate = (record.orderCount / Math.max(record.roomCount, 1)) * 100;

        const formatRate =
          Number.isInteger(rate) || rate === 0 ? rate : rate.toFixed(2);

        const item: OccupancyRateLineChartData = {
          label: dayjs(record.date).format("MM/DD"),
          date: dayjs(record.date).format("YYYY/MM/DD"),
          value: `${formatRate}`,
          roomCount: record.roomCount,
          orderCount: record.orderCount,
        };
        return item;
      }) || [],
    [data],
  );

  const todayDataIndex =
    occupancyRateData?.findIndex(
      (el) => el.label === dayjs(data?.currentTime).format("MM/DD"),
    ) || notFoundIndex;

  return { occupancyRateData, todayDataIndex, loading };
};

export const usePercentageOfOTA = () => {
  const { data, loading } = useRequest(() =>
    DashboardService.OtaPlatformProportion({}),
  );

  // top 4
  const breakNumber = 4;

  const sortedData = useMemo(() => {
    const sorted = orderBy(
      data?.otaPlatformProportions,
      ["orderCount"],
      ["desc"],
    );
    return sorted;
  }, [data]);

  const topFourItems = useMemo(() => {
    // 当前数据长度小于 breakNumber 时，直接返回全部
    if (sortedData.length < breakNumber) {
      return sortedData;
    }
    return sortedData.slice(0, breakNumber);
  }, [sortedData]);

  const otherItemsTotal = useMemo(() => {
    // 当前数据长度小于 breakNumber 时，直接返回 0
    if (sortedData.length < breakNumber) {
      return 0;
    }

    const otherItems = sortedData.slice(breakNumber, sortedData.length);
    return otherItems.reduce((pre, cur) => pre + cur.orderCount, 0);
  }, [sortedData]);

  return { loading, topFourItems, otherItemsTotal, sortedData };
};
