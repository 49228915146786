/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type TimeRange } from "../common/common_type";
import {
  type ReviewForList,
  type ReviewContent,
  type Review,
} from "./ota_review_type";

export namespace OtaReviewService {
  // 列表查询
  export function ListByCond(req: ListByCondReq, config?: any) {
    return webapi.post<ListByCondResp>(
      "/api/v1/OtaReviewService_ListByCond",
      req,
      config,
    );
  }

  // 发布评价
  export function Submit(req: SubmitReq, config?: any) {
    return webapi.post<SubmitResp>(
      "/api/v1/OtaReviewService_Submit",
      req,
      config,
    );
  }

  // 回复评价
  export function Respond(req: RespondReq, config?: any) {
    return webapi.post<RespondResp>(
      "/api/v1/OtaReviewService_Respond",
      req,
      config,
    );
  }

  // 评价详情
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/OtaReviewService_Get", req, config);
  }
}
// ListByCond
export interface ListByCondReq {
  // 房间id列表
  roomIds: string[];
  // 评价时间（已公开）
  publishedAt?: TimeRange;
  // 评价时间（未公开）
  createdAt?: TimeRange;
  // 综合评分 @uint32
  overallRatings: number[];
  // 是否公开 @bool
  isPublished: boolean;
  // ids
  ids: string[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
}

export interface ListByCondResp {
  reviews: ReviewForList[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
  // 记录总数 @uint32
  total: number;
}

// Submit
export interface SubmitReq {
  id: string;
  reviewContent: ReviewContent;
}

export interface SubmitResp {}

// Respond
export interface RespondReq {
  id: string;
  respondContent: string;
}

export interface RespondResp {}

export interface GetReq {
  orderId: string;
  //  @bool
  sync?: boolean;
}

export interface GetResp {
  review?: Review;
}
