import { useMemo, type FC } from "react";

export const TextHighLight: FC<{ text: string; keyword?: string }> = ({
  text,
  keyword,
}) =>
  useMemo(() => {
    if (!keyword) {
      return <span>{text}</span>;
    }
    const lowerText = text.toLowerCase();
    const lowerKeyword = keyword.toLowerCase();
    const curPos = lowerText.indexOf(lowerKeyword);
    if (curPos === -1) {
      return <span>{text}</span>;
    }
    return (
      <span>
        {text.slice(0, curPos)}
        <span key={curPos} className="text-color-primary-6 font-black">
          {text.slice(curPos, curPos + lowerKeyword.length)}
        </span>
        {text.slice(curPos + lowerKeyword.length)}
      </span>
    );
  }, [text, keyword]);
