import { Select, type SelectProps } from "@arco-design/web-react";
import { type FC, useMemo } from "react";
import { OtaPlatformKind as ApiOtaPlatformKind } from "@api/http_pms/const/const_enum";
import { OtaPlatformKind } from "@/common/const";
import { useConstOption } from "@/common/const/utils";

export const OtaPlatformSelector: FC<
  SelectProps & { limitOtaPlatformKinds?: Set<ApiOtaPlatformKind> }
> = ({ value, onChange, limitOtaPlatformKinds, ...props }) => {
  const otaPlatformOptions = useConstOption(OtaPlatformKind);
  const finalOptions = useMemo(
    () =>
      otaPlatformOptions
        .filter((item) => item.value !== ApiOtaPlatformKind.Reservation)
        .map((item) => ({
          value: item.value,
          label: (
            <div className="flex items-center">
              <img src={item.iconUrlSquare} className="mr-2 size-5" />
              {item.label}
            </div>
          ),
          disabled:
            limitOtaPlatformKinds && !limitOtaPlatformKinds?.has(item.value),
        })),
    [],
  );

  return (
    <Select
      placeholder="请选择OTA类型"
      options={finalOptions}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};
