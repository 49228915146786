import {
  Form,
  type FormInstance,
  type FormItemProps,
} from "@arco-design/web-react";
import { useCreation } from "ahooks";
import React, { type ReactElement, type FC } from "react";

export const CompactFormItem: FC<
  Omit<FormItemProps, "children"> & {
    form: FormInstance;
    /** { [组件中的key] : "表单中的key" } */
    fieldMap: Record<string, string>;
    children: ReactElement;
  }
> = ({ form, fieldMap, children, ...rawProps }) => {
  const fieldList = useCreation(() => Object.entries(fieldMap), []);
  const value: Record<string, any> = {};
  const fields = fieldList.map(([ck, fk]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    value[ck] = Form.useWatch(fk, form);
    return <Form.Item key={ck} field={fk} hidden={true} />;
  });

  return (
    <>
      {fields}
      <Form.Item {...rawProps}>
        <Pipe>
          {(props) =>
            React.cloneElement(children, {
              ...props,
              value,
              onChange: (v: Record<string, unknown>) => {
                const res = Object.entries(v).map(([ck, cv]) => [
                  fieldMap[ck],
                  cv,
                ]);
                if (rawProps.field) {
                  res.push([rawProps.field, v]);
                }
                form.setFieldsValue(Object.fromEntries(res));
              },
            })
          }
        </Pipe>
      </Form.Item>
    </>
  );
};

export const Pipe: FC<{
  children: (props: Record<string, unknown>) => ReactElement;
}> = ({ children, ...props }) => children(props);
