import { Input, type FormInstance } from "@arco-design/web-react";
import { type FC } from "react";
import { ContentKind } from "@api/http_pms/const/const_enum";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { type Order } from "@api/http_pms/order/order_type";
import { TimeSelector } from "@/common/Inputer/TimeSelector";
import { FastSaveFormItem } from "@/comps/FastSaveFormItem";
import { required } from "@/utils/validator";
import { orderUpdateFastSave } from "../utils";
import { OrderDatesInput } from "../../../../../common/OrderDatesInput";
import { permissionHas } from "@/store/useUserInfo";
import { useRoomInfo } from "@/store/useRoomInfo";
import { RoomSelector } from "@/common/Inputer/RoomSelector";
import { StepCounter } from "@/common/Inputer/StepCounter";

export const BaseEditor: FC<{
  isEdit: boolean;
  form: FormInstance;
  orderId?: string;
  mutateOrderData?: (data: Order) => void;
}> = ({ isEdit, form, orderId, mutateOrderData }) => {
  const { roomMapId } = useRoomInfo();

  const handleRoomChange = (roomId: string) => {
    const room = roomMapId[roomId];
    if (room) {
      form.setFieldsValue({
        checkInTime: room.checkInTime,
        checkOutTime: room.checkOutTime,
      });
    }
  };

  return (
    <>
      <FastSaveFormItem
        field="roomId"
        label="订单房间"
        rules={[required("订单房间")]}
        onSubmit={async (val: string) => {
          const room = roomMapId[val];
          const res = await orderUpdateFastSave(
            {
              order: {
                roomId: val,
                checkInTime: room?.checkInTime,
                checkOutTime: room?.checkOutTime,
              },
            },
            orderId,
          );
          if (room) {
            form.setFieldsValue({
              checkInTime: room.checkInTime,
              checkOutTime: room.checkOutTime,
            });
          }
          if (res?.order) {
            mutateOrderData?.(res.order);
          }
        }}
        debounceWait="fast"
        fastSaveMode={isEdit}
        form={form}
      >
        <RoomSelector onChange={isEdit ? undefined : handleRoomChange} />
      </FastSaveFormItem>

      <FastSaveFormItem
        field="orderDates"
        label="入退期间"
        rules={[
          required("入退期间"),
          {
            validator(
              value: string[] | undefined,
              cb: (error?: string) => void,
            ) {
              if (value && value[0] === value[1]) {
                return cb("入退期间不能是同一天");
              }
              return cb();
            },
          },
        ]}
        onSubmit={async (val) => {
          const res = await orderUpdateFastSave(
            {
              order: {
                dateStart: val[0],
                dateEnd: val[1],
              },
            },
            orderId,
          );
          if (res?.order) {
            mutateOrderData?.(res.order);
          }
        }}
        debounceWait="fast"
        fastSaveMode={isEdit}
        form={form}
      >
        <OrderDatesInput />
      </FastSaveFormItem>

      <FastSaveFormItem
        field="checkInTime"
        label="入住时间"
        rules={[required("入住时间")]}
        onSubmit={(val) =>
          orderUpdateFastSave(
            {
              order: {
                checkInTime: val,
              },
            },
            orderId,
          )
        }
        debounceWait="fast"
        fastSaveMode={isEdit}
        form={form}
      >
        <TimeSelector placeholder="请选择入住时间" />
      </FastSaveFormItem>

      <FastSaveFormItem
        field="checkOutTime"
        label="退房时间"
        rules={[required("退房时间")]}
        onSubmit={(val) =>
          orderUpdateFastSave(
            {
              order: {
                checkOutTime: val,
              },
            },
            orderId,
          )
        }
        debounceWait="fast"
        fastSaveMode={isEdit}
        form={form}
      >
        <TimeSelector placeholder="请选择退房时间" />
      </FastSaveFormItem>

      <FastSaveFormItem
        field="tenantCount"
        label="房客人数"
        rules={[required("房客人数")]}
        onSubmit={(val) =>
          orderUpdateFastSave(
            {
              order: {
                tenantCount: val,
              },
            },
            orderId,
          )
        }
        debounceWait="fast"
        fastSaveMode={isEdit}
        form={form}
      >
        <StepCounter min={1} />
      </FastSaveFormItem>

      {permissionHas(ApiPermission.OrderAdvancedView) && (
        <FastSaveFormItem
          field="remark"
          label="备注"
          onSubmit={(val) =>
            orderUpdateFastSave(
              {
                remark: {
                  content: val,
                  contentKind: ContentKind.Text,
                },
              },
              orderId,
            )
          }
          debounceWait="fast"
          fastSaveMode={isEdit}
          form={form}
        >
          <Input.TextArea
            placeholder="请输入备注"
            autoSize={{ minRows: 3, maxRows: 8 }}
            {...(permissionHas(ApiPermission.OrderSetRemarkAndTag)
              ? { disabled: false }
              : {})}
          />
        </FastSaveFormItem>
      )}
    </>
  );
};
