/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type Currency } from "../const/const_enum";
import { type RemarkContent, type Image } from "../common/common_type";
import { type OrderPure } from "../order/order_type";

export interface ShareBill {
  shareId: string;
  // update的时候，保证shareId不变，subId可能会变化。
  // subId != shareId哪怕只有一个 子帐单
  // 根据 subBills 长度=1 来判断是否是子帐单
  subBills: SubBill[];
  billKind: BillKind;
  walletId: string;
  currency: Currency;
  // 入账日期
  postingDate: string;
  // 费用产生日期
  receiptDate: string;
  // 确认时间，空为未确认 @uint64
  confirmAt?: string;
  // 录入时间 @uint64
  createdAt?: string;
  // 更新时间 @uint64
  updatedAt?: string;
  // 录入人ID
  creatorId?: string;
  // 录入人
  creator?: string;
  // 仅展示
  remark?: RemarkContent;
  images: Image[];
}

export interface SubBill {
  id: string;
  //  @int32
  amount: number;
  // 数据库额外加一个字段, relationRoomIds pg.ArrayString
  // 更新帐单时更新所属shareId下所有子帐单的 relationRoomIds=包含的所有roomId
  roomId?: string;
  // 数据库额外加一个字段, relationOrderIds pg.ArrayString
  // 更新帐单时更新所属shareId下所有子帐单的 relationOrderIds=包含的所有roomId
  orderId?: string;
  // 所属月报周期，空为未生成月报
  settleMonth?: string;
  // 子账单生成时间 @uint64
  subCreatedAt?: string;
  // 子账单生成时间 @uint64
  subUpdatedAt?: string;
  // 仅展示
  order?: OrderPure;
}
export enum BillKind {
  Unknown = 0,
  // 收入
  RoomFee = 1,
  // 押金 o+
  Deposit = 2,
  // 理赔 o+
  Claims = 3,
  // 佣金收入 o+
  OtaFee = 4,
  // 支出
  Water = 5,
  // 电 b-
  Electricity = 6,
  // 煤 b-
  Gas = 7,
  // 网 b-
  Network = 8,
  // 杂费 2-
  Extra = 9,
  // 清扫费 2-
  Clear = 10,
  // 房费退款 o-
  Refund = 11,
  // 佣金退款 o-
  OtaRefund = 12,
  // 额外房费 o+
  ExtraRoomFee = 13,
  // 特别收入 o+
  ExtraIncome = 14,
  // 自由
  TransferAccounts = 20,
}
