import { Divider, Link } from "@arco-design/web-react";

import {
  BillKind as ApiBillKind,
  type ShareBill,
} from "@api/http_pms/bill/bill_type";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import { type FC } from "react";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { PictureListShow } from "@/comps/PictureListShow";
import { BillKind } from "@/common/const";
import { AsyncPopconfirm } from "@/comps/AsyncPopconfirm";
import { AsyncButton } from "@/comps/AsyncButton";
import { MoneyShow } from "./MoneyShow";
import { permissionHas, useUserInfo } from "@/store/useUserInfo";

const noDeleteBillKinds = new Set([ApiBillKind.RoomFee, ApiBillKind.OtaFee]);
interface ItemShowProps {
  shareBill: ShareBill;
  onEdit: () => void;
  onRemove: () => Promise<void>;
  onOk?: (item: ShareBill) => Promise<void>;
}

export const BillItemShow: FC<ItemShowProps> = ({
  shareBill,
  onEdit,
  onRemove,
  onOk,
}) => {
  const { userInfo } = useUserInfo();
  return (
    <div className="bg-color-fill-1 h-full justify-between rounded-xl px-4 py-3">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-2 font-medium">
            {BillKind[shareBill.billKind]?.label}
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          {(permissionHas(ApiPermission.BillSuper) ||
            (permissionHas(ApiPermission.BillUpdate) &&
              !shareBill?.confirmAt)) && (
            <Link
              onClick={() => {
                onEdit();
              }}
            >
              编辑
            </Link>
          )}
          {(permissionHas(ApiPermission.BillSuper) ||
            (permissionHas(ApiPermission.BillUpdate) &&
              !shareBill.confirmAt &&
              shareBill.creatorId === userInfo.id)) &&
            !noDeleteBillKinds.has(shareBill.billKind) && (
              <AsyncPopconfirm title="确定删除此条目？">
                <AsyncButton type="link" onClick={onRemove}>
                  移除
                </AsyncButton>
              </AsyncPopconfirm>
            )}
        </div>
      </div>

      <MoneyShow shareBill={shareBill} onSubmit={onOk} />

      {shareBill?.receiptDate && (
        <div className="text-color-text-2 mt-2 flex">
          <div className="shrink-0">入账日期：</div>
          <div>{shareBill?.postingDate}</div>
        </div>
      )}

      {shareBill.remark?.content && (
        <div className="text-color-text-2 mt-2 flex">
          <div className="shrink-0">备注：</div>
          {shareBill.remark.content}
        </div>
      )}

      {!isEmpty(shareBill.images) && (
        <div className="text-color-text-2 mt-2 flex">
          <div className="shrink-0">附件：</div>
          <PictureListShow
            urls={shareBill.images?.map((item) => item.uri) || []}
          />
        </div>
      )}

      <Divider className="my-2" />
      {shareBill.subBills[0]?.subCreatedAt && (
        <div className="text-color-text-3 mt-2 flex justify-between">
          <div>{shareBill.creator}</div>
          <div>
            {dayjs.unixStr(shareBill.subBills[0].subCreatedAt).dateStr()}
          </div>
        </div>
      )}
    </div>
  );
};
