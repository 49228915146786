import { useLatest } from "ahooks";
import { useCallback, useRef, useState } from "react";

export type DebounceWaitType = "noWait" | "fast" | "medium" | "slow";
const debounceWaitMap = {
  noWait: 0,
  fast: 250,
  medium: 500,
  slow: 1000,
};

export const usePromiseDebounce = <T, P extends any[]>(
  fn: (...p: P) => Promise<T>,
  wait: DebounceWaitType | number,
  opts?: {
    onSuccess?: (resp: T, req: P) => any;
    onError?: (err: unknown, req: P) => any;
  },
) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>();
  const [data, setData] = useState<T>();

  const fnRef = useLatest(fn);

  const waitRef = useLatest(
    typeof wait === "number" ? wait : debounceWaitMap[wait],
  );

  const timerRef = useRef<{
    stop: () => void;
    timer: NodeJS.Timeout;
  }>();

  const run = useCallback((...p: P): void => {
    setLoading(true);
    setSuccess(undefined);
    let isStopped = false;

    if (timerRef.current) {
      timerRef.current.stop();
      clearTimeout(timerRef.current.timer);
    }

    timerRef.current = {
      stop: () => {
        isStopped = true;
      },
      timer: setTimeout(async () => {
        try {
          const resp = await fnRef.current(...p);
          if (!isStopped) {
            try {
              await opts?.onSuccess?.(resp, p);
            } catch {}
            setData(resp);
            setSuccess(true);
            setLoading(false);
          }
        } catch (e) {
          if (!isStopped) {
            try {
              await opts?.onError?.(e, p);
            } catch {}
            setSuccess(false);
            setLoading(false);
          }
        }
      }, waitRef.current),
    };
  }, []);

  const stop = useCallback((): void => {
    setLoading(false);
    setSuccess(undefined);

    if (timerRef.current) {
      timerRef.current.stop();
      clearTimeout(timerRef.current.timer);
    }
  }, []);

  return { run, stop, success, loading, data, setData };
};
