import { useMemo, type FC, useRef } from "react";
import clsx from "clsx";
import { useLongPress } from "ahooks";
import { IconFont } from "../IconFont";
import { useInterval } from "@/utils/useInterval";

export const StepCounter: FC<{
  value?: number;
  onChange?: (v: number) => void;
  min?: number;
  max?: number;
  className?: string;
  disabled?: boolean;
}> = ({ value, onChange, className, min, max, disabled }) => {
  const display = useMemo(() => {
    const intValue = parseInt(value as any as string) || 0;
    if (max !== undefined && intValue > max) {
      const target = Math.floor(max);
      onChange?.(target);
      return target;
    }
    if (min !== undefined && intValue < min) {
      const target = Math.ceil(min);
      onChange?.(target);
      return target;
    }
    if (intValue !== value) {
      onChange?.(intValue);
    }
    return intValue;
  }, [value]);

  const handleChange = (nv: number) => {
    const v = display + nv;
    if (min !== undefined && v < min) {
      return false;
    }
    if ((max !== undefined && v > max) || disabled) {
      return false;
    }
    onChange?.(v);
    return true;
  };

  const minusBtnRef = useRef<HTMLDivElement>(null);
  const plusBtnRef = useRef<HTMLDivElement>(null);

  const { start, end } = useInterval(handleChange, 200);
  useLongPress(() => start(-1), minusBtnRef, {
    onLongPressEnd: () => end(),
    onClick: () => handleChange(-1),
  });
  useLongPress(() => start(+1), plusBtnRef, {
    onLongPressEnd: () => end(),
    onClick: () => handleChange(+1),
  });

  return (
    <span
      className={clsx(className, "flex w-[72px] items-center justify-between")}
    >
      <div
        className={clsx(
          "flex size-5 items-center justify-center rounded-full",
          (min !== undefined && display <= min) || disabled
            ? "cursor-not-allowed bg-color-primary-2 text-color-primary"
            : "cursor-pointer bg-color-primary text-white",
        )}
        ref={minusBtnRef}
      >
        <IconFont type="icon-minus" className="size-2" />
      </div>
      <div className={clsx({ "text-color-text-4": disabled })}>{display}</div>
      <div
        className={clsx(
          "flex size-5 items-center justify-center rounded-full",
          (max !== undefined && display >= max) || disabled
            ? "cursor-not-allowed bg-color-primary-2 text-color-primary"
            : "cursor-pointer bg-color-primary text-white",
        )}
        ref={plusBtnRef}
      >
        <IconFont type="icon-plus" className="size-2" />
      </div>
    </span>
  );
};
