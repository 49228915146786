import { UserService } from "@api/http_pms/user/user_srv";
import { Button, Form, Input, Message } from "@arco-design/web-react";
import { useState } from "react";
import { satisfyEmail, required } from "@/utils/validator";
import { useUserInfo } from "@/store/useUserInfo";
import { AsyncButton } from "@/comps/AsyncButton";

export const EmailChangeForm = () => {
  const [changeEmailMode, setChangeEmailMode] = useState(false);
  const [form] = Form.useForm();
  const { userInfo, refresh } = useUserInfo();

  return (
    <>
      <div className="mb-6 text-base font-medium">绑定邮箱</div>
      <Form
        form={form}
        initialValues={{
          ...userInfo,
        }}
        layout="vertical"
        onSubmit={async (values) => {
          await UserService.SetEmail({
            email: userInfo.email,
            newEmail: values.newEmail,
            code: values.code,
          });
          await refresh();
          Message.success("绑定成功");
          form.setFieldValue("email", values.newEmail);
          setChangeEmailMode(false);
        }}
      >
        {changeEmailMode && (
          <>
            <div className="text-xs text-color-text-3">
              验证码已发送到{userInfo.email}，输入验证码并绑定新邮箱。
            </div>
            <Form.Item
              field="code"
              label="验证码"
              className="mt-6"
              rules={[required("验证码")]}
            >
              <Input placeholder="请输入验证码" className="w-52" size="large" />
            </Form.Item>
            <Form.Item
              field="newEmail"
              label="新邮箱"
              rules={[required("新邮箱"), satisfyEmail("onBlur")]}
            >
              <Input placeholder="请输入绑定邮箱" size="large" />
            </Form.Item>
            <div className="mt-2">
              <Button type="primary" htmlType="submit" className="mr-3">
                绑定
              </Button>
            </div>
          </>
        )}
        {!changeEmailMode && (
          <>
            <Form.Item field="email" label="绑定邮箱">
              <Input
                placeholder="请输入绑定邮箱"
                disabled={true}
                size="large"
              />
            </Form.Item>
            <div className="mt-2">
              <AsyncButton
                type="primary"
                className="mr-3"
                onClick={async () => {
                  await UserService.SendEmailVerifyCode({
                    email: userInfo.email,
                  });
                  Message.success("发送验证码成功");
                  setChangeEmailMode(true);
                }}
              >
                更换绑定
              </AsyncButton>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
