import { DatePicker, type RangePickerProps } from "@arco-design/web-react";
import { useState, type FC } from "react";
import { type CalendarValue } from "@arco-design/web-react/es/Calendar/interface";
import dayjs, { type Dayjs } from "dayjs";
import { isEmpty } from "lodash-es";

export const OrderDatesInput: FC<
  {
    value?: CalendarValue[];
    onChange?: (val: CalendarValue[]) => void;
  } & RangePickerProps
> = ({ value, onChange, ...props }) => {
  const [selectDate, setSelectDate] = useState<Dayjs>();

  return (
    <DatePicker.RangePicker
      getPopupContainer={() => document.body}
      triggerProps={{
        containerScrollToClose: true,
      }}
      value={value}
      onChange={(val) => {
        onChange?.(val);
      }}
      className="!w-full"
      css={`
        .arco-picker-input {
          flex: auto;
        }
        .arco-picker-suffix {
          width: auto;
          flex-shrink: 0;
        }
        .arco-picker-suffix::before {
          content: "共${dayjs(value?.[1] as string).diff(
            dayjs(value?.[0] as string),
            "day",
          )}晚";
          font-size: 12px;
          margin-right: 4px;
          color: var(--color-text-${props.disabled ? 4 : 3});
        }
      `}
      onSelect={(_, _value, { type }) => {
        if (type === "start") {
          setSelectDate(_value[0]);
        } else {
          setSelectDate(_value[1]);
        }
      }}
      onVisibleChange={() => {
        setSelectDate(undefined);
      }}
      disabledDate={(current) => {
        if (!isEmpty(selectDate)) {
          const disable = current.isSame(selectDate, "day");
          return Boolean(disable);
        }

        return false;
      }}
      {...props}
    />
  );
};
