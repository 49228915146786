import { Form, Input, Message, Modal } from "@arco-design/web-react";
import { OtaReviewService } from "@api/http_pms/ota_review/ota_review_srv";
import { AsyncButton } from "@/comps/AsyncButton";
import { createNiceModal } from "@/utils/nicemodel";
import { required } from "@/utils/validator";

export const openRespondContent = createNiceModal<
  { reviewId: string }, // 调用的入参参数
  {} // 调用的出参参数
>(({ _modal, reviewId }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title={<span tabIndex={0}>回复评价</span>}
      {..._modal.props} // 需要解构给Modal组件
      className="w-[600px]"
      footer={
        <div className="flex w-full items-center justify-end">
          <AsyncButton
            type="primary"
            onClick={async () => {
              const values = await form.validate();
              await OtaReviewService.Respond({
                id: reviewId,
                respondContent: values.respondContent,
              });
              Message.success("回复成功");
              _modal.resolve({});
            }}
          >
            提交
          </AsyncButton>
        </div>
      }
      css={`
        .arco-modal-content {
          padding: 16px 24px;
          height: 50vh;
          overflow-y: auto;
        }
        .arco-form {
          height: 100%;
        }
      `}
    >
      <Form form={form} size="large" wrapperCol={{ span: 24 }}>
        <Form.Item
          field="respondContent"
          className="h-full"
          rules={[required("回复内容")]}
        >
          <Input.TextArea
            placeholder="发布回复 (仅一次)"
            className="size-full"
            style={{ height: "calc(50vh - 40px)" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});
