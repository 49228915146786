import { Message } from "@arco-design/web-react";
import { BillService } from "@api/http_pms/bill/bill_srv";
import { produce } from "immer";
import { cloneDeep, get, sum } from "lodash-es";
import { type SubBill, type ShareBill } from "@api/http_pms/bill/bill_type";
import { type ListByCondResp } from "./interface";

export const uniqueSubData = <T>(row: T[], field: string): T | undefined => {
  const res = new Set(row.map((r) => get(r, field)));
  return res.size === 1 ? row[0] : undefined;
};

export const batchConfirm = async ({
  shareIds,
  data,
  mutate,
  isCancel,
}: {
  shareIds: string[];
  data: ListByCondResp;
  mutate?: (val: ListByCondResp) => void;
  isCancel?: boolean;
}) => {
  const res = await BillService.BatchConfirm({
    shareIds,
    isCancel: Boolean(isCancel),
  });
  const newData = produce(data, (draft) => {
    res.bill.forEach((bill) => {
      const index = draft.bills.findIndex(
        (item) => item.shareId === bill.shareId,
      );
      if (index !== -1) {
        draft.bills[index] = bill;
      }
    });
  });
  mutate?.(newData);
  if (isCancel) {
    Message.warning("撤销账单成功");
  } else {
    Message.success("确定账单成功");
  }
};

export const newEmptySubBill = (s: Partial<SubBill> = {}): SubBill => ({
  id: "",
  amount: 0,
  ...s,
});

export const refundedDepositDealWith = (shareBill: ShareBill) => {
  const newItem = cloneDeep(shareBill);
  const orderId = shareBill.subBills[0]!.orderId!;
  const inSubBillList = newItem.subBills.filter((bill) => bill.amount > 0);
  const inTotalAmount = sum(inSubBillList.map((s) => s.amount));
  const inSubBill = inSubBillList[0] || newEmptySubBill({ orderId });
  inSubBill.amount = inTotalAmount;
  const outSubBill =
    newItem.subBills.find((bill) => bill.amount <= 0) ||
    newEmptySubBill({ orderId });
  outSubBill.amount = -inTotalAmount;

  newItem.subBills = [inSubBill, outSubBill];
  return newItem;
};
