import { create } from "zustand";

import {
  Currency as ApiCurrency,
  Area,
  Language,
} from "@api/http_pms/const/const_enum";
import { OrgService } from "@api/http_pms/org/org_srv";
import { type Org } from "@api/http_pms/org/org_type";
import { SystemService } from "@api/http_pms/system/system_srv";

type OrgInfo = Org & {
  currency: ApiCurrency;
  timeZone: string;
  language: Language;
};

interface OrgInfoStore {
  orgInfo: OrgInfo;
  refresh: () => Promise<void>;
}

const initOrgInfo: OrgInfo = {
  id: "",
  name: "",
  alias: "",
  area: Area.UnknownArea,
  capabilities: [],
  maxRoomCount: 0,
  checkInTime: 0,
  checkOutTime: 0,
  currency: ApiCurrency.UnknownCurrency,
  timeZone: "",
  language: Language.en,
  expiredDate: "",
  orderDefaultTagIds: [],
};

export const useOrgInfo = create<OrgInfoStore>((set) => ({
  orgInfo: initOrgInfo,
  refresh: async () => {
    const orgRes = await OrgService.Get({});
    const systemRes = await SystemService.GetInfo({});
    const config = systemRes.areaConfigs.find(
      (_config) => _config.area === orgRes.org.area,
    );
    console.info("version: ", systemRes.version);
    if (config) {
      set({
        orgInfo: {
          ...orgRes.org,
          currency: config.currency,
          timeZone: config.timeZone,
          language: config.language,
        },
      });
    }
  },
}));
