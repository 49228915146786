export const checkProd = () => window.location.hostname.endsWith(".easbnb.com");

export const getCheckinUrl = (signedId: string) => {
  return checkProd()
    ? `https://checkin.easbnb.com/?signedId=${signedId}`
    : `https://checkin.easbnb.fun/?signedId=${signedId}`;
};

export const getQrUrl = (text: string, width: number) =>
  `${getStorageUrl()}/?qr=${encodeURIComponent(text)}&w=${width}`;

export const getStorageUrl = () =>
  checkProd() ? `https://file.easit.cc` : `https://test-file.easit.cc`;

export const getImUrl = () => {
  return checkProd()
    ? "https://api-im.easbnb.com"
    : "https://api-im.easbnb.fun";
};
