/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import {
  type EntityInfo,
  type Remark,
  type RemarkPreview,
  type Tag,
  type PartitionKind,
  type Image,
} from "./common_type";
import { type ContentKind, type EntityKind } from "../const/const_enum";

export namespace CommonService {
  // remark
  // 根据实体类型和id获取备注详情
  export function GetRemark(req: GetRemarkReq, config?: any) {
    return webapi.post<GetRemarkResp>(
      "/api/v1/CommonService_GetRemark",
      req,
      config,
    );
  }

  // 根据实体类型和id获取备注预览列表
  export function ListRemarkPreview(req: ListRemarkPreviewReq, config?: any) {
    return webapi.post<ListRemarkPreviewResp>(
      "/api/v1/CommonService_ListRemarkPreview",
      req,
      config,
    );
  }

  // 根据实体类型和id获取备注详情列表
  export function ListRemark(req: ListRemarkReq, config?: any) {
    return webapi.post<ListRemarkResp>(
      "/api/v1/CommonService_ListRemark",
      req,
      config,
    );
  }

  // 批量删除备注
  export function BatchDelRemark(req: BatchDelRemarkReq, config?: any) {
    return webapi.post<BatchDelRemarkResp>(
      "/api/v1/CommonService_BatchDelRemark",
      req,
      config,
    );
  }

  // 批量设置备注
  export function BatchSetRemark(req: BatchSetRemarkReq, config?: any) {
    return webapi.post<BatchSetRemarkResp>(
      "/api/v1/CommonService_BatchSetRemark",
      req,
      config,
    );
  }

  // tag
  // 根据tag类型获取单个
  export function AllTag(req: AllTagReq, config?: any) {
    return webapi.post<AllTagResp>("/api/v1/CommonService_AllTag", req, config);
  }

  // 创建tag
  export function CreateTag(req: CreateTagReq, config?: any) {
    return webapi.post<CreateTagResp>(
      "/api/v1/CommonService_CreateTag",
      req,
      config,
    );
  }

  // 批量删除tag
  export function BatchDelTag(req: BatchDelTagReq, config?: any) {
    return webapi.post<BatchDelTagResp>(
      "/api/v1/CommonService_BatchDelTag",
      req,
      config,
    );
  }

  // 修改tag
  export function UpdateTag(req: UpdateTagReq, config?: any) {
    return webapi.post<UpdateTagResp>(
      "/api/v1/CommonService_UpdateTag",
      req,
      config,
    );
  }

  // 用到图片的地方:房间、房客、账单
  // 图片PATH拼接规则 entity/relationId/kind/name
  // 根据实体类型和id获取图片
  export function ListImageByEntity(req: ListImageByEntityReq, config?: any) {
    return webapi.post<ListImageByEntityResp>(
      "/api/v1/CommonService_ListImageByEntity",
      req,
      config,
    );
  }

  // 创建预览图
  export function ListPreviewImages(req: ListPreviewImagesReq, config?: any) {
    return webapi.post<ListPreviewImagesResp>(
      "/api/v1/CommonService_ListPreviewImages",
      req,
      config,
    );
  }

  // 根据实体获取主图
  export function GetMainImage(req: GetMainImageReq, config?: any) {
    return webapi.post<GetMainImageResp>(
      "/api/v1/CommonService_GetMainImage",
      req,
      config,
    );
  }

  export function SetMainImage(req: SetMainImageReq, config?: any) {
    return webapi.post<SetMainImageResp>(
      "/api/v1/CommonService_SetMainImage",
      req,
      config,
    );
  }

  // 批量删除图片
  export function BatchDelImage(req: BatchDelImageReq, config?: any) {
    return webapi.post<BatchDelImageResp>(
      "/api/v1/CommonService_BatchDelImage",
      req,
      config,
    );
  }

  // 文件上传预签名
  export function BatchPresignPut(req: BatchPresignPutReq, config?: any) {
    return webapi.post<BatchPresignPutResp>(
      "/api/v1/CommonService_BatchPresignPut",
      req,
      config,
    );
  }

  // 批量设置图片顺序
  export function BatchSetImageRank(req: BatchSetImageRankReq, config?: any) {
    return webapi.post<BatchSetImageRankResp>(
      "/api/v1/CommonService_BatchSetImageRank",
      req,
      config,
    );
  }
}
// GetVersion
export interface GetVersionReq {}

export interface GetVersionResp {
  name: string;
  time: string;
}

// GetRemark
export interface GetRemarkReq {
  entityInfo: EntityInfo;
}

export interface GetRemarkResp {
  remark: Remark;
}

// ListRemarkPreview
export interface ListRemarkPreviewReq {
  entityInfos: EntityInfo[];
}

export interface ListRemarkPreviewResp {
  remarkPreviews: RemarkPreview[];
}

// ListRemark
export interface ListRemarkReq {
  entityInfos: EntityInfo[];
}

export interface ListRemarkResp {
  remarks: Remark[];
}

// BatchDelRemark
export interface BatchDelRemarkReq {
  entityInfos: EntityInfo[];
}

export interface BatchDelRemarkResp {
  //  @uint32
  rows: number;
}

// SetRemark
export interface BatchSetRemarkReq {
  entityInfos: EntityInfo[];
  content: string;
  contentKind: ContentKind;
}

export interface BatchSetRemarkResp {
  remarks: Remark[];
}

// AllTag
export interface AllTagReq {
  entityKind?: EntityKind;
}

export interface AllTagResp {
  tags: Tag[];
}

// CreateTag
export interface CreateTagReq {
  tag: Tag;
}

export interface CreateTagResp {
  tag: Tag;
}

// BatchDelTag
export interface BatchDelTagReq {
  ids: string[];
}

export interface BatchDelTagResp {
  //  @uint32
  rows: number;
}

// UpdateTag
export interface UpdateTagReq {
  tag: Tag;
}

export interface UpdateTagResp {
  tag: Tag;
}

// ListImageByEntity
export interface ListImageByEntityReq {
  // 某个实体下的图片 entity 和 对应id
  entityInfo: EntityInfo;
  // 分区
  partition?: PartitionKind;
}

export interface ListImageByEntityResp {
  images: Image[];
}

// BatchDelImage
export interface BatchDelImageReq {
  entityInfo: EntityInfo;
  partition?: PartitionKind;
  names: string[];
}

export interface BatchDelImageResp {
  //  @uint32
  rows: number;
}

// BatchPresignPut
// entity/_tmp/kind/name
export interface BatchPresignPutReq {
  // 实体信息 relationId == "" 是不鉴权 签发到tmp下
  entityInfo: EntityInfo;
  // 分区
  partition: PartitionKind;
  // 文件名原始名称列表
  realNames: string[];
}

export interface BatchPresignPutResp {
  signedPutUrls: string[];
  images: Image[];
}

// BatchSetImageRank
export interface BatchSetImageRankReq {
  entityInfo: EntityInfo;
  partition: PartitionKind;
  names: string[];
}

export interface BatchSetImageRankResp {
  images: Image[];
}

// SetMainImage 设置主图
export interface SetMainImageReq {
  entityInfo: EntityInfo;
  partition: PartitionKind;
  // 当id用
  name: string;
  // 取消主图 @uint64
  mainImageAt?: string;
}

export interface SetMainImageResp {
  image: Image;
}

// GetMainImage 获取主图
export interface GetMainImageReq {
  entityInfo: EntityInfo;
  partition?: PartitionKind;
}

export interface GetMainImageResp {
  image: Image;
}

// ListPreviewImages doc 上传完之后，主动创建pdf预览图
export interface ListPreviewImagesReq {
  // 某个实体下的图片 entity 和 对应id
  entityInfo: EntityInfo;
  // pdf 图片分区
  partition: PartitionKind;
}

export interface ListPreviewImagesResp {
  images: Image[];
}
