import { create } from "zustand";

import { type User } from "@api/http_pms/user/user_type";
import { UserService } from "@api/http_pms/user/user_srv";
import { type Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { Permission } from "@/pages/Account/const";

interface UserInfoStore {
  userInfo: User;
  refresh: () => Promise<void>;
  permissionsSet: Set<ApiPermission>;
}

const initUserInfo: User = {
  id: "",
  username: "",
  nickname: "",
  email: "",
  accountName: "",
  permissions: [],
  simulationLogin: false,
};

export const useUserInfo = create<UserInfoStore>((set) => ({
  userInfo: initUserInfo, // 初始化之前不可使用
  permissionsSet: new Set(),
  refresh: async () => {
    const res = await UserService.Info({});
    set({
      userInfo: res.user,
      permissionsSet: new Set(res.user.permissions),
    });
  },
}));

export const permissionHas = (p: ApiPermission): boolean =>
  useUserInfo.getState().permissionsSet.has(p);

// TODO优化性能
export const permissionOver = (p: ApiPermission): boolean => {
  const currentPermission = Permission[p];
  return Object.values(Permission).some(
    (permission) =>
      useUserInfo.getState().permissionsSet.has(permission.value) &&
      permission.group === currentPermission.group &&
      permission.level > currentPermission.level,
  );
};
