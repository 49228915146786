import { useState, type FC } from "react";
import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import { LogItemForm } from "./LogItemForm";

import { LogItemShow } from "./LogItemShow";
import { checkIsAddId } from "@/utils/dealWithId";

interface ItemProps {
  item: OperateLog;
  onSubmit: (item: OperateLog) => Promise<void>;
  onRemove: () => void;
}

export const LogItem: FC<ItemProps> = ({ item, onSubmit, onRemove }) => {
  const [isEdit, setIsEdit] = useState(() => checkIsAddId(item.id));

  return (
    <div className="mb-4 overflow-hidden transition-[height] duration-500">
      {isEdit ? (
        <LogItemForm
          item={item}
          onCancel={() => {
            setIsEdit(false);
            if (checkIsAddId(item.id)) {
              onRemove();
            }
          }}
          onOk={async (_item) => {
            await onSubmit(_item);
            setIsEdit(false);
          }}
        />
      ) : (
        <LogItemShow
          item={item}
          onEdit={() => {
            setIsEdit(true);
          }}
          onRemove={() => {
            onRemove();
          }}
        />
      )}
    </div>
  );
};
