import {
  ReviewerRole,
  type CategoryTagKind as ApiCategoryTagKind,
  type CategoryKind,
} from "@api/http_pms/ota_review/ota_review_type";
import { useMemo, type FC } from "react";
import clsx from "clsx";
import { CategoryTagKind } from "../const";

export const CategoryTagKindInput: FC<{
  value?: ApiCategoryTagKind[];
  onChange?: (val: ApiCategoryTagKind[]) => void;
  categoryKind: CategoryKind;
  isPositive: boolean;
  hasOther?: boolean;
}> = ({ value, onChange, categoryKind, isPositive, hasOther = true }) => {
  const categoryTags = useMemo(() => {
    return Object.values(CategoryTagKind).filter((item) => {
      if (item.isOther && hasOther) {
        return item.categoryKind === categoryKind;
      }
      return (
        item.categoryKind === categoryKind &&
        Boolean(item.isPositive) === isPositive &&
        item.reviewerRole === ReviewerRole.Host
      );
    });
  }, [isPositive]);

  return (
    <div className="mt-2 flex flex-wrap gap-2">
      {categoryTags.map((item) => (
        <div
          className={clsx(
            "bg-color-fill-2 flex h-6 cursor-pointer items-center justify-center rounded px-2",
            {
              "bg-color-primary-1 text-color-primary-6": value?.includes(
                item.value,
              ),
            },
          )}
          key={item.value}
          onClick={() => {
            if (value?.includes(item.value)) {
              onChange?.(value.filter((v) => v !== item.value));
            } else {
              onChange?.([...(value ?? []), item.value]);
            }
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};
