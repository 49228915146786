import {
  type BillKind as ApiBillKind,
  type SubBill,
} from "@api/http_pms/bill/bill_type";
import { Form, Radio } from "@arco-design/web-react";
import { cloneDeep, sum } from "lodash-es";
import { useLayoutEffect, type FC } from "react";
import { type Currency } from "@api/http_pms/const/const_enum";
import { useUpdateEffect } from "ahooks";
import { type CurrencyAmount } from "@api/http_pms/common/common_type";
import { newEmptySubBill } from "../../utils";
import { type MoneyFormProps } from ".";
import { gtZero } from "@/utils/validator";
import { BillKind } from "@/common/const";
import { MoneyCurrencyInput } from "@/common/Inputer/MoneyCurrencyInput";
import { useAmountTypeOptions } from "../utils";

export interface MoneyFormSingleData {
  amountType: 1 | -1;
  currencyAmount: CurrencyAmount;
}

export const MoneyFormSingle: FC<MoneyFormProps> = ({
  form,
  initShareBill,
  canEdit,
  orderId,
}) => {
  // 外部表单数据
  const roomIds: string[] | undefined = Form.useWatch("roomIds", form);
  const billKind: ApiBillKind = Form.useWatch("billKind", form);
  const finalAmountTypeOptions = useAmountTypeOptions(billKind, form);

  // 内部表单数据
  const subFormData: MoneyFormSingleData = Form.useWatch(
    ["amountType", "currencyAmount"],
    form,
  );

  // 初始化逻辑
  useLayoutEffect(() => {
    const currency: Currency = form.getFieldValue("currency");
    const subBills: SubBill[] = form.getFieldValue("subBills");

    const amountList = subBills.map((b) => b.amount);
    let amount = sum(amountList);
    let amountType: number;
    if (amount === 0) {
      amountType = BillKind[billKind].amountType || -1;
    } else if (amount < 0) {
      amount = -amount;
      amountType = -1;
    } else {
      amountType = 1;
    }

    form.setFieldsValue({
      amountType,
      currencyAmount: {
        currency,
        amount: amount >= 0 ? amount : -amount,
      },
    });
    return () => {
      form.resetFields(["amountType", "currencyAmount"]);
    };
  }, []);

  // 相关数据变化时，更新 外部数据
  useUpdateEffect(() => {
    if (!canEdit) {
      return;
    }
    let subBill = initShareBill?.subBills[0];
    if (subBill) {
      subBill = cloneDeep(subBill);
    } else {
      subBill = newEmptySubBill();
    }
    subBill.orderId = orderId;
    subBill.amount = subFormData.currencyAmount.amount * subFormData.amountType;
    subBill.roomId = roomIds?.[0];

    form.setFieldsValue({
      currency: subFormData.currencyAmount.currency,
      subBills: [subBill],
    });
  }, [roomIds, subFormData]);

  return (
    <>
      <Form.Item field="amountType" label="收支类型">
        <Radio.Group options={finalAmountTypeOptions} />
      </Form.Item>

      <Form.Item
        field="currencyAmount"
        label="金额"
        rules={[gtZero("金额", "amount")]}
      >
        <MoneyCurrencyInput />
      </Form.Item>
    </>
  );
};
