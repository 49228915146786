import { type FC, type ReactNode } from "react";
import { Popconfirm } from "@arco-design/web-react";
import { UserService } from "@api/http_pms/user/user_srv";
import clsx from "clsx";
import { User } from "@/pages/User";
import { IconFont } from "../IconFont";
import { useUserInfo } from "@/store/useUserInfo";

export const PageLayout: FC<{
  title: ReactNode;
  topBar?: ReactNode;
  children: ReactNode;
  childrenClassName?: string;
}> = ({ title, topBar, children, childrenClassName }) => {
  const { userInfo } = useUserInfo();
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-[84px] flex-none items-center justify-between">
        <div className="flex items-center">
          {typeof title === "string" ? (
            <span className="text-lg font-medium">{title}</span>
          ) : (
            title
          )}
          {topBar}
        </div>
        <div className="flex items-center">
          {userInfo.simulationLogin && (
            <div className="mr-3 flex h-9 items-center rounded-lg bg-[#F74D361A] px-3 leading-none text-[#F74D36]">
              <IconFont type="icon-simulate-login" className="mr-2 size-4" />
              模拟登录需谨慎操作
              <Popconfirm
                title="确定要退出模拟登录？"
                onOk={async () => {
                  await UserService.SignOut({});
                }}
                okText="退出"
                position="bottom"
              >
                <span className="ml-3 cursor-pointer text-[#4D6BFF]">退出</span>
              </Popconfirm>
            </div>
          )}
          <User />
        </div>
      </div>
      {/* 目前不上：版本刷新功能 */}
      {/* <div className="mb-3 flex h-[68px] items-center rounded-lg bg-white px-6">
        <Button
          type="primary"
          icon={<IconRefresh />}
          onClick={() => window.location.reload()}
        >
          刷新页面
        </Button>
        <Alert
          className="ml-3"
          type="warning"
          content="系统版本已升级，请刷新页面，避免数据错误。"
        />
      </div> */}
      <div
        className={clsx(
          "relative flex-auto overflow-y-auto",
          childrenClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
