/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import {
  type Order,
  type OrderForList,
  type OrderCreate,
  type OrderUpdate,
} from "./order_type";
import {
  type DateRange,
  type TimeRange,
  type RemarkContent,
  type Remark,
  type CurrencyAmount,
} from "../common/common_type";
import { type OtaPlatformKind } from "../const/const_enum";
import { type Tenant } from "../tenant/tenant_type";

export namespace OrderService {
  // Get/List -> Create/Del -> Update/Set/OtherChange
  // 根据订单id获取单个信息详情
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/OrderService_Get", req, config);
  }

  // 根据订单id列表获取订单列表
  export function List(req: ListReq, config?: any) {
    return webapi.post<ListResp>("/api/v1/OrderService_List", req, config);
  }

  // 冲突订单
  export function ListConflict(req: ListConflictReq, config?: any) {
    return webapi.post<ListConflictResp>(
      "/api/v1/OrderService_ListConflict",
      req,
      config,
    );
  }

  // 订单筛选
  export function ListByFilter(req: ListByFilterReq, config?: any) {
    return webapi.post<ListByFilterResp>(
      "/api/v1/OrderService_ListByFilter",
      req,
      config,
    );
  }

  // 创建订单
  export function Create(req: CreateReq, config?: any) {
    return webapi.post<CreateResp>("/api/v1/OrderService_Create", req, config);
  }

  // 预检查接口
  export function CreatePreCheck(req: CreateReq, config?: any) {
    return webapi.post<CreatePreCheckResp>(
      "/api/v1/OrderService_CreatePreCheck",
      req,
      config,
    );
  }

  // 批量删除
  export function BatchDel(req: BatchDelReq, config?: any) {
    return webapi.post<BatchDelResp>(
      "/api/v1/OrderService_BatchDel",
      req,
      config,
    );
  }

  // 订单取消
  export function Cancel(req: CancelReq, config?: any) {
    return webapi.post<CancelResp>("/api/v1/OrderService_Cancel", req, config);
  }

  // 修改订单
  export function Update(req: UpdateReq, config?: any) {
    return webapi.post<UpdateResp>("/api/v1/OrderService_Update", req, config);
  }

  // 设置订单标签
  export function ApplyTag(req: ApplyTagReq, config?: any) {
    return webapi.post<ApplyTagResp>(
      "/api/v1/OrderService_ApplyTag",
      req,
      config,
    );
  }
}
// Get
export interface GetReq {
  // 订单id
  id: string;
}

export interface GetResp {
  order: Order;
}

// List
export interface ListReq {
  // 订单id列表
  ids: string[];
  // 需要返回的字段
  keys: string[];
}

export interface ListResp {
  // 订单列表（信息简化）
  orders: OrderForList[];
}

// ListConflict
export interface ListConflictReq {
  // 需要返回的字段
  keys: string[];
}

export interface ListConflictResp {
  orders: OrderForList[];
}

// ListFilter
export interface ListByFilterReq {
  // 需要返回的字段
  keys: string[];
  // 入住日期区间
  dateStart?: DateRange;
  // 退房日期区间
  dateEnd?: DateRange;
  // 房间id列表
  roomIds: string[];
  // 取消日期区间
  cancelledAt?: TimeRange;
  // 预定日期区间
  bookAt?: TimeRange;
  // ota平台
  otaPlatformKinds: OtaPlatformKind[];
  // 标签id
  tagIds: string[];
  // 订单id
  orderId?: string;
  // 渠道来源ids
  orderSourceIds: string[];
  // 订单来源key
  orderSourceKey?: string;
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
}

export interface ListByFilterResp {
  orders: OrderForList[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
  // 记录总数 @uint32
  total: number;
}

// Create
export interface CreateReq {
  order: OrderCreate;
  tenants: Tenant[];
  remark?: RemarkContent;
}

export interface CreateResp {
  order: Order;
  tenants: Tenant[];
  remark: Remark;
}

// This the module of CreatePreCheckResp
export namespace CreatePreCheckResp {
  export enum Reason {
    // 订单冲突
    OrderConflict = 0,
    // 渠道编号缺失
    OrderSourceMissing = 1,
    // 渠道号重复
    OrderSourceRepeat = 2,
  }
}
export interface CreatePreCheckResp {
  reasons: CreatePreCheckResp.Reason[];
}

// BatchDel
export interface BatchDelReq {
  // 订单id列表
  ids: string[];
}

export interface BatchDelResp {
  //  @uint32
  rows: number;
}

// Update
export interface UpdateReq {
  order: OrderUpdate;
  remark?: RemarkContent;
}

export interface UpdateResp {
  order: Order;
  tenants: Tenant[];
  remark: Remark;
}

// This the module of CancelReq
export namespace CancelReq {
  export interface CancelOrder {
    refundAmount: CurrencyAmount;
    walletId: string;
  }
} // Cancel
export interface CancelReq {
  id: string;
  roomFeeBill: CancelReq.CancelOrder;
  otaFeeBill: CancelReq.CancelOrder;
}

export interface CancelResp {
  order: Order;
}

// ApplyTag
export interface ApplyTagReq {
  id: string;
  tagIds: string[];
}

export interface ApplyTagResp {
  id: string;
  tagIds: string[];
}

// GetOccupancyRate 入住率统计
export interface GetOccupancyRateReq {}

// This the module of GetOccupancyRateResp
export namespace GetOccupancyRateResp {
  export interface OccupancyRate {
    // 日期
    date: string;
    // 入住数量
    roomDayCount: string;
    // 可售卖数量（即空房量）
    canSellCount: string;
    // 均价
    averagePrice: string;
  }
}
export interface GetOccupancyRateResp {
  occupancyRate: GetOccupancyRateResp.OccupancyRate[];
}

// CountOverview
export interface CountOverviewReq {
  date: string;
}

export interface CountOverviewResp {
  // 入住 @uint32
  checkIn: number;
  // 退房 @uint32
  checkOut: number;
  // 入住中 @uint32
  staying: number;
  // 空房 @uint32
  canSell: number;
  // 咨询 @uint32
  inquiry?: number;
  // 预定 @uint32
  new?: number;
  // 取消 @uint32
  cancel?: number;
  // 任务 @uint32
  mission?: number;
}
