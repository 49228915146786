/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type User } from "./user_type";

export namespace UserService {
  // 获取用户信息
  export function Info(req: InfoReq, config?: any) {
    return webapi.post<InfoResp>("/api/v1/UserService_Info", req, config);
  }

  // 设置邮箱
  export function SetEmail(req: SetEmailReq, config?: any) {
    return webapi.post<SetEmailResp>(
      "/api/v1/UserService_SetEmail",
      req,
      config,
    );
  }

  // 发送邮箱验证
  export function SendEmailVerifyCode(
    req: SendEmailVerifyCodeReq,
    config?: any,
  ) {
    return webapi.post<SendEmailVerifyCodeResp>(
      "/api/v1/UserService_SendEmailVerifyCode",
      req,
      config,
    );
  }

  // 更新用户昵称
  export function SetNickname(req: SetNicknameReq, config?: any) {
    return webapi.post<SetNicknameResp>(
      "/api/v1/UserService_SetNickname",
      req,
      config,
    );
  }

  // 登出 把cookie写成空 再302重定向
  export function SignOut(req: SignOutReq, config?: any) {
    return webapi.post<SignOutResp>("/api/v1/UserService_SignOut", req, config);
  }

  // 注销
  export function LogOff(req: LogOffReq, config?: any) {
    return webapi.post<LogOffResp>("/api/v1/UserService_LogOff", req, config);
  }

  // 通过原密码重置密码
  export function ResetPasswordByOldPassword(
    req: ResetPasswordByOldPasswordReq,
    config?: any,
  ) {
    return webapi.post<ResetPasswordByOldPasswordResp>(
      "/api/v1/UserService_ResetPasswordByOldPassword",
      req,
      config,
    );
  }
}
// Info
export interface InfoReq {}

export interface InfoResp {
  user: User;
}

export interface SetEmailReq {
  // 邮箱
  email: string;
  // 邮箱
  newEmail: string;
  // 验证码
  code: string;
}

export interface SetEmailResp {}

export interface SetNicknameReq {
  nickname: string;
}

export interface SetNicknameResp {
  user: User;
}

// SignOut
export interface SignOutReq {}

export interface SignOutResp {}

// LogOff
export interface LogOffReq {}

export interface LogOffResp {}

export interface ResetPasswordByOldPasswordReq {
  // 新密码
  newPassword: string;
  // 旧密码
  oldPassword: string;
  // 确认密码
  confirmPassword: string;
}

export interface ResetPasswordByOldPasswordResp {}

// SendEmailVerifyCodeReq
export interface SendEmailVerifyCodeReq {
  // 邮箱
  email: string;
}

export interface SendEmailVerifyCodeResp {}
