/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type OtaPlatformKind } from "../const/const_enum";
import { type OrderPure } from "../order/order_type";

export interface Review {
  id: string;
  // 平台
  otaPlatformKind: OtaPlatformKind;
  // 评论者名字
  guestName: string;
  // 房客是否评论 @bool
  guestHasReviewed: boolean;
  // 房东是否评论 @bool
  hostHasReviewed: boolean;
  // 房客评论
  guestReview?: ReviewContent;
  // 房东评论
  hostReview?: ReviewContent;
  // 评价开始时间 落库 根据过期时间减去14天计算出来 @uint64
  createdAt: string;
  // 公开时间 @uint64
  publishedAt?: string;
  // 过期时间 @uint64
  expiredAt: string;
  // 是否过期 后端根据当前时间和过期时间判断 @bool
  isExpired: boolean;
}

export interface ReviewForList {
  id: string;
  // 平台
  otaPlatformKind: OtaPlatformKind;
  // 评论者名字
  guestName: string;
  // 房客是否评论 @bool
  guestHasReviewed: boolean;
  // 房东是否评论 @bool
  hostHasReviewed: boolean;
  // 房客评论
  guestReview?: ReviewContent;
  // 仅展示
  order: OrderPure;
  // 评价开始时间 落库 根据过期时间减去14天计算出来 @uint64
  createdAt: string;
  // 公开时间 @uint64
  publishedAt?: string;
  // 过期时间 @uint64
  expiredAt: string;
  // 是否过期 后端根据当前时间和过期时间判断 @bool
  isExpired: boolean;
}

export interface ReviewContent {
  id: string;
  // 综合评分 @uint32
  overallRating: number;
  // 分类评分 是否推荐 只有房东评价才有 变成打分项
  categoryRatings: CategoryRatings[];
  // 私信评价
  privateContent: string;
  // 公开评价
  publicContent: string;
  // 评论者角色
  reviewerRole: ReviewerRole;
  // 评论者
  reviewerName: string;
  // 评论时间 @uint64
  reviewedAt?: string;
  // 回复者角色
  respondRole: ReviewerRole;
  // 回复者
  respondName: string;
  // 回复内容
  respondContent: string;
  // 回复时间 @uint64
  respondedAt?: string;
}

export interface CategoryRatings {
  // 评分分类
  categoryKind: CategoryKind;
  // 如果写了comment表示勾选了【其他】
  comment: string;
  // 评分 @uint32
  rating: number;
  // 分类评分标签
  categoryTags: CategoryTagKind[];
}
export enum ReviewerRole {
  UnknownReviewerRole = 0,
  // 房东
  Host = 1,
  // 房客
  Guest = 2,
}
export enum CategoryKind {
  UnknownCategoryKind = 0,
  // 【房东】推荐 0-1
  Recommend = 1,
  // 【房东】遵守规则 1-5
  RespectHouseRules = 2,
  // 【房东、房客】干净卫生 1-5
  Cleanliness = 3,
  // 【房东、房客】沟通顺畅 1-5
  Communication = 4,
  // 【房客】如实描述 1-5
  Accuracy = 5,
  // 【房客】地段优越 1-5
  Location = 6,
  // 【房客】入住便捷 1-5
  Checkin = 7,
  // 【房客】高性价比 1-5
  Value = 8,
}
export enum CategoryTagKind {
  UnknownCategoryTagKind = 0,
  // 推荐
  HostReviewGuestRecommend = 1,
  // 不推荐
  HostReviewGuestUnRecommend = 2,
  // Host Review Guest Positive (Cleanliness)
  HostReviewGuestPositiveNeatAndTidy = 10,
  HostReviewGuestPositiveKeptInGoodCondition = 11,
  HostReviewGuestPositiveTookCareOfGarbage = 12,
  // Host Review Guest Negative (Cleanliness)
  HostReviewGuestNegativeIgnoredCheckoutDirections = 50,
  HostReviewGuestNegativeGarbage = 51,
  HostReviewGuestNegativeMessyKitchen = 52,
  HostReviewGuestNegativeDamage = 53,
  HostReviewGuestNegativeRuinedBedLinens = 54,
  // Host Review Guest Negative (Respect House Rules)
  HostReviewGuestNegativeArrivedEarly = 100,
  HostReviewGuestNegativeStayedPastCheckout = 101,
  HostReviewGuestNegativeUnapprovedGuests = 102,
  HostReviewGuestNegativeUnapprovedPet = 103,
  HostReviewGuestNegativeDidNotRespectQuietHours = 104,
  HostReviewGuestNegativeUnapprovedFilming = 105,
  HostReviewGuestNegativeUnapprovedEvent = 106,
  HostReviewGuestNegativeSmoking = 107,
  // Host Review Guest Positive (Communication)
  HostReviewGuestPositiveHelpfulMessages = 150,
  HostReviewGuestPositiveRespectful = 151,
  HostReviewGuestPositiveAlwaysResponded = 152,
  // Host Review Guest Negative (Communication)
  HostReviewGuestNegativeUnhelpfulMessages = 200,
  HostReviewGuestNegativeDisrespectful = 201,
  HostReviewGuestNegativeUnreachable = 202,
  HostReviewGuestNegativeSlowResponses = 203,
  // Guest Review Host Positive (Accuracy)
  GuestReviewHostPositiveLookedLikePhotos = 250,
  GuestReviewHostPositiveMatchedDescription = 251,
  GuestReviewHostPositiveHadListedAmenitiesAndServices = 252,
  // Guest Review Host Negative (Accuracy)
  GuestReviewHostNegativeSmallerThanExpected = 300,
  GuestReviewHostNegativeDidNotMatchPhotos = 301,
  GuestReviewHostNegativeNeedsMaintenance = 302,
  GuestReviewHostNegativeUnexpectedFees = 303,
  GuestReviewHostNegativeExcessiveRules = 304,
  GuestReviewHostNegativeUnexpectedNoise = 305,
  GuestReviewHostNegativeInaccurateLocation = 306,
  GuestReviewHostNegativeMissingAmenity = 307,
  // Guest Review Host Positive (Check-in)
  GuestReviewHostPositiveResponsiveHost = 400,
  GuestReviewHostPositiveClearInstructions = 401,
  GuestReviewHostPositiveEasyToFind = 402,
  GuestReviewHostPositiveEasyToGetInside = 403,
  GuestReviewHostPositiveFlexibleCheckIn = 404,
  GuestReviewHostPositiveFeltAtHome = 405,
  // Guest Review Host Negative (Check-in)
  GuestReviewHostNegativeHardToLocate = 450,
  GuestReviewHostNegativeUnclearInstructions = 451,
  GuestReviewHostNegativeTroubleWithLock = 452,
  GuestReviewHostNegativeUnresponsiveHost = 453,
  GuestReviewHostNegativeHadToWait = 454,
  GuestReviewHostNegativeHardToGetInside = 455,
  // Guest Review Host Positive (Cleanliness)
  GuestReviewHostPositiveSpotlessFurnitureAndLinens = 500,
  GuestReviewHostPositiveFreeOfClutter = 501,
  GuestReviewHostPositiveSqueakyCleanBathroom = 502,
  GuestReviewHostPositivePristineKitchen = 503,
  // Guest Review Host Negative (Cleanliness)
  GuestReviewHostNegativeDirtyOrDusty = 550,
  GuestReviewHostNegativeNoticeableSmell = 551,
  GuestReviewHostNegativeStains = 552,
  GuestReviewHostNegativeExcessiveClutter = 553,
  GuestReviewHostNegativeMessyKitchen = 554,
  GuestReviewHostNegativeHairOrPetHair = 555,
  GuestReviewHostNegativeDirtyBathroom = 556,
  GuestReviewHostNegativeTrashLeftBehind = 557,
  // Guest Review Host Negative (Accuracy)
  GuestReviewHostNegativeBrokenOrMissingLock = 600,
  GuestReviewHostNegativeUnexpectedGuests = 601,
  GuestReviewHostNegativeIncorrectBathroom = 602,
  // Guest Review Host Positive (Communication)
  GuestReviewHostPositiveAlwaysResponsive = 700,
  GuestReviewHostPositiveLocalRecommendations = 701,
  GuestReviewHostPositiveProactive = 702,
  GuestReviewHostPositiveHelpfulInstructions = 703,
  GuestReviewHostPositiveConsiderate = 704,
  // Guest Review Host Negative (Communication)
  GuestReviewHostNegativeSlowToRespond = 750,
  GuestReviewHostNegativeNotHelpful = 751,
  GuestReviewHostNegativeMissingHouseInstructions = 752,
  GuestReviewHostNegativeUnclearCheckoutTasks = 753,
  GuestReviewHostNegativeInconsiderate = 754,
  GuestReviewHostNegativeExcessiveCheckoutTasks = 755,
  // Guest Review Host Positive (Location)
  GuestReviewHostPositivePeaceful = 800,
  GuestReviewHostPositiveBeautifulSurroundings = 801,
  GuestReviewHostPositivePrivate = 802,
  GuestReviewHostPositiveGreatRestaurants = 803,
  GuestReviewHostPositiveLotsToDo = 804,
  GuestReviewHostPositiveWalkable = 805,
  // Guest Review Host Negative (Location)
  GuestReviewHostNegativeNoisy = 850,
  GuestReviewHostNegativeNotMuchToDo = 851,
  GuestReviewHostNegativeBlandSurroundings = 852,
  GuestReviewHostNegativeNotPrivate = 853,
  GuestReviewHostNegativeInconvenientLocation = 854,
  // Other Categories
  AccuracyOther = 2001,
  CheckInOther = 2002,
  CleanlinessOther = 2003,
  CommunicationOther = 2004,
  LocationOther = 2005,
  RespectHouseRulesOther = 2006,
}
