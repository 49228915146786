import { type FC } from "react";
import { useRequest } from "ahooks";
import { DashboardService } from "@api/http_pms/dashboard/dashboard";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { PageLayout } from "@/common/Layout/PageLayout";
import { TomorrowStatusCard } from "./TomorrowStatusCard";
import { TodayStatusCard } from "./TodayStatusCard";
import { PercentageOfOTACard } from "./PercentageOfOTACard";
import { OccupancyRateCard } from "./OccupancyRateCard";
import { OrderListCard } from "./OrderListCard";
import { permissionHas } from "@/store/useUserInfo";

export const WorkBench: FC = () => {
  const { data, loading } = useRequest(() =>
    DashboardService.TodayOccupancy({}),
  );

  return (
    <PageLayout title="工作台">
      <div className="flex size-full flex-col gap-y-4">
        <div className="flex h-[240px] gap-x-4">
          {/* 今日状态 */}
          <TodayStatusCard statusData={data} loading={loading} />

          {/* 明日状态 */}
          <TomorrowStatusCard statusData={data} loading={loading} />
        </div>
        <div className="flex h-[316px] gap-x-4">
          {/* 入住率 */}
          <OccupancyRateCard />

          {/* OTA平台占比 */}
          <PercentageOfOTACard />
        </div>
        {permissionHas(ApiPermission.OrderView) && <OrderListCard />}
      </div>
    </PageLayout>
  );
};
