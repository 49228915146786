import { create } from "zustand";

import { type RoomGroup } from "@api/http_pms/room/room_type";
import { RoomService } from "@api/http_pms/room/room_srv";
import { type Option } from "@/common/const/interface";

interface RoomGroupStore {
  groupList: RoomGroup[];
  groupListWithDeleted: RoomGroup[]; // 包含删除
  groupOptions: Option[];
  groupMap: Record<string, RoomGroup>; // 包含删除
  groupRefresh: () => Promise<void>;
  groupSet: (groups: RoomGroup[]) => void;
  groupFetch: () => Promise<RoomGroup[]>;
}

export const useRoomGroup = create<RoomGroupStore>((set) => {
  const groupFetch = async () => {
    const res = await RoomService.AllRoomGroup({});
    return res.groups;
  };

  const groupSet = (groups: RoomGroup[]) => {
    const sortedGroups = [...groups].sort(
      (a, b) => (b.rank || 0) - (a.rank || 0),
    );
    const entries = sortedGroups.map((group) => [group.id, group]);

    const noDeleteSortedGroups = sortedGroups.filter((item) => !item.deletedAt);

    set({
      // 含删除
      groupListWithDeleted: sortedGroups,
      groupMap: Object.fromEntries(entries),
      // 不含删除
      groupList: noDeleteSortedGroups,
      groupOptions: noDeleteSortedGroups.map((group) => ({
        label: group.name,
        value: group.id,
      })),
    });
  };

  return {
    groupListWithDeleted: [],
    groupList: [],
    groupMap: {},
    groupOptions: [],
    groupSet,
    groupFetch,
    groupRefresh: async () => {
      const groups = await groupFetch();
      groupSet(groups);
    },
  };
});
