/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type Tenant } from "./tenant_type";

export namespace TenantService {
  // 根据id获取
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/TenantService_Get", req, config);
  }

  // 根据房客id列表或根据订单号查询
  export function List(req: ListReq, config?: any) {
    return webapi.post<ListResp>("/api/v1/TenantService_List", req, config);
  }

  // 添加房客
  export function BatchCreate(req: BatchCreateReq, config?: any) {
    return webapi.post<BatchCreateResp>(
      "/api/v1/TenantService_BatchCreate",
      req,
      config,
    );
  }

  // 批量删除
  export function BatchDel(req: BatchDelReq, config?: any) {
    return webapi.post<BatchDelResp>(
      "/api/v1/TenantService_BatchDel",
      req,
      config,
    );
  }

  // 修改
  export function Update(req: UpdateReq, config?: any) {
    return webapi.post<UpdateResp>("/api/v1/TenantService_Update", req, config);
  }
}
// Get
export interface GetReq {
  // 房客id
  id: string;
}

export interface GetResp {
  tenant: Tenant;
}

// List
export interface ListReq {
  // 房客id列表
  ids: string[];
  // 根据订单号查询
  orderId?: string;
}

export interface ListResp {
  tenants: Tenant[];
}

// BatchCreate
export interface BatchCreateReq {
  tenants: Tenant[];
}

export interface BatchCreateResp {
  tenants: Tenant[];
}

// BatchDel
export interface BatchDelReq {
  // 房客id列表
  ids: string[];
}

export interface BatchDelResp {
  //  @uint32
  rows: number;
}

// Update
export interface UpdateReq {
  tenant: Tenant;
}

export interface UpdateResp {
  tenant: Tenant;
}
