import { create } from "zustand";

import { RoomDayService } from "@api/http_pms/room_day/room_day_srv";
import { type Activity } from "@api/http_pms/room_day/room_day_type";

interface RoomDayActivityStore {
  activityList: Activity[];
  activityMapId: Record<string, Activity>;
  activityRefresh: () => Promise<void>;
}

export const useRoomDayActivity = create<RoomDayActivityStore>((set) => ({
  activityList: [],
  activityMapId: {},
  activityRefresh: async () => {
    const res = await RoomDayService.AllActivity({});
    set({
      activityList: res.activities,
      activityMapId: Object.fromEntries(res.activities.map((a) => [a.id, a])),
    });
  },
}));
