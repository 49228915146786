import { InputNumber, type InputNumberProps } from "@arco-design/web-react";
import { type FC } from "react";
import { apiMoneyToShowMoney, showMoneyToApiMoney } from "@/utils/money";

export type MoneyInputProps = Omit<InputNumberProps, "value" | "onChange"> & {
  value?: number;
  onChange?: (v?: number) => void;
  hasDec: boolean;
};

/** 不要直接用这个组件，用 MoneyInputWithCurrency 代替，否则你知道你要做什么 */
export const MoneyInput: FC<MoneyInputProps> = ({ hasDec, ...props }) => {
  const handleFormatter = (
    v: string | number,
    {
      userTyping,
      input,
    }: {
      userTyping: boolean;
      input: string;
    },
  ) => {
    if (v === undefined || v === "") return "";
    if (!userTyping || !hasDec) {
      const amount = apiMoneyToShowMoney(Number(v), hasDec);
      return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    input = input
      .replace(/[^\d.]+/g, "")
      .replace(/(\.\d*?)\./g, "$1")
      .replace(/(\.\d{2})\d+/, "$1");
    return input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleParser = (v: string) => {
    if (v === undefined || v === "") return "";
    v = v.replace(/[^\d.]+/g, "");
    if (!hasDec) {
      v = v.replace(/\.\d*/g, "");
    }
    const money = showMoneyToApiMoney(v, hasDec);
    return Math.max(0, Math.min(money, 100000000));
  };

  return (
    <InputNumber
      hideControl={true}
      {...props}
      formatter={handleFormatter}
      parser={handleParser}
      autoComplete="new-password"
    />
  );
};
