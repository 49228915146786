import {
  type PartitionKind,
  type Image as ApiImage,
  type EntityInfo,
} from "@api/http_pms/common/common_type";
import { Button, Progress } from "@arco-design/web-react";
import Upload, { type UploadItem } from "@arco-design/web-react/es/Upload";
import { IconRefresh } from "@arco-design/web-react/icon";
import { useCreation } from "ahooks";
import { createStore } from "hox";
import { isEmpty } from "lodash-es";
import { useEffect, useState, type FC, useRef } from "react";
import { fileUpload, makeImageUrl } from "@/utils/fileUpload";
import { genId } from "@/utils/dealWithId";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
import { IconFont } from "@/common/IconFont";
import { PictureListShow } from "../comps/PictureListShow";

export const [useUploadingStore, IsFileUploading] = createStore(
  (props: { onUploadingChange: (s: boolean) => void }) => {
    const uploadKey = useRef(new Set<string>());
    const setUploadKeyState = (key: string, isUploading: boolean) => {
      if (isUploading) {
        uploadKey.current.add(key);
        props.onUploadingChange(true);
      } else {
        uploadKey.current.delete(key);
        props.onUploadingChange(uploadKey.current.size !== 0);
      }
    };
    return { setUploadKeyState };
  },
);

export const FileUploadList: FC<{
  value?: ApiImage[];
  onChange?: (val: ApiImage[]) => void;
  entityInfo: EntityInfo;
  partition: PartitionKind;
  itemClassName?: string;
  disabled?: boolean;
}> = ({ entityInfo, partition, onChange, value, itemClassName, disabled }) => {
  const [pictureList, setPictureList] = useState<UploadItem[]>([]); // 组件内维护的图片list
  const [uidPercent, setUidPercent] = useState<Record<string, number>>({}); // 上传进度维护

  const uploadKey = useCreation(genId, []);
  const { setUploadKeyState } = useUploadingStore();
  useEffect(() => () => setUploadKeyState(uploadKey, false), []);

  useEffect(() => {
    if (isEmpty(pictureList)) {
      setPictureList(
        (value || []).map((i) => ({
          uid: i.name,
          name: i.realName,
          url: makeImageUrl(i.uri, 24, 24, "l"),
        })),
      );
    }
  }, [value]);

  if (disabled) {
    if (isEmpty(value)) return "-";
    return <PictureListShow urls={value?.map((item) => item.uri) || []} />;
  }

  return (
    <Upload
      onProgress={(f) => {
        setUidPercent((percent) => ({ ...percent, [f.uid]: f.percent || 0 }));
      }}
      customRequest={({ onProgress, onError, onSuccess, file }) => {
        const newEntityInfo = { ...entityInfo, relationId: "_tmp" };
        fileUpload(file, newEntityInfo, partition, onProgress)
          .then(onSuccess)
          .catch(onError);
      }}
      fileList={pictureList}
      onChange={(filesList, file) => {
        const doneFilesList = filesList.filter((f) => f.status === "done");
        // 初始、上传中、成功、失败都会触发onChange
        // 上传成功，修改value值
        if (file.status === "done") {
          const changeFilesList = doneFilesList.map((f) => {
            const existImage = value?.find((item) => item.name === f.uid);
            return existImage || (f.response as ApiImage);
          });
          onChange?.(changeFilesList);
        }

        // 更新上传中状态
        setUploadKeyState(uploadKey, doneFilesList.length !== filesList.length);

        // 修改组件内维护值
        setPictureList(filesList);
      }}
      listType="picture-list"
      renderUploadList={(filesList, props) => (
        <div className="mt-2 flex flex-col gap-1">
          {filesList.map((file) => {
            const url =
              file.url || URL.createObjectURL(file?.originFile as Blob);
            return (
              <Item
                key={file.uid}
                file={file}
                url={url}
                onReupload={(_file) => {
                  props?.onReupload?.(_file);
                }}
                onRemove={(_file) => {
                  void props?.onRemove?.(_file);
                }}
                percent={uidPercent[file.uid] || 0}
                itemClassName={itemClassName}
              />
            );
          })}
        </div>
      )}
      accept="image/*"
      multiple={true}
    >
      <Button type="outline" className="!bg-white" size="default">
        上传
      </Button>
    </Upload>
  );
};

const Item = ({
  file,
  url,
  onRemove,
  percent,
  itemClassName,
  onReupload,
}: {
  file: UploadItem;
  url: string;
  onReupload?: (file: UploadItem) => void;
  onRemove: (file: UploadItem) => void;
  percent: number;
  itemClassName?: string;
}) => (
  <div
    className={`flex h-10 w-full items-center justify-between rounded-md bg-white px-2 ${itemClassName}`}
  >
    <div className="flex items-center">
      {/* 重新上传 */}
      {file.status === "error" && (
        <div
          className="absolute inset-0 m-auto flex cursor-pointer flex-col items-center justify-center text-white"
          onClick={() => {
            onReupload?.(file);
          }}
        >
          <IconRefresh className="mb-3 size-6" />
          <div>重新上传</div>
        </div>
      )}
      <div className="bg-color-fill-2 relative mr-2 flex size-6 shrink-0 items-center justify-center rounded">
        {/* 进度条 */}
        {file.status !== "done" && (
          <Progress
            percent={percent}
            size="mini"
            className="absolute inset-0 m-auto flex flex-col justify-center"
            strokeWidth={1}
            trailColor="#CCCCCC"
            color="#fff"
            css={`
              .arco-progress-circle-wrapper {
                width: 14px !important;
                height: 14px !important;
              }
            `}
            status="success"
          />
        )}
        {file.status === "error" && (
          <IconRefresh className="absolute inset-0 m-auto flex size-4 flex-col justify-center text-white" />
        )}
        <img src={url} className="size-full rounded object-contain" />
      </div>
      <div>
        <OverflowTooltip>{file.name}</OverflowTooltip>
      </div>
    </div>
    <div className="flex items-center">
      <IconFont
        type="icon-delete"
        className="text-color-text-2 hover:text-color-primary-6 size-[13px] cursor-pointer"
        onClick={() => {
          onRemove(file);
        }}
      />
    </div>
  </div>
);
