import { CommonService } from "@api/http_pms/common/common_srv";
import {
  type PartitionKind,
  type EntityInfo,
} from "@api/http_pms/common/common_type";
import { getStorageUrl } from "./url";

const storageBaseUrl = getStorageUrl();

export const fileUpload = async (
  file: File,
  entityInfo: EntityInfo,
  partition: PartitionKind,
  onProgress?: (percent: number, event?: ProgressEvent) => void,
) => {
  onProgress?.(0);
  const { images, signedPutUrls } = await CommonService.BatchPresignPut({
    entityInfo,
    partition,
    realNames: [file.name],
  });

  await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", `${storageBaseUrl}${signedPutUrls[0]!}`, true);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.floor((event.loaded / event.total) * 100);
        onProgress?.(percentComplete, event);
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject(new Error(`HTTP error: ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error("Network error"));
    };
    xhr.send(file);
  });

  return images[0];
};

const imageResizeMap = {
  f: 0, // 缩放到指定尺寸
  l: 1, // 保持长宽比，缩放到长边尺寸，会有留白
  s: 2, // 保持长宽比，缩放到短边尺寸，多余裁剪
};
export const makeImageUrl = (
  uri: string,
  w: number,
  h: number,
  r: "f" | "l" | "s",
) => {
  if (uri.match(/^(blob:|(https?:)?\/\/)/)) {
    return uri;
  }
  return `${storageBaseUrl}${uri}?w=${w}&h=${h}&r=${imageResizeMap[r]}&f=webp`;
};
