import { Button, Link } from "@arco-design/web-react";
import { IconPlus, IconRefresh } from "@arco-design/web-react/icon";
import { type FC } from "react";
import { type Order } from "@api/http_pms/order/order_type";
import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { ReviewerRole } from "@api/http_pms/ota_review/ota_review_type";
import { OtaReviewService } from "@api/http_pms/ota_review/ota_review_srv";
import { HostReviewCard } from "./HostReviewCard";
import { TimeNode } from "./TimeNode";
import empty from "@/assets/review/empty.svg";
import { getCurrentTimeZoneDate } from "@/utils/orderStatus";
import { Loading } from "@/comps/Loading";
import { openPublishReview } from "@/pages/Review/PublishReview/openPublishReview";
import { OtaPlatformKind } from "@/common/const";
import { openRespondContent } from "./openRespondContent";
import { GuestReviewCard } from "./GuestReviewCard";

export const ReviewContent: FC<{ order: Order }> = ({ order }) => {
  const {
    data: otaReview,
    refresh,
    run,
    loading,
  } = useRequest(async (isRefresh) => {
    const res = await OtaReviewService.Get({
      orderId: order.id,
      sync: isRefresh,
    });
    const review = res.review;
    if (!review) {
      return undefined;
    }
    const reviews = [];
    if (
      review.guestHasReviewed &&
      review.hostHasReviewed &&
      review.guestReview?.reviewedAt &&
      review.hostReview?.reviewedAt
    ) {
      if (review.guestReview.reviewedAt > review.hostReview?.reviewedAt) {
        reviews.push(review.hostReview);
        reviews.push(review.guestReview);
      } else {
        reviews.push(review.guestReview);
        reviews.push(review.hostReview);
      }
    } else if (review.guestHasReviewed && review.guestReview) {
      reviews.push(review.guestReview);
    } else if (review.hostHasReviewed && review.hostReview) {
      reviews.push(review.hostReview);
    }
    return { ...res?.review, reviews };
  });

  return (
    <Loading loading={loading}>
      {otaReview ? (
        <div>
          <TimeNode
            nodeTitle={
              <div className="flex items-center justify-between">
                <div className="text-color-text-2">
                  {otaReview.createdAt &&
                    dayjs.unixStr(otaReview.createdAt).dateStr(true)}
                </div>
                <Link
                  icon={<IconRefresh className="size-[15px]" />}
                  onClick={() => run(true)}
                >
                  拉取最新数据
                </Link>
              </div>
            }
            content={<div>订单结束，等待评价</div>}
          />

          {otaReview.reviews.map((review, index) => {
            if (review.reviewerRole === ReviewerRole.Host) {
              return (
                <TimeNode
                  key={index}
                  nodeTitle={
                    <div className="flex items-center justify-between">
                      <div className="text-color-text-2">
                        {review.reviewedAt &&
                          dayjs.unixStr(review.reviewedAt).dateStr(true)}
                      </div>
                    </div>
                  }
                  content={
                    <div>
                      <div className="flex items-center justify-between">
                        房东发表评价
                        {review.respondedAt && (
                          <span className="text-color-text-2">房客已回复</span>
                        )}
                      </div>
                      <HostReviewCard review={review} />
                    </div>
                  }
                />
              );
            }
            return (
              <TimeNode
                key={index}
                nodeTitle={
                  <div className="flex items-center justify-between">
                    <div className="text-color-text-2">
                      {review.reviewedAt &&
                        dayjs.unixStr(review.reviewedAt).dateStr(true)}
                    </div>
                  </div>
                }
                content={
                  otaReview.publishedAt ? (
                    <div>
                      <div className="flex items-center justify-between">
                        <div>房客发表评价</div>
                        {!review.respondedAt && otaReview.publishedAt && (
                          <span
                            className="text-color-primary-6 cursor-pointer"
                            onClick={async () => {
                              if (otaReview.id) {
                                await openRespondContent({
                                  reviewId: otaReview.id,
                                });
                                refresh();
                              }
                            }}
                          >
                            回复评价
                          </span>
                        )}
                        {review.respondedAt && (
                          <span className="text-color-text-2">房东已回复</span>
                        )}
                      </div>
                      {otaReview?.otaPlatformKind && (
                        <GuestReviewCard
                          review={review}
                          otaPlatformKind={otaReview.otaPlatformKind}
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div>房客发表评价</div>
                      </div>
                      <div className="bg-color-fill-1 mt-2 rounded-xl p-3">
                        <div className="flex items-center">
                          {otaReview.otaPlatformKind && (
                            <img
                              src={
                                OtaPlatformKind[otaReview.otaPlatformKind]
                                  .iconUrlSquare
                              }
                              className="size-6 rounded-full"
                            />
                          )}

                          <span className="ml-2 font-medium">
                            {review.reviewerName}
                          </span>
                        </div>
                        <div className="text-color-primary-6 mt-3">
                          房东发表评价后可查看评价内容
                        </div>
                      </div>
                    </div>
                  )
                }
              />
            );
          })}

          {/* 评价公开 */}
          {otaReview.publishedAt && (
            <TimeNode
              nodeTitle={
                <div className="text-color-text-2">
                  {dayjs.unixStr(otaReview.publishedAt).dateStr(true)}
                </div>
              }
              content={
                otaReview.guestHasReviewed && otaReview.hostHasReviewed
                  ? "双方完成互评，评价公开"
                  : `${otaReview.guestHasReviewed ? "房东" : "房客"}未评价，评价公开`
              }
              showLine={false}
            />
          )}

          {/* 过期 */}
          {otaReview.isExpired && !otaReview.publishedAt && (
            <TimeNode
              nodeTitle={
                <div className="text-color-text-2">
                  {otaReview.expiredAt &&
                    dayjs.unixStr(otaReview.expiredAt).dateStr(true)}
                </div>
              }
              content="评价已过期，双方未评价"
              showLine={false}
            />
          )}

          {/* 未过期 */}
          {!otaReview.isExpired &&
            !otaReview.publishedAt &&
            (!otaReview.hostHasReviewed ? (
              <TimeNode
                nodeTitle={
                  <div className="flex items-center gap-2">
                    <Button
                      type="primary"
                      icon={<IconPlus />}
                      onClick={async () => {
                        if (
                          otaReview.id &&
                          otaReview.guestReview?.reviewerName
                        ) {
                          await openPublishReview({
                            reviewerName: otaReview.guestReview.reviewerName,
                            id: otaReview.id,
                          });
                          refresh();
                        }
                      }}
                    >
                      发布评价
                    </Button>
                    {!otaReview.isExpired && (
                      <div className="text-color-text-2 rounded-lg border px-2">
                        剩余
                        {otaReview.expiredAt &&
                          dayjs
                            .unixStr(otaReview.expiredAt)
                            .diff(getCurrentTimeZoneDate(), "day")}
                        天可评价
                      </div>
                    )}
                  </div>
                }
                showLine={false}
              />
            ) : (
              <TimeNode
                nodeTitle={
                  <div className="flex items-center gap-2">
                    待房客评价
                    {!otaReview.isExpired && (
                      <div className="text-color-text-2 rounded-lg border px-2">
                        剩余
                        {otaReview.expiredAt &&
                          dayjs
                            .unixStr(otaReview.expiredAt)
                            .diff(getCurrentTimeZoneDate(), "day")}
                        天可评价
                      </div>
                    )}
                  </div>
                }
                showLine={false}
              />
            ))}
        </div>
      ) : (
        <div className="mt-20 flex size-full flex-col items-center justify-center">
          <img src={empty} className="w-[200px]" />
          <span className="text-color-text-2 mt-2">
            订单进行中，评价功能尚未启用
          </span>
          <Button
            type="primary"
            icon={<IconRefresh className="size-4" />}
            className="mt-8"
            onClick={() => run(true)}
          >
            拉取最新数据
          </Button>
        </div>
      )}
    </Loading>
  );
};
