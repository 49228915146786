import { Grid, Progress, Rate } from "@arco-design/web-react";
import { type FC } from "react";
import dayjs from "dayjs";
import { type ReviewForList } from "@api/http_pms/ota_review/ota_review_type";
import publishStatus from "@/assets/review/publishStatus.png";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
// eslint-disable-next-line import/no-extraneous-dependencies
import "wc-waterfall";
import { CategoryKind } from "./const";
import { OtaPlatformKind } from "@/common/const";
import { useRoomGroup } from "@/store/useRoomGroup";
import { useRoomInfo } from "@/store/useRoomInfo";
import { LinkOrder } from "@/common/Links/LinkOrder";
import { openOrderDrawer } from "../Order/OrderDrawer";

const Row = Grid.Row;
const Col = Grid.Col;

export const PublicCardList: FC<{
  list: ReviewForList[];
  colNumber: number;
}> = ({ list, colNumber }) => {
  const { roomMapId } = useRoomInfo();
  const { groupMap } = useRoomGroup();

  return (
    <wc-waterfall gap={16} cols={colNumber}>
      {list?.map((item, idx) => (
        <div
          key={idx}
          className="cursor-pointer"
          onClick={() => {
            void openOrderDrawer({
              orderId: item.order.id,
              defaultActiveTab: "3",
            });
          }}
        >
          <div className="relative rounded-lg bg-white p-4">
            <div className="absolute left-0 top-0 h-[22px] w-[68px]">
              <span className="absolute left-2 z-[1] text-white">已公开</span>
              <img
                src={publishStatus}
                className="absolute left-0 top-0 h-[22px] w-[68px]"
              />
            </div>
            {item.hostHasReviewed && (
              <div className="text-color-text-2 absolute right-4 top-3">
                房东已评价
              </div>
            )}
            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={OtaPlatformKind[item.otaPlatformKind].iconUrlSquare}
                  className="size-6 rounded-full"
                />
                <span className="ml-2 font-medium">
                  {item.guestReview?.reviewerName}
                </span>
                <span className="text-color-text-3 ml-3">
                  {item.publishedAt &&
                    dayjs.unixStr(item.publishedAt).dateStr(true)}
                </span>
              </div>
              {item.guestHasReviewed && (
                <div className="flex items-center">
                  <span className="text-xs">综合评分</span>
                  <span className="ml-1 font-medium leading-none">
                    {item.guestReview?.overallRating}
                  </span>
                  <Rate
                    className="ml-2 text-sm"
                    value={item.guestReview?.overallRating}
                    readonly={true}
                    css={`
                      .arco-icon {
                        width: 15px;
                        height: 15px;
                      }
                      .arco-rate-character:not(:last-child) {
                        margin-right: 4px;
                      }
                      .arco-rate-inner {
                        min-height: 0px;
                      }
                    `}
                  />
                </div>
              )}
            </div>
            {!item.guestHasReviewed && (
              <div className="text-color-primary-6 mt-2">房客未评价</div>
            )}
            <div className="mt-2 w-full">
              <Row gutter={[16, 8]}>
                {item.guestReview?.categoryRatings.map(
                  (categoryRating, index) => (
                    <Col key={index} span={8}>
                      <div className="flex w-full items-center">
                        <div className="text-color-text-2 shrink-0">
                          {CategoryKind[categoryRating.categoryKind].label}
                        </div>
                        <div className="mx-2 font-medium">
                          {categoryRating.rating}
                        </div>
                        <Progress
                          percent={(categoryRating.rating / 5) * 100}
                          showText={false}
                        />
                      </div>
                    </Col>
                  ),
                )}
              </Row>
            </div>
            {item.guestReview?.publicContent &&
            item.guestReview?.privateContent ? (
              <div className="mt-2 flex w-full items-center gap-2">
                <div className="bg-color-fill-1 w-1/2 rounded p-2">
                  <OverflowTooltip line={2}>
                    <span className="text-color-primary-6">公开：</span>
                    <span className="whitespace-pre-line">
                      {item.guestReview.publicContent}
                    </span>
                  </OverflowTooltip>
                </div>
                <div className="bg-color-fill-1 w-1/2 rounded p-2">
                  <OverflowTooltip line={2}>
                    <span className="text-color-primary-6">私信：</span>
                    <span className="whitespace-pre-line">
                      {item.guestReview.privateContent}
                    </span>
                  </OverflowTooltip>
                </div>
              </div>
            ) : item.guestReview?.publicContent ||
              item.guestReview?.privateContent ? (
              <div className="bg-color-fill-1 mt-2 rounded p-2">
                <OverflowTooltip line={2}>
                  <span className="text-color-primary-6">公开/私信：</span>
                  <span className="whitespace-pre-line">
                    {item.guestReview.privateContent ||
                      item.guestReview?.publicContent}
                  </span>
                </OverflowTooltip>
              </div>
            ) : null}

            <div className="bg-color-fill-1 mt-3 flex h-9 items-center justify-between rounded px-2">
              <span>
                {roomMapId[item.order.roomId]?.groupId &&
                  groupMap[roomMapId[item.order.roomId]!.groupId]?.name}
                <span className="mx-1">#</span>
                {roomMapId[item.order.roomId]?.name}
              </span>
              <LinkOrder
                order={item.order}
                showOrderSource={false}
                orderSourceTextSmallSize={false}
              />
            </div>
          </div>
        </div>
      ))}
    </wc-waterfall>
  );
};
