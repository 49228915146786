import {
  EntityKind,
  OtaPlatformKind as ApiOtaPlatformKind,
} from "@api/http_pms/const/const_enum";
import {
  OrderService,
  type CreatePreCheckResp,
} from "@api/http_pms/order/order_srv";
import { type Order } from "@api/http_pms/order/order_type";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { TenantService } from "@api/http_pms/tenant/tenant_srv";
import {
  Button,
  Drawer,
  Form,
  Popconfirm,
  Popover,
  Tabs,
  Tooltip,
} from "@arco-design/web-react";
import { IconInfoCircle } from "@arco-design/web-react/icon";
import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import { useState } from "react";
import { createNiceModal } from "@/utils/nicemodel";
import { permissionHas } from "@/store/useUserInfo";
import { AsyncButton } from "@/comps/AsyncButton";
import { BaseInfoContent } from "./BaseInfoContent";
import {
  formToOrderBase,
  formValuesToCreateOrder,
  orderRawToForm,
} from "./BaseInfoContent/utils";
import { BillList } from "./BillContent/BillList";
import { ReasonText } from "./const";
import { Operate } from "./Operate";
import { genDefaultFormData, type DefaultOrderData } from "./uitils";
import { ReviewContent } from "./ReviewContent";
import { IconFont } from "@/common/IconFont";
import ErrorBoundary from "@/comps/ErrorBoundary";
import { Loading } from "@/comps/Loading";
import { OtaPlatformKind } from "@/common/const";
import { LogList } from "@/common/LogContent/LogList";

const { TabPane } = Tabs;

export const openOrderDrawer = createNiceModal<
  {
    orderId?: string;
    defaultOrderData?: DefaultOrderData;
    afterSubmit?: () => Promise<any>;
    defaultActiveTab?: string;
  }, // 调用的入参参数
  { order?: Order } // 调用的出参参数
>(
  ({
    _modal, // 注入的控制器，其中的props属性需要丢给实际使用的Modal组件，还有resolve、reject需要手动调用
    orderId, // 调用时的入参数
    defaultOrderData,
    afterSubmit,
    defaultActiveTab = "1",
  }) => {
    const isAdd = !orderId;
    const [form] = Form.useForm();
    const [createPreCheckReason, setCreatePreCheckReason] =
      useState<CreatePreCheckResp.Reason[]>();

    const onSubmit = async () => {
      const values = await form.validate();

      const order = await OrderService.Create(formValuesToCreateOrder(values));
      await afterSubmit?.();
      _modal.resolve({ order: order.order });
    };

    const {
      data: initOrderData,
      refreshAsync: refreshOrderData,
      mutate: mutateOrderData,
    } = useRequest(
      async () => {
        const orderRes = await OrderService.Get({
          id: orderId!,
        });
        return orderRes.order;
      },
      { manual: true },
    );

    const { data: initFormData, loading } = useRequest(async () => {
      if (orderId) {
        const [_order, tenantRes] = await Promise.all([
          refreshOrderData(),
          TenantService.List({
            ids: [],
            orderId,
          }),
        ]);
        return orderRawToForm(_order, tenantRes.tenants);
      }

      return genDefaultFormData(defaultOrderData);
    }, {});

    const onCancel = () => {
      try {
        void afterSubmit?.();
        const values = form.getFieldsValue();
        if (isEmpty(values)) {
          _modal.resolve({
            order: {
              ...initOrderData!,
            },
          });
        } else {
          const order = formToOrderBase(values);
          _modal.resolve({
            order: {
              ...initOrderData!,
              ...order,
            },
          });
        }
      } catch {
        _modal.reject();
      }
    };

    return (
      <Drawer
        title={
          <div className="flex items-center" tabIndex={0}>
            {isAdd ? "录入" : "查看"}订单
            {initOrderData?.isCancelled && initOrderData?.cancelledAt ? (
              <Tooltip
                position="right"
                content={dayjs.unixStr(initOrderData.cancelledAt).dateStr(true)}
              >
                <div className="text-color-text-3 flex cursor-pointer items-center text-sm">
                  <IconInfoCircle className="ml-2 mr-1" />
                  <span>订单已取消</span>
                </div>
              </Tooltip>
            ) : initOrderData?.cancelledAt ? (
              <Tooltip
                position="right"
                content={`截止时间：${dayjs.unixStr(initOrderData.cancelledAt).dateStr(true)}`}
              >
                <div className="text-color-text-3 flex cursor-pointer items-center text-sm">
                  <IconFont
                    type="icon-exclamation-circle"
                    className="text-color-danger-6 ml-2 mr-1"
                  />
                  <span>订单预定中</span>
                </div>
              </Tooltip>
            ) : !isEmpty(initOrderData?.conflictIds) ? (
              <Popover
                content={
                  <div className="select-text">
                    {initOrderData?.conflictIds.map((id) => (
                      <div key={id}>{id}</div>
                    ))}
                  </div>
                }
              >
                <div className="flex cursor-pointer items-center text-sm">
                  <IconFont
                    type="icon-warn"
                    className="text-color-danger-6 ml-2 mr-1"
                  />
                  <span className="text-color-text-3">订单日期冲突</span>
                </div>
              </Popover>
            ) : null}
          </div>
        }
        closeIcon={null}
        maskClosable={Boolean(orderId)}
        {..._modal.props} // 需要解构给Modal组件
        onCancel={onCancel}
        className="min-w-[480px] max-w-[720px]"
        css={`
          &.arco-drawer {
            width: 33vw !important;
          }
          .arco-drawer-content {
            padding: 8px 0 8px 16px;
          }
          .arco-drawer-footer {
            padding: 20px 16px;
          }
          .arco-tabs-horizontal {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          .arco-tabs-content {
            flex: 1;
            overflow: auto;
          }
        `}
        footer={
          isAdd ? (
            <div className="flex w-full items-center justify-start gap-3">
              <Popconfirm
                title="录入内容存在问题，请再次确认！"
                content={
                  <div className="mt-3 flex flex-col gap-2">
                    {createPreCheckReason?.map((reason) => (
                      <div key={reason} className="flex items-center">
                        <div className="bg-color-waming-6 mr-2 size-1 rounded-full" />
                        {ReasonText[reason]}
                      </div>
                    ))}
                  </div>
                }
                okText="继续保存"
                onOk={onSubmit}
                okButtonProps={{
                  style: { backgroundColor: "rgb(var(--waming-6))" },
                }}
                cancelText="检查一下"
                onCancel={() => {
                  setCreatePreCheckReason([]);
                }}
                position="top"
                popupVisible={!isEmpty(createPreCheckReason)}
              >
                <>
                  <AsyncButton
                    type="primary"
                    onClick={async () => {
                      const values = await form.validate();
                      const res = await OrderService.CreatePreCheck(
                        formValuesToCreateOrder(values),
                      );
                      if (isEmpty(res.reasons)) {
                        await onSubmit();
                      } else {
                        setCreatePreCheckReason(res.reasons);
                      }
                    }}
                  >
                    确定
                  </AsyncButton>
                </>
              </Popconfirm>
              <Button
                type="outline"
                onClick={async () => {
                  _modal.reject();
                }}
              >
                取消
              </Button>
            </div>
          ) : (
            <div className="flex w-full items-center justify-between gap-3">
              <div className="flex gap-3">
                <Button type="outline" onClick={onCancel}>
                  关闭
                </Button>
                {permissionHas(ApiPermission.OrderSuper) && (
                  <Operate
                    initOrderData={initOrderData}
                    cancelAfterSubmit={refreshOrderData}
                    deleteAfterSubmit={async () => {
                      await afterSubmit?.();
                      _modal.resolve({});
                    }}
                  />
                )}
              </div>
              <div className="flex flex-col items-start">
                <div className="text-color-text-3">
                  订单ID：
                  <span className="cursor-text select-text">{orderId}</span>
                </div>
                {initOrderData?.otaOutOrderId ? (
                  <div className="text-color-text-3">
                    {OtaPlatformKind[initOrderData.otaPlatformKind].label}-
                    {initOrderData.otaOutOrderId}
                  </div>
                ) : null}
              </div>
            </div>
          )
        }
      >
        {isAdd ? (
          <Loading loading={loading} isEmpty={isEmpty(initFormData)}>
            <BaseInfoContent form={form} initFormData={initFormData} />
          </Loading>
        ) : (
          <Loading loading={loading} isEmpty={isEmpty(initFormData)}>
            <Tabs
              defaultActiveTab={defaultActiveTab}
              css={`
                .arco-tabs-content {
                  padding-right: 16px;
                }
                .arco-tabs-header-nav::before {
                  width: calc(100% - 16px);
                }
              `}
            >
              <TabPane key="1" title="基本信息" className="overflow-y-auto">
                <ErrorBoundary>
                  <Loading loading={loading} isEmpty={isEmpty(initFormData)}>
                    <BaseInfoContent
                      initFormData={initFormData}
                      mutateOrderData={mutateOrderData}
                      form={form}
                      initOrderData={initOrderData}
                    />
                  </Loading>
                </ErrorBoundary>
              </TabPane>
              {permissionHas(ApiPermission.BillTabView) && (
                <TabPane key="2" title="财务账单" destroyOnHide={true}>
                  <ErrorBoundary>
                    <BillList orderId={orderId} />
                  </ErrorBoundary>
                </TabPane>
              )}
              {initOrderData?.otaPlatformKind === ApiOtaPlatformKind.Airbnb && (
                <TabPane key="3" title="评价详情" destroyOnHide={true}>
                  <ErrorBoundary>
                    <ReviewContent order={initOrderData} />
                  </ErrorBoundary>
                </TabPane>
              )}

              {permissionHas(ApiPermission.OrderAdvancedView) && (
                <TabPane key="4" title="日志信息" destroyOnHide={true}>
                  <ErrorBoundary>
                    <LogList
                      relationId={orderId}
                      entityKind={EntityKind.Order}
                    />
                  </ErrorBoundary>
                </TabPane>
              )}
            </Tabs>
          </Loading>
        )}
      </Drawer>
    );
  },
);
