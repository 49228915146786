import { Select, type SelectProps } from "@arco-design/web-react";
import { useCreation } from "ahooks";
import { type FC } from "react";
import { toLowerCaseSearch } from "@/utils/toLowerCaseSearch";

export const genTimeOptions = () => {
  const options = [] as { value: number; label: string }[];
  for (let i = 0; i < 24; i++) {
    options.push({
      value: i * 60,
      label: `${i.toString().padStart(2, "0")}:00`,
    });
    options.push({
      value: i * 60 + 30,
      label: `${i.toString().padStart(2, "0")}:30`,
    });
  }
  return options;
};

export const TimeSelector: FC<
  {
    value?: number;
    onChange?: (v: number) => void;
  } & SelectProps
> = ({ value, onChange, ...props }) => {
  const options = useCreation(() => genTimeOptions(), []);

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      showSearch={true}
      filterOption={(inputValue, option) =>
        toLowerCaseSearch(String(option.props.children), inputValue)
      }
      triggerProps={{
        containerScrollToClose: true,
      }}
      {...props}
    />
  );
};
