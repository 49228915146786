import {
  Form,
  type FormInstance,
  Spin,
  type RulesProps,
  type FormItemProps,
} from "@arco-design/web-react";
import { get } from "lodash-es";
import { cloneElement, useRef } from "react";
import {
  usePromiseDebounce,
  type DebounceWaitType,
} from "@/utils/usePromiseDebounce";
import { checkImCompletedFlag } from "@/hooks/usePartialControllable";

interface ItemInfo {
  field: string;
  label?: string | React.ReactNode;
  children: React.ReactElement;
  rules?: RulesProps<any>[];
  onSubmit: (val: any) => Promise<any>;
  debounceWait: DebounceWaitType;
  fastSaveMode?: boolean;
  formatter?: ((value: any) => any) | undefined;
  normalize?:
    | ((value: any, prevValue: any, allValues: Partial<any>) => any)
    | undefined;
  noStyle?: boolean;
  hidden?: boolean;
  form: FormInstance;
}

export const FastSaveFormItem = ({
  field,
  label,
  children,
  rules,
  onSubmit,
  debounceWait,
  fastSaveMode,
  formatter,
  normalize,
  noStyle,
  hidden,
  form,
  ...props
}: ItemInfo & Omit<FormItemProps, "onSubmit">) => {
  const errRef = useRef<string>();
  const finalOnSubmit = async () => {
    const val = await form.validate([field]);
    try {
      await onSubmit(get(val, field));
    } catch {
      errRef.current = "网络错误";
      void form.validate([field]);
    }
  };
  const { run, loading, stop } = usePromiseDebounce(
    finalOnSubmit,
    debounceWait,
  );

  return (
    <Form.Item
      field={field}
      label={<Spin loading={loading}>{label}</Spin>}
      rules={[
        ...(rules || []),
        {
          validator: (_, cb) => {
            cb(errRef.current);
          },
        },
      ]}
      formatter={formatter}
      normalize={normalize}
      noStyle={noStyle}
      hidden={hidden}
      {...props}
    >
      <InputComponent
        onChange={async (val) => {
          if (fastSaveMode) {
            try {
              errRef.current = undefined;
              await form.validate([field]);
              if (checkImCompletedFlag(val)) {
                stop();
              } else {
                run();
              }
            } catch {
              stop();
            }
          }
        }}
      >
        {children}
      </InputComponent>
    </Form.Item>
  );
};

interface InputComponentInfo<T> {
  children: React.ReactElement;
  onChange?: (val: T | undefined) => void;
  value?: T;
  disabled?: boolean;
}

export const InputComponent = <T,>({
  children,
  value,
  onChange,
  disabled,
}: InputComponentInfo<T>) =>
  cloneElement(children, {
    disabled: "disabled" in children.props ? children.props.disabled : disabled,
    value,
    checked: value,
    onChange: (val: T | undefined) => {
      children.props?.onChange?.(val);
      onChange?.(val);
    },
  });
