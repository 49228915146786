import { Form, Input, Modal, Rate } from "@arco-design/web-react";
import {
  CategoryKind as ApiCategoryKind,
  type CategoryRatings,
  CategoryTagKind as ApiCategoryTagKind,
  ReviewerRole,
} from "@api/http_pms/ota_review/ota_review_type";
import { useMemo } from "react";
import { OtaReviewService } from "@api/http_pms/ota_review/ota_review_srv";
import { AsyncButton } from "@/comps/AsyncButton";
import { createNiceModal } from "@/utils/nicemodel";
import { WorkbenchItemTitle } from "../../WorkBench/components/WorkbenchItemTitle";
import { RecommendInput } from "./RecommendInput";
import { CategoryKind, CategoryTagKind } from "../const";
import { CategoryTagKindInput } from "./CategoryTagKindInput";
import { required } from "@/utils/validator";

const TextArea = Input.TextArea;

export const openPublishReview = createNiceModal<
  { reviewerName: string; id: string }, // 调用的入参参数
  {} // 调用的出参参数
>(({ _modal, reviewerName, id }) => {
  const [form] = Form.useForm();
  const categoryRatings: CategoryRatings[] =
    Form.useWatch("categoryRatings", form) || [];

  const otherCategoryTagKindMap = useMemo(() => {
    const obj: Record<string, ApiCategoryTagKind> = {};
    Object.values(CategoryTagKind)
      .filter((item) => item.isOther)
      .forEach((item) => {
        if (item.categoryKind) {
          obj[item.categoryKind] = item.value;
        }
      });
    return obj;
  }, []);

  return (
    <Modal
      title={<span tabIndex={0}>发表评价</span>}
      {..._modal.props} // 需要解构给Modal组件
      className="w-[640px]"
      cancelText="删除"
      maskClosable={false}
      footer={
        <div className="flex w-full items-center justify-end">
          <AsyncButton
            type="primary"
            onClick={async () => {
              const values = await form.validate();
              const categoryRating = {
                categoryKind: ApiCategoryKind.Recommend,
                categoryTags: [
                  values.recommend
                    ? ApiCategoryTagKind.HostReviewGuestRecommend
                    : ApiCategoryTagKind.HostReviewGuestUnRecommend,
                ],
              };
              await OtaReviewService.Submit({
                id,
                reviewContent: {
                  id: "",
                  overallRating: 0,
                  categoryRatings: [...values.categoryRatings, categoryRating],
                  privateContent: values.privateContent,
                  publicContent: values.publicContent,
                  reviewerRole: ReviewerRole.Host,
                  reviewerName: "",
                  respondRole: ReviewerRole.Guest,
                  respondName: "",
                  respondContent: "",
                },
              });
              _modal.resolve({});
            }}
          >
            提交
          </AsyncButton>
        </div>
      }
      css={`
        .arco-modal-content {
          padding: 16px 24px;
          height: 60vh;
          overflow-y: auto;
        }
      `}
    >
      <Form
        form={form}
        wrapperCol={{ span: 24 }}
        size="large"
        initialValues={{
          categoryRatings: [
            { categoryKind: ApiCategoryKind.Cleanliness },
            { categoryKind: ApiCategoryKind.RespectHouseRules },
            { categoryKind: ApiCategoryKind.Communication },
          ],
        }}
      >
        <WorkbenchItemTitle className="!pl-2 text-sm">
          您对“{reviewerName}”的满意度如何？
        </WorkbenchItemTitle>

        <Form.List field="categoryRatings">
          {(fields) => (
            <div className="mt-4 flex flex-col gap-2">
              {fields.map((item, index) => (
                <div key={item.key}>
                  <div className="bg-color-fill-1 rounded-xl px-4 py-3">
                    {categoryRatings[index] && (
                      <div className="flex items-center">
                        <div className="mb-[5px] shrink-0">
                          {
                            CategoryKind[categoryRatings[index].categoryKind]
                              .label
                          }
                        </div>
                        <Form.Item
                          field={`${item.field}.rating`}
                          rules={[required("评分")]}
                          className="!mb-0"
                        >
                          <Rate
                            className="ml-2 text-sm"
                            css={`
                              .arco-icon {
                                width: 20px;
                                height: 20px;
                              }
                              .arco-rate-inner {
                                min-height: 0px;
                              }
                              .arco-rate-character-full
                                .arco-rate-character-right {
                                color: rgb(var(--primary-6));
                              }
                            `}
                          />
                        </Form.Item>
                      </div>
                    )}

                    <Form.Item
                      field={`${item.field}.categoryKind`}
                      hidden={true}
                    >
                      <Input />
                    </Form.Item>

                    {categoryRatings[index]?.rating && (
                      <>
                        <Form.Item
                          field={`${item.field}.categoryTags`}
                          noStyle={true}
                        >
                          <CategoryTagKindInput
                            categoryKind={categoryRatings[index].categoryKind}
                            isPositive={categoryRatings[index]?.rating > 3}
                            hasOther={
                              !(
                                categoryRatings[index].categoryKind ===
                                  ApiCategoryKind.RespectHouseRules &&
                                categoryRatings[index]?.rating > 3
                              )
                            }
                          />
                        </Form.Item>

                        {categoryRatings[index].categoryTags?.includes(
                          otherCategoryTagKindMap[
                            `${categoryRatings[index].categoryKind}`
                          ]!,
                        ) && (
                          <Form.Item
                            field={`${item.field}.comment`}
                            rules={[required("其他输入")]}
                          >
                            <Input className="mt-2" />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Form.List>

        <WorkbenchItemTitle className="mt-6 !pl-2 text-sm">
          您愿意推荐“{reviewerName}”给其他房东吗？
        </WorkbenchItemTitle>
        <Form.Item field="recommend" rules={[required("愿意推荐")]}>
          <RecommendInput />
        </Form.Item>

        <WorkbenchItemTitle className="mt-2 !pl-2 text-sm">
          发表评价
        </WorkbenchItemTitle>
        <div className="mt-3 flex w-full gap-3">
          <div className="flex-auto rounded-lg border">
            <div className="bg-color-fill-1 flex h-9 items-center rounded-t-lg border-b px-2">
              公开评价
            </div>
            <Form.Item field="publicContent" noStyle={true}>
              <TextArea
                placeholder="请输入内容"
                className="h-[150px] border-none"
              />
            </Form.Item>
          </div>
          <div className="flex-auto rounded-lg border">
            <div className="bg-color-fill-1 flex h-9 items-center rounded-t-lg border-b px-2">
              私信评价 (仅对方可见)
            </div>
            <Form.Item field="privateContent" noStyle={true}>
              <TextArea
                placeholder="请输入内容"
                className="h-[150px] border-none"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
});
