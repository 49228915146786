import React from "react";
import clsx from "clsx";

export const WorkbenchItemTitle = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={clsx(
      "relative  pl-4 text-base font-medium leading-[22px] text-color-text-1",
      "before:absolute before:left-0 before:top-1/2 before:h-[55%] before:w-0.5 before:-translate-y-1/2  before:content-['']",
      "before:rounded-xl before:bg-color-primary-6",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
