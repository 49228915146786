import { EntityKind } from "@api/http_pms/const/const_enum";
import { DatePicker, Form, Grid, Input, Select } from "@arco-design/web-react";
import { TelInput } from "@/common/Inputer/TelInput";
import { Nationality } from "@/common/const/nationality";
import { useConstOption } from "@/common/const/utils";
import { required, satisfyEmail } from "@/utils/validator";
import { SexRadio } from "./SexInput";
import { ImageList } from "@/common/ImageList";

const Row = Grid.Row;
const Col = Grid.Col;
export const EditFormItem = () => {
  const nationalityOptions = useConstOption(Nationality);

  return (
    <>
      <Form.Item field="id" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item field="orderId" hidden={true}>
        <Input />
      </Form.Item>
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label="房客姓名"
            field="name"
            labelCol={{ span: 6 }}
            rules={[required("房客姓名")]}
          >
            <Input placeholder="请输入姓名" autoComplete="new-password" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item field="sex" label="房客性别" labelCol={{ span: 6 }}>
            <SexRadio />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item field="tel" label="联系方式" labelCol={{ span: 6 }}>
            <TelInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            field="email"
            label="邮箱地址"
            rules={[satisfyEmail("onBlur")]}
            labelCol={{ span: 6 }}
          >
            <Input placeholder="请输入邮箱地址" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            field="nationality"
            label="国籍/地域"
            labelCol={{ span: 6 }}
            formatter={(d) => d || undefined}
          >
            <Select
              placeholder="请选择国籍"
              options={nationalityOptions}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.props.children
                  ?.toLowerCase()
                  .includes(inputValue?.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item field="birthday" label="出生日期" labelCol={{ span: 6 }}>
            <DatePicker placeholder="请选择出生日期" className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item field="profession" label="职业" labelCol={{ span: 6 }}>
            <Input placeholder="请输入职业" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item field="address" label="居住地址" labelCol={{ span: 6 }}>
            <Input placeholder="请输入居住地址" autoComplete="new-password" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            field="images"
            label="附件"
            className="!mb-0"
            labelCol={{ span: 6 }}
            css={`
              .picture-card-item {
                height: 86px !important;
                width: 86px !important;
              }
              .arco-upload-trigger-picture {
                height: 86px !important;
                width: 86px !important;
              }
              .main-image {
                display: none;
              }
            `}
          >
            <ImageList
              entityInfo={{
                entityKind: EntityKind.Tenant,
                relationId: "_tmp",
                relationSubId: "",
              }}
              partition={0}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
