import {
  Cascader,
  Checkbox,
  Empty,
  type CascaderProps,
} from "@arco-design/web-react";
import { useMemo, useState, type FC } from "react";
import { type RoomGroup, type RoomSimple } from "@api/http_pms/room/room_type";
import clsx from "clsx";
import { IconClose } from "@arco-design/web-react/icon";
import { useRoomGroup } from "@/store/useRoomGroup";
import { useRoomInfo } from "@/store/useRoomInfo";
import { IconFont } from "../IconFont";
import { TextHighLight } from "../../comps/TextHighLight";

/** 候选项 */
interface CandidateOption {
  value: string;
  label: string;
  isDeleted: boolean;
  children: {
    value: string;
    label: string;
    isClosed: boolean;
    isDeleted: boolean;
  }[];
}

export const isRoomClosed = (room: RoomSimple | undefined) =>
  Boolean(room?.isClosed || room?.deletedAt);

export const isRoomDeleted = (room: RoomSimple | undefined) =>
  Boolean(room?.deletedAt);

export const isRoomGroupDeleted = (roomGroup: RoomGroup | undefined) =>
  Boolean(roomGroup?.deletedAt);

export const RoomSelector: FC<
  Omit<CascaderProps, "value" | "onChange"> &
    (
      | {
          mode: "multiple";
          value?: string[];
          onChange?: (val: string[]) => void;
        }
      | {
          mode?: undefined;
          value?: string;
          onChange?: (val: string) => void;
        }
    ) & {
      includeClosedRooms?: boolean;
    }
> = ({ value, onChange, includeClosedRooms, ...props }) => {
  const { roomsWithClosedMapGroupId, roomMapId } = useRoomInfo();
  const { groupMap, groupListWithDeleted } = useRoomGroup();
  const [search, setSearch] = useState<string>();
  const [visible, setVisible] = useState(false);
  const isMultiple = props.mode === "multiple";

  // 给组件的值
  const cascaderValue = useMemo(() => {
    if (value instanceof Array) {
      return value
        .filter((item) => roomMapId[item])
        .map((item) => {
          const room = roomMapId[item]!;
          return [room.groupId, room.id];
        });
    }
    const room = value ? roomMapId[value] : undefined;
    return room ? [room.groupId, room.id] : undefined;
  }, [value]);

  // 从组件回传的值
  const cascaderHandleChange = (val: (string | string[])[] | undefined) => {
    if (isMultiple) {
      const roomIds = val?.map((item) => item[1]!);
      // @ts-expect-error 内部不会自动判断，这里props.mode === "multiple"一定是数组
      onChange?.(roomIds);
    } else {
      // @ts-expect-error 内部不会自动判断，理由同上
      onChange?.(val?.[1]);
    }
  };

  // 最终房间-分组map

  // 最终options
  const [finalOptions, finalOptionsMapGroupId] = useMemo(() => {
    const finalGroupList = includeClosedRooms
      ? groupListWithDeleted.filter((g) => !isRoomGroupDeleted(g))
      : groupListWithDeleted;

    const _finalOptions: CandidateOption[] = [];
    const _finalOptionsMapGroupId: Record<string, CandidateOption> = {};
    finalGroupList.forEach((group) => {
      const rooms = roomsWithClosedMapGroupId[group.id] || [];
      const closedRooms: RoomSimple[] = [];
      const finalRooms: RoomSimple[] = [];
      rooms.forEach((room) => {
        if (!isRoomClosed(room)) {
          finalRooms.push(room);
        } else if (includeClosedRooms) {
          closedRooms.push(room);
        }
      });
      finalRooms.push(...closedRooms);

      if (finalRooms.length) {
        const res = {
          value: group.id,
          label: group.name,
          isDeleted: isRoomGroupDeleted(group),
          children: finalRooms.map((room) => ({
            value: room.id,
            label: room.name,
            isClosed: isRoomClosed(room),
            isDeleted: isRoomDeleted(room),
          })),
        };
        _finalOptions.push(res);
        _finalOptionsMapGroupId[group.id] = res;
      }
    });

    return [_finalOptions, _finalOptionsMapGroupId];
  }, [includeClosedRooms, groupListWithDeleted, roomsWithClosedMapGroupId]);

  const aggregateSet = useMemo(() => {
    const roomIdSet = new Set(
      value ? (Array.isArray(value) ? value : [value]) : undefined,
    );
    const groupIdSet = new Set<string>();
    roomIdSet.forEach(
      (id) => roomMapId[id] && groupIdSet.add(roomMapId[id].groupId),
    );

    groupIdSet.forEach((groupId) => {
      const fullRooms = finalOptionsMapGroupId[groupId]?.children || [];
      if (fullRooms.every((r) => roomIdSet.has(r.value))) {
        fullRooms.forEach((r) => roomIdSet.delete(r.value));
        roomIdSet.add(groupId);
      }
    });

    return roomIdSet;
  }, [value, finalOptionsMapGroupId, roomMapId]);

  return (
    <Cascader
      value={cascaderValue}
      onChange={cascaderHandleChange}
      triggerProps={{
        containerScrollToClose: true,
      }}
      placeholder="请选择订单房间"
      options={finalOptions}
      showSearch={true}
      onSearch={(val) => setSearch(val)}
      popupVisible={visible}
      onVisibleChange={setVisible}
      {...props}
      // 单选时的渲染
      renderFormat={
        !isMultiple ? () => showItem(value as string, true) : undefined
      }
      // 级联选项
      renderOption={(node, level) => {
        const name =
          level === 0
            ? groupMap[node.value]?.name
            : roomMapId[node.value]?.name;
        const isDeleted =
          level === 0
            ? isRoomGroupDeleted(groupMap[node.value])
            : isRoomDeleted(roomMapId[node.value]);
        const isClosed = level === 1 && isRoomClosed(roomMapId[node.value]);

        return (
          <span
            className={clsx(
              {
                "line-through text-color-text-3": isDeleted || isClosed,
              },
              "flex items-center",
            )}
          >
            {name}
            {isDeleted ? (
              <IconFont
                type="icon-tingyong"
                className="text-color-waming-7 ml-2 size-4"
              />
            ) : isClosed ? (
              <IconFont type="icon-solid-delete" className="ml-2 size-5" />
            ) : null}
          </span>
        );
      }}
      renderTag={(_, index) => {
        if (index !== 0) {
          return undefined;
        }
        const maxTags = [...aggregateSet].slice(
          0,
          typeof props.maxTagCount === "number" ? props.maxTagCount : undefined,
        );
        return (
          <span className="flex flex-wrap items-center">
            {maxTags.map((tagId, _index) => (
              <span
                className="my-px mr-1 flex h-7 items-center rounded-lg border px-2 text-xs"
                key={tagId}
              >
                {showItem(tagId)}
                {!props.disabled && (
                  <div className="hover:bg-color-fill-2 ml-1 flex size-[16px] cursor-pointer items-center justify-center rounded-full">
                    <IconClose
                      className="text-xs"
                      onClick={() => {
                        const _value = value as string[] | undefined;
                        const _onChange = onChange as (v?: string[]) => void;
                        if (finalOptionsMapGroupId[tagId]) {
                          const _valueSet = new Set(value);
                          finalOptionsMapGroupId[tagId].children.forEach(
                            (c) => {
                              _valueSet.delete(c.value);
                            },
                          );
                          _onChange?.([..._valueSet]);
                        } else {
                          _onChange?.(_value?.filter((v) => v !== tagId));
                        }
                      }}
                    />
                  </div>
                )}
              </span>
            ))}
            {aggregateSet.size > maxTags.length && (
              <div className="my-px mr-1 flex h-7 items-center rounded-lg border px-2 text-xs">
                <span>+{aggregateSet.size - maxTags.length}</span>
              </div>
            )}
          </span>
        );
      }}
      dropdownRender={
        search
          ? () =>
              searchDropdownRender(
                finalOptions,
                aggregateSet,
                search,
                isMultiple,
                () => setVisible(false),
                value,
                onChange,
              )
          : undefined
      }
    />
  );
};

// 输入框内容自定义显示，单选要显示删除的icon
const showItem = (id: string, showIcon?: boolean) => {
  const roomMapId = useRoomInfo.getState().roomMapId;
  const groupMap = useRoomGroup.getState().groupMap;

  const room = id ? roomMapId[id] : undefined;
  const group = room ? groupMap[room.groupId] : groupMap[id];

  return (
    <span>
      {group && (
        <span
          className={clsx({
            "line-through text-color-text-3": isRoomGroupDeleted(group),
          })}
        >
          {group?.name}
        </span>
      )}
      {group && room && <span className="mx-1">#</span>}
      {room && (
        <>
          <span
            className={clsx({
              "line-through text-color-text-3":
                isRoomClosed(room) || isRoomDeleted(room),
            })}
          >
            {room.name}
          </span>
          {showIcon ? (
            isRoomDeleted(room) ? (
              <IconFont type="icon-solid-delete" className="ml-2 size-5" />
            ) : isRoomClosed(room) ? (
              <IconFont
                type="icon-tingyong"
                className="text-color-waming-7 ml-2 size-4"
              />
            ) : null
          ) : null}
        </>
      )}
    </span>
  );
};

const searchDropdownRender = (
  candidateOptions: CandidateOption[],
  aggregateSet: Set<string>,
  searchText: string,
  isMultiple: boolean,
  closeDropdown: () => void,
  value?: string[] | string,
  onChange?: ((val: string[]) => void) | ((val: string) => void),
) => {
  const lowerSearch = searchText.toLowerCase();

  const searchOptions: {
    label: string;
    value: string;
    checked: boolean;
    group?: string[];
    closed: boolean;
    deleted: boolean;
  }[] = [];
  candidateOptions.forEach((opt) => {
    if (opt.label.toLowerCase().includes(lowerSearch)) {
      const group = opt.children?.map((c) => c.value);
      if (isMultiple && group && group?.length > 1) {
        searchOptions.push({
          label: opt.label,
          value: opt.value,
          checked: aggregateSet.has(opt.value),
          group,
          deleted: opt.isDeleted,
          closed: opt.isDeleted,
        });
      }
      const appendChildren =
        opt.children?.map((c) => ({
          label: `${opt.label} # ${c.label}`,
          value: c.value,
          checked: aggregateSet.has(c.value) || aggregateSet.has(opt.value),
          closed: c.isClosed,
          deleted: opt.isDeleted || c.isDeleted,
        })) || [];
      searchOptions.push(...appendChildren);
    } else {
      opt.children?.forEach((c) => {
        if (c.label.toLowerCase().includes(lowerSearch)) {
          searchOptions.push({
            label: `${opt.label} # ${c.label}`,
            value: c.value,
            checked: aggregateSet.has(c.value) || aggregateSet.has(opt.value),
            closed: c.isClosed,
            deleted: opt.isDeleted || c.isDeleted,
          });
        }
      });
    }
  });

  return (
    <div
      className="max-h-[200px] min-w-[120px] overflow-y-auto py-1"
      css={`
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      `}
    >
      {!searchOptions?.length ? (
        <Empty />
      ) : (
        searchOptions.map((cur) => (
          <div
            key={cur.value}
            className="flex h-9 cursor-pointer items-center pl-3 pr-6 hover:bg-gray-100"
            onClick={() => {
              const _onChange = onChange as any;
              if (!isMultiple) {
                _onChange?.(cur.value);
                closeDropdown();
                return;
              }
              const _value = value as string[];
              if (cur.checked) {
                if (cur.group) {
                  const groupSet = new Set(cur.group);
                  _onChange?.(_value?.filter((v) => !groupSet.has(v)) || []);
                } else {
                  _onChange?.(_value?.filter((v) => v !== cur.value) || []);
                }
              } else {
                const valueSet = new Set(_value);
                if (cur.group) {
                  cur.group.forEach((g) => valueSet.add(g));
                } else {
                  valueSet.add(cur.value);
                }
                _onChange?.([...valueSet]);
              }
            }}
          >
            {isMultiple && <Checkbox checked={cur.checked} />}
            <span
              className={clsx("ml-2 flex items-center", {
                "line-through text-color-text-3": cur.closed,
              })}
            >
              <TextHighLight text={cur.label} keyword={searchText} />
              {cur.deleted ? (
                <IconFont type="icon-solid-delete" className="ml-2 size-5" />
              ) : cur.closed ? (
                <IconFont
                  type="icon-tingyong"
                  className="text-color-waming-7 ml-2"
                />
              ) : null}
            </span>
          </div>
        ))
      )}
    </div>
  );
};
