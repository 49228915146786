import { BillKind as ApiBillKind } from "@api/http_pms/bill/bill_type";
import {
  Area as ApiArea,
  Currency as ApiCurrency,
  Language as ApiLanguage,
  OtaPlatformKind as ApiOtaPlatformKind,
  Color,
} from "@api/http_pms/const/const_enum";
import { Capability as ApiCapability } from "@api/http_pms/org/org_type";
import {
  HolidayKind as ApiHolidayKind,
  Room as ApiRoom,
} from "@api/http_pms/room/room_type";
import { IconKind as ApiIconKind } from "@api/http_pms/wallet/wallet_type";
import { Tenant } from "@api/http_pms/tenant/tenant_type";
import { CheckInKind as ApiCheckInKind } from "@api/http_pms/order/order_type";
import { type ConstOptionMap } from "./interface";
import agoda from "@/assets/otaPlatformKind/agoda.jpeg";
import airbnb from "@/assets/otaPlatformKind/airbnb.jpeg";
import booking from "@/assets/otaPlatformKind/booking.jpeg";
import ctrip from "@/assets/otaPlatformKind/ctrip.jpeg";
import expedia from "@/assets/otaPlatformKind/expedia.jpeg";
import fliggy from "@/assets/otaPlatformKind/fliggy.jpeg";
import jalan from "@/assets/otaPlatformKind/jalan.jpeg";
import meituan from "@/assets/otaPlatformKind/meituan.jpeg";
import muniao from "@/assets/otaPlatformKind/muniao.jpeg";
import rakutenTravel from "@/assets/otaPlatformKind/rakutenTravel.jpeg";
import trip from "@/assets/otaPlatformKind/trip.jpeg";
import tujia from "@/assets/otaPlatformKind/tujia.jpeg";
import vrbo from "@/assets/otaPlatformKind/vrbo.jpeg";
import xiaozhu from "@/assets/otaPlatformKind/xiaozhu.jpeg";
import xiaohongshu from "@/assets/otaPlatformKind/xiaohongshu.jpeg";
import douyin from "@/assets/otaPlatformKind/douyin.jpeg";
import agodaSquare from "@/assets/otaPlatformKind/agodaSquare.jpeg";
import airbnbSquare from "@/assets/otaPlatformKind/airbnbSquare.jpeg";
import bookingSquare from "@/assets/otaPlatformKind/bookingSquare.jpeg";
import ctripSquare from "@/assets/otaPlatformKind/ctripSquare.jpeg";
import expediaSquare from "@/assets/otaPlatformKind/expediaSquare.jpeg";
import fliggySquare from "@/assets/otaPlatformKind/fliggySquare.jpeg";
import jalanSquare from "@/assets/otaPlatformKind/jalanSquare.jpeg";
import meituanSquare from "@/assets/otaPlatformKind/meituanSquare.jpeg";
import muniaoSquare from "@/assets/otaPlatformKind/muniaoSquare.jpeg";
import rakutenTravelSquare from "@/assets/otaPlatformKind/rakutenTravelSquare.jpeg";
import tripSquare from "@/assets/otaPlatformKind/tripSquare.jpeg";
import tujiaSquare from "@/assets/otaPlatformKind/tujiaSquare.jpeg";
import vrboSquare from "@/assets/otaPlatformKind/vrboSquare.jpeg";
import xiaozhuSquare from "@/assets/otaPlatformKind/xiaozhuSquare.jpeg";
import defaultImg from "@/assets/otaPlatformKind/default.jpeg";
import reservationSquare from "@/assets/otaPlatformKind/reservation.jpeg";
import xiaohongshuSquare from "@/assets/otaPlatformKind/xiaohongshuSquare.jpeg";
import douyinSquare from "@/assets/otaPlatformKind/douyinSquare.jpeg";

export const Currency: ConstOptionMap<
  ApiCurrency,
  { letterLabel: string; symbol: string; hasDecimal: boolean }
> = {
  [ApiCurrency.UnknownCurrency]: {
    value: ApiCurrency.UnknownCurrency,
    label: "",
    letterLabel: "",
    symbol: "¥",
    hidden: true,
    hasDecimal: false,
  },
  [ApiCurrency.JPY]: {
    value: ApiCurrency.JPY,
    label: "JPY - 日元",
    letterLabel: "JPY",
    symbol: "¥",
    hasDecimal: false,
  },
  [ApiCurrency.CNY]: {
    value: ApiCurrency.CNY,
    label: "CNY - 元",
    letterLabel: "CNY",
    symbol: "¥",
    hasDecimal: true,
  },
  [ApiCurrency.USD]: {
    value: ApiCurrency.USD,
    label: "USD - 美元",
    letterLabel: "USD",
    symbol: "$",
    hasDecimal: true,
  },
};

export const Sex: ConstOptionMap<Tenant.Sex> = {
  [Tenant.Sex.Unknown]: {
    value: Tenant.Sex.Unknown,
    label: "",
    hidden: true,
  },
  [Tenant.Sex.Male]: {
    value: Tenant.Sex.Male,
    label: "先生",
  },
  [Tenant.Sex.Female]: {
    value: Tenant.Sex.Female,
    label: "女士",
  },
};

export const BaseColor: ConstOptionMap<
  Color,
  {
    code: string;
    bg: string;
    border: string;
  }
> = {
  [Color.Color_0]: {
    value: Color.Color_0,
    label: "Color_0",
    code: "#6F9F0A",
    bg: "#F4F7EC",
    border: "#DFE9C8",
  },
  [Color.Color_1]: {
    value: Color.Color_1,
    label: "Color_1",
    code: "#FF6F00",
    bg: "#FFF4EB",
    border: "#FFE8D6",
  },
  [Color.Color_2]: {
    value: Color.Color_2,
    label: "Color_2",
    code: "#FFAB00",
    bg: "#FFF8EB",
    border: "#FFF1D6",
  },
  [Color.Color_3]: {
    value: Color.Color_3,
    label: "Color_3",
    code: "#FF2D55",
    bg: "#FFEFF2",
    border: "#FFDDE4",
  },
  [Color.Color_4]: {
    value: Color.Color_4,
    label: "Color_4",
    code: "#5AD8A6",
    bg: "#E9FFF6",
    border: "#DDF4EB",
  },
  [Color.Color_5]: {
    value: Color.Color_5,
    label: "Color_5",
    code: "#56C6FF",
    bg: "#E7F8FF",
    border: "#E1F0F7",
  },
  [Color.Color_6]: {
    value: Color.Color_6,
    label: "Color_6",
    code: "#1173E5",
    bg: "#D3E8FF",
    border: "#C8DAF1",
  },
  [Color.Color_7]: {
    value: Color.Color_7,
    label: "Color_7",
    code: "#651EFE",
    bg: "#F3EDFF",
    border: "#E6DBFF",
  },
};

export const HolidayKind: ConstOptionMap<ApiHolidayKind> = {
  [ApiHolidayKind.UnknownHoliday]: {
    value: ApiHolidayKind.UnknownHoliday,
    label: "",
    hidden: true,
  },
  [ApiHolidayKind.ChineseHolidays]: {
    value: ApiHolidayKind.ChineseHolidays,
    label: "中国节假日",
  },
  [ApiHolidayKind.JapaneseHolidays]: {
    value: ApiHolidayKind.JapaneseHolidays,
    label: "日本节假日",
  },
};

export const OccupyStatus: ConstOptionMap<ApiRoom.OccupyStatus> = {
  [ApiRoom.OccupyStatus.Vacant]: {
    value: ApiRoom.OccupyStatus.Vacant,
    label: "空房",
  },
  [ApiRoom.OccupyStatus.Occupied]: {
    value: ApiRoom.OccupyStatus.Occupied,
    label: "入住中",
  },
  [ApiRoom.OccupyStatus.Uncleaned]: {
    value: ApiRoom.OccupyStatus.Uncleaned,
    label: "待清扫",
  },
};

export const TimeZone: ConstOptionMap<string> = {
  "Asia/Shanghai": {
    value: "Asia/Shanghai",
    label: "Asia/Shanghai(UTC+8)",
  },
  "Asia/Tokyo": {
    value: "Asia/Tokyo",
    label: "Asia/Tokyo(UTC+9)",
  },
  "America/New_York": {
    value: "America/New_York",
    label: "America/New_York(UTC-5)",
  },
};

export const CountryCode: ConstOptionMap<string> = {
  "+86": {
    label: "+86",
    value: "+86",
  },
  "+81": {
    label: "+81",
    value: "+81",
  },
};

export interface OtaSupportField {
  supportWeeklyPriceDiscount?: boolean;
  supportMonthlyPriceDiscount?: boolean;
  supportHeadMoney?: boolean;
  supportHeadMoneyDisabled?: boolean;
  supportMinNights?: boolean;
  supportCleaningFee?: boolean;
  supportRoomTitleDisabled?: boolean;
}

export const OtaPlatformKind: ConstOptionMap<
  ApiOtaPlatformKind,
  {
    /* 平台对应色值 */
    color?: string;
    iconUrl?: string; // 房态使用，圆形
    iconUrlSquare?: string; // 方形图标
    linkAccount?: "link" | "outLink";
  } & OtaSupportField
> = {
  [ApiOtaPlatformKind.UnknownOtaPlatform]: {
    value: ApiOtaPlatformKind.UnknownOtaPlatform,
    label: "无",
    rank: 0,
    iconUrlSquare: defaultImg,
    color: "#cccccc",
  },
  [ApiOtaPlatformKind.Airbnb]: {
    value: ApiOtaPlatformKind.Airbnb,
    label: "Airbnb",
    color: "#ff3b2d",
    rank: 100,
    iconUrl: airbnb,
    iconUrlSquare: airbnbSquare,
    linkAccount: "outLink",
    supportWeeklyPriceDiscount: true,
    supportMonthlyPriceDiscount: true,
    supportHeadMoney: true,
    supportMinNights: true,
    supportCleaningFee: true,
  },
  [ApiOtaPlatformKind.Booking]: {
    value: ApiOtaPlatformKind.Booking,
    label: "Booking",
    color: "#424fff",
    rank: 99,
    iconUrl: booking,
    iconUrlSquare: bookingSquare,
  },
  [ApiOtaPlatformKind.Expedia]: {
    value: ApiOtaPlatformKind.Expedia,
    label: "Expedia",
    color: "#1e9493",
    rank: 98,
    iconUrl: expedia,
    iconUrlSquare: expediaSquare,
  },
  [ApiOtaPlatformKind.Agoda]: {
    value: ApiOtaPlatformKind.Agoda,
    label: "Agoda",
    color: "#28ddb9",
    rank: 97,
    iconUrl: agoda,
    iconUrlSquare: agodaSquare,
  },
  [ApiOtaPlatformKind.Tujia]: {
    value: ApiOtaPlatformKind.Tujia,
    label: "途家",
    color: "#ff6f00",
    rank: 96,
    iconUrl: tujia,
    iconUrlSquare: tujiaSquare,
    linkAccount: "outLink",
    supportHeadMoney: true,
    supportHeadMoneyDisabled: true,
    supportCleaningFee: true,
    supportRoomTitleDisabled: true,
  },
  [ApiOtaPlatformKind.Ctrip]: {
    value: ApiOtaPlatformKind.Ctrip,
    label: "携程",
    color: "#5fd3ff",
    rank: 95,
    iconUrl: ctrip,
    iconUrlSquare: ctripSquare,
  },
  [ApiOtaPlatformKind.Xiaohongshu]: {
    value: ApiOtaPlatformKind.Xiaohongshu,
    label: "小红书",
    color: "#e70000",
    rank: 94,
    iconUrl: xiaohongshu,
    iconUrlSquare: xiaohongshuSquare,
  },
  [ApiOtaPlatformKind.Trip]: {
    value: ApiOtaPlatformKind.Trip,
    label: "Trip",
    color: "#3fa2ff",
    rank: 93,
    iconUrl: trip,
    iconUrlSquare: tripSquare,
    linkAccount: "link",
  },
  [ApiOtaPlatformKind.Vrbo]: {
    value: ApiOtaPlatformKind.Vrbo,
    label: "Vrbo",
    color: "#0041B0",
    rank: 92,
    iconUrl: vrbo,
    iconUrlSquare: vrboSquare,
  },
  [ApiOtaPlatformKind.Jalan]: {
    value: ApiOtaPlatformKind.Jalan,
    label: "Jalan",
    color: "#CF6000",
    rank: 91,
    iconUrl: jalan,
    iconUrlSquare: jalanSquare,
  },
  [ApiOtaPlatformKind.RakutenTravel]: {
    value: ApiOtaPlatformKind.RakutenTravel,
    label: "RakutenTravel",
    color: "#43C600",
    rank: 90,
    iconUrl: rakutenTravel,
    iconUrlSquare: rakutenTravelSquare,
  },
  [ApiOtaPlatformKind.Meituan]: {
    value: ApiOtaPlatformKind.Meituan,
    label: "美团",
    color: "#ffc718",
    rank: 89,
    iconUrl: meituan,
    iconUrlSquare: meituanSquare,
  },
  [ApiOtaPlatformKind.Xiaozhu]: {
    value: ApiOtaPlatformKind.Xiaozhu,
    label: "小猪",
    color: "#ff99c3",
    rank: 88,
    iconUrl: xiaozhu,
    iconUrlSquare: xiaozhuSquare,
  },
  [ApiOtaPlatformKind.Muniao]: {
    value: ApiOtaPlatformKind.Muniao,
    label: "木鸟",
    color: "#faad14",
    rank: 87,
    iconUrl: muniao,
    iconUrlSquare: muniaoSquare,
  },
  [ApiOtaPlatformKind.Fliggy]: {
    value: ApiOtaPlatformKind.Fliggy,
    label: "飞猪",
    color: "#F5E408",
    rank: 86,
    iconUrl: fliggy,
    iconUrlSquare: fliggySquare,
  },
  [ApiOtaPlatformKind.Douyin]: {
    value: ApiOtaPlatformKind.Douyin,
    label: "抖音",
    color: "#7e05ff",
    rank: 85,
    iconUrl: douyin,
    iconUrlSquare: douyinSquare,
  },
  [ApiOtaPlatformKind.Reservation]: {
    value: ApiOtaPlatformKind.Reservation,
    label: "微店",
    color: "#F5E408",
    rank: 84,
    iconUrlSquare: reservationSquare,
  },
};

export const Capability: ConstOptionMap<ApiCapability> = {
  [ApiCapability.Unknown]: {
    value: ApiCapability.OtaBase,
    label: "",
  },
  [ApiCapability.OtaBase]: {
    value: ApiCapability.OtaBase,
    label: "基础的OTA能力",
  },
  [ApiCapability.OtaMessage]: {
    value: ApiCapability.OtaMessage,
    label: "OTA消息",
  },
};

export const Language: ConstOptionMap<ApiLanguage> = {
  [ApiLanguage.en]: {
    value: ApiLanguage.en,
    label: "EN 英语",
  },
  [ApiLanguage.ja]: {
    value: ApiLanguage.ja,
    label: "JA 日语",
  },
  [ApiLanguage.zh_CN]: {
    value: ApiLanguage.zh_CN,
    label: "ZH_CN 简体中文",
  },
};

export const Area: ConstOptionMap<ApiArea> = {
  [ApiArea.UnknownArea]: {
    value: ApiArea.UnknownArea,
    label: "",
    hidden: true,
  },
  [ApiArea.US]: {
    value: ApiArea.US,
    label: "美国",
  },
  [ApiArea.JP]: {
    value: ApiArea.JP,
    label: "日本",
  },
  [ApiArea.CN]: {
    value: ApiArea.CN,
    label: "中国",
  },
};

export const IconKind: ConstOptionMap<
  ApiIconKind,
  {
    value: ApiIconKind;
    label: string;
    icon: string;
  }
> = {
  [ApiIconKind.None]: {
    value: ApiIconKind.None,
    label: "默认",
    icon: "",
  },
  [ApiIconKind.AliPay]: {
    value: ApiIconKind.AliPay,
    label: "支付宝",
    icon: "icon-trademark-zhifubao",
  },
  [ApiIconKind.WxPay]: {
    value: ApiIconKind.WxPay,
    label: "微信",
    icon: "icon-trademark-weixin",
  },
  [ApiIconKind.Bank]: {
    value: ApiIconKind.Bank,
    label: "银行账户",
    icon: "icon-trademark-bank",
  },
};
// 收支类型
export const BillKind: ConstOptionMap<
  ApiBillKind,
  {
    amountType: number | undefined; // 1收入，-1支出，undefined不限制
  }
> = {
  [ApiBillKind.Unknown]: {
    value: ApiBillKind.Unknown,
    label: "",
    hidden: true,
    amountType: undefined,
    rank: 16,
  },
  [ApiBillKind.RoomFee]: {
    value: ApiBillKind.RoomFee,
    label: "房费收入",
    amountType: 1,
    rank: 15,
  },
  [ApiBillKind.ExtraRoomFee]: {
    value: ApiBillKind.ExtraRoomFee,
    label: "额外房费",
    amountType: 1,
    rank: 14,
  },
  [ApiBillKind.Refund]: {
    value: ApiBillKind.Refund,
    label: "房费退款",
    amountType: -1,
    rank: 13,
  },
  [ApiBillKind.OtaFee]: {
    value: ApiBillKind.OtaFee,
    label: "佣金收入",
    amountType: 1,
    rank: 12,
  },
  [ApiBillKind.OtaRefund]: {
    value: ApiBillKind.OtaRefund,
    label: "佣金退款",
    amountType: -1,
    rank: 11,
  },
  [ApiBillKind.Deposit]: {
    value: ApiBillKind.Deposit,
    label: "押金",
    amountType: 1,
    rank: 10,
  },
  [ApiBillKind.Claims]: {
    value: ApiBillKind.Claims,
    label: "理赔",
    amountType: 1,
    rank: 9,
  },
  [ApiBillKind.Extra]: {
    value: ApiBillKind.Extra,
    label: "杂费",
    amountType: -1,
    rank: 8,
  },
  [ApiBillKind.ExtraIncome]: {
    value: ApiBillKind.ExtraIncome,
    label: "特别收入",
    amountType: 1,
    rank: 7,
  },
  [ApiBillKind.TransferAccounts]: {
    value: ApiBillKind.TransferAccounts,
    label: "转账",
    amountType: undefined,
    rank: 6,
  },
  [ApiBillKind.Clear]: {
    value: ApiBillKind.Clear,
    label: "清扫费",
    amountType: -1,
    rank: 5,
  },
  [ApiBillKind.Water]: {
    value: ApiBillKind.Water,
    label: "水",
    amountType: -1,
    rank: 4,
  },
  [ApiBillKind.Electricity]: {
    value: ApiBillKind.Electricity,
    label: "电",
    amountType: -1,
    rank: 3,
  },
  [ApiBillKind.Gas]: {
    value: ApiBillKind.Gas,
    label: "煤",
    amountType: -1,
    rank: 2,
  },
  [ApiBillKind.Network]: {
    value: ApiBillKind.Network,
    label: "网",
    amountType: -1,
    rank: 1,
  },
};
export const amountTypeOptions = [
  { label: "收入 +", value: +1 },
  { label: "支出 -", value: -1 },
];

export enum MonthlyConfigModeEnum {
  Self,
  Hosting,
  Rental,
}
export const MonthlyConfigMode: ConstOptionMap<MonthlyConfigModeEnum> = {
  [MonthlyConfigModeEnum.Hosting]: {
    value: MonthlyConfigModeEnum.Hosting,
    label: "托管",
  },
  [MonthlyConfigModeEnum.Rental]: {
    value: MonthlyConfigModeEnum.Rental,
    label: "包租",
  },
  [MonthlyConfigModeEnum.Self]: {
    value: MonthlyConfigModeEnum.Self,
    label: "自营",
  },
};
export const CheckInKind: ConstOptionMap<ApiCheckInKind> = {
  [ApiCheckInKind.CheckInDefault]: {
    value: ApiCheckInKind.CheckInDefault,
    label: "停用",
  },
  [ApiCheckInKind.CheckInView]: {
    value: ApiCheckInKind.CheckInView,
    label: "登记中",
  },
  [ApiCheckInKind.CheckInSuper]: {
    value: ApiCheckInKind.CheckInSuper,
    label: "完成",
  },
};
