/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import {
  type Room,
  type OtaRoomAccountConfig,
  type RoomSimple,
  type RoomCreate,
  type OtaRoomAccountConfigUpdate,
  type PriceRules,
  type RoomPure,
  type RoomGroup,
} from "./room_type";

export namespace RoomService {
  // Room
  // 根据id查询单个
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/RoomService_Get", req, config);
  }

  // 根据id列表查询列表
  export function List(req: ListReq, config?: any) {
    return webapi.post<ListResp>("/api/v1/RoomService_List", req, config);
  }

  // [弃用] 根据条件查询列表
  // rpc ListByCond(ListByCondReq) returns (ListByCondResp) {}
  // [弃用] 查询房间列表（房态页面调用）
  // rpc ListForCalender(ListForCalenderReq) returns (ListForCalenderResp) {}
  // 根据id查询ota房间设置信息
  export function ListOtaRoomAccountConfig(
    req: ListOtaRoomAccountConfigReq,
    config?: any,
  ) {
    return webapi.post<ListOtaRoomAccountConfigResp>(
      "/api/v1/RoomService_ListOtaRoomAccountConfig",
      req,
      config,
    );
  }

  // 批量创建
  export function BatchCreate(req: BatchCreateReq, config?: any) {
    return webapi.post<BatchCreateResp>(
      "/api/v1/RoomService_BatchCreate",
      req,
      config,
    );
  }

  // 批量删除
  export function BatchDel(req: BatchDelReq, config?: any) {
    return webapi.post<BatchDelResp>(
      "/api/v1/RoomService_BatchDel",
      req,
      config,
    );
  }

  // 设置房间基础信息（PMS层） 名称/分组/房型
  export function SetBasic(req: SetBasicReq, config?: any) {
    return webapi.post<SetBasicResp>(
      "/api/v1/RoomService_SetBasic",
      req,
      config,
    );
  }

  // 批量设置部分属性 （SCHED层）
  export function BatchSet(req: BatchSetReq, config?: any) {
    return webapi.post<BatchSetResp>(
      "/api/v1/RoomService_BatchSet",
      req,
      config,
    );
  }

  // 批量设置房间顺序
  export function BatchSetRank(req: BatchSetRankReq, config?: any) {
    return webapi.post<BatchSetRankResp>(
      "/api/v1/RoomService_BatchSetRank",
      req,
      config,
    );
  }

  // 批量设置房间的状态（房态页面调用）
  export function BatchSetOccupyStatus(
    req: BatchSetOccupyStatusReq,
    config?: any,
  ) {
    return webapi.post<BatchSetOccupyStatusResp>(
      "/api/v1/RoomService_BatchSetOccupyStatus",
      req,
      config,
    );
  }

  // 设置ota房源信息、定价
  export function SetOtaRoomAccountConfig(
    req: SetOtaRoomAccountConfigReq,
    config?: any,
  ) {
    return webapi.post<SetOtaRoomAccountConfigResp>(
      "/api/v1/RoomService_SetOtaRoomAccountConfig",
      req,
      config,
    );
  }

  // Apply
  // 设置房间标签
  export function ApplyRoomTag(req: ApplyRoomTagReq, config?: any) {
    return webapi.post<ApplyRoomTagResp>(
      "/api/v1/RoomService_ApplyRoomTag",
      req,
      config,
    );
  }

  // 设置房间分组
  export function ApplyRoomGroup(req: ApplyRoomGroupReq, config?: any) {
    return webapi.post<ApplyRoomGroupResp>(
      "/api/v1/RoomService_ApplyRoomGroup",
      req,
      config,
    );
  }

  // RoomGroup
  // 获取全部房间分组列表
  export function AllRoomGroup(req: AllRoomGroupReq, config?: any) {
    return webapi.post<AllRoomGroupResp>(
      "/api/v1/RoomService_AllRoomGroup",
      req,
      config,
    );
  }

  // 创建房间分组
  export function CreateRoomGroup(req: CreateRoomGroupReq, config?: any) {
    return webapi.post<CreateRoomGroupResp>(
      "/api/v1/RoomService_CreateRoomGroup",
      req,
      config,
    );
  }

  // 批量删除房间分组
  export function BatchDelRoomGroup(req: BatchDelRoomGroupReq, config?: any) {
    return webapi.post<BatchDelRoomGroupResp>(
      "/api/v1/RoomService_BatchDelRoomGroup",
      req,
      config,
    );
  }

  // 修改房间分组
  export function UpdateRoomGroup(req: UpdateRoomGroupReq, config?: any) {
    return webapi.post<UpdateRoomGroupResp>(
      "/api/v1/RoomService_UpdateRoomGroup",
      req,
      config,
    );
  }

  // 设置分组排序
  export function BatchSetRoomGroupRank(
    req: BatchSetRoomGroupRankReq,
    config?: any,
  ) {
    return webapi.post<BatchSetRoomGroupRankResp>(
      "/api/v1/RoomService_BatchSetRoomGroupRank",
      req,
      config,
    );
  }
}
// Get
export interface GetReq {
  // 房间id
  id: string;
}

export interface GetResp {
  room: Room;
}

// ListOtaRoomAccountConfig
export interface ListOtaRoomAccountConfigReq {
  id: string;
}

export interface ListOtaRoomAccountConfigResp {
  otaRoomAccountConfigs: OtaRoomAccountConfig[];
}

// List
export interface ListReq {
  // 房间id列表
  ids: string[];
  // 分组id列表
  groupIds: string[];
  // 返回全部 @bool
  all?: boolean;
}

export interface ListResp {
  rooms: RoomSimple[];
}

// Create
export interface BatchCreateReq {
  rooms: RoomCreate[];
  // 在哪个分组下创建 就应该直接归属到那个分组
  groupId: string;
}

export interface BatchCreateResp {
  roomSimple: RoomSimple[];
}

// BatchDel
export interface BatchDelReq {
  ids: string[];
}

export interface BatchDelResp {
  //  @uint32
  rows: number;
}

// SetBasic
export interface SetBasicReq {
  // 房间id
  id: string;
  // 房间名称
  name: string;
  // 分组id
  groupId: string;
  // 开始运营日期
  operationDate: string;
}

export interface SetBasicResp {
  room: Room;
}

// SetOtaRoomAccountConfig
export interface SetOtaRoomAccountConfigReq {
  otaRoomAccountConfigs: OtaRoomAccountConfigUpdate[];
}

export interface SetOtaRoomAccountConfigResp {
  OtaRoomAccountConfigs: OtaRoomAccountConfig[];
}

// BatchSetRoom
export interface BatchSetReq {
  // 房间id列表
  ids: string[];
  // 房间是否关闭 @bool
  isClosed?: boolean;
  // 房间的状态 @OccupyStatus
  occupyStatus?: Room.OccupyStatus;
  // 最早入住时间 @int32
  checkInTime?: number;
  // 最晚退房时间 @int32
  checkOutTime?: number;
  // 基准价格（精确到最小货币单位，rmb为分） @uint32
  basePrice?: number;
  // 周末基准价格（精确到最小货币单位，rmb为分） @uint32
  weekendPrice?: number;
  // 房间级别的各种折扣信息
  priceRules?: PriceRules;
  // 档期最大时间 @uint32
  maxOpenDays?: number;
}

export interface BatchSetResp {
  rooms: Room[];
}

// BatchSetRank
export interface BatchSetRankReq {
  // 房间id列表（分组下房间量应与ids长度一致）数组中的id严格按照排序后的降序排列
  ids: string[];
}

export interface BatchSetRankResp {
  // 排序后的列表
  rooms: RoomPure[];
}

// BatchSetOccupyStatus
export interface BatchSetOccupyStatusReq {
  // 房间id列表
  ids: string[];
  // 房间状态 @OccupyStatus
  occupyStatus: Room.OccupyStatus;
}

export interface BatchSetOccupyStatusResp {
  // 房间id列表
  ids: string[];
  // 房间状态 @OccupyStatus
  occupyStatus: Room.OccupyStatus;
}

// ListGroup
export interface AllRoomGroupReq {}

export interface AllRoomGroupResp {
  groups: RoomGroup[];
}

// CreateGroup
export interface CreateRoomGroupReq {
  roomGroup: RoomGroup;
}

export interface CreateRoomGroupResp {
  roomGroup: RoomGroup;
}

// BatchDelGroup
export interface BatchDelRoomGroupReq {
  // 分组id列表
  roomGroupIds: string[];
}

export interface BatchDelRoomGroupResp {
  //  @uint32
  rows: number;
}

// ApplyGroup
export interface ApplyRoomGroupReq {
  // 房间id列表
  ids: string[];
  // 分组id
  roomGroupId: string;
}

export interface ApplyRoomGroupResp {
  // 房间id列表
  ids: string[];
  // 分组id
  roomGroupId: string;
}

// ApplyTag
export interface ApplyRoomTagReq {
  // 房间id
  id: string;
  // 标签id列表
  tagIds: string[];
}

export interface ApplyRoomTagResp {
  // 房间id
  id: string;
  // 标签id列表
  tagIds: string[];
}

// UpdateGroup
export interface UpdateRoomGroupReq {
  roomGroup: RoomGroup;
}

export interface UpdateRoomGroupResp {
  roomGroup: RoomGroup;
}

// BatchSetRoomGroupRank
export interface BatchSetRoomGroupRankReq {
  // 分组id 数组中的id严格按照排序后的降序排列
  groupIds: string[];
}

export interface BatchSetRoomGroupRankResp {
  // 排序后的分组
  roomGroups: RoomGroup[];
}
