import { DatePicker, Link, Select } from "@arco-design/web-react";
import { type FC } from "react";
import { type ListByCondReq } from "@api/http_pms/ota_review/ota_review_srv";
import dayjs from "dayjs";
import { RoomSelector } from "@/common/Inputer/RoomSelector";

const overallRatingsOptions = Array.from({ length: 5 }).map((_, index) => ({
  label: `${index + 1}分`,
  value: index + 1,
}));

export const Query: FC<{
  query: ListByCondReq;
  assignQuery: (newQuery: Partial<ListByCondReq>) => void;
}> = ({ query, assignQuery }) => {
  return (
    <div className="ml-5 flex items-center gap-x-4">
      <RoomSelector
        allowClear={true}
        placeholder="房间"
        mode="multiple"
        maxTagCount={3}
        value={query.roomIds}
        onChange={(ids) => {
          assignQuery({ roomIds: ids, current: 1 });
        }}
        autoWidth={{ minWidth: 68, maxWidth: 500 }}
      />

      {query.isPublished ? (
        <DatePicker.RangePicker
          mode="date"
          className="w-[240px]"
          allowClear={true}
          value={
            query.publishedAt?.start && query.publishedAt?.end
              ? [
                  dayjs.unixStr(query.publishedAt.start).dateStr(),
                  dayjs.unixStr(query.publishedAt.end).dateStr(),
                ]
              : undefined
          }
          onChange={(val) => {
            if (val?.[0] && val?.[1]) {
              assignQuery({
                publishedAt: {
                  start: dayjs.dateStr(val?.[0]).startOf("day").unixStr(),
                  end: dayjs.dateStr(val?.[1]).endOf("day").unixStr(),
                },
                current: 1,
              });
            } else {
              assignQuery({
                publishedAt: undefined,
                current: 1,
              });
            }
          }}
          placeholder={["评价开始日期", "评价结束日期"]}
        />
      ) : (
        <DatePicker.RangePicker
          mode="date"
          className="w-[240px]"
          allowClear={true}
          value={
            query.createdAt?.start && query.createdAt?.end
              ? [
                  dayjs.unixStr(query.createdAt.start).dateStr(),
                  dayjs.unixStr(query.createdAt.end).dateStr(),
                ]
              : undefined
          }
          onChange={(val) => {
            if (val?.[0] && val?.[1]) {
              assignQuery({
                createdAt: {
                  start: dayjs.dateStr(val?.[0]).startOf("day").unixStr(),
                  end: dayjs.dateStr(val?.[1]).endOf("day").unixStr(),
                },
                current: 1,
              });
            } else {
              assignQuery({ createdAt: undefined, current: 1 });
            }
          }}
          placeholder={["评价开始日期", "评价结束日期"]}
        />
      )}

      {query.isPublished && (
        <Select
          mode="multiple"
          placeholder="综合评分"
          value={query.overallRatings}
          onChange={(val: number[]) =>
            assignQuery({ overallRatings: val, current: 1 })
          }
          options={overallRatingsOptions}
          autoWidth={{ minWidth: 68, maxWidth: 500 }}
          maxTagCount={3}
          allowClear={true}
        />
      )}

      <Link
        type="text"
        onClick={() => {
          assignQuery({
            roomIds: [],
            overallRatings: [],
            publishedAt: undefined,
            createdAt: undefined,
            current: 1,
          });
        }}
      >
        重置
      </Link>
    </div>
  );
};
