import {
  Select,
  type SelectProps,
  type InputProps,
} from "@arco-design/web-react";
import { type FC } from "react";
import { type Currency as ApiCurrency } from "@api/http_pms/const/const_enum";
import { usePartialControllable } from "@/hooks/usePartialControllable";
import { CompactGroup } from "@/common/CompactGroup";
import { useWallet } from "@/store/useWallet";
import {
  MoneyInputWithCurrency,
  type MoneyInputWithCurrencyProps,
} from "@/common/Inputer/MoneyInputWithCurrency";

export interface MoneyWalletValue {
  walletId: string;
  amount: number;
}

export const MoneyWalletInput: FC<{
  value?: Partial<MoneyWalletValue>;
  onChange?: (v?: Partial<MoneyWalletValue>) => void;
  currency?: ApiCurrency;
  beforeInputProps?: MoneyInputWithCurrencyProps;
  afterInputProps?: SelectProps;
  disabled?: boolean;
  size?: InputProps["size"];
}> = ({
  beforeInputProps,
  afterInputProps,
  currency,
  disabled,
  size,
  ...props
}) => {
  const { walletOptions } = useWallet();
  const { currentValue, handleCurrentValueChange } = usePartialControllable(
    props,
    [],
  );

  return (
    <CompactGroup>
      <MoneyInputWithCurrency
        css={`
          input.arco-input {
            padding-left: 4px;
          }
        `}
        onChange={(m) => handleCurrentValueChange("amount", m)}
        value={currentValue.amount}
        suffix={null}
        disabled={disabled}
        size={size}
        currency={currency}
        className="w-1/2"
        {...beforeInputProps}
      />
      <Select
        className="w-1/2 flex-none"
        options={walletOptions}
        onChange={(m: ApiCurrency) => handleCurrentValueChange("walletId", m)}
        value={currentValue.walletId}
        disabled={disabled}
        size={size}
        {...afterInputProps}
        triggerProps={{
          style: { minWidth: 160 },
          position: "br",
          containerScrollToClose: true,
        }}
      />
    </CompactGroup>
  );
};
