import { Message, Modal } from "@arco-design/web-react";
import { IconCheck, IconClose, IconPlus } from "@arco-design/web-react/icon";

import { useState } from "react";
import { Color, type EntityKind } from "@api/http_pms/const/const_enum";
import clsx from "clsx";
import { useImmer } from "use-immer";
import { CommonService } from "@api/http_pms/common/common_srv";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { ColorInput, type ColorInputValue } from "@/common/Inputer/ColorInput";
import { EasTag } from "@/common/EasTag";
import { createNiceModal } from "@/utils/nicemodel";
import { IconFont } from "@/common/IconFont";
import { BaseColor } from "@/common/const";
import { useTag } from "@/store/useTag";
import { permissionHas } from "@/store/useUserInfo";
import { DeletePopconfirm } from "../comps/DeletePopconfirm";
import { AsyncButton } from "@/comps/AsyncButton";

export const openTagManager = createNiceModal<
  {
    onSubmit: (tagIds: string[]) => Promise<any>;
    entityKind: EntityKind;
    tagIds: string[];
  },
  {}
>(({ _modal, onSubmit, entityKind, tagIds }) => {
  const [isColorInputEdit, setColorInputEdit] = useState(false);
  const [colorInputValue, setColorInputValue] = useState<ColorInputValue>();
  const { tagList, tagMap, tagRefresh } = useTag(entityKind);
  const [selectedIds, setSelectedIds] = useImmer(new Set<string>(tagIds));

  const onOk = async () => {
    await onSubmit([...selectedIds]);
    Message.success("编辑成功");
    _modal.resolve({});
  };

  const createTag = async () => {
    if (colorInputValue?.text) {
      const tag = tagMap[colorInputValue.text];
      // 如果是创建过，自动选中
      if (!tag) {
        // 创建
        const res = await CommonService.CreateTag({
          tag: {
            color: Number(colorInputValue.color),
            name: colorInputValue.text,
            entityKind,
            id: "",
          },
        });
        setSelectedIds((ids) => {
          ids.add(res.tag.id);
        });
        Message.success("创建成功");
        await tagRefresh();
      }
    }
    setColorInputEdit(false);
    setColorInputValue(undefined);
  };

  return (
    <div>
      <Modal
        title="标签管理"
        {..._modal.props}
        unmountOnExit={true}
        className="w-[480px]"
        onOk={onOk}
      >
        <div>
          <div className="h-60 overflow-auto rounded-lg border p-4">
            <div className="text-color-text-3 mb-3">所有标签</div>
            {permissionHas(ApiPermission.Operation) && (
              <>
                {isColorInputEdit ? (
                  <ColorInput
                    value={colorInputValue}
                    onChange={setColorInputValue}
                    suffix={
                      <AsyncButton
                        size="mini"
                        className="flex items-center justify-center"
                        onClick={createTag}
                        icon={
                          colorInputValue?.text ? <IconCheck /> : <IconClose />
                        }
                        status="success"
                      />
                    }
                    onPressEnter={() => {
                      void createTag();
                    }}
                  />
                ) : (
                  <EasTag
                    className="cursor-pointer"
                    prefix={<IconPlus className="mr-1" />}
                    onClick={() => setColorInputEdit(true)}
                    text="创建标签"
                  />
                )}
              </>
            )}
            <div className="mt-3 flex flex-wrap gap-3">
              {tagList.map((cur) => (
                <EasTag
                  key={cur.id}
                  className={clsx("cursor-pointer", {
                    "!bg-white": !selectedIds.has(cur.id),
                  })}
                  color={cur.color}
                  onClick={() => {
                    setSelectedIds((ids) => {
                      if (ids.has(cur.id)) {
                        ids.delete(cur.id);
                      } else {
                        ids.add(cur.id);
                      }
                    });
                  }}
                  suffix={
                    permissionHas(ApiPermission.Operation) ? (
                      <span
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeletePopconfirm
                          title="确认删除此标签？"
                          content="确认删除后其他关联此标签的房间将不再展示当前标签。"
                          onOk={async () => {
                            await CommonService.BatchDelTag({
                              ids: [cur.id],
                            });
                            Message.success("删除成功");
                            setSelectedIds((ids) => {
                              ids.delete(cur.id);
                            });
                            void tagRefresh();
                          }}
                        >
                          <IconClose
                            className="ml-1 size-3 rounded-md p-px transition-colors duration-100"
                            style={{
                              color: BaseColor[cur.color].code,
                            }}
                            css={`
                              &:hover {
                                background-color: ${BaseColor[cur.color]
                                  .code}33;
                              }
                            `}
                          />
                        </DeletePopconfirm>
                      </span>
                    ) : undefined
                  }
                  text={cur.name}
                />
              ))}
            </div>
          </div>
          <div className="mt-4 h-36 overflow-auto rounded-lg border p-4">
            <div className="text-color-text-3">
              已选：{selectedIds.size}个标签
            </div>
            <div>
              {[...selectedIds].map((id) => (
                <EasTag
                  key={id}
                  className="relative mr-3 mt-3"
                  color={tagMap[id]?.color}
                  suffix={
                    <div
                      className="absolute -right-1 -top-1 flex size-3 cursor-pointer items-center justify-center rounded-full hover:scale-110"
                      style={{
                        backgroundColor:
                          BaseColor[tagMap[id]?.color || Color.Color_0].code,
                      }}
                      onClick={() => {
                        setSelectedIds((ids) => {
                          ids.delete(id);
                        });
                      }}
                    >
                      <IconFont
                        type="icon-right-top-minus"
                        className="text-white"
                      />
                    </div>
                  }
                  text={tagMap[id]?.name || ""}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});
