import { type FC } from "react";
import { useChatStore } from "./store";
import { ChatConvKind } from "./store/interface";

export const ChatRedDotCount: FC = () => {
  const { waitReplayCount } = useChatStore((s) => ({
    waitReplayCount: s.convIdsMapKind[ChatConvKind.WaitReplay].size,
  }));
  // const unreadCount = useMemo(() => {
  //   const ids = new Set();
  //   Object.values(convMapId).forEach((c) => {
  //     c.unreadMsgIds.forEach((id) => ids.add(id));
  //   });
  //   return ids.size;
  // }, [convMapId]);

  return waitReplayCount ? (
    <div className="ml-2 flex h-5 min-w-5 flex-none items-center justify-center overflow-hidden rounded-[20px] bg-red-500 px-1 text-white">
      {waitReplayCount > 99 ? "99+" : waitReplayCount}
    </div>
  ) : null;
};
