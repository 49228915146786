import { Layout, Menu } from "@arco-design/web-react";
import { IconCalendar } from "@arco-design/web-react/icon";
import {
  type ReactNode,
  Suspense,
  Fragment,
  cloneElement,
  useState,
  useMemo,
} from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { flatMap, maxBy } from "lodash-es";
import clsx from "clsx";
import { useRequest } from "ahooks";
import { IconFont } from "@/common/IconFont";
import noPermission from "@/assets/system/noPermission.svg";
import ErrorBoundary from "../../comps/ErrorBoundary";
import { LayoutLoading } from "../../comps/LayoutLoading";

interface MenuItem {
  path: string;
  link?: string;
  name: string;
  afterName?: ReactNode;
  icon?: React.ReactElement;
  subMenus?: MenuItem[];
  noPermission?: () => boolean;
}

type MenuItemWithRender = MenuItem | { key: string; render: () => ReactNode };

export const RouterLayout: React.FC<{
  menuItems: MenuItemWithRender[];
  headerRender?: () => ReactNode;
  onInitial: () => Promise<void>;
}> = ({ menuItems, onInitial }) => {
  const location = useLocation();

  const openingMenu = useMemo(() => {
    const _menuItems = flatMap(menuItems, (item) =>
      "subMenus" in item ? [item, ...item.subMenus!] : item,
    );
    const matchedItems = _menuItems.filter(
      (item) => "path" in item && location.pathname.startsWith(item.path),
    ) as MenuItem[];
    const bestItem = maxBy(matchedItems, (item) =>
      "path" in item ? item.path.length : 0,
    );
    return bestItem;
  }, [location]);

  const [collapsed, setCollapsed] = useState(false);

  const { error, loading } = useRequest(onInitial);
  if (loading) {
    return <LayoutLoading />;
  }
  if (error) {
    return <div>initial error</div>;
  }
  return (
    // arco-layout-has-sider 是为了避免框架无效重渲染，导致第一次content区域渲染宽度没有减去sider, 会导致 echart 首次加载时宽度异常
    <Layout className="arco-layout-has-sider h-full">
      <Layout.Sider
        width={150}
        collapsedWidth={68}
        collapsed={collapsed}
        // collapsible
        // trigger={<IconSiderArrow />}
      >
        <IconFont
          type="icon-sider-arrow"
          className={clsx(
            "absolute right-[-12px] top-[90px] z-10 size-6 cursor-pointer",
            {
              "!rotate-180": collapsed,
            },
          )}
          onClick={() => {
            setCollapsed((c) => !c);
          }}
        />
        <Menu
          autoOpen={true}
          selectedKeys={[openingMenu?.path || ""]}
          className="h-full"
          css={`
            &.arco-menu-collapse {
              .collapse-hide {
                opacity: 0;
              }
            }
            .arco-menu-inline-content {
              display: flex;
              flex-direction: column;
            }
            .arco-menu-inline-header {
              margin: 6px 0;
            }
            .arco-menu-inner {
              overflow-x: hidden;
            }
          `}
        >
          <div className="flex h-full flex-col">
            <IconFont
              type="icon-main-logo"
              className={clsx("mx-auto mt-4 block h-[43px] w-[36px] flex-none")}
            />
            <IconFont
              type="icon-main-name"
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="collapse-hide mx-auto my-4 block h-[24px] w-[120px] flex-none"
            />
            {menuItems.map((item) => {
              if ("render" in item) {
                return <Fragment key={item.key}>{item.render()}</Fragment>;
              }
              if (item.noPermission?.()) {
                return null;
              }
              if ("subMenus" in item) {
                return (
                  <Menu.SubMenu
                    key={item.path}
                    className={clsx({ "!my-1.5 h-10": collapsed })}
                    title={
                      <div className="flex items-center">
                        {item.icon ? (
                          cloneElement(item.icon, {
                            className: "arco-icon size-5 flex-none",
                          })
                        ) : (
                          <IconCalendar />
                        )}
                        {item.name}
                      </div>
                    }
                  >
                    {item.subMenus?.map((subItem) => {
                      if (subItem.noPermission?.()) {
                        return null;
                      }
                      return (
                        "path" in subItem && (
                          <Link
                            to={subItem.link ?? subItem.path}
                            key={subItem.path}
                            className="inline no-underline"
                          >
                            <Menu.Item
                              key={subItem.path}
                              className={clsx({
                                "!my-1.5 h-10 !pl-7": !collapsed,
                              })}
                              css={
                                openingMenu?.path === subItem.path && !collapsed
                                  ? `
                                      &::before {
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        border-left: 2px solid rgb(var(--primary-6));
                                        height: 16px;
                                        margin-right: 10px; /* 可选，用于调整竖线与元素之间的间距 */
                                        top: 50%;
                                        transform: translate(0, -50%);
                                      }
                                    `
                                  : undefined
                              }
                            >
                              {subItem.name}
                            </Menu.Item>
                          </Link>
                        )
                      );
                    })}
                  </Menu.SubMenu>
                );
              }
              return (
                <Link
                  to={item.link ?? item.path}
                  key={item.path}
                  className="inline"
                >
                  <Menu.Item
                    key={item.path}
                    className="!my-1.5 flex h-10 items-center"
                  >
                    {item.icon ? (
                      cloneElement(item.icon, {
                        className: "arco-icon size-5 flex-none",
                      })
                    ) : (
                      <IconCalendar />
                    )}
                    {item.name}
                    {item.afterName}
                  </Menu.Item>
                </Link>
              );
            })}
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <div
              onClick={() => {
                window.open(
                  "https://easit-jp.feishu.cn/wiki/DWFDww8N7i9QxbkmIehc3covnXf",
                );
              }}
              className="collapse-hide text-color-text-3 flex w-[126px] flex-none cursor-pointer items-center justify-center overflow-hidden py-4"
            >
              <IconFont type="icon-sider-helper" className="mr-1.5" />
              <span>帮助手册</span>
            </div>
          </div>
        </Menu>
      </Layout.Sider>
      <Layout.Content className="bg-[#EFF2F8] px-8 pb-8">
        <ErrorBoundary resetKey={openingMenu?.path}>
          <Suspense fallback={<LayoutLoading />}>
            {openingMenu?.noPermission?.() ? (
              <div className="flex size-full flex-col items-center justify-center">
                <img src={noPermission} className="w-[200px]" />
                无权限访问，请联系组织管理员
              </div>
            ) : (
              <Outlet />
            )}
          </Suspense>
        </ErrorBoundary>
      </Layout.Content>
    </Layout>
  );
};

export default RouterLayout;
