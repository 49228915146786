import { type SubBill } from "@api/http_pms/bill/bill_type";
import { Form, Link } from "@arco-design/web-react";
import { cloneDeep, sum } from "lodash-es";
import { useLayoutEffect, type FC } from "react";
import { type Currency } from "@api/http_pms/const/const_enum";
import { useUpdateEffect } from "ahooks";
import { MoneyCurrencyInput } from "@/common/Inputer/MoneyCurrencyInput";
import { getMoneyText } from "@/utils/money";
import { gtZero } from "@/utils/validator";
import { type MoneyFormProps } from ".";
import { newEmptySubBill } from "../../utils";
import { CompactFormItem } from "@/common/CompactFormItem";

export interface MoneyFormDepositData {
  inAmount: number;
  outAmount: number;
}

export const MoneyFormDeposit: FC<MoneyFormProps> = ({
  form,
  initShareBill,
  orderId,
  canEdit,
}) => {
  // 内部表单数据
  const subFormData: MoneyFormDepositData = Form.useWatch(
    ["inAmount", "outAmount"],
    form,
  );

  // 初始化逻辑
  useLayoutEffect(() => {
    const currency: Currency = form.getFieldValue("currency");
    const subBills: SubBill[] = form.getFieldValue("subBills");
    const inSubBills = subBills.filter((bill) => bill.amount > 0);
    const outSubBills = subBills.filter((bill) => bill.amount <= 0);
    const inAmount = sum(inSubBills?.map((b) => b.amount));
    const outAmount = -sum(outSubBills?.map((b) => b.amount));

    form.setFieldsValue({
      inAmount,
      outAmount,
      inCurrencyAmount: {
        currency,
        amount: inAmount,
      },
      outCurrencyAmount: {
        currency,
        amount: outAmount,
      },
    });
    return () => {
      form.resetFields([
        "inAmount",
        "outAmount",
        "inCurrencyAmount",
        "outCurrencyAmount",
      ]);
    };
  }, []);

  // 相关数据变化时，更新 外部数据
  useUpdateEffect(() => {
    let inSubBill = initShareBill?.subBills.find((bill) => bill.amount > 0);
    if (inSubBill) {
      inSubBill = cloneDeep(inSubBill);
    } else {
      inSubBill = newEmptySubBill();
    }
    inSubBill.orderId = orderId;
    inSubBill.amount = subFormData.inAmount || 0;

    if (!subFormData.outAmount) {
      form.setFieldsValue({
        subBills: [inSubBill],
      });
      return;
    }

    let outSubBill = initShareBill?.subBills.find((bill) => bill.amount <= 0);
    if (outSubBill) {
      outSubBill = cloneDeep(outSubBill);
    } else {
      outSubBill = newEmptySubBill();
    }
    outSubBill.orderId = orderId;
    outSubBill.amount = -subFormData.outAmount;

    form.setFieldsValue({
      subBills: [inSubBill, outSubBill],
    });
  }, [subFormData]);

  const refoundRender = () => {
    const currency: Currency = form.getFieldValue("currency");
    const inAmount = subFormData.inAmount || 0;
    const outAmount = subFormData.outAmount || 0;
    if (!canEdit || inAmount === outAmount) {
      return null;
    }
    return (
      <Form.Item label="待退还" disabled={!canEdit}>
        <div className="flex items-center">
          <div className="mr-4">
            {getMoneyText(inAmount - outAmount, currency, {
              hidePlusSign: true,
            })}
          </div>
          <Link
            onClick={() => {
              form.setFieldValue("outAmount", inAmount);
            }}
          >
            退还
          </Link>
        </div>
      </Form.Item>
    );
  };
  return (
    <>
      <CompactFormItem
        form={form}
        fieldMap={{
          amount: "inAmount",
          currency: "currency",
        }}
        field="inCurrencyAmount"
        label="收入押金"
        rules={[gtZero("收入押金", "amount")]}
      >
        <MoneyCurrencyInput />
      </CompactFormItem>

      {!initShareBill ? null : (
        <>
          <CompactFormItem
            form={form}
            fieldMap={{
              amount: "outAmount",
              currency: "currency",
            }}
            field="outCurrencyAmount"
            label="退还押金"
          >
            <MoneyCurrencyInput />
          </CompactFormItem>
          {refoundRender()}
        </>
      )}
    </>
  );
};
