import { IconPlus } from "@arco-design/web-react/icon";
import { type FC } from "react";
import { type Tenant } from "@api/http_pms/tenant/tenant_type";
import { EasTag } from "@/common/EasTag";
import { Displayer } from "./Displayer";
import { openEditor } from "./openEditor";

export const TenantEditor: FC<{
  orderId?: string;
  value?: Tenant[];
  onChange?: (v: Tenant[]) => void;
}> = ({ value, onChange, orderId }) => (
  <div>
    {value?.map((item, index) => (
      <Displayer
        key={item.id}
        value={item}
        index={index}
        onEdit={async () => {
          const res = await openEditor({
            tenant: item,
            orderId,
          });
          const newTenant = [...(value || [])];
          if (res.tenant) {
            newTenant.splice(index, 1, res.tenant);
            onChange?.(newTenant);
          } else {
            newTenant.splice(index, 1);
            onChange?.(newTenant);
          }
        }}
      />
    ))}
    <EasTag
      prefix={<IconPlus className="mr-1" />}
      onClick={async () => {
        const res = await openEditor({
          orderId,
        });
        if (res.tenant) {
          onChange?.([...(value || []), res.tenant]);
        }
      }}
      className="cursor-pointer"
      text="添加房客"
    />
  </div>
);
