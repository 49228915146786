/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type EntityKind } from "../const/const_enum";

// This the module of OperateLog
export namespace OperateLog {
  export enum Kind {
    // 留言
    Message = 0,
    // 订单
    OrderLog = 1,
    // 租客
    TenantLog = 2,
    // 标签
    TagLog = 3,
    // 账单
    BillLog = 4,
  }
  export enum OperateKind {
    Create = 0,
    Delete = 1,
    Update = 2,
    Cancel = 3,
  }

  export interface FieldContent {
    field: string;
    before?: string;
    after?: string;
    current?: string;
  }
}
export interface OperateLog {
  id: string;
  // 实体类型
  entityKind: EntityKind;
  // 实体id
  relationId: string;
  // 日志类型
  kind: OperateLog.Kind;
  // 字段变更内容
  fieldContents: OperateLog.FieldContent[];
  // 地点
  location: string;
  // 设备
  device: string;
  // IP
  ip: string;
  // 创建时间 @uint64
  createdAt: string;
  // 创建者
  creatorId: string;
  // 创建者
  creatorName: string;
  // 编辑时间 @uint64
  editAt?: string;
  // 撤销时间 @uint64
  revokeAt?: string;
  // 操作类型
  operateKind: OperateLog.OperateKind;
}

export interface OperateLogMessage {
  // id
  id?: string;
  // 实体id
  relationId: string;
  // 类型
  entityKind: EntityKind;
  // 内容
  content: string;
  // 撤回 @uint64
  revokeAt?: string;
}
