import { Button, Form, Space, Input } from "@arco-design/web-react";
import { type FC } from "react";
import clsx from "clsx";
import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import { checkIsAddId } from "@/utils/dealWithId";
import { required } from "@/utils/validator";
import { AsyncButton } from "@/comps/AsyncButton";
import { getMessageContent } from "./LogList";

interface ItemFormProps {
  onOk?: (item: OperateLog) => void;
  onCancel?: () => void;
  item: OperateLog;
}

export const LogItemForm: FC<ItemFormProps> = ({ item, onCancel, onOk }) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.validate();
    const finalValue: OperateLog = {
      ...item,
      fieldContents: [{ field: "Message", current: values.content }],
    };
    onOk?.(finalValue);
  };

  return (
    <div
      className={clsx({
        "bg-color-fill-1 rounded-lg p-4": !checkIsAddId(item.id),
      })}
    >
      <Form
        form={form}
        wrapperCol={{ span: 24 }}
        initialValues={{
          ...item,
          content: getMessageContent(item.fieldContents),
        }}
      >
        <Form.Item field="content" rules={[required("留言内容")]}>
          <Input.TextArea placeholder="请输入留言内容" className="h-[100px]" />
        </Form.Item>
        <Form.Item className="!mb-0">
          <Space>
            <AsyncButton onClick={onSubmit} type="outline" status="success">
              保存
            </AsyncButton>
            <Button
              onClick={() => {
                onCancel?.();
              }}
              type="outline"
            >
              取消
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};
