import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import { Sex } from "@/common/const";
import { tenantShowRule } from "./showRule";
import { InfoChange } from "./InfoChange";
import { FileChange } from "./FileChange";

export const UserChange = ({ list }: { list: OperateLog.FieldContent[] }) => {
  const finalList = list.filter((item) => item.after || item.before);
  const tenantName = list
    .find((item) => item.field === "Name")
    ?.current?.trim();
  const sex = list.find((item) => item.field === "Sex")?.current;

  const name = !tenantName ? null : (
    <div className="mr-1 text-base font-medium text-gray-800">
      <span className="mr-1">{tenantName}</span>
      <span>{Sex[sex as unknown as keyof typeof Sex]?.label}</span>
    </div>
  );

  return (
    <div className="mt-2 flex-auto select-text text-gray-500">
      <div className="mb-1">{name}</div>
      <InfoChange fieldContents={finalList} showRules={tenantShowRule} />
      <FileChange list={list} />
    </div>
  );
};
