import { type TableProps } from "@arco-design/web-react/es/Table";
import { useState, useCallback, useMemo } from "react";
import { useQueryImmer } from "@/utils/useQueryState";

export const useTableProps = <R, F>({
  defaultRequestQuery,
  defaultFilterQuery,
  tableProps,
  withUrlStore,
}: {
  defaultRequestQuery: R;
  defaultFilterQuery: F;
  tableProps?: TableProps;
  withUrlStore?: boolean; // 是否连接url
}) => {
  const [requestQuery, changeRequestQuery] = useQueryImmer<R>(
    "r",
    defaultRequestQuery,
    withUrlStore,
  );
  const [filterQuery, changeFilterQuery] = useQueryImmer<F>(
    "f",
    defaultFilterQuery,
    withUrlStore,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const assignRequestQuery = useCallback((newRequestQuery: Partial<R>) => {
    changeRequestQuery((val) => ({ ...val, ...newRequestQuery }));
  }, []);

  const assignFilterQuery = useCallback((newFilterQuery: Partial<F>) => {
    changeFilterQuery((val) => ({ ...val, ...newFilterQuery }));
  }, []);

  const finallyTableProps: TableProps = useMemo(
    () => ({
      ...tableProps,
      rowSelection:
        "rowSelection" in (tableProps || {}) && !tableProps?.rowSelection
          ? undefined
          : {
              type: "checkbox",
              selectedRowKeys,
              onChange: (_selectedRowKeys) => {
                setSelectedRowKeys(_selectedRowKeys as string[]);
              },
              ...tableProps?.rowSelection,
            },
    }),
    [selectedRowKeys, tableProps],
  );

  return {
    query: {
      request: requestQuery,
      filter: filterQuery,
    },
    changeQuery: {
      request: changeRequestQuery,
      filter: changeFilterQuery,
    }, // 修改方式changeQuery((val) => { val.title = '' })
    assignQuery: {
      request: assignRequestQuery,
      filter: assignFilterQuery,
    }, // 修改方式assignQuery({ title: '' })
    selectedRowKeys,
    setSelectedRowKeys,
    tableProps: finallyTableProps,
  };
};
