import { type Color } from "@api/http_pms/const/const_enum";
import { useMemo, type FC } from "react";
import { BaseColor } from "@/common/const";

export const Avatar: FC<{ className?: string; name: string; url?: string }> = ({
  className,
  name,
  url,
}) => {
  const firstChar = useMemo(() => name.trim().slice(0, 1), [name]);

  return (
    <div
      className={`flex items-center justify-center rounded-full font-medium text-white ${className}`}
      style={
        url
          ? {
              backgroundImage: `url(${url})`,
            }
          : {
              backgroundColor:
                BaseColor[(firstChar.charCodeAt(0) % 8) as Color]?.code,
            }
      }
    >
      {firstChar}
    </div>
  );
};
