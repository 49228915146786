import {
  type GetImConvReq,
  type GetImConvResp,
  type ListImConvReq,
  type ListImConvResp,
  type ListImMsgReq,
  type ListImMsgResp,
  type SubscribeReq,
  type PublishReq,
  type PublishResp,
  type BatchPresignUrlReq,
  type BatchPresignUrlResp,
} from "@api/http_im/im_srv";
import { publish } from "pubsub-js";
import { ImService } from "@api/http_pms/im/im_srv";
import { ApiError } from "@/utils/webapi";
import { getImUrl } from "@/utils/url";

export namespace ChatService {
  export function BatchPresignUrl(req: BatchPresignUrlReq) {
    return post<BatchPresignUrlResp>("/api/v1/ImService_BatchPresignUrl", req);
  }

  export function GetImConv(req: GetImConvReq) {
    return post<GetImConvResp>("/api/v1/ImService_GetImConv", req);
  }

  export function ListImConv(req: ListImConvReq) {
    return post<ListImConvResp>("/api/v1/ImService_ListImConv", req);
  }

  export function ListImMsg(req: ListImMsgReq) {
    return post<ListImMsgResp>("/api/v1/ImService_ListImMsg", req);
  }

  export function Publish(req: PublishReq) {
    return post<PublishResp>("/api/v1/ImService_Publish", req);
  }

  export async function Subscribe(req: SubscribeReq) {
    if (!imToken) {
      await refreshToken();
    }
    const imSSE = new EventSource(
      `${getImUrl()}/api/v1/ImService_Subscribe?token=${imToken}`,
    );
    return imSSE;
    // return await new Promise<EventSource>((resolve, reject) => {
    //   imSSE.onerror = (err) => {
    //     console.error("ImService_Subscribe failed:", err);
    //   };
    //   imSSE.onopen = () => {
    //     console.info("ImService_Subscribe connected!");
    //   };
    //   resolve(imSSE);
    // });
  }
}

let imToken = "";
let isLocked = false;
const lockQueue: {
  resolve: (v: string) => void;
  reject: (v: unknown) => void;
}[] = [];
const refreshToken = async () => {
  if (isLocked) {
    return await new Promise<string>((resolve, reject) => {
      lockQueue.push({ resolve, reject });
    });
  }
  isLocked = true;
  try {
    const { token } = await ImService.GetToken({});
    imToken = token;
    lockQueue.forEach((d) => d.resolve(token));
    isLocked = false;
    return token;
  } catch (e) {
    lockQueue.forEach((d) => d.reject(e));
    isLocked = false;
    throw e;
  }
};

const post = async <T>(
  url: string,
  params: any,
  config?: { tokenRefreshed?: boolean },
) => {
  if (!imToken) {
    await refreshToken();
  }

  const resp = await fetch(getImUrl() + url, {
    method: "post",
    body: JSON.stringify(params),
    headers: {
      "eas-im-token": imToken,
    },
  });

  if (resp.status === 401 && !config?.tokenRefreshed) {
    await refreshToken();
    return await post(url, params, { tokenRefreshed: true });
  }

  if (resp.status < 200 || resp.status > 299) {
    publish("webapi_net_err", "网络错误");
    throw new Error("net error");
  }

  const respData: {
    code: string;
    msg: string;
    data: T;
  } = await resp.json();
  if (respData.code) {
    publish("webapi_net_err", respData.msg);
    throw new ApiError(respData.code, respData.msg);
  }

  return respData.data;
};
