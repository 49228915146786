import { type RemarkContent } from "@api/http_pms/common/common_type";
import { ContentKind } from "@api/http_pms/const/const_enum";
import { OrderService, type UpdateReq } from "@api/http_pms/order/order_srv";
import { type OrderUpdate, type Order } from "@api/http_pms/order/order_type";
import { type Tenant as ApiTenant } from "@api/http_pms/tenant/tenant_type";

export const orderBaseToForm = (order: Order) => ({
  roomId: order.roomId,
  orderDates: [order.dateStart, order.dateEnd],
  checkInTime: order.checkInTime,
  checkOutTime: order.checkOutTime,
  tenantCount: order.tenantCount,
  remark: order.remark?.content,
});
export const orderBookToForm = (order: Order) => ({
  roomFeeGroup: {
    currency: order.roomFeeCurrency,

    roomFeeAmount: order.roomFee,
    roomFeeWalletId: order.roomFeeWalletId,
    otaFeeAmount: order.otaFee,
    otaFeeWalletId: order.otaFeeWalletExist ? order.otaFeeWalletId : undefined,

    handlingFee: order.handlingFee,
  },
  bookAt: order.bookAt,
  orderSource: {
    kind: order.orderSourceId,
    key: order.orderSourceKey,
  },
});
export const orderTenantToForm = (tenants: ApiTenant[]) => ({
  tenants: tenants.map((tenant) => ({
    id: tenant.id,
    orderId: tenant.orderId,
    name: tenant.name,
    sex: tenant.sex,
    tel: {
      countryCode: tenant.countryCode,
      phoneNumber: tenant.phoneNumber,
    },
    email: tenant.email,
    birthday: tenant.birthday || "",
    profession: tenant.profession || "",
    nationality: tenant.nationality || 0,
    address: tenant.address,
    images: tenant.images,
  })),
});

export const orderRawToForm = (order: Order, tenants: ApiTenant[]) => ({
  id: order.id,
  ...orderBaseToForm(order),
  ...orderBookToForm(order),
  tagIds: order.tagIds,
  tenants,
});

export const formToOrderBase = (values: any) => ({
  // 表单一
  roomId: values.roomId,
  dateStart: values.orderDates[0],
  dateEnd: values.orderDates[1],
  checkInTime: values.checkInTime,
  checkOutTime: values.checkOutTime,
  tenantCount: values.tenantCount,
  // 表单二
  orderSourceId: values.orderSource.kind,
  orderSourceKey: values.orderSource.key,
  actualReceivedFee: values?.actualReceivedFee?.amount,
  actualReceivedFeeCurrency: values?.actualReceivedFee?.currency,
  roomFeeCurrency: values?.roomFeeGroup?.currency,
  roomFee: values?.roomFeeGroup?.roomFeeAmount,
  roomFeeWalletId: values?.roomFeeGroup?.roomFeeWalletId,
  otaFee: values?.roomFeeGroup?.otaFeeAmount,
  otaFeeWalletId: values?.roomFeeGroup?.otaFeeWalletId,
  otaFeeWalletExist: values?.roomFeeGroup?.otaFeeWalletId !== undefined,
  handlingFee: values?.roomFeeGroup?.handlingFee,
  bookAt: values.bookAt,
  tagIds: values.tagIds,
  checkInKind: values.checkInKind,
});

export const orderUpdateFastSave = async (
  values: {
    order?: Partial<OrderUpdate>;
    remark?: RemarkContent;
  },
  orderId?: string,
) => {
  if (orderId) {
    const newValues = values as UpdateReq;
    newValues.order = { ...newValues.order, id: orderId };
    const res = await OrderService.Update(newValues);
    return res;
  }
};

export const formValuesToCreateOrder = (values: any) => ({
  order: formToOrderBase(values),
  remark: {
    content: values.remark,
    contentKind: ContentKind.Text,
  },
  tenants: values.tenants,
});
