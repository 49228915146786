import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import { Grid } from "@arco-design/web-react";
import { isEmpty } from "lodash-es";
import { useMemo } from "react";
import { PictureListShow } from "@/comps/PictureListShow";

const { Row } = Grid;
const { Col } = Grid;

export const FileChange = ({ list }: { list: OperateLog.FieldContent[] }) => {
  const [addAddImages, deleteImages] = useMemo(() => {
    // TODO 改造成 JSON String
    const _addAddImages =
      list.find((item) => item.field === "AddImages")?.current?.split("、") ||
      [];
    const _deleteImages =
      list
        .find((item) => item.field === "DeleteImages")
        ?.current?.split("、") || [];

    return [_addAddImages, _deleteImages];
  }, []);

  return (
    <Row className="!mt-1">
      {!isEmpty(addAddImages) && (
        <>
          <Col span={5} className="text-right">
            新增附件：
          </Col>
          <Col span={19}>
            <PictureListShow urls={addAddImages || []} />
          </Col>
        </>
      )}
      {!isEmpty(deleteImages) && !isEmpty(addAddImages) && (
        <Col className="h-1" span={24} />
      )}
      {!isEmpty(deleteImages) && (
        <>
          <Col span={5} className="text-right">
            删除附件：
          </Col>
          <Col span={19}>
            <PictureListShow urls={deleteImages || []} />
          </Col>
        </>
      )}
    </Row>
  );
};
