import { Button, Message } from "@arco-design/web-react";
import { type FC } from "react";
import { IconPlus } from "@arco-design/web-react/icon";
import { OperateLogService } from "@api/http_pms/operate_log/operate_log_srv";
import { useRequest } from "ahooks";
import { OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import dayjs from "dayjs";
import { EntityKind } from "@api/http_pms/const/const_enum";
import { checkIsAddId, genAddId } from "@/utils/dealWithId";
import { LogItem } from "./LogItem";

export const LogList: FC<{ relationId: string; entityKind: EntityKind }> = ({
  relationId,
  entityKind,
}) => {
  const { data: logList, mutate } = useRequest(
    async () => {
      const res = await OperateLogService.List({
        relationId,
        entityKind,
      });
      return res.logs;
    },
    {
      ready: Boolean(relationId),
    },
  );

  return (
    <>
      {logList?.every((item) => !checkIsAddId(item.id)) && (
        <Button
          type="primary"
          icon={<IconPlus />}
          className="mb-4"
          onClick={() => {
            mutate([
              {
                id: genAddId(),
                kind: OperateLog.Kind.Message,
                relationId,
                fieldContents: [],
                location: "",
                device: "",
                createdAt: "",
                creatorName: "",
                ip: "",
                editAt: "",
                revokeAt: "",
                operateKind: OperateLog.OperateKind.Create,
                entityKind,
                creatorId: "",
              },
              ...logList,
            ]);
          }}
        >
          添加留言
        </Button>
      )}

      {logList?.map((log, i) => (
        <LogItem
          key={log.id}
          item={log}
          onSubmit={async (item) => {
            const newLogList = [...logList];
            const res = await OperateLogService.UpdateMessage({
              log: {
                content: getMessageContent(item.fieldContents),
                relationId,
                id: checkIsAddId(item?.id) ? "" : item.id,
                entityKind,
              },
            });
            if (checkIsAddId(item?.id)) {
              Message.success("添加成功");
            } else {
              Message.success("编辑成功");
            }
            newLogList.splice(i, 1, res.log);
            mutate(newLogList);
          }}
          onRemove={async () => {
            const newLogList = [...logList];
            if (!checkIsAddId(log?.id)) {
              const res = await OperateLogService.UpdateMessage({
                log: {
                  id: log.id,
                  entityKind: EntityKind.Order,
                  relationId,
                  revokeAt: dayjs().unixStr(),
                  content: getMessageContent(log.fieldContents),
                },
              });
              newLogList.splice(i, 1, res.log);
              Message.success("删除成功");
            } else {
              newLogList.splice(i, 1);
            }
            mutate(newLogList);
          }}
        />
      ))}
    </>
  );
};

export const getMessageContent = (fieldContents: OperateLog.FieldContent[]) =>
  fieldContents.find((content) => content.field === "Message")?.current || "";
