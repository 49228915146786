import { ImMsg, type ImConv, ImUser } from "@api/http_im/im_type";
import dayjs from "dayjs";
import { type User } from "@api/http_pms/user/user_type";
import { type ChatMsg, type ChatConv } from "./interface";
import { useUserInfo } from "@/store/useUserInfo";

export const baseDay = dayjs("2000/01/01");

/** 插入元素，在数组中命中元素的下一个位置（从后向前查找,会修改元数组） */
export const insertItemInOrder = <T>(
  arr: T[],
  item: T,
  compareField?: (i: T) => string,
  fromTop?: boolean,
) => {
  if (arr.length === 0) {
    arr.push(item);
    return;
  }

  const target = (compareField ? compareField(item) : item) as string;

  if (!fromTop) {
    for (let index = arr.length - 1; index >= 0; index--) {
      const current = (
        compareField ? compareField(arr[index]!) : arr[index]!
      ) as string;
      if (current.startsWith("_")) {
        continue;
      } else if (target === current) {
        arr[index] = item;
        return;
      } else if (target > current) {
        arr.splice(index + 1, 0, item);
        return;
      }
    }
    arr.unshift(item);
  } else {
    for (let index = 0; index < arr.length; index++) {
      const current = (
        compareField ? compareField(arr[index]!) : arr[index]!
      ) as string;
      if (current.startsWith("_")) {
        continue;
      } else if (target === current) {
        arr[index] = item;
        return;
      } else if (target < current) {
        arr.splice(index, 0, item);
        return;
      }
    }
    arr.unshift(item);
  }
};

/** 删除数组中，小于等于指定值的item, 返回是否成功有删除 */
export const removeLteItem = (arr: string[], item: string) => {
  if (arr.length === 0) {
    return false;
  }
  const target = item;
  for (let index = 0; index <= arr.length; index++) {
    const current = arr[index]!;
    if (target < current) {
      if (index === 0) {
        return false;
      }
      arr.splice(0, index);
      return true;
    }
  }
  arr.splice(0, arr.length);
  return true;
};

export const newImConv = (id: string, c?: ImConv): ChatConv => ({
  id,
  extra: c?.extra || {},
  entrant: c?.outEntrant || newUser(false),
  unreadMsgIds: c?.pmsUnreadMsgIds || [],
  msgs: c?.lastMsg ? [newImMsg(c.lastMsg)] : [],
  ready: Boolean(c),
  hasMoreMsg: true,
  createdAt: c ? dayjs.unixStr(c?.createdAt) : dayjs(),
});

export const newImMsg = (m: ImMsg): ChatMsg => ({
  id: m.id,
  kind: m.kind,
  content: m.content,
  sender: m.sender,
  sentAt: dayjs.unixStr(m.sentAt),
  deliverAt:
    m.deliverAt === "0"
      ? null
      : !m.deliverAt
        ? undefined
        : dayjs.unixStr(m.deliverAt),
});

export const newUser = (useMe: boolean): ImUser => {
  if (useMe) {
    const myUser = useUserInfo.getState()?.userInfo as User | undefined;
    return {
      id: myUser?.id || "",
      nickname: myUser?.nickname || "",
      kind: ImUser.Kind.Pms,
    };
  }
  return {
    id: "",
    nickname: "",
    kind: ImUser.Kind.System,
  };
};

export const updateWaitReplaySet = (
  waitReplaySet: Set<string>,
  convId: string,
  msgs: ChatMsg[],
) => {
  const lastMsg = msgs.at(-1);
  if (lastMsg && checkWaitReplay(lastMsg.sender)) {
    waitReplaySet.add(convId);
  } else {
    waitReplaySet.delete(convId);
  }
};

export const checkWaitReplay = (sender?: ImUser) =>
  sender &&
  (sender.kind === ImUser.Kind.OtaGuest || sender.kind === ImUser.Kind.Admin);

export const deleteSendingMsgByTraceId = (
  msgs: ChatMsg[],
  traceId: string,
): boolean => {
  if (msgs.length === 0) {
    return false;
  }
  const _traceId = `_${traceId}`;
  for (let index = msgs.length - 1; index >= 0; index--) {
    const current = msgs[index]!;
    if (current.id === _traceId) {
      msgs.splice(index, 1);
      return true;
    }
  }
  return false;
};

export const msgPreview = (msg: ChatMsg) => {
  switch (msg.kind) {
    case ImMsg.Kind.Image:
      return "[图片]";
    case ImMsg.Kind.Dealed:
      return "[标记为已处理]";
    case ImMsg.Kind.OrderBook:
      return "[用户下单]";
    case ImMsg.Kind.OrderCancel:
      return "[用户取消订单]";
    case ImMsg.Kind.OrderUpdate:
      return "[订单发生变更]";
    default:
      return msg.content.slice(0, 70);
  }
};

export const getConvLastTime = (conv: ChatConv) => {
  const lastMsg = conv.msgs.at(-1);
  return lastMsg ? lastMsg.sentAt : conv.createdAt;
};
