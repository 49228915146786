import { OrderService } from "@api/http_pms/order/order_srv";
import { Button, Dropdown, Menu, Message } from "@arco-design/web-react";
import { useMemo, useState, type FC } from "react";
import { type Order } from "@api/http_pms/order/order_type";
import clsx from "clsx";
import { OtaPlatformKind } from "@api/http_pms/const/const_enum";
import { openCancelOrder } from "./openCancelOrder";
import { DeletePopconfirm } from "@/comps/DeletePopconfirm";

const canCancelOtaPlatformKind = new Set([OtaPlatformKind.Reservation]);

export const Operate: FC<{
  initOrderData: Order | undefined;
  cancelAfterSubmit: () => Promise<any>;
  deleteAfterSubmit: () => Promise<any>;
}> = ({ initOrderData, cancelAfterSubmit, deleteAfterSubmit }) => {
  const [popupVisible, setPopupVisible] = useState(false);

  const dropList = useMemo(
    () => (
      <Menu>
        <Menu.Item
          key="1"
          // 禁用逻辑：取消过的 || ota订单 且 不为微店
          disabled={Boolean(
            initOrderData?.cancelledAt ||
              (initOrderData?.otaOutOrderId &&
                !canCancelOtaPlatformKind.has(initOrderData?.otaPlatformKind)),
          )}
          onClick={async () => {
            if (initOrderData) {
              setPopupVisible(false);
              await openCancelOrder({
                id: initOrderData.id,
                afterSubmit: cancelAfterSubmit,
              });
            }
          }}
        >
          取消订单
        </Menu.Item>
        <DeletePopconfirm
          title="你确定要删除此订单？"
          onOk={async () => {
            if (initOrderData?.id) {
              await OrderService.BatchDel({ ids: [initOrderData.id] });
              Message.success("删除成功");
              await deleteAfterSubmit();
              setPopupVisible(false);
            }
          }}
          disabled={Boolean(initOrderData?.otaOutOrderId)}
        >
          <Menu.Item
            key="2"
            className={clsx("text-color-danger-6", {
              "hover:text-color-danger-6": !initOrderData?.otaOutOrderId,
            })}
            // 禁用逻辑：ota订单不能删除
            disabled={Boolean(initOrderData?.otaOutOrderId)}
          >
            删除订单
          </Menu.Item>
        </DeletePopconfirm>
      </Menu>
    ),
    [initOrderData],
  );

  return (
    <Dropdown
      droplist={dropList}
      position="top"
      popupVisible={popupVisible}
      triggerProps={{
        onClickOutside: () => {
          setPopupVisible(false);
        },
      }}
    >
      <Button
        type="outline"
        className="mr-2"
        onClick={() => setPopupVisible(true)}
        status="danger"
      >
        取消/删除
      </Button>
    </Dropdown>
  );
};
