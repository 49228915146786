let init = 0;
export const blockFindDOMNodeWarm = () => {
  if (init) {
    return;
  }
  init = 1;
  // eslint-disable-next-line
  const consoleError = console.error.bind(console);
  // eslint-disable-next-line
  console.error = (err, ...args) => {
    if (typeof err === "string" && err.includes("findDOMNode")) {
      return;
    }
    // eslint-disable-next-line
    consoleError(err, ...args);
  };
};
