import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import { InfoChangeItem } from "./InfoChangeItem";
import { type ShowRuleItem } from "./showRule";

export const InfoChange = ({
  fieldContents,
  showRules,
}: {
  fieldContents: OperateLog.FieldContent[];
  showRules: ShowRuleItem[];
}) => {
  // 查到实际存在的rules，以rules为维度，可以保证显示顺序
  const finalShowRules = showRules.filter((rule) => {
    // 查到到当前rule下content，before或after不为空是有效的修改数据的值（有时候会有一些辅助数据，after或before无值，塞入current）
    const content = fieldContents.find(
      (_content) => _content.field === rule.key,
    );
    return content?.after || content?.before;
  });
  return (
    <>
      {finalShowRules.map((rule) => {
        const content = fieldContents.find(
          (_content) => _content.field === rule.key,
        )!;
        return (
          <InfoChangeItem
            key={rule.key}
            title={rule.title}
            before={
              rule.render
                ? rule.render(content.before, fieldContents, "before")
                : content.before
            }
            after={
              rule.render
                ? rule.render(content.after, fieldContents, "after")
                : content.after
            }
          />
        );
      })}
    </>
  );
};
