import { Input } from "@arco-design/web-react";
import { type FC } from "react";
import { CompactGroup } from "@/common/CompactGroup";
import { usePartialControllable } from "@/hooks/usePartialControllable";
import { OrderSourceKindSelector } from "@/common/Inputer/OrderSourceKindSelector";

interface OrderSourceValue {
  kind: string;
  key: string;
}
export const OrderSourceInput: FC<{
  value?: Partial<OrderSourceValue>;
  onChange?: (val?: Partial<OrderSourceValue>) => void;
  disabled?: boolean;
  otaOutOrderId?: string;
}> = ({ disabled, otaOutOrderId, ...props }) => {
  const { currentValue, handleCurrentValueChange } = usePartialControllable(
    props,
    [],
  );
  return (
    <CompactGroup>
      <OrderSourceKindSelector
        className="w-1/2"
        value={currentValue.kind}
        onChange={(t: string) => handleCurrentValueChange("kind", t)}
        disabled={disabled || Boolean(otaOutOrderId)}
      />
      <Input
        placeholder="请输入"
        className="w-1/2"
        value={currentValue.key}
        onChange={(t: string) => handleCurrentValueChange("key", t)}
        disabled={disabled || Boolean(otaOutOrderId)}
      />
    </CompactGroup>
  );
};
