import { type ReactElement, type FC, cloneElement } from "react";
import clsx from "clsx";
import { IconQuestionCircle } from "@arco-design/web-react/icon";
import { Tooltip } from "@arco-design/web-react";

export type StatusType = "success" | "warning" | "cancel";

export const PointAndTextStatus: FC<{
  type?: "success" | "warning" | "cancel" | "danger";
  typeCustomizeColor?: string;
  tipContent?: string;
  tipIcon?: ReactElement;
  children: React.ReactNode;
  pointClassName?: string;
}> = ({
  type,
  tipContent,
  children,
  tipIcon,
  typeCustomizeColor,
  pointClassName,
}) => (
  <div className="flex items-center">
    <div
      className={clsx(
        "mr-2 size-2 rounded-full",
        {
          "bg-color-primary-6": type === "success",
          "bg-color-waming-6": type === "warning",
          "bg-color-fill-4": type === "cancel",
          "bg-color-danger-6": type === "danger",
        },
        pointClassName,
      )}
      style={{ backgroundColor: typeCustomizeColor }}
    />
    <div>{children}</div>
    {tipContent && (
      <Tooltip content={tipContent}>
        {tipIcon ? (
          cloneElement(tipIcon, {
            className: "text-color-text-3 ml-2 size-4 cursor-pointer",
          })
        ) : (
          <IconQuestionCircle className="ml-2 size-4 cursor-pointer text-color-text-3" />
        )}
      </Tooltip>
    )}
  </div>
);
