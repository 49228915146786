import { useEffect, useState } from "react";
import { useImmer } from "use-immer";

export const useQueryState = <T>(name: string, initial?: T | (() => T)) => {
  const [state, setState] = useState<T>(() => {
    const url = new URL(window.location.href);
    const jsonState = url.searchParams.get(name);
    if (jsonState) {
      try {
        return JSON.parse(jsonState);
      } catch {}
    }
    if (typeof initial === "function") {
      return (initial as () => T)();
    }
    return initial;
  });

  useEffect(() => {
    const jsonState = JSON.stringify(state);
    const url = new URL(window.location.href);
    url.searchParams.set(name, jsonState);
    window.history.replaceState(null, "", url.search);
  }, [state, name]);

  return [state, setState] as const;
};

export const useQueryImmer = <T>(
  name: string,
  initial?: T | (() => T),
  withUrlStore = true, // 是否连接url
) => {
  const [state, setState] = useImmer<T>(() => {
    if (withUrlStore) {
      const url = new URL(window.location.href);
      const jsonState = url.searchParams.get(name);
      if (jsonState) {
        try {
          return JSON.parse(jsonState);
        } catch {}
      }
    }

    if (typeof initial === "function") {
      return (initial as () => T)();
    }
    return initial;
  });

  useEffect(() => {
    if (withUrlStore) {
      const jsonState = JSON.stringify(state);
      const url = new URL(window.location.href);
      url.searchParams.set(name, jsonState);
      window.history.replaceState(null, "", url.search);
    }
  }, [state, name]);

  return [state, setState] as const;
};
