import { type FC } from "react";
import { IconMore } from "@arco-design/web-react/icon";
import clsx from "clsx";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
import { useRoomGroup } from "@/store/useRoomGroup";
import defaultRoom from "@/assets/room/defaultRoom.png";
import { makeImageUrl } from "@/utils/fileUpload";
import { openImagePreview } from "../comps/openImagePreview";

export const RoomNameColumnShow: FC<{
  groupId?: string;
  roomName: string;
  imgUrl: string;
  hasMore?: boolean;
}> = ({ groupId, roomName, imgUrl, hasMore }) => {
  const { groupMap } = useRoomGroup();

  return (
    <div className="flex items-center">
      <div className="relative mr-2 h-6 w-8 ">
        <img
          src={imgUrl ? makeImageUrl(imgUrl, 32, 24, "s") : defaultRoom}
          className={clsx("relative z-[1] mr-2 h-6 w-8 shrink-0 rounded", {
            "cursor-pointer": imgUrl,
          })}
          onClick={() => {
            if (imgUrl && !hasMore) {
              void openImagePreview({ imgUrls: [imgUrl], currentIndex: 0 });
            }
          }}
        />
        {hasMore && (
          <>
            <div className="bg-color-primary-2 absolute left-[3px] top-[-3px] h-6 w-8 rounded" />
            <IconMore className="absolute inset-0 z-[2] m-auto w-5 text-white" />
          </>
        )}
      </div>
      <OverflowTooltip>
        {groupId !== undefined ? `${groupMap[groupId]?.name} # ` : ""}
        {roomName}
      </OverflowTooltip>
    </div>
  );
};
