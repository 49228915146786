import { type FC } from "react";
import { openImagePreview } from "@/comps/openImagePreview";
import { makeImageUrl } from "@/utils/fileUpload";

export const PictureListShow: FC<{
  urls: string[];
  width?: number;
  height?: number;
}> = ({ urls, width = 48, height = 32 }) => (
  <div className="flex flex-wrap gap-2">
    {urls.map((url, i) => (
      <div
        key={url}
        className="bg-color-fill-2 flex cursor-pointer items-center justify-center rounded"
        style={{ width, height }}
        onClick={(e) => {
          void openImagePreview({
            imgUrls: urls,
            currentIndex: i,
          });
          e.stopPropagation();
        }}
      >
        <img
          key={i}
          src={makeImageUrl(url, width, height, "s")}
          className="size-full rounded object-contain"
        />
      </div>
    ))}
  </div>
);
