/* 获取当前时间段： 上午/下午/晚上/"" */
export const getTimeFrame = () => {
  const hour = new Date().getHours();

  // 上午 6-12:00
  if (hour >= 6 && hour < 12) {
    return `上午好`;
  }

  // 上午 12-18:00
  if (hour >= 12 && hour < 18) {
    return `下午好`;
  }

  // 晚上 18-24:00  0-6:00
  if ((hour >= 18 && hour < 24) || (hour >= 0 && hour < 6)) {
    return `晚上好`;
  }

  return "";
};
