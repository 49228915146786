import { Nationality as ApiNationality } from "@api/http_pms/tenant/tenant_type";
import { type ConstOptionMap } from "./interface";

export const Nationality: ConstOptionMap<ApiNationality> = {
  [ApiNationality.UnknownNationality]: {
    value: ApiNationality.UnknownNationality,
    label: "",
    hidden: true,
  },
  [ApiNationality.US]: {
    value: ApiNationality.US,
    label: "美国",
  },
  [ApiNationality.CN]: {
    value: ApiNationality.CN,
    label: "中国大陆",
  },
  [ApiNationality.IN]: {
    value: ApiNationality.IN,
    label: "印度",
  },
  [ApiNationality.JP]: {
    value: ApiNationality.JP,
    label: "日本",
  },
  [ApiNationality.DE]: {
    value: ApiNationality.DE,
    label: "德国",
  },
  [ApiNationality.GB]: {
    value: ApiNationality.GB,
    label: "英国",
  },
  [ApiNationality.FR]: {
    value: ApiNationality.FR,
    label: "法国",
  },
  [ApiNationality.IT]: {
    value: ApiNationality.IT,
    label: "意大利",
  },
  [ApiNationality.BR]: {
    value: ApiNationality.BR,
    label: "巴西",
  },
  [ApiNationality.RU]: {
    value: ApiNationality.RU,
    label: "俄罗斯联邦",
  },
  [ApiNationality.CA]: {
    value: ApiNationality.CA,
    label: "加拿大",
  },
  [ApiNationality.AU]: {
    value: ApiNationality.AU,
    label: "澳大利亚",
  },
  [ApiNationality.KR]: {
    value: ApiNationality.KR,
    label: "韩国",
  },
  [ApiNationality.ES]: {
    value: ApiNationality.ES,
    label: "西班牙",
  },
  [ApiNationality.MX]: {
    value: ApiNationality.MX,
    label: "墨西哥",
  },
  [ApiNationality.ID]: {
    value: ApiNationality.ID,
    label: "印度尼西亚",
  },
  [ApiNationality.TR]: {
    value: ApiNationality.TR,
    label: "土耳其",
  },
  [ApiNationality.SA]: {
    value: ApiNationality.SA,
    label: "沙特阿拉伯",
  },
  [ApiNationality.NG]: {
    value: ApiNationality.NG,
    label: "尼日利亚",
  },
  [ApiNationality.AR]: {
    value: ApiNationality.AR,
    label: "阿根廷",
  },
  [ApiNationality.BE]: {
    value: ApiNationality.BE,
    label: "比利时",
  },
  [ApiNationality.TW]: {
    value: ApiNationality.TW,
    label: "中国台湾",
  },
  [ApiNationality.HK]: {
    value: ApiNationality.HK,
    label: "中国香港",
  },
  [ApiNationality.BM]: {
    value: ApiNationality.BM,
    label: "百慕大",
  },
  [ApiNationality.BT]: {
    value: ApiNationality.BT,
    label: "不丹",
  },
  [ApiNationality.BO]: {
    value: ApiNationality.BO,
    label: "玻利维亚",
  },
  [ApiNationality.BA]: {
    value: ApiNationality.BA,
    label: "波黑",
  },
  [ApiNationality.BW]: {
    value: ApiNationality.BW,
    label: "博茨瓦纳",
  },
  [ApiNationality.BV]: {
    value: ApiNationality.BV,
    label: "布维岛",
  },
  [ApiNationality.AQ]: {
    value: ApiNationality.AQ,
    label: "南极洲",
  },
  [ApiNationality.IO]: {
    value: ApiNationality.IO,
    label: "英属印度洋领地",
  },
  [ApiNationality.BN]: {
    value: ApiNationality.BN,
    label: "文莱",
  },
  [ApiNationality.BG]: {
    value: ApiNationality.BG,
    label: "保加利亚",
  },
  [ApiNationality.BF]: {
    value: ApiNationality.BF,
    label: "布基纳法索",
  },
  [ApiNationality.BI]: {
    value: ApiNationality.BI,
    label: "布隆迪",
  },
  [ApiNationality.KH]: {
    value: ApiNationality.KH,
    label: "柬埔寨",
  },
  [ApiNationality.CM]: {
    value: ApiNationality.CM,
    label: "喀麦隆",
  },
  [ApiNationality.BY]: {
    value: ApiNationality.BY,
    label: "白俄罗斯",
  },
  [ApiNationality.CV]: {
    value: ApiNationality.CV,
    label: "佛得角",
  },
  [ApiNationality.KY]: {
    value: ApiNationality.KY,
    label: "开曼群岛",
  },
  [ApiNationality.CF]: {
    value: ApiNationality.CF,
    label: "中非",
  },
  [ApiNationality.TD]: {
    value: ApiNationality.TD,
    label: "乍得",
  },
  [ApiNationality.CL]: {
    value: ApiNationality.CL,
    label: "智利",
  },
  [ApiNationality.CX]: {
    value: ApiNationality.CX,
    label: "圣诞岛",
  },
  [ApiNationality.CC]: {
    value: ApiNationality.CC,
    label: "科科斯 (基林) 群岛",
  },
  [ApiNationality.CO]: {
    value: ApiNationality.CO,
    label: "哥伦比亚",
  },
  [ApiNationality.KM]: {
    value: ApiNationality.KM,
    label: "科摩罗",
  },
  [ApiNationality.CG]: {
    value: ApiNationality.CG,
    label: "刚果 (布)",
  },
  [ApiNationality.CD]: {
    value: ApiNationality.CD,
    label: "刚果 (金)",
  },
  [ApiNationality.CK]: {
    value: ApiNationality.CK,
    label: "库克群岛",
  },
  [ApiNationality.CR]: {
    value: ApiNationality.CR,
    label: "哥斯达黎加",
  },
  [ApiNationality.CI]: {
    value: ApiNationality.CI,
    label: "科特迪瓦",
  },
  [ApiNationality.HR]: {
    value: ApiNationality.HR,
    label: "克罗地亚",
  },
  [ApiNationality.CU]: {
    value: ApiNationality.CU,
    label: "古巴",
  },
  [ApiNationality.CY]: {
    value: ApiNationality.CY,
    label: "塞浦路斯",
  },
  [ApiNationality.CZ]: {
    value: ApiNationality.CZ,
    label: "捷克",
  },
  [ApiNationality.DK]: {
    value: ApiNationality.DK,
    label: "丹麦",
  },
  [ApiNationality.DJ]: {
    value: ApiNationality.DJ,
    label: "吉布提",
  },
  [ApiNationality.DM]: {
    value: ApiNationality.DM,
    label: "多米尼克",
  },
  [ApiNationality.DO]: {
    value: ApiNationality.DO,
    label: "多米尼加",
  },
  [ApiNationality.EC]: {
    value: ApiNationality.EC,
    label: "厄瓜多尔",
  },
  [ApiNationality.EG]: {
    value: ApiNationality.EG,
    label: "埃及",
  },
  [ApiNationality.SV]: {
    value: ApiNationality.SV,
    label: "萨尔瓦多",
  },
  [ApiNationality.GQ]: {
    value: ApiNationality.GQ,
    label: "赤道几内亚",
  },
  [ApiNationality.ER]: {
    value: ApiNationality.ER,
    label: "厄立特里亚",
  },
  [ApiNationality.EE]: {
    value: ApiNationality.EE,
    label: "爱沙尼亚",
  },
  [ApiNationality.ET]: {
    value: ApiNationality.ET,
    label: "埃塞俄比亚",
  },
  [ApiNationality.FK]: {
    value: ApiNationality.FK,
    label: "福克兰群岛 (马尔维纳斯)",
  },
  [ApiNationality.FO]: {
    value: ApiNationality.FO,
    label: "法罗群岛",
  },
  [ApiNationality.FJ]: {
    value: ApiNationality.FJ,
    label: "斐济",
  },
  [ApiNationality.FI]: {
    value: ApiNationality.FI,
    label: "芬兰",
  },
  [ApiNationality.AO]: {
    value: ApiNationality.AO,
    label: "安哥拉",
  },
  [ApiNationality.GF]: {
    value: ApiNationality.GF,
    label: "法属圭亚那",
  },
  [ApiNationality.PF]: {
    value: ApiNationality.PF,
    label: "法属波利尼西亚",
  },
  [ApiNationality.TF]: {
    value: ApiNationality.TF,
    label: "法属南部领地",
  },
  [ApiNationality.GA]: {
    value: ApiNationality.GA,
    label: "加蓬",
  },
  [ApiNationality.GM]: {
    value: ApiNationality.GM,
    label: "冈比亚",
  },
  [ApiNationality.GE]: {
    value: ApiNationality.GE,
    label: "格鲁吉亚",
  },
  [ApiNationality.AS]: {
    value: ApiNationality.AS,
    label: "美属萨摩亚",
  },
  [ApiNationality.GH]: {
    value: ApiNationality.GH,
    label: "加纳",
  },
  [ApiNationality.GI]: {
    value: ApiNationality.GI,
    label: "直布罗陀",
  },
  [ApiNationality.GR]: {
    value: ApiNationality.GR,
    label: "希腊",
  },
  [ApiNationality.GL]: {
    value: ApiNationality.GL,
    label: "格陵兰",
  },
  [ApiNationality.GD]: {
    value: ApiNationality.GD,
    label: "格林纳达",
  },
  [ApiNationality.GP]: {
    value: ApiNationality.GP,
    label: "瓜德罗普",
  },
  [ApiNationality.GU]: {
    value: ApiNationality.GU,
    label: "关岛",
  },
  [ApiNationality.GT]: {
    value: ApiNationality.GT,
    label: "危地马拉",
  },
  [ApiNationality.GG]: {
    value: ApiNationality.GG,
    label: "格恩西岛",
  },
  [ApiNationality.GN]: {
    value: ApiNationality.GN,
    label: "几内亚",
  },
  [ApiNationality.GW]: {
    value: ApiNationality.GW,
    label: "几内亚比绍",
  },
  [ApiNationality.GY]: {
    value: ApiNationality.GY,
    label: "圭亚那",
  },
  [ApiNationality.HT]: {
    value: ApiNationality.HT,
    label: "海地",
  },
  [ApiNationality.HM]: {
    value: ApiNationality.HM,
    label: "赫德岛和麦克唐纳岛",
  },
  [ApiNationality.VA]: {
    value: ApiNationality.VA,
    label: "梵蒂冈",
  },
  [ApiNationality.HN]: {
    value: ApiNationality.HN,
    label: "洪都拉斯",
  },
  [ApiNationality.HU]: {
    value: ApiNationality.HU,
    label: "匈牙利",
  },
  [ApiNationality.IS]: {
    value: ApiNationality.IS,
    label: "冰岛",
  },
  [ApiNationality.AL]: {
    value: ApiNationality.AL,
    label: "阿尔巴尼亚",
  },
  [ApiNationality.BS]: {
    value: ApiNationality.BS,
    label: "巴哈马",
  },
  [ApiNationality.IR]: {
    value: ApiNationality.IR,
    label: "伊朗",
  },
  [ApiNationality.IQ]: {
    value: ApiNationality.IQ,
    label: "伊拉克",
  },
  [ApiNationality.IE]: {
    value: ApiNationality.IE,
    label: "爱尔兰",
  },
  [ApiNationality.IM]: {
    value: ApiNationality.IM,
    label: "曼岛",
  },
  [ApiNationality.IL]: {
    value: ApiNationality.IL,
    label: "以色列",
  },
  [ApiNationality.AI]: {
    value: ApiNationality.AI,
    label: "安圭拉",
  },
  [ApiNationality.JM]: {
    value: ApiNationality.JM,
    label: "牙买加",
  },
  [ApiNationality.DZ]: {
    value: ApiNationality.DZ,
    label: "阿尔及利亚",
  },
  [ApiNationality.JE]: {
    value: ApiNationality.JE,
    label: "泽西岛",
  },
  [ApiNationality.JO]: {
    value: ApiNationality.JO,
    label: "约旦",
  },
  [ApiNationality.KZ]: {
    value: ApiNationality.KZ,
    label: "哈萨克斯坦",
  },
  [ApiNationality.KE]: {
    value: ApiNationality.KE,
    label: "肯尼亚",
  },
  [ApiNationality.KI]: {
    value: ApiNationality.KI,
    label: "基里巴斯",
  },
  [ApiNationality.KP]: {
    value: ApiNationality.KP,
    label: "朝鲜",
  },
  [ApiNationality.AW]: {
    value: ApiNationality.AW,
    label: "阿鲁巴",
  },
  [ApiNationality.KW]: {
    value: ApiNationality.KW,
    label: "科威特",
  },
  [ApiNationality.KG]: {
    value: ApiNationality.KG,
    label: "吉尔吉斯斯坦",
  },
  [ApiNationality.LA]: {
    value: ApiNationality.LA,
    label: "老挝",
  },
  [ApiNationality.LV]: {
    value: ApiNationality.LV,
    label: "拉脱维亚",
  },
  [ApiNationality.LB]: {
    value: ApiNationality.LB,
    label: "黎巴嫩",
  },
  [ApiNationality.LS]: {
    value: ApiNationality.LS,
    label: "莱索托",
  },
  [ApiNationality.LR]: {
    value: ApiNationality.LR,
    label: "利比里亚",
  },
  [ApiNationality.LY]: {
    value: ApiNationality.LY,
    label: "利比亚",
  },
  [ApiNationality.LI]: {
    value: ApiNationality.LI,
    label: "列支敦士登",
  },
  [ApiNationality.LT]: {
    value: ApiNationality.LT,
    label: "立陶宛",
  },
  [ApiNationality.LU]: {
    value: ApiNationality.LU,
    label: "卢森堡",
  },
  [ApiNationality.MO]: {
    value: ApiNationality.MO,
    label: "中国澳门",
  },
  [ApiNationality.MK]: {
    value: ApiNationality.MK,
    label: "北马其顿",
  },
  [ApiNationality.MG]: {
    value: ApiNationality.MG,
    label: "马达加斯加",
  },
  [ApiNationality.MW]: {
    value: ApiNationality.MW,
    label: "马拉维",
  },
  [ApiNationality.MY]: {
    value: ApiNationality.MY,
    label: "马来西亚",
  },
  [ApiNationality.MV]: {
    value: ApiNationality.MV,
    label: "马尔代夫",
  },
  [ApiNationality.ML]: {
    value: ApiNationality.ML,
    label: "马里",
  },
  [ApiNationality.MT]: {
    value: ApiNationality.MT,
    label: "马耳他",
  },
  [ApiNationality.MH]: {
    value: ApiNationality.MH,
    label: "马绍尔群岛",
  },
  [ApiNationality.MQ]: {
    value: ApiNationality.MQ,
    label: "马提尼克",
  },
  [ApiNationality.MR]: {
    value: ApiNationality.MR,
    label: "毛里塔尼亚",
  },
  [ApiNationality.MU]: {
    value: ApiNationality.MU,
    label: "毛里求斯",
  },
  [ApiNationality.YT]: {
    value: ApiNationality.YT,
    label: "马约特",
  },
  [ApiNationality.AZ]: {
    value: ApiNationality.AZ,
    label: "阿塞拜疆",
  },
  [ApiNationality.FM]: {
    value: ApiNationality.FM,
    label: "密克罗尼西亚",
  },
  [ApiNationality.MD]: {
    value: ApiNationality.MD,
    label: "摩尔多瓦",
  },
  [ApiNationality.MC]: {
    value: ApiNationality.MC,
    label: "摩纳哥",
  },
  [ApiNationality.MN]: {
    value: ApiNationality.MN,
    label: "蒙古",
  },
  [ApiNationality.ME]: {
    value: ApiNationality.ME,
    label: "黑山",
  },
  [ApiNationality.MS]: {
    value: ApiNationality.MS,
    label: "蒙特塞拉特",
  },
  [ApiNationality.MA]: {
    value: ApiNationality.MA,
    label: "摩洛哥",
  },
  [ApiNationality.MZ]: {
    value: ApiNationality.MZ,
    label: "莫桑比克",
  },
  [ApiNationality.MM]: {
    value: ApiNationality.MM,
    label: "缅甸",
  },
  [ApiNationality.NA]: {
    value: ApiNationality.NA,
    label: "纳米比亚",
  },
  [ApiNationality.NR]: {
    value: ApiNationality.NR,
    label: "瑙鲁",
  },
  [ApiNationality.NP]: {
    value: ApiNationality.NP,
    label: "尼泊尔",
  },
  [ApiNationality.NL]: {
    value: ApiNationality.NL,
    label: "荷兰",
  },
  [ApiNationality.AN]: {
    value: ApiNationality.AN,
    label: "荷属安的列斯",
  },
  [ApiNationality.NC]: {
    value: ApiNationality.NC,
    label: "新喀里多尼亚",
  },
  [ApiNationality.NZ]: {
    value: ApiNationality.NZ,
    label: "新西兰",
  },
  [ApiNationality.NI]: {
    value: ApiNationality.NI,
    label: "尼加拉瓜",
  },
  [ApiNationality.NE]: {
    value: ApiNationality.NE,
    label: "尼日尔",
  },
  [ApiNationality.BB]: {
    value: ApiNationality.BB,
    label: "巴巴多斯",
  },
  [ApiNationality.NU]: {
    value: ApiNationality.NU,
    label: "纽埃",
  },
  [ApiNationality.NF]: {
    value: ApiNationality.NF,
    label: "诺福克岛",
  },
  [ApiNationality.MP]: {
    value: ApiNationality.MP,
    label: "北马里亚纳群岛",
  },
  [ApiNationality.NO]: {
    value: ApiNationality.NO,
    label: "挪威",
  },
  [ApiNationality.OM]: {
    value: ApiNationality.OM,
    label: "阿曼",
  },
  [ApiNationality.PK]: {
    value: ApiNationality.PK,
    label: "巴基斯坦",
  },
  [ApiNationality.PW]: {
    value: ApiNationality.PW,
    label: "帕劳",
  },
  [ApiNationality.PS]: {
    value: ApiNationality.PS,
    label: "巴勒斯坦",
  },
  [ApiNationality.PA]: {
    value: ApiNationality.PA,
    label: "巴拿马",
  },
  [ApiNationality.PG]: {
    value: ApiNationality.PG,
    label: "巴布亚新几内亚",
  },
  [ApiNationality.PY]: {
    value: ApiNationality.PY,
    label: "巴拉圭",
  },
  [ApiNationality.PE]: {
    value: ApiNationality.PE,
    label: "秘鲁",
  },
  [ApiNationality.PH]: {
    value: ApiNationality.PH,
    label: "菲律宾",
  },
  [ApiNationality.AM]: {
    value: ApiNationality.AM,
    label: "亚美尼亚",
  },
  [ApiNationality.PN]: {
    value: ApiNationality.PN,
    label: "皮特凯恩群岛",
  },
  [ApiNationality.PL]: {
    value: ApiNationality.PL,
    label: "波兰",
  },
  [ApiNationality.PT]: {
    value: ApiNationality.PT,
    label: "葡萄牙",
  },
  [ApiNationality.PR]: {
    value: ApiNationality.PR,
    label: "波多黎各",
  },
  [ApiNationality.QA]: {
    value: ApiNationality.QA,
    label: "卡塔尔",
  },
  [ApiNationality.RE]: {
    value: ApiNationality.RE,
    label: "留尼汪",
  },
  [ApiNationality.RO]: {
    value: ApiNationality.RO,
    label: "罗马尼亚",
  },
  [ApiNationality.RW]: {
    value: ApiNationality.RW,
    label: "卢旺达",
  },
  [ApiNationality.SH]: {
    value: ApiNationality.SH,
    label: "圣赫勒拿",
  },
  [ApiNationality.KN]: {
    value: ApiNationality.KN,
    label: "圣基茨和尼维斯",
  },
  [ApiNationality.LC]: {
    value: ApiNationality.LC,
    label: "圣卢西亚",
  },
  [ApiNationality.PM]: {
    value: ApiNationality.PM,
    label: "圣皮埃尔和密克隆",
  },
  [ApiNationality.VC]: {
    value: ApiNationality.VC,
    label: "圣文森特和格林纳丁斯",
  },
  [ApiNationality.WS]: {
    value: ApiNationality.WS,
    label: "萨摩亚",
  },
  [ApiNationality.SM]: {
    value: ApiNationality.SM,
    label: "圣马力诺",
  },
  [ApiNationality.ST]: {
    value: ApiNationality.ST,
    label: "圣多美和普林西比",
  },
  [ApiNationality.BD]: {
    value: ApiNationality.BD,
    label: "孟加拉国",
  },
  [ApiNationality.SN]: {
    value: ApiNationality.SN,
    label: "塞内加尔",
  },
  [ApiNationality.RS]: {
    value: ApiNationality.RS,
    label: "塞尔维亚",
  },
  [ApiNationality.SC]: {
    value: ApiNationality.SC,
    label: "塞舌尔",
  },
  [ApiNationality.SL]: {
    value: ApiNationality.SL,
    label: "塞拉利昂",
  },
  [ApiNationality.SG]: {
    value: ApiNationality.SG,
    label: "新加坡",
  },
  [ApiNationality.SK]: {
    value: ApiNationality.SK,
    label: "斯洛伐克",
  },
  [ApiNationality.SI]: {
    value: ApiNationality.SI,
    label: "斯洛文尼亚",
  },
  [ApiNationality.SB]: {
    value: ApiNationality.SB,
    label: "所罗门群岛",
  },
  [ApiNationality.SO]: {
    value: ApiNationality.SO,
    label: "索马里",
  },
  [ApiNationality.ZA]: {
    value: ApiNationality.ZA,
    label: "南非",
  },
  [ApiNationality.GS]: {
    value: ApiNationality.GS,
    label: "南乔治亚岛和南桑威奇群岛",
  },
  [ApiNationality.AT]: {
    value: ApiNationality.AT,
    label: "奥地利",
  },
  [ApiNationality.LK]: {
    value: ApiNationality.LK,
    label: "斯里兰卡",
  },
  [ApiNationality.SD]: {
    value: ApiNationality.SD,
    label: "苏丹",
  },
  [ApiNationality.SR]: {
    value: ApiNationality.SR,
    label: "苏里南",
  },
  [ApiNationality.SJ]: {
    value: ApiNationality.SJ,
    label: "斯瓦尔巴和扬马延",
  },
  [ApiNationality.SZ]: {
    value: ApiNationality.SZ,
    label: "斯威士兰",
  },
  [ApiNationality.SE]: {
    value: ApiNationality.SE,
    label: "瑞典",
  },
  [ApiNationality.CH]: {
    value: ApiNationality.CH,
    label: "瑞士",
  },
  [ApiNationality.SY]: {
    value: ApiNationality.SY,
    label: "叙利亚",
  },
  [ApiNationality.TJ]: {
    value: ApiNationality.TJ,
    label: "塔吉克斯坦",
  },
  [ApiNationality.TH]: {
    value: ApiNationality.TH,
    label: "泰国",
  },
  [ApiNationality.TL]: {
    value: ApiNationality.TL,
    label: "东帝汶",
  },
  [ApiNationality.TG]: {
    value: ApiNationality.TG,
    label: "多哥",
  },
  [ApiNationality.TK]: {
    value: ApiNationality.TK,
    label: "托克劳",
  },
  [ApiNationality.TO]: {
    value: ApiNationality.TO,
    label: "汤加",
  },
  [ApiNationality.TT]: {
    value: ApiNationality.TT,
    label: "特立尼达和多巴哥",
  },
  [ApiNationality.TN]: {
    value: ApiNationality.TN,
    label: "突尼斯",
  },
  [ApiNationality.BH]: {
    value: ApiNationality.BH,
    label: "巴林",
  },
  [ApiNationality.TM]: {
    value: ApiNationality.TM,
    label: "土库曼斯坦",
  },
  [ApiNationality.TC]: {
    value: ApiNationality.TC,
    label: "特克斯和凯科斯群岛",
  },
  [ApiNationality.TV]: {
    value: ApiNationality.TV,
    label: "图瓦卢",
  },
  [ApiNationality.UG]: {
    value: ApiNationality.UG,
    label: "乌干达",
  },
  [ApiNationality.UA]: {
    value: ApiNationality.UA,
    label: "乌克兰",
  },
  [ApiNationality.AE]: {
    value: ApiNationality.AE,
    label: "阿联酋",
  },
  [ApiNationality.AD]: {
    value: ApiNationality.AD,
    label: "安道尔",
  },
  [ApiNationality.UM]: {
    value: ApiNationality.UM,
    label: "美国本土外小岛屿",
  },
  [ApiNationality.TZ]: {
    value: ApiNationality.TZ,
    label: "坦桑尼亚",
  },
  [ApiNationality.UY]: {
    value: ApiNationality.UY,
    label: "乌拉圭",
  },
  [ApiNationality.UZ]: {
    value: ApiNationality.UZ,
    label: "乌兹别克斯坦",
  },
  [ApiNationality.VU]: {
    value: ApiNationality.VU,
    label: "瓦努阿图",
  },
  [ApiNationality.VE]: {
    value: ApiNationality.VE,
    label: "委内瑞拉",
  },
  [ApiNationality.VN]: {
    value: ApiNationality.VN,
    label: "越南",
  },
  [ApiNationality.VG]: {
    value: ApiNationality.VG,
    label: "英属维尔京群岛",
  },
  [ApiNationality.VI]: {
    value: ApiNationality.VI,
    label: "美属维尔京群岛",
  },
  [ApiNationality.WF]: {
    value: ApiNationality.WF,
    label: "瓦利斯和富图纳",
  },
  [ApiNationality.EH]: {
    value: ApiNationality.EH,
    label: "西撒哈拉",
  },
  [ApiNationality.YE]: {
    value: ApiNationality.YE,
    label: "也门",
  },
  [ApiNationality.ZM]: {
    value: ApiNationality.ZM,
    label: "赞比亚",
  },
  [ApiNationality.ZW]: {
    value: ApiNationality.ZW,
    label: "津巴布韦",
  },
  [ApiNationality.AG]: {
    value: ApiNationality.AG,
    label: "安提瓜和巴布达",
  },
  [ApiNationality.AF]: {
    value: ApiNationality.AF,
    label: "阿富汗",
  },
  [ApiNationality.BJ]: {
    value: ApiNationality.BJ,
    label: "贝宁",
  },
  [ApiNationality.BZ]: {
    value: ApiNationality.BZ,
    label: "伯利兹",
  },
  [ApiNationality.AX]: {
    value: ApiNationality.AX,
    label: "奥兰群岛",
  },
};
