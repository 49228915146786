import React, { type FC, useMemo } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { type PieSeriesOption } from "echarts/charts";
import { type OtaPlatformProportionResp } from "@api/http_pms/dashboard/dashboard";
import { WorkbenchItemCard } from "./components/WorkbenchItemCard";
import { WorkbenchItemTitle } from "./components/WorkbenchItemTitle";
import { usePercentageOfOTA } from "./hooks";
import percentageOfOTASkeleton from "@/assets/workbench/percentageOfOTASkeleton.svg";
import { notFoundIndex, percentageOfOTAListSkeletonColumn } from "./const";
import { useOrderSource } from "@/store/useOrderSource";
import { OtaPlatformKind } from "@/common/const";
import { OverflowTooltip } from "@/comps/OverflowTooltip";

// 自定义格式化tooltip
function tooltipFormatter(params: {
  color: string;
  name: string;
  value: number;
  percent: number;
}) {
  return `
<div
  style="padding: 12px; display: flex; flex-direction: column; color: #333"
>
  <div style="display: flex; align-items: center">
    <div
      style="
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: ${params.color};
        margin-right: 8px;
      "
    ></div>
    <div style="color: #666">${params.name}</div>
  </div>
  <div style="padding-left: 16px; padding-top: 8px; padding-bottom: 8px">
    占比&nbsp;${params.percent}%
  </div>
  <div style="padding-left: 16px">订单数&nbsp;${params.value}</div>
</div>
  `;
}

interface PercentageOfOTAListProps {
  topFourItems: OtaPlatformProportionResp.OtaPlatformProportion[];
  otherItemsTotal: number;
}

const PercentageOfOTAList: FC<PercentageOfOTAListProps> = ({
  topFourItems,
  otherItemsTotal,
}) => {
  const { orderSourceMapId } = useOrderSource();
  // 判断 其它项 是否有值，有值才展示 其它项
  const dataList = otherItemsTotal
    ? [
        ...topFourItems,
        // notFoundIndex(-1) 为自己手动定义的，特殊值，仅代表其他
        {
          orderCount: otherItemsTotal,
          orderSourceId: notFoundIndex,
        },
      ]
    : topFourItems;

  return (
    <div className="flex flex-1 justify-end space-x-2">
      <div className="flex flex-col  text-xs">
        <div className="text-color-text-3 mb-2.5">平台名称</div>
        {dataList.map((item) => (
          <div
            key={item.orderSourceId}
            className="text-color-text mb-4 flex max-w-[70px] items-center"
          >
            <span
              className="mr-1.5 size-2 shrink-0 rounded-full"
              style={{
                backgroundColor:
                  OtaPlatformKind[
                    orderSourceMapId[item.orderSourceId]?.otaPlatformKind || 0
                  ].color,
              }}
            />
            <OverflowTooltip>
              <span>
                {item.orderSourceId === notFoundIndex
                  ? "其他"
                  : orderSourceMapId[item.orderSourceId]?.name}
              </span>
            </OverflowTooltip>
          </div>
        ))}
      </div>
      <div className="flex flex-col text-xs">
        <div className="text-color-text-3 mb-2.5">订单数</div>
        {dataList.map((item) => (
          <div
            key={item.orderSourceId}
            className="text-color-text mb-4 flex items-center justify-end"
          >
            <span>{item.orderCount}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

interface SkeletonProps {
  columnNum?: number;
}

const PercentageOfOTAListSkeleton: FC<SkeletonProps> = ({
  columnNum = percentageOfOTAListSkeletonColumn,
}) => {
  const row = <div className="bg-color-fill-2 mb-4 h-4 w-14 rounded" />;

  return (
    <div className="flex flex-1 justify-end space-x-2">
      <div className="flex flex-col text-xs">
        <div className="text-color-text-3 mb-2.5">平台名称</div>
        {Array.from({ length: columnNum }).map((_, idx) => (
          <React.Fragment key={idx}>{row}</React.Fragment>
        ))}
      </div>
      <div className="flex flex-col text-xs">
        <div className="text-color-text-3 mb-2.5">订单数</div>
        {Array.from({ length: columnNum }).map((_, idx) => (
          <React.Fragment key={idx}>{row}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

interface PercentageOfOTAPieChartProps {
  option: echarts.ComposeOption<PieSeriesOption>;
}

const PercentageOfOTAPieChart: FC<PercentageOfOTAPieChartProps> = ({
  option,
}) => (
  <div className="size-[160px]">
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      style={{ height: "160px", width: "160px" }}
      lazyUpdate={true}
    />
  </div>
);

export const PercentageOfOTACard = () => {
  const { loading, sortedData, topFourItems, otherItemsTotal } =
    usePercentageOfOTA();
  const { orderSourceMapId } = useOrderSource();

  const pieData = useMemo(
    () =>
      sortedData.map((item) => ({
        name: orderSourceMapId[item.orderSourceId]?.name,
        value: item.orderCount,
        itemStyle: {
          color:
            OtaPlatformKind[
              orderSourceMapId[item.orderSourceId]?.otaPlatformKind || 0
            ].color,
        },
      })),
    [sortedData],
  );

  const option: echarts.ComposeOption<PieSeriesOption> = {
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(255, 255, 255, 0.795)", // 背景颜色
      borderColor: "#ffffff", // 边框颜色
      borderWidth: 0, // 边框宽度
      textStyle: {
        color: "#333", // 文本颜色
        fontSize: 14, // 字体大小
      },
      borderRadius: 8,
      formatter: tooltipFormatter,
    },
    series: [
      {
        name: "OTA平台占比",
        type: "pie",
        radius: ["60%", "90%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: pieData,
      },
    ],
  };

  return (
    <WorkbenchItemCard className="flex w-[364px] min-w-[364px] flex-col px-8 py-6">
      <WorkbenchItemTitle>OTA平台占比</WorkbenchItemTitle>
      <div className="flex size-full flex-row pt-8">
        {loading ? (
          <>
            <img src={percentageOfOTASkeleton} className="block size-[160px]" />

            <PercentageOfOTAListSkeleton />
          </>
        ) : (
          renderChartAndList()
        )}
      </div>
    </WorkbenchItemCard>
  );

  function renderChartAndList() {
    if (!sortedData.length) {
      return (
        <div className="grid size-full place-content-center">暂无数据</div>
      );
    }

    return (
      <>
        <PercentageOfOTAPieChart option={option} />

        <PercentageOfOTAList
          topFourItems={topFourItems}
          otherItemsTotal={otherItemsTotal}
        />
      </>
    );
  }
};
