export const lineChartConst = {
  primaryColor: "#6f9f0a",
  linearGradientStartColor: "rgba(111, 159, 10, 0.3)",
  linearGradientEndColor: "rgba(111, 159, 10, 0)",
  splitLineColor: "#D2DDF8",
  axisLineColor: "#DDDDDD",
  axisLabelColor: "rgba(0,0,0,0.45)",
  axisPointerLineColor: "#CCCCCC",
  tooltipTextColor: "#333333",
  tooltipTitleColor: "#666666",
};

export const notFoundIndex = -1;

export const percentageOfOTAListSkeletonColumn = 5;

export const todayStatusListSkeletonColumn = 6;

export const tomorrowStatusListSkeletonColumn = 4;
