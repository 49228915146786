import { Link, Popconfirm, Tooltip } from "@arco-design/web-react";

import clsx from "clsx";
import dayjs from "dayjs";
import { sum } from "lodash-es";
import { useMemo, type FC } from "react";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { useWallet } from "@/store/useWallet";
import { getMoneyText } from "@/utils/money";
import { refundedDepositDealWith } from "@/pages/Bill/utils";
import { type MoneyShowProps } from ".";
import { permissionHas } from "@/store/useUserInfo";

export const MoneyShowDeposit: FC<MoneyShowProps> = ({
  shareBill,
  onSubmit,
}) => {
  const { walletMap } = useWallet();

  const { sumAmount, sumInDepositAmount, refundDepositSubBill } = useMemo(
    () => ({
      sumAmount: sum(shareBill?.subBills.map((bill) => bill.amount)),
      sumInDepositAmount: sum(
        shareBill?.subBills
          .filter((bill) => bill.amount > 0)
          .map((bill) => bill.amount),
      ),
      refundDepositSubBill: shareBill?.subBills.find(
        (bill) => bill.amount <= 0,
      ),
    }),
    [shareBill],
  );

  return (
    <>
      <div className="text-color-text-2 mt-3 flex items-center justify-between">
        <div
          className={clsx("flex", {
            "text-color-primary-6": sumInDepositAmount >= 0,
            "text-color-danger-6": sumInDepositAmount < 0,
          })}
        >
          {getMoneyText(sumInDepositAmount, shareBill.currency, {})}
          {sumAmount === 0 && (
            <Tooltip
              position="right"
              content={
                refundDepositSubBill?.subUpdatedAt
                  ? dayjs
                      .unixStr(refundDepositSubBill.subUpdatedAt)
                      .dateStr(true)
                  : ""
              }
            >
              <div className="text-color-text-4 ml-4 cursor-pointer">
                已退还
              </div>
            </Tooltip>
          )}
        </div>
        <div>{walletMap[shareBill.walletId]?.name}</div>
      </div>

      {sumAmount !== 0 && (
        <div className="mt-2 flex h-6">
          <div className="mr-4">
            待退还：
            {getMoneyText(sumAmount, shareBill.currency, {
              hidePlusSign: true,
            })}
          </div>
          {permissionHas(ApiPermission.BillUpdate) && (
            <Popconfirm
              title="确定退还押金？"
              onOk={async () => {
                await onSubmit?.(refundedDepositDealWith(shareBill));
              }}
              okText="确定"
            >
              <Link>退还</Link>
            </Popconfirm>
          )}
        </div>
      )}
    </>
  );
};
