import { RouterProvider, createBrowserRouter } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { HoxRoot } from "hox";
import { Divider } from "@arco-design/web-react";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import React, { Suspense } from "react";
import { RouterLayout } from "../common/Layout/RouterLayout";
import { WorkBench } from "./WorkBench";
import { IconFont } from "@/common/IconFont";
import { permissionHas, useUserInfo } from "@/store/useUserInfo";
import { useAllTag } from "@/store/useTag";
import { useWallet } from "@/store/useWallet";
import { useOrgInfo } from "@/store/useOrgInfo";
import { useRoomGroup } from "@/store/useRoomGroup";
import { useRoomDayActivity } from "@/store/useRoomDayActivity";
import ErrorBoundary from "@/comps/ErrorBoundary";
import { useRoomInfo } from "@/store/useRoomInfo";
import { useOrderSource } from "@/store/useOrderSource";
import { initWasm } from "@/wasm/core";
import { LayoutLoading } from "@/comps/LayoutLoading";
import { ChatRedDotCount } from "./Chat/ChatRedDotCount";
import { useChatStore } from "./Chat/store";
import Review from "./Review";

const Calendar = React.lazy(() => import("./Calendar"));
const Chat = React.lazy(() => import("./Chat"));
const Room = React.lazy(() => import("./Room"));
const Mission = React.lazy(() => import("./Mission"));
const Order = React.lazy(() => import("./Order"));
const Bill = React.lazy(() => import("./Bill"));
const MonthlyReport = React.lazy(() => import("./MonthlyReport"));
const Statistic = React.lazy(() => import("./Statistic"));
const Ota = React.lazy(() => import("./Ota"));
const Setting = React.lazy(() => import("./Setting"));
const Register = React.lazy(() => import("./LoginRegister/Register"));
const Login = React.lazy(() => import("./LoginRegister/Login"));
const ForgetSendEmail = React.lazy(
  () => import("./LoginRegister/ForgetSendEmail"),
);
const ForgetResetPassword = React.lazy(
  () => import("./LoginRegister/ForgetResetPassword"),
);
const Account = React.lazy(() => import("./Account"));
const Wallet = React.lazy(() => import("./Wallet"));
const Reservation = React.lazy(() => import("./Reservation"));

const onInitial = async () => {
  await Promise.all([
    initWasm(),
    useOrgInfo.getState().refresh(),
    useUserInfo.getState().refresh(),
    useRoomGroup.getState().groupRefresh(),
    useAllTag.getState().tagRefresh(null),
    useRoomDayActivity.getState().activityRefresh(),
    useWallet.getState().walletRefresh(),
    useRoomInfo.getState().refreshAll(),
    useOrderSource.getState().refresh(),
  ]);
  if (permissionHas(ApiPermission.Chat)) {
    void useChatStore.getState().cmd.initSSE();
  }
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forget/sendEmail",
    element: <ForgetSendEmail />,
  },
  {
    path: "/forget/resetPassword",
    element: <ForgetResetPassword />,
  },
  {
    path: "/",
    element: (
      <RouterLayout
        headerRender={() => "IM Center"}
        onInitial={onInitial}
        menuItems={[
          {
            path: "/",
            name: "工作台",
            icon: <IconFont type="icon-sider-workbench" />,
          },
          {
            path: "/chat",
            name: "聊天",
            afterName: <ChatRedDotCount />,
            icon: <IconFont type="icon-sider-chat" />,
            noPermission: () => !permissionHas(ApiPermission.Chat),
          },
          {
            path: "/calendar",
            name: "房态",
            icon: <IconFont type="icon-menu-room-state" />,
            noPermission: () => !permissionHas(ApiPermission.RoomDayView),
          },
          {
            path: "/room",
            name: "房间",
            icon: <IconFont type="icon-sider-room" />,
            noPermission: () => !permissionHas(ApiPermission.RoomView),
          },
          // {
          //   path: "/mission",
          //   name: "任务",
          //   icon: <IconFont type="icon-sider-mission" />,
          // },
          {
            path: "/order",
            name: "订单",
            icon: <IconFont type="icon-sider-order" />,
            noPermission: () => !permissionHas(ApiPermission.OrderView),
          },
          {
            path: "/review",
            name: "评价",
            icon: <IconFont type="icon-sider-evaluate" />,
            noPermission: () => !permissionHas(ApiPermission.OrderReview),
          },
          {
            path: "",
            name: "财务",
            icon: <IconFont type="icon-sider-bill" />,
            noPermission: () =>
              !permissionHas(ApiPermission.BillView) &&
              !permissionHas(ApiPermission.BillMonthlyView) &&
              !permissionHas(ApiPermission.BillWalletView),
            subMenus: [
              {
                path: "/bill",
                name: "账单",
                noPermission: () => !permissionHas(ApiPermission.BillView),
              },
              {
                path: "/bill/monthlyReport",
                name: "月报",
                noPermission: () =>
                  !permissionHas(ApiPermission.BillMonthlyView),
              },
              {
                path: "/bill/wallet",
                name: "钱包",
                noPermission: () =>
                  !permissionHas(ApiPermission.BillWalletView),
              },
            ],
          },
          {
            path: "/statistic",
            name: "统计",
            icon: <IconFont type="icon-sider-statistic" />,
            noPermission: () => !permissionHas(ApiPermission.OccupancyRateView),
          },
          {
            path: "/reservation",
            name: "微店",
            icon: <IconFont type="icon-menu-reservation" />,
            noPermission: () => !permissionHas(ApiPermission.ReservationView),
          },
          {
            key: "Divider",
            render: () => <Divider className="m-0 flex-auto" />,
          },
          {
            path: "/ota",
            name: "OTA直连",
            icon: <IconFont type="icon-sider-ota" />,
            noPermission: () => !permissionHas(ApiPermission.OtaAccount),
          },
          {
            path: "/account",
            name: "账号管理",
            icon: <IconFont type="icon-sider-user" />,
            noPermission: () => !permissionHas(ApiPermission.Account),
          },
          {
            path: "/setting",
            name: "系统管理",
            icon: <IconFont type="icon-sider-setting" />,
            noPermission: () => !permissionHas(ApiPermission.System),
          },
        ]}
      />
    ),
    children: [
      {
        path: "/",
        element: <WorkBench />,
      },
      {
        path: "/chat",
        element: <Chat />,
      },
      {
        path: "/calendar",
        element: <Calendar />,
      },
      {
        path: "/room",
        element: <Room />,
      },
      {
        path: "/mission",
        element: <Mission />,
      },
      {
        path: "/order",
        element: <Order />,
      },
      {
        path: "/review",
        element: <Review />,
      },
      {
        path: "/bill",
        children: [
          {
            path: "/bill",
            element: <Bill />,
          },
          {
            path: "/bill/monthlyReport",
            element: <MonthlyReport />,
          },
          {
            path: "/bill/wallet",
            element: <Wallet />,
          },
        ],
      },
      {
        path: "/statistic",
        element: <Statistic />,
      },
      {
        path: "/reservation",
        element: <Reservation />,
      },
      {
        path: "/ota",
        element: <Ota />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/setting",
        element: <Setting />,
      },
    ],
  },
]);

function App() {
  return (
    <ErrorBoundary>
      <NiceModal.Provider>
        <HoxRoot>
          <Suspense fallback={<LayoutLoading />}>
            <RouterProvider router={router} />
          </Suspense>
        </HoxRoot>
      </NiceModal.Provider>
    </ErrorBoundary>
  );
}

export default App;
