import { Link } from "@arco-design/web-react";
import { IconLink } from "@arco-design/web-react/icon";
import { useMemo, type FC } from "react";
import dayjs from "dayjs";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import clsx from "clsx";
import { permissionHas } from "@/store/useUserInfo";
import { useOrderSource } from "@/store/useOrderSource";
import { openOrderDrawer } from "@/pages/Order/OrderDrawer";

export const LinkOrder: FC<{
  order?: {
    id: string;
    dateStart?: string;
    dateEnd?: string;
    orderSourceId?: string;
    orderSourceKey?: string;
  };
  showOrderSource?: boolean;
  orderSourceTextSmallSize?: boolean;
  layout?: "vertical" | "horizontal";
  refreshAsync?: () => Promise<any>;
}> = ({
  order,
  showOrderSource,
  orderSourceTextSmallSize,
  layout = "vertical",
  refreshAsync,
}) => {
  const { orderSourceMapId } = useOrderSource();

  const text = useMemo(() => {
    if (!order) {
      return "-";
    }
    if (order.dateStart && order.dateEnd) {
      return `${dayjs(order.dateStart).dateStr(false, true)} - ${dayjs(order.dateEnd).dateStr(false, true)}`;
    }
    return order.id;
  }, [order?.id, order?.dateStart, order?.dateEnd]);

  const orderSourceText = useMemo(() => {
    if (order && showOrderSource) {
      return (
        <div
          className={`text-color-text-2 ${orderSourceTextSmallSize ? "text-[10px]" : "text-xs"}`}
        >
          {[
            order.orderSourceId
              ? orderSourceMapId[order.orderSourceId]?.name
              : undefined,
            order.orderSourceKey || order.id,
          ]
            .filter((item) => item)
            .join(" - ")}
        </div>
      );
    }
  }, [order, showOrderSource]);

  if (!order) {
    return "-";
  }
  return (
    <div
      className={clsx(
        `flex ${layout === "vertical" ? "flex-col" : "flex-row items-center gap-[10px]"}`,
      )}
    >
      <Link
        className={
          permissionHas(ApiPermission.OrderView)
            ? "cursor-pointer"
            : "cursor-default"
        }
        icon={<IconLink />}
        onClick={(e) => {
          e.stopPropagation();
          if (permissionHas(ApiPermission.OrderView)) {
            void openOrderDrawer({
              orderId: order.id,
              afterSubmit: refreshAsync,
            });
          }
        }}
      >
        {text}
      </Link>
      {orderSourceText}
    </div>
  );
};
