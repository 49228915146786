import { publish } from "pubsub-js";

export const webapi = {
  post: async <T>(url: string, params: any, config: any) => {
    const resp = await fetch(url, {
      method: "post",
      body: JSON.stringify(params),
      credentials: "include",
    });
    authMiddleware(resp);

    if (resp.status < 200 || resp.status > 299) {
      publish("webapi_net_err", "网络错误");
      throw new Error("net error");
    }

    const respData: {
      code: string;
      msg: string;
      data: T;
    } = await resp.json();
    if (respData.code) {
      publish("webapi_net_err", respData.msg);
      throw new ApiError(respData.code, respData.msg);
    }

    return respData.data;
  },
};

const authMiddleware = (resp: Response) => {
  switch (resp.status) {
    case 401:
      publish("webapi_status_401");
      throw new Error();
    case 403:
      publish("webapi_status_403");
      throw new Error();
    default:
  }
};

export class ApiError extends Error {
  code: string;
  constructor(code: string, msg: string) {
    super(msg);
    this.code = code;
  }
}
