import { BillKind, type ShareBill } from "@api/http_pms/bill/bill_type";
import { type FC } from "react";
import { MoneyShowSingle } from "./Single";
import { MoneyShowDeposit } from "./Deposit";

export interface MoneyShowProps {
  shareBill: ShareBill;
  onSubmit?: (item: ShareBill) => Promise<void>;
}

export const MoneyShow: FC<MoneyShowProps> = (props) => {
  const { shareBill } = props;
  if (shareBill.billKind === BillKind.Deposit) {
    return <MoneyShowDeposit {...props} />;
  }
  return <MoneyShowSingle {...props} />;
};
