import { type FC } from "react";
import { EntityKind } from "@api/http_pms/const/const_enum";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import clsx from "clsx";
import { EasTag } from "@/common/EasTag";
import { openTagManager } from "@/common/openTagManager";
import { IconFont } from "@/common/IconFont";
import { useTag } from "@/store/useTag";
import { permissionHas } from "@/store/useUserInfo";

export const TagEditor: FC<{
  value?: string[];
  onChange?: (v: string[]) => void;
  onSubmit: (v: string[]) => Promise<string[]>;
  className?: string;
}> = ({ value, onChange, onSubmit, className }) => {
  const { tagMap } = useTag(EntityKind.Order);

  return (
    <div
      className={clsx("flex flex-auto flex-wrap items-end gap-2", className)}
    >
      {value?.map((tag) => (
        <EasTag
          key={tag}
          color={tagMap[tag]?.color}
          text={tagMap[tag]?.name || ""}
        />
      ))}
      {permissionHas(ApiPermission.OrderSetRemarkAndTag) && (
        <EasTag
          prefix={<IconFont type="icon-edit" className="mr-1" />}
          onClick={async () => {
            await openTagManager({
              tagIds: value || [],
              onSubmit: async (tagIds) => {
                const resTagIds = await onSubmit(tagIds);
                onChange?.(resTagIds);
              },
              entityKind: EntityKind.Order,
            });
          }}
          className="cursor-pointer"
          text="标签编辑"
        />
      )}
    </div>
  );
};
