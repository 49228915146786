import { Popconfirm, type PopconfirmProps } from "@arco-design/web-react";
import { cloneElement, useRef, useState, type FC } from "react";

export const AsyncPopconfirm: FC<
  PopconfirmProps & {
    children: React.ReactElement<{ onClick?: (e: any) => void }>;
  }
> = ({ onOk, children, ...props }) => {
  const okRef = useRef<{
    resolve: (v?: any) => void;
    reject: () => void;
  }>();

  const injectedOnClick = async (e: any) => {
    setVisible(true);
    await new Promise((resolve, reject) => {
      okRef.current = { resolve, reject };
    });
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await children.props.onClick?.(e);
  };

  const [visible, setVisible] = useState(false);

  return (
    <Popconfirm
      {...props}
      popupVisible={visible}
      onOk={async (e) => {
        await onOk?.(e);
        okRef.current?.resolve?.();
        setVisible(false);
      }}
      onCancel={() => {
        okRef.current?.reject?.();
        setVisible(false);
      }}
    >
      <>{cloneElement(children, { onClick: injectedOnClick })}</>
    </Popconfirm>
  );
};
