/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type OrderSourceConfig } from "./order_source_config_type";

export namespace OrderSourceConfigService {
  export function All(req: AllReq, config?: any) {
    return webapi.post<AllResp>(
      "/api/v1/OrderSourceConfigService_All",
      req,
      config,
    );
  }

  export function Save(req: SaveReq, config?: any) {
    return webapi.post<SaveResp>(
      "/api/v1/OrderSourceConfigService_Save",
      req,
      config,
    );
  }

  export function Del(req: DelReq, config?: any) {
    return webapi.post<DelResp>(
      "/api/v1/OrderSourceConfigService_Del",
      req,
      config,
    );
  }

  export function BatchSetRank(req: BatchSetRankReq, config?: any) {
    return webapi.post<BatchSetRankResp>(
      "/api/v1/OrderSourceConfigService_BatchSetRank",
      req,
      config,
    );
  }
}
// All
export interface AllReq {}

export interface AllResp {
  configs: OrderSourceConfig[];
}

// Save
export interface SaveReq {
  config: OrderSourceConfig;
}

export interface SaveResp {
  config: OrderSourceConfig;
}

// Del
export interface DelReq {
  id: string;
}

export interface DelResp {
  //  @uint32
  rows: number;
}

// BatchSetRank
export interface BatchSetRankReq {
  ids: string[];
}

export interface BatchSetRankResp {
  configs: OrderSourceConfig[];
}
