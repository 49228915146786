import { type FormInstance } from "@arco-design/web-react";
import { pick } from "lodash-es";
import {
  type ShareBill,
  type BillKind as ApiBillKind,
} from "@api/http_pms/bill/bill_type";
import { useMemo } from "react";
import { type BillFormData } from ".";
import { BillKind, amountTypeOptions } from "@/common/const";

export const getShareBillSubmitData = async (
  form: FormInstance,
): Promise<ShareBill> => {
  await form.validate();
  const res = form.getFields() as BillFormData;
  const shareBill: ShareBill = pick(
    res,
    "shareId",
    "subBills",
    "billKind",
    "currency",
    "receiptDate",
    "walletId",
    "remark",
    "images",
    "postingDate",
  );
  return shareBill;
};

export const useAmountTypeOptions = (
  billKind: ApiBillKind,
  form: FormInstance,
) =>
  useMemo(() => {
    const amountType = BillKind[billKind]?.amountType;
    if (amountType) {
      form.setFieldValue("amountType", amountType);
      return amountTypeOptions.map((option) => ({
        ...option,
        disabled: option.value !== amountType,
      }));
    }
    return amountTypeOptions;
  }, [billKind]);
