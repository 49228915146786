import { Radio } from "@arco-design/web-react";
import { type FC } from "react";
import { Sex } from "@/common/const";
import { useConstOption } from "@/common/const/utils";

export const SexRadio: FC<{
  value?: number;
  onChange?: (v?: number) => void;
}> = ({ value, onChange }) => {
  const sexOptions = useConstOption(Sex);

  return (
    <Radio.Group size="default" value={value}>
      {sexOptions.map((option) => (
        <Radio
          className="!m-0"
          key={option.value}
          value={option.value}
          onClick={() => {
            onChange?.(value === option.value ? undefined : option.value);
          }}
        >
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
