import { create } from "zustand";

import { OrderSourceConfigService } from "@api/http_pms/order_source_config/order_source_config_srv";
import { type OrderSourceConfig } from "@api/http_pms/order_source_config/order_source_config_type";
import { OtaPlatformKind } from "@api/http_pms/const/const_enum";

const defaultOrderSources: OrderSourceConfig[] = [
  {
    id: "",
    name: "无",
    otaPlatformKind: OtaPlatformKind.UnknownOtaPlatform,
    enableOrderSourceKeyCheck: false,
    rank: 0,
  },
  {
    id: "reservation",
    name: "微店",
    otaPlatformKind: OtaPlatformKind.Reservation,
    enableOrderSourceKeyCheck: false,
    rank: 0,
  },
];

interface OrderSourceStore {
  orderSourceList: OrderSourceConfig[];
  orderSourceMapId: Record<string, OrderSourceConfig>;
  refresh: () => Promise<void>;
}

export const useOrderSource = create<OrderSourceStore>((set) => ({
  orderSourceList: [],
  orderSourceMapId: {},
  orderSourceOptions: [],
  refresh: async () => {
    const res = await OrderSourceConfigService.All({});
    const configs = res.configs.sort((a, b) => b.rank - a.rank);

    set({
      orderSourceList: configs,
      orderSourceMapId: Object.fromEntries(
        [...defaultOrderSources, ...configs].map((a) => [a.id, a]),
      ),
    });
  },
}));
