import dayjs, { type Dayjs } from "dayjs";
import { useOrgInfo } from "@/store/useOrgInfo";

export enum OrderStatusEnum {
  Cancelled,
  WaitingCheckIn,
  CheckInToday,
  CheckIn,
  CheckOutToday,
  CheckedOut,
  SevenDayAfterCheckIn,
  SevenDayDuringCheckIn,
  Reserving,
}

export const OrderStatus: Record<
  OrderStatusEnum,
  {
    value: OrderStatusEnum;
    label: string;
    color: string;
    calendarColor: string;
  }
> = {
  [OrderStatusEnum.Cancelled]: {
    value: OrderStatusEnum.Cancelled,
    label: "已取消",
    color: "#C1C8CD",
    calendarColor: "#C1C8CD",
  },
  [OrderStatusEnum.WaitingCheckIn]: {
    value: OrderStatusEnum.WaitingCheckIn,
    label: "待入住",
    color: "#BED491",
    calendarColor: "#6F9F0A",
  },
  [OrderStatusEnum.CheckInToday]: {
    value: OrderStatusEnum.CheckInToday,
    label: "今日入住",
    color: "#6F9F0A",
    calendarColor: "#FF6100",
  },
  [OrderStatusEnum.CheckIn]: {
    value: OrderStatusEnum.CheckIn,
    label: "入住中",
    color: "#FF6100",
    calendarColor: "#FF6100",
  },
  [OrderStatusEnum.CheckOutToday]: {
    value: OrderStatusEnum.CheckOutToday,
    label: "今日退房",
    color: "#FFAB00",
    calendarColor: "#FF6100",
  },
  [OrderStatusEnum.CheckedOut]: {
    value: OrderStatusEnum.CheckedOut,
    label: "已退房",
    color: "#C1C8CD",
    calendarColor: "#C1C8CD",
  },
  [OrderStatusEnum.SevenDayDuringCheckIn]: {
    value: OrderStatusEnum.SevenDayDuringCheckIn,
    label: "7日内入住",
    color: "#BED491",
    calendarColor: "#6F9F0A",
  },
  [OrderStatusEnum.SevenDayAfterCheckIn]: {
    value: OrderStatusEnum.SevenDayAfterCheckIn,
    label: "7日后入住",
    color: "#BED491",
    calendarColor: "#6F9F0A",
  },
  [OrderStatusEnum.Reserving]: {
    value: OrderStatusEnum.Reserving,
    label: "预定中",
    color: "#5AD8A6",
    calendarColor: "#5AD8A6",
  },
};

export const getCurrentTimeZoneDate = (customDate?: string | Dayjs) =>
  dayjs.tz(
    customDate ? dayjs(customDate) : dayjs(),
    useOrgInfo.getState().orgInfo.timeZone,
  );

export const getOrderStatus = ({
  dateStart,
  dateEnd,
  isCancelled,
  cancelledAt,
}: {
  dateStart: string;
  dateEnd: string;
  isCancelled: boolean;
  cancelledAt?: string;
}) => {
  const nowDate = getCurrentTimeZoneDate();

  if (isCancelled) {
    return OrderStatusEnum.Cancelled;
  }

  if (cancelledAt) {
    return OrderStatusEnum.Reserving;
  }

  // 今日入住
  if (nowDate.isSame(dateStart, "day")) {
    return OrderStatusEnum.CheckInToday;
  }

  // 待入住
  if (nowDate.isBefore(dateStart, "day")) {
    return OrderStatusEnum.WaitingCheckIn;
  }

  // 入住中
  if (nowDate.isAfter(dateStart, "day") && nowDate.isBefore(dateEnd, "day")) {
    return OrderStatusEnum.CheckIn;
  }

  // 今日退房
  if (nowDate.isSame(dateEnd, "day")) {
    return OrderStatusEnum.CheckOutToday;
  }

  // 已退房
  if (nowDate.isAfter(dateEnd, "day")) {
    return OrderStatusEnum.CheckedOut;
  }

  return OrderStatusEnum.CheckedOut;
};
