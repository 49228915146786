import clsx from "clsx";
import { sum } from "lodash-es";
import { useMemo, type FC } from "react";
import { getMoneyText } from "@/utils/money";
import { type MoneyShowProps } from ".";
import { useWallet } from "@/store/useWallet";

export const MoneyShowSingle: FC<MoneyShowProps> = ({ shareBill }) => {
  const { walletMap } = useWallet();
  const { sumAmount } = useMemo(
    () => ({
      sumAmount: sum(shareBill?.subBills.map((bill) => bill.amount)),
    }),
    [shareBill],
  );

  return (
    <div className="text-color-text-2 mt-3 flex items-center justify-between">
      <span
        className={clsx({
          "text-color-primary-6": sumAmount >= 0,
          "text-color-danger-6": sumAmount < 0,
        })}
      >
        {getMoneyText(sumAmount, shareBill.currency, {})}
      </span>
      <span>{walletMap[shareBill.walletId]?.name}</span>
    </div>
  );
};
