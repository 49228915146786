import { Grid } from "@arco-design/web-react";
import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import clsx from "clsx";
import { type Currency as ApiCurrency } from "@api/http_pms/const/const_enum";
import { billShowRule } from "./showRule";
import { IconFont } from "@/common/IconFont";
import { InfoChange } from "./InfoChange";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
import { getMoneyText } from "@/utils/money";
import { FileChange } from "./FileChange";

const { Row } = Grid;
const { Col } = Grid;

interface SubBill {
  Currency: ApiCurrency;
  Amount: number;
  RoomId: string;
}

export const BillChange = ({ list }: { list: OperateLog.FieldContent[] }) => {
  const finalList = list.filter(
    (item) =>
      !["AddSubBills", "DeleteSubBills", "UpdateSubBills"].includes(item.field),
  );
  const addSubBill = list.find((item) => item.field === "AddSubBills");
  const deleteSubBill = list.find((item) => item.field === "DeleteSubBills");
  const updateSubBill = list.find((item) => item.field === "UpdateSubBills");
  const beforeUpdateSubBills: SubBill[] = updateSubBill?.before
    ? JSON.parse(updateSubBill?.before)
    : {};
  const afterUpdateSubBills: SubBill[] = updateSubBill?.after
    ? JSON.parse(updateSubBill?.after)
    : [];

  return (
    <div className="mt-2">
      {/* 新增 */}
      <BillAddDelete item={addSubBill} />

      {/* 删除 */}
      <BillAddDelete item={deleteSubBill} isDelete={true} />

      {/* 修改 */}
      {updateSubBill && (
        <Row>
          <Col span={5} className="text-color-text-2 text-right">
            子帐单：
          </Col>
          <Col span={19}>
            {beforeUpdateSubBills.map(
              (
                item: { Currency: ApiCurrency; Amount: number; RoomId: string },
                index: number,
              ) => {
                const currentAfter = afterUpdateSubBills.find(
                  (after) => after.RoomId === item.RoomId,
                );
                return (
                  <div
                    className="flex w-full items-center justify-between"
                    key={index}
                  >
                    <OverflowTooltip className="max-w-32">
                      {item.RoomId}
                    </OverflowTooltip>
                    <div className="flex items-center gap-4">
                      <div className="line-through">
                        {getMoneyText(item.Amount, item.Currency, {
                          hide00Dec: true,
                        })}
                      </div>
                      <IconFont
                        type="icon-single-right-arrow"
                        className="mr-1 size-4 shrink-0"
                      />
                      <div>
                        {currentAfter &&
                          getMoneyText(
                            currentAfter.Amount,
                            currentAfter.Currency,
                            {
                              hide00Dec: true,
                            },
                          )}
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </Col>
        </Row>
      )}

      <InfoChange fieldContents={finalList} showRules={billShowRule} />
      <FileChange list={list} />
    </div>
  );
};

export const BillAddDelete = ({
  isDelete,
  item,
}: {
  isDelete?: boolean;
  item: OperateLog.FieldContent | undefined;
}) => {
  if (item?.current) {
    return (
      <Row>
        <Col span={5} className="text-color-text-2 text-right">
          {isDelete ? "删除" : "新增"}：
        </Col>
        <Col span={19} className={clsx({ "line-through": isDelete })}>
          {JSON.parse(item?.current).map((_item: SubBill, index: number) => (
            <div className="flex w-full justify-between" key={index}>
              <div>{_item.RoomId}</div>
              <div>
                {getMoneyText(_item.Amount, _item.Currency, {
                  hide00Dec: true,
                })}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    );
  }
};
