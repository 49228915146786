import { type ReviewForList } from "@api/http_pms/ota_review/ota_review_type";
import { Button, Grid } from "@arco-design/web-react";
import { IconPlus } from "@arco-design/web-react/icon";
import { type FC } from "react";
import dayjs from "dayjs";
import { OtaReviewService } from "@api/http_pms/ota_review/ota_review_srv";
import { isEmpty } from "lodash-es";
import notPublishStatus from "@/assets/review/notPublishStatus.png";
import { useRoomInfo } from "@/store/useRoomInfo";
import { useRoomGroup } from "@/store/useRoomGroup";
import { LinkOrder } from "@/common/Links/LinkOrder";
import { OtaPlatformKind } from "@/common/const";
import { getCurrentTimeZoneDate } from "@/utils/orderStatus";
import { openPublishReview } from "./PublishReview/openPublishReview";
import { openOrderDrawer } from "../Order/OrderDrawer";

const Row = Grid.Row;
const Col = Grid.Col;

export const NotPublicCardList: FC<{
  list: ReviewForList[];
  colNumber: number;
  mutateNewList: (list: ReviewForList[]) => void;
}> = ({ list, colNumber, mutateNewList }) => {
  const { roomMapId } = useRoomInfo();
  const { groupMap } = useRoomGroup();

  const _mutateNewList = async (id: string, index: number) => {
    const newList = [...list];
    const res = await OtaReviewService.ListByCond({
      roomIds: [],
      overallRatings: [],
      isPublished: false,
      ids: [id],
      current: 1,
      pageSize: 18,
    });
    const _list = res.reviews || [];
    if (isEmpty(_list)) {
      newList.splice(index, 1);
    } else if (_list[0]) {
      newList.splice(index, 1, _list[0]);
    }
    mutateNewList(newList);
  };

  return (
    <Row gutter={[16, 16]}>
      {list?.map((item, idx) => (
        <Col span={colNumber === 3 ? 8 : 12} key={idx}>
          <div
            className="relative cursor-pointer rounded-lg bg-white p-4"
            onClick={async () => {
              await openOrderDrawer({
                orderId: item.order.id,
                defaultActiveTab: "3",
              });
              void _mutateNewList(item.id, idx);
            }}
          >
            <div className="absolute left-0 top-0 h-[22px] w-[68px]">
              <span className="absolute left-2 z-[1] text-white">未公开</span>
              <img
                src={notPublishStatus}
                className="absolute left-0 top-0 h-[22px] w-[68px]"
              />
            </div>
            {item.isExpired || item.hostHasReviewed ? (
              <Button
                disabled={true}
                className="!border-color-fill-4 absolute right-4"
              >
                {item.isExpired ? "已过期" : "已发布"}
              </Button>
            ) : (
              <Button
                type="primary"
                icon={<IconPlus />}
                className="absolute right-4"
                onClick={async (e) => {
                  e.stopPropagation();
                  await openPublishReview({
                    reviewerName: item.guestReview!.reviewerName,
                    id: item.id,
                  });
                  void _mutateNewList(item.id, idx);
                }}
              >
                发布评价
              </Button>
            )}

            <div className="mt-4 flex items-center">
              <img
                src={OtaPlatformKind[item.otaPlatformKind].iconUrlSquare}
                className="size-6 rounded-full"
              />
              <span className="ml-2 font-medium">
                {item.guestReview?.reviewerName}
              </span>
              <span className="text-color-text-3 ml-3">
                {item.createdAt && dayjs.unixStr(item.createdAt).dateStr(true)}
              </span>
            </div>
            <div className="mt-2 flex justify-between">
              <span className="text-color-primary-6">
                {/* 过期双方都未评价 */}
                {item.isExpired &&
                !item.guestHasReviewed &&
                !item.hostHasReviewed
                  ? "双方未评价"
                  : item.hostHasReviewed && !item.guestHasReviewed
                    ? "房东已评价，等待房客评价"
                    : !item.hostHasReviewed && item.guestHasReviewed
                      ? "房客已评价，添加评价后可查看房客评价内容"
                      : "等待双方评价"}
              </span>
              <span className="text-color-text-2">
                {item.isExpired ? (
                  "评价已关闭"
                ) : (
                  <span>
                    剩余
                    {dayjs
                      .unixStr(item.expiredAt)
                      .diff(getCurrentTimeZoneDate(), "day")}
                    天可评价
                  </span>
                )}
              </span>
            </div>
            <div className="bg-color-fill-1 mt-3 flex h-9 items-center justify-between rounded px-2">
              <span>
                {roomMapId[item.order.roomId]?.groupId &&
                  groupMap[roomMapId[item.order.roomId]!.groupId]?.name}
                <span className="mx-1">#</span>
                {roomMapId[item.order.roomId]?.name}
              </span>
              <LinkOrder
                order={item.order}
                showOrderSource={false}
                orderSourceTextSmallSize={false}
              />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};
