/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type Currency } from "../const/const_enum";
import { type ExchangeAmount } from "../common/common_type";

export interface Wallet {
  // 钱包id
  id: string;
  // 名称
  name: string;
  // 结算币种
  currency: Currency;
  // 钱包余额
  balances?: ExchangeAmount;
  // 图标
  iconKind: IconKind;
}

export interface WalletPure {
  // 钱包id
  id: string;
  // 名称
  name: string;
  // 图标
  iconKind: IconKind;
}
export enum IconKind {
  // 空
  None = 0,
  // 支付宝
  AliPay = 1,
  // 微信
  WxPay = 2,
  // 银行
  Bank = 3,
}
