import { UserService } from "@api/http_pms/user/user_srv";
import { Form, Message } from "@arco-design/web-react";
import { type ReactNode } from "react";
import { satisfyPassword, required } from "@/utils/validator";
import { PasswordInput } from "@/common/Inputer/PasswordInput";
import { AsyncButton } from "@/comps/AsyncButton";

export const PasswordManageForm = () => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.validate();
    await UserService.ResetPasswordByOldPassword({
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
      confirmPassword: values.confirmPassword,
    });
    Message.success("修改成功");
    form.resetFields();
  };

  return (
    <>
      <div className="mb-6 text-base font-medium">修改密码</div>
      <Form form={form} layout="vertical">
        <Form.Item field="oldPassword" label="当前密码">
          <PasswordInput
            size="large"
            placeholder="请输入当前密码，没有设置过请留空"
          />
        </Form.Item>
        <Form.Item
          field="newPassword"
          label="新密码"
          rules={[required("新密码"), satisfyPassword("onBlur")]}
        >
          <PasswordInput
            size="large"
            placeholder="请输入新密码，8-32位，需包含数字及字母"
          />
        </Form.Item>
        <Form.Item
          field="confirmPassword"
          label="再次输入新密码"
          rules={[
            required("再次输入新密码"),
            {
              validateTrigger: "onBlur",
              validator(value: any, cb: (error?: ReactNode) => void) {
                if (value !== form.getFieldValue("newPassword")) {
                  return cb("两次密码输入不相同，请重新输入");
                }
                return cb();
              },
            },
          ]}
        >
          <PasswordInput
            size="large"
            placeholder="请再次输入新密码，确保两次输入一样"
          />
        </Form.Item>
        <div className="mt-2">
          <AsyncButton type="primary" className="mr-3" onClick={onSubmit}>
            确定
          </AsyncButton>
        </div>
      </Form>
    </>
  );
};
