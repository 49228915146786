import { useCreation } from "ahooks";
import { type ConstOptionMap, type ConstOption } from "./interface";

export const genConstOption = <
  K extends PropertyKey,
  T extends Record<string, any> = {},
>(
  c: ConstOptionMap<K, T>,
  filter?: (obj: ConstOption<K, T>) => boolean,
): ConstOption<K, T>[] =>
  Object.values<ConstOption<K, T>>(c)
    .filter((f) => !f.hidden && (!filter || filter(f)))
    .sort((a, b) => {
      if (b.rank !== undefined && a.rank === undefined) {
        return -1;
      }
      if (b.rank === undefined && a.rank !== undefined) {
        return 1;
      }
      if (b.rank !== undefined && a.rank !== undefined) {
        return b.rank - a.rank;
      }
      return Number(a.value) - Number(b.value);
    }) as any;

export const useConstOption = <
  K extends PropertyKey,
  T extends Record<string, any> = {},
>(
  c: ConstOptionMap<K, T>,
  filter?: (obj: ConstOption<K, T>) => boolean,
): ConstOption<K, T>[] =>
  useCreation(() => {
    const res = genConstOption(c, filter);
    return res;
  }, []);
