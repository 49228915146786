import { Input, type InputProps } from "@arco-design/web-react";

export const PasswordInput = (props: InputProps) => (
  <Input.Password
    css={`
      .arco-input-inner-wrapper {
        align-items: stretch;
      }
      .arco-input-group-suffix {
        height: auto;
      }
      .arco-input-group-suffix > svg {
        font-size: 16px !important;
        height: auto;
      }
    `}
    {...props}
  />
);
