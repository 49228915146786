import React, { type FC } from "react";
import { Empty, Spin } from "@arco-design/web-react";
import clsx from "clsx";
import loadingGif from "@/assets/system/loading.gif";
import empty from "@/assets/system/empty.svg";
import pageWrong from "@/assets/system/pageWrong.svg";

export const Loading: FC<{
  loading?: boolean;
  loadingText?: string;
  // 第一次加载后，有后续操作，需要在之前加载数据进行loading时开启，需要自己处理空态
  renderChildrenWhenLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  errorText?: string;
  children?: React.ReactNode;
  className?: string;
  boxClassName?: string;
  spinCss?: string;
}> = ({
  loading = true,
  loadingText = "",
  renderChildrenWhenLoading = false,
  isEmpty = false,
  isError = false,
  errorText = "加载失败",
  children,
  className,
  boxClassName,
  spinCss,
}) => {
  if (renderChildrenWhenLoading) {
    return (
      <Spin
        element={
          isError ? (
            <div className="text-center">
              <img src={pageWrong} className="w-[200px]" />
              <div>{errorText}</div>
            </div>
          ) : (
            <div className="text-center">
              <img className="w-[200px]" src={loadingGif} />
              <div>{loadingText}</div>
            </div>
          )
        }
        loading={loading || isError}
        className={className}
        css={spinCss}
      >
        {children}
      </Spin>
    );
  }
  if (loading) {
    return (
      <div
        className={clsx(
          "flex size-full items-center justify-center",
          boxClassName,
        )}
      >
        <img src={loadingGif} className="w-[200px]" />
      </div>
    );
  }
  if (isError) {
    return <div className="text-center">{errorText}</div>;
  }
  if (isEmpty) {
    return (
      <div
        className={clsx(
          "flex size-full items-center justify-center",
          boxClassName,
        )}
      >
        <Empty imgSrc={empty} />
      </div>
    );
  }
  return children;
};
