import { type Order } from "@api/http_pms/order/order_type";
import dayjs from "dayjs";
import { useRoomInfo } from "@/store/useRoomInfo";
import { useOrgInfo } from "@/store/useOrgInfo";
import { type orderRawToForm } from "./BaseInfoContent/utils";

export const genOldAndYearOptions = () => {
  const options = [];
  const currentYear = Number(new Date().getFullYear());
  for (let i = currentYear; i >= 1930; i--) {
    options.push({
      label: `${currentYear - i} (${i})`,
      value: i,
    });
  }
  return options;
};

export type DefaultOrderData = Pick<
  Order,
  "roomId" | "dateStart" | "dateEnd" | "roomFee"
>;

export const genDefaultFormData = (defaultOrderData?: DefaultOrderData) => {
  const orgInfo = useOrgInfo.getState().orgInfo;
  const roomId = defaultOrderData?.roomId || "";
  const roomInfo = useRoomInfo.getState().roomMapId[roomId];

  const initOrder: ReturnType<typeof orderRawToForm> = {
    id: "",
    tenantCount: 1,
    remark: "",
    roomId,
    orderDates:
      defaultOrderData?.dateStart && defaultOrderData?.dateEnd
        ? [defaultOrderData.dateStart, defaultOrderData.dateEnd]
        : [],
    checkInTime: roomInfo?.checkInTime || 0,
    checkOutTime: roomInfo?.checkOutTime || 0,
    roomFeeGroup: {
      currency: orgInfo.currency,
      roomFeeAmount: defaultOrderData?.roomFee || 0,
      roomFeeWalletId: "",
      otaFeeWalletId: undefined,
      otaFeeAmount: 0,
      handlingFee: 0,
    },
    bookAt: dayjs().unixStr(),
    tenants: [],
    orderSource: {
      kind: "",
      key: "",
    },
    tagIds: orgInfo.orderDefaultTagIds,
  };

  return initOrder;
};
