import clsx from "clsx";
import { type FC } from "react";

export const TimeNode: FC<{
  content?: React.ReactNode;
  nodeTitle: React.ReactNode;
  showLine?: boolean;
}> = ({ content, nodeTitle, showLine = true }) => {
  return (
    <div>
      <div className="flex items-center">
        <div className="bg-color-primary-2 flex size-5 shrink-0 items-center justify-center rounded-full">
          <div className="bg-color-primary-6 size-2 rounded-full" />
        </div>
        <div className="flex-auto pl-3">{nodeTitle}</div>
      </div>
      <div
        className={clsx(
          "mb-3 ml-[10px] mt-[6px]  border-dashed pb-4 pl-[22px]",
          {
            "border-l": showLine,
          },
        )}
      >
        {content}
      </div>
    </div>
  );
};
