import { IconDown } from "@arco-design/web-react/icon";
import clsx from "clsx";
import { useMemo, useState, type FC } from "react";
import { Progress } from "@arco-design/web-react";
import {
  CategoryKind as ApiCategoryKind,
  CategoryTagKind as ApiCategoryTagKind,
  type ReviewContent,
} from "@api/http_pms/ota_review/ota_review_type";
import { IconFont } from "@/common/IconFont";
import { CategoryKind, CategoryTagKind } from "@/pages/Review/const";
import { OverflowTooltip } from "@/comps/OverflowTooltip";

export const HostReviewCard: FC<{ review: ReviewContent }> = ({ review }) => {
  const [isExpand, setIsExpand] = useState(false);

  const isRecommend = useMemo(() => {
    const categoryRating = review?.categoryRatings.find(
      (_item) => _item.categoryKind === ApiCategoryKind.Recommend,
    );
    return categoryRating?.categoryTags.includes(
      ApiCategoryTagKind.HostReviewGuestRecommend,
    );
  }, []);

  return (
    <div className="bg-color-fill-1 mt-2 rounded-xl p-3">
      <div className="flex items-center font-medium">
        {review.reviewerName}
        {isRecommend ? (
          <div
            className={clsx(
              "border-color-primary-6 text-color-primary-6 ml-[9px] flex items-center justify-center rounded-lg",
            )}
          >
            <IconFont type="icon-thumb-up" className="mr-[5px]" />
            推荐
          </div>
        ) : (
          <div
            className={clsx(
              "border-color-danger-6 text-color-danger-6 ml-[9px] flex items-center justify-center rounded-lg",
            )}
          >
            <IconFont
              type="icon-thumb-up"
              className="mr-[5px]"
              style={{
                transform: `rotateX(180deg)`,
              }}
            />
            不推荐
          </div>
        )}
      </div>
      <div className="mb-3 mt-4 font-medium">指标打分</div>
      <div className="mt-2 flex w-full flex-col gap-2">
        {review?.categoryRatings
          .filter(
            (categoryRating) =>
              categoryRating.categoryKind !== ApiCategoryKind.Recommend,
          )
          .map((categoryRating, index) => (
            <div key={index}>
              <div className="flex w-full items-start">
                <div className="flex shrink-0 items-center">
                  <div className="text-color-text-2 shrink-0">
                    {CategoryKind[categoryRating.categoryKind].label}
                  </div>
                  <div className="mx-2 font-medium">
                    {categoryRating.rating}
                  </div>
                </div>
                <div className="flex-auto">
                  <div className="flex h-[22px] w-10/12 items-center">
                    <Progress
                      percent={(categoryRating.rating / 5) * 100}
                      showText={false}
                    />
                  </div>
                  {isExpand && (
                    <div className="bg-color-fill-2 mt-1 rounded-lg px-2 pb-1">
                      <OverflowTooltip line={2}>
                        {categoryRating.categoryTags.map((categoryTag) => (
                          <span
                            key={categoryTag}
                            css={`
                              &:before {
                                content: "";
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background-color: rgb(var(--primary-6));
                                display: inline-block;
                                margin-bottom: 2px;
                                margin-right: 8px;
                              }
                            `}
                            className="mr-6 mt-1 inline-block"
                          >
                            {CategoryTagKind[categoryTag].label !== "其他"
                              ? CategoryTagKind[categoryTag].label
                              : categoryRating.comment}
                          </span>
                        ))}
                      </OverflowTooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      {isExpand && (
        <>
          {review.publicContent && (
            <>
              <div className="mb-2 mt-4 font-medium">公开评价</div>
              <div className="text-color-text-2 whitespace-pre-line">
                {review.publicContent}
              </div>
            </>
          )}
          {review.respondContent && (
            <div className="bg-color-primary-1 mt-2 rounded-lg p-2">
              <span className="text-color-primary-6">房客：</span>
              {review.respondContent}
            </div>
          )}
          {review.privateContent && (
            <>
              <div className="mb-2 mt-4 font-medium">私信评价</div>
              <div className="text-color-text-2 whitespace-pre-line">
                {review.privateContent}
              </div>
            </>
          )}
        </>
      )}

      <div className="text-color-primary-6 mt-3 text-center">
        {isExpand ? (
          <span
            className="cursor-pointer"
            onClick={() => setIsExpand(!isExpand)}
          >
            收起 <IconDown className="ml-2 rotate-180" />
          </span>
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => setIsExpand(!isExpand)}
          >
            查看评价详情 <IconDown className="ml-2" />
          </span>
        )}
      </div>
    </div>
  );
};
