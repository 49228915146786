import { Input, type InputProps, Trigger } from "@arco-design/web-react";
import { IconCheck } from "@arco-design/web-react/icon";

import { useState, type FC } from "react";
import { type Color } from "@api/http_pms/const/const_enum";
import { BaseColor } from "@/common/const";
import { useConstOption } from "../const/utils";
import { useInitialValue } from "@/hooks/useInitialValue";

export interface ColorInputValue {
  text: string;
  color: Color;
}

type ColorInputProps = Omit<InputProps, "value" | "onChange"> & {
  value?: ColorInputValue;
  onChange?: (val: ColorInputValue) => void;
};

export const ColorInput: FC<ColorInputProps> = ({
  value: _value,
  onChange,
  ...props
}) => {
  const [triggerVisible, setTriggerVisible] = useState(false);
  const baseColorOptions = useConstOption(BaseColor);

  const value = useInitialValue(_value, onChange, () => ({
    color: Math.floor(Math.random() * 8) as Color,
    text: "",
  }));

  return (
    <Input
      prefix={
        <Trigger
          popupAlign={{
            bottom: [-8, 8],
          }}
          position="bl"
          popupVisible={triggerVisible}
          onVisibleChange={(visible) => {
            setTriggerVisible(visible);
          }}
          trigger="click"
          popup={() => (
            <div
              className="flex h-11 items-center rounded-lg bg-white p-3"
              css={`
                box-shadow: 0px 2px 20px 0px rgba(39, 44, 62, 0.16);
              `}
            >
              {baseColorOptions.map((c, i) => (
                <div
                  key={i}
                  className="mr-3 flex h-5 w-8 cursor-pointer items-center justify-center rounded-lg border"
                  style={{
                    backgroundColor: c.bg,
                    borderColor: c.border,
                  }}
                  onClick={() => {
                    onChange?.({ ...value, color: c.value });
                  }}
                >
                  {c.value === value.color && (
                    <IconCheck style={{ color: c.code }} />
                  )}
                </div>
              ))}
            </div>
          )}
        >
          <div
            className="-ml-1 h-5 w-8 cursor-pointer rounded-lg border bg-slate-400"
            style={{
              backgroundColor: BaseColor[value.color].bg,
              borderColor: BaseColor[value.color].border,
            }}
            onClick={() => setTriggerVisible(true)}
          />
        </Trigger>
      }
      value={value?.text}
      onChange={(val) => onChange?.({ ...value, text: val })}
      {...props}
    />
  );
};
