import clsx from "clsx";
import { type FC } from "react";
import { IconFont } from "@/common/IconFont";

export const RecommendInput: FC<{
  value?: boolean;
  onChange?: (val: boolean) => void;
}> = ({ value, onChange }) => {
  return (
    <div className="mt-3 flex items-center gap-2">
      <div
        className={clsx(
          "bg-color-fill-1 flex h-[42px] w-[156px] cursor-pointer items-center justify-center rounded-lg",
          {
            "border border-color-primary-6 text-color-primary-6 bg-color-primary-1":
              value !== undefined && value,
          },
        )}
        onClick={() => onChange?.(true)}
      >
        <IconFont type="icon-thumb-up" className="mr-2" />
        推荐
      </div>
      <div
        className={clsx(
          "bg-color-fill-1 flex h-[42px] w-[156px] cursor-pointer items-center justify-center rounded-lg",
          {
            "border border-color-danger-6 text-color-danger-6 !bg-color-danger-1":
              value !== undefined && !value,
          },
        )}
        onClick={() => onChange?.(false)}
      >
        <IconFont
          type="icon-thumb-up"
          className="mr-2"
          style={{
            transform: `rotateX(180deg)`,
          }}
        />
        不推荐
      </div>
    </div>
  );
};
