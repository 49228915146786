/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";

export namespace DashboardService {
  // 今日和明日状态
  export function TodayOccupancy(req: DashboardReq, config?: any) {
    return webapi.post<TodayOccupancyResp>(
      "/api/v1/DashboardService_TodayOccupancy",
      req,
      config,
    );
  }

  // 入住率（当日前后15日）
  export function OccupancyRate(req: DashboardReq, config?: any) {
    return webapi.post<OccupancyRateResp>(
      "/api/v1/DashboardService_OccupancyRate",
      req,
      config,
    );
  }

  // OTA平台占比
  export function OtaPlatformProportion(req: DashboardReq, config?: any) {
    return webapi.post<OtaPlatformProportionResp>(
      "/api/v1/DashboardService_OtaPlatformProportion",
      req,
      config,
    );
  }
}
export interface DashboardReq {}

export interface TodayOccupancyResp {
  // 入住：入住时间为当日的订单数量 @uint32
  todayCheckInCount: number;
  // 退房：退房时间为当日 @uint32
  todayCheckOutCount: number;
  // 换客 当日退房又有几个人入住的 @uint32
  todayChangeTenantCount: number;
  // 空房 当日所剩余空房 @uint32
  todayRemainingCount: number;
  // 在住 当日在住数量 @uint32
  todayStayCount: number;
  // 预定 当日下单数量 @uint32
  todayBookCount: number;
  // 取消 当日取消数量 @uint32
  todayCancelCount: number;
  // 入住：入住时间为明日的订单数量 @uint32
  tomorrowCheckInCount: number;
  // 退房：退房时间为当日 @uint32
  tomorrowCheckOutCount: number;
  // 换客 当日退房又有几个人入住的 @uint32
  tomorrowChangeTenantCount: number;
  // 空房 当日所剩余空房 @uint32
  tomorrowRemainingCount: number;
}

// This the module of OccupancyRateResp
export namespace OccupancyRateResp {
  export interface DailyOccupancyRate {
    // 2006-01-02
    date: string;
    // 当日入住中的订单数 @uint32
    orderCount: number;
    // 总房数（房间创建时间小于当天日期） @uint32
    roomCount: number;
  }
}
export interface OccupancyRateResp {
  currentTime: string;
  dailyOccupancyRates: OccupancyRateResp.DailyOccupancyRate[];
}

// This the module of OtaPlatformProportionResp
export namespace OtaPlatformProportionResp {
  export interface OtaPlatformProportion {
    // 订单来源
    orderSourceId: string;
    // 订单数 @uint32
    orderCount: number;
  }
}
export interface OtaPlatformProportionResp {
  currentTime: string;
  // 总数 @uint32
  totalOrderCount: number;
  otaPlatformProportions: OtaPlatformProportionResp.OtaPlatformProportion[];
}
