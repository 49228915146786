import { type RulesProps } from "@arco-design/web-react";
import { type ReactNode } from "react";

export const required = (
  title: string,
  validateKey?: string,
  length?: [number, number],
): RulesProps<any> => ({
  validator(value: any, cb: (error?: ReactNode) => void) {
    const validateValue = validateKey ? value?.[validateKey] : value;
    if (validateValue === 0) {
      return cb();
    }
    const _len: number = validateValue?.toString().length || 0;
    if (_len === 0) {
      return cb(`${title}是必填项`);
    }
    if (length && (_len < length[0] || _len > length[1])) {
      return cb(`${title}需满足${length[0]}-${length[1]}个字符`);
    }
    return cb();
  },
});

export const trimmed = (
  title: string,
  validateKey?: string,
): RulesProps<any> => ({
  validator(value: any, cb: (error?: ReactNode) => void) {
    const validateValue = validateKey ? value?.[validateKey] : value;
    const _val: string = validateValue?.toString() || "";
    if (/^\s|\s$/.test(_val)) {
      return cb(`${title}不得已空白字符开头或结尾`);
    }
  },
});

export const gtZero = (
  title: string,
  validateKey?: string,
): RulesProps<any> => ({
  validator(value: any, cb: (error?: ReactNode) => void) {
    const validateValue = validateKey ? value?.[validateKey] : value;
    if ((validateValue || 0) <= 0) {
      return cb(`${title}需要大于0`);
    }
    return cb();
  },
});

export const satisfyPassword = (
  validateTrigger?: string | string[],
): RulesProps<any> => ({
  validateTrigger,
  validator(value: any, cb: (error?: ReactNode) => void) {
    if (value && !/^(?=.*[a-zA-Z])(?=.*\d).{8,32}$/.test(value as string)) {
      return cb("需包含数字及字母且长度8-32位");
    }
    return cb();
  },
});

export const satisfyEmail = (
  validateTrigger?: string | string[],
): RulesProps<any> => ({
  validateTrigger,
  validator(value: any, cb: (error?: ReactNode) => void) {
    if (value && !/^[\w.-]+@[\w.-]+\.\w+$/.test(value as string)) {
      return cb("邮箱格式不正确");
    }
    return cb();
  },
});

export const satisfyUserName = (
  validateTrigger?: string | string[],
): RulesProps<any> => ({
  validateTrigger,
  validator(value: any, cb: (error?: ReactNode) => void) {
    if (!/^[\w.-]+$/.test(value as string)) {
      return cb("仅支持数字、字母、下划线、减号、小数点");
    }
    return cb();
  },
});

export const coordinate = (validateKey?: string): RulesProps<any> => ({
  validator(value: any, cb: (error?: ReactNode) => void) {
    const val =
      validateKey && value ? String(value[validateKey]) : String(value);
    if (val) {
      if (!/^\d+\.\d+,\d+\.\d+$/.test(val)) {
        return cb("格式错误，格式为：纬度坐标, 经度坐标");
      }
    }
    return cb();
  },
});
