/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type EntityKind } from "../const/const_enum";
import { type OperateLog, type OperateLogMessage } from "./operate_log_type";

export namespace OperateLogService {
  export function List(req: ListReq, config?: any) {
    return webapi.post<ListResp>("/api/v1/OperateLogService_List", req, config);
  }

  export function UpdateMessage(req: UpdateMessageReq, config?: any) {
    return webapi.post<UpdateMessageResp>(
      "/api/v1/OperateLogService_UpdateMessage",
      req,
      config,
    );
  }
}
// List
export interface ListReq {
  // 实体类型
  entityKind: EntityKind;
  relationId: string;
}

export interface ListResp {
  logs: OperateLog[];
}

export interface UpdateMessageReq {
  log: OperateLogMessage;
}

export interface UpdateMessageResp {
  log: OperateLog;
}
