import { BillKind, type ShareBill } from "@api/http_pms/bill/bill_type";
import { Form, type FormInstance } from "@arco-design/web-react";
import { type FC } from "react";
import { MoneyFormDeposit } from "./Deposit";
import { MoneyFormSingle } from "./Single";
import { MoneyFormSplitRoom } from "./SplitRoom";

export interface MoneyFormProps {
  form: FormInstance;
  /** 仅用来取 subBills 来改写, 初始值从外 form 中获取 */
  initShareBill?: ShareBill;
  orderId?: string;
  canEdit?: boolean;
}
export const MoneyForm: FC<MoneyFormProps> = (props) => {
  const { form, orderId } = props;
  const billKind = Form.useWatch("billKind", form);
  const roomIds = Form.useWatch("roomIds", form);

  if (orderId) {
    if (billKind === BillKind.Deposit) {
      return <MoneyFormDeposit {...props} />;
    }
    return <MoneyFormSingle {...props} />;
  }
  if (roomIds.length <= 1) {
    return <MoneyFormSingle {...props} />;
  }
  return <MoneyFormSplitRoom {...props} />;
};
