import { type TodayOccupancyResp } from "@api/http_pms/dashboard/dashboard";
import { type FC, useMemo } from "react";
import { WorkbenchItemCard } from "./components/WorkbenchItemCard";
import { WorkbenchItemTitle } from "./components/WorkbenchItemTitle";
import { type StatusItem } from "./interface";
import { tomorrowStatusListSkeletonColumn } from "./const";

interface TomorrowStatusListSkeletonProps {
  columnNum?: number;
}

const TomorrowStatusListSkeleton: FC<TomorrowStatusListSkeletonProps> = ({
  columnNum = tomorrowStatusListSkeletonColumn,
}) => (
  <div className="grid flex-1 grid-cols-2 gap-4 pt-[18px]">
    {Array.from({ length: columnNum }).map((_, idx) => (
      <div
        key={idx}
        className="flex h-[68px] items-end space-x-1 rounded-lg bg-color-fill-1 px-6 py-[18px]"
      >
        <div className="h-[28px] w-[38px] rounded bg-color-fill-3" />
        <div className="h-[20px] w-[55px] rounded bg-color-fill-3" />
      </div>
    ))}
  </div>
);

interface TomorrowStatusListProps {
  items: StatusItem[];
}

const TomorrowStatusList: FC<TomorrowStatusListProps> = ({ items }) => (
  <div className="grid flex-1 grid-cols-2 gap-4 pt-[18px]">
    {items.map((item) => (
      <div
        key={item.label}
        className="flex items-end rounded-lg bg-color-fill-1 px-6 py-[18px]"
      >
        <div className="pr-2 align-bottom text-2xl font-medium leading-[34px] text-color-text-1">
          {item.value}
        </div>
        <div className="align-bottom text-sm leading-[28px] text-color-text-3">
          {item.label}
        </div>
      </div>
    ))}
  </div>
);

interface Props {
  statusData?: TodayOccupancyResp;
  loading?: boolean;
}

export const TomorrowStatusCard: FC<Props> = ({ statusData, loading }) => {
  const statusItems = useMemo<StatusItem[]>(
    () => [
      {
        label: "入住",
        value: statusData?.tomorrowCheckInCount ?? 0,
      },
      {
        label: "退房",
        value: statusData?.tomorrowCheckOutCount ?? 0,
      },
      {
        label: "换客",
        value: statusData?.tomorrowChangeTenantCount ?? 0,
      },
      {
        label: "空房",
        value: statusData?.tomorrowRemainingCount ?? 0,
      },
    ],
    [statusData],
  );

  return (
    <WorkbenchItemCard className="w-[364px]">
      <WorkbenchItemTitle>明日状态</WorkbenchItemTitle>
      {loading ? (
        <TomorrowStatusListSkeleton />
      ) : (
        <TomorrowStatusList items={statusItems} />
      )}
    </WorkbenchItemCard>
  );
};
