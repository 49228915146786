/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type Org } from "./org_type";

export namespace OrgService {
  // 获取组织信息（包含部分设置）
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/OrgService_Get", req, config);
  }

  // 修改组织
  export function Update(req: UpdateReq, config?: any) {
    return webapi.post<UpdateResp>("/api/v1/OrgService_Update", req, config);
  }
}
// Get
export interface GetReq {}

export interface GetResp {
  org: Org;
}

// Update
export interface UpdateReq {
  org: Org;
}

export interface UpdateResp {
  org: Org;
}
