/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type Image } from "../common/common_type";

// This the module of Tenant
export namespace Tenant {
  export enum Sex {
    Unknown = 0,
    // 男
    Male = 1,
    // 女
    Female = 2,
  }
}
export interface Tenant {
  // 房客id
  id: string;
  // 订单号
  orderId: string;
  // 姓名
  name: string;
  // 国家代号
  countryCode: string;
  // 手机号
  phoneNumber: string;
  // 邮箱
  email: string;
  // 性别
  sex: Tenant.Sex;
  // 生日
  birthday: string;
  // 职业
  profession: string;
  // 国籍
  nationality: Nationality;
  // 居住地址
  address: string;
  // 房客图片信息
  images: Image[];
}

export interface TenantSimple {
  // 房客id
  id?: string;
  // 姓名
  name: string;
  // 性别
  sex: Tenant.Sex;
}
export enum Nationality {
  // 暂时无法辨认/提取
  UnknownNationality = 0,
  // 美国
  US = 1,
  // 中国大陆
  CN = 2,
  // 印度
  IN = 3,
  // 日本
  JP = 4,
  // 德国
  DE = 5,
  // 英国
  GB = 6,
  // 法国
  FR = 7,
  // 意大利
  IT = 8,
  // 巴西
  BR = 9,
  // 俄罗斯联邦
  RU = 10,
  // 加拿大
  CA = 11,
  // 澳大利亚
  AU = 12,
  // 韩国
  KR = 13,
  // 西班牙
  ES = 14,
  // 墨西哥
  MX = 15,
  // 印度尼西亚
  ID = 16,
  // 土耳其
  TR = 17,
  // 沙特阿拉伯
  SA = 18,
  // 尼日利亚
  NG = 19,
  // 阿根廷
  AR = 20,
  // 比利时
  BE = 21,
  // 中国台湾
  TW = 22,
  // 中国香港
  HK = 23,
  // 百慕大
  BM = 24,
  // 不丹
  BT = 25,
  // 玻利维亚
  BO = 26,
  // 波黑
  BA = 27,
  // 博茨瓦纳
  BW = 28,
  // 布维岛
  BV = 29,
  // 南极洲
  AQ = 30,
  // 英属印度洋领地
  IO = 31,
  // 文莱
  BN = 32,
  // 保加利亚
  BG = 33,
  // 布基纳法索
  BF = 34,
  // 布隆迪
  BI = 35,
  // 柬埔寨
  KH = 36,
  // 喀麦隆
  CM = 37,
  // 白俄罗斯
  BY = 38,
  // 佛得角
  CV = 39,
  // 开曼群岛
  KY = 40,
  // 中非
  CF = 41,
  // 乍得
  TD = 42,
  // 智利
  CL = 43,
  // 圣诞岛
  CX = 44,
  // 科科斯（基林）群岛
  CC = 45,
  // 哥伦比亚
  CO = 46,
  // 科摩罗
  KM = 47,
  // 刚果（布）
  CG = 48,
  // 刚果（金）
  CD = 49,
  // 库克群岛
  CK = 50,
  // 哥斯达黎加
  CR = 51,
  // 科特迪瓦
  CI = 52,
  // 克罗地亚
  HR = 53,
  // 古巴
  CU = 54,
  // 塞浦路斯
  CY = 55,
  // 捷克
  CZ = 56,
  // 丹麦
  DK = 57,
  // 吉布提
  DJ = 58,
  // 多米尼克
  DM = 59,
  // 多米尼加
  DO = 60,
  // 厄瓜多尔
  EC = 61,
  // 埃及
  EG = 62,
  // 萨尔瓦多
  SV = 63,
  // 赤道几内亚
  GQ = 64,
  // 厄立特里亚
  ER = 65,
  // 爱沙尼亚
  EE = 66,
  // 埃塞俄比亚
  ET = 67,
  // 福克兰群岛（马尔维纳斯）
  FK = 68,
  // 法罗群岛
  FO = 69,
  // 斐济
  FJ = 70,
  // 芬兰
  FI = 71,
  // 安哥拉
  AO = 72,
  // 法属圭亚那
  GF = 73,
  // 法属波利尼西亚
  PF = 74,
  // 法属南部领地
  TF = 75,
  // 加蓬
  GA = 76,
  // 冈比亚
  GM = 77,
  // 格鲁吉亚
  GE = 78,
  // 美属萨摩亚
  AS = 79,
  // 加纳
  GH = 80,
  // 直布罗陀
  GI = 81,
  // 希腊
  GR = 82,
  // 格陵兰
  GL = 83,
  // 格林纳达
  GD = 84,
  // 瓜德罗普
  GP = 85,
  // 关岛
  GU = 86,
  // 危地马拉
  GT = 87,
  // 格恩西岛
  GG = 88,
  // 几内亚
  GN = 89,
  // 几内亚比绍
  GW = 90,
  // 圭亚那
  GY = 91,
  // 海地
  HT = 92,
  // 赫德岛和麦克唐纳岛
  HM = 93,
  // 梵蒂冈
  VA = 94,
  // 洪都拉斯
  HN = 95,
  // 匈牙利
  HU = 96,
  // 冰岛
  IS = 97,
  // 阿尔巴尼亚
  AL = 98,
  // 巴哈马
  BS = 99,
  // 伊朗
  IR = 100,
  // 伊拉克
  IQ = 101,
  // 爱尔兰
  IE = 102,
  // 曼岛
  IM = 103,
  // 以色列
  IL = 104,
  // 安圭拉
  AI = 105,
  // 牙买加
  JM = 106,
  // 阿尔及利亚
  DZ = 107,
  // 泽西岛
  JE = 108,
  // 约旦
  JO = 109,
  // 哈萨克斯坦
  KZ = 110,
  // 肯尼亚
  KE = 111,
  // 基里巴斯
  KI = 112,
  // 朝鲜
  KP = 113,
  // 阿鲁巴
  AW = 114,
  // 科威特
  KW = 115,
  // 吉尔吉斯斯坦
  KG = 116,
  // 老挝
  LA = 117,
  // 拉脱维亚
  LV = 118,
  // 黎巴嫩
  LB = 119,
  // 莱索托
  LS = 120,
  // 利比里亚
  LR = 121,
  // 利比亚
  LY = 122,
  // 列支敦士登
  LI = 123,
  // 立陶宛
  LT = 124,
  // 卢森堡
  LU = 125,
  // 中国澳门
  MO = 126,
  // 北马其顿
  MK = 127,
  // 马达加斯加
  MG = 128,
  // 马拉维
  MW = 129,
  // 马来西亚
  MY = 130,
  // 马尔代夫
  MV = 131,
  // 马里
  ML = 132,
  // 马耳他
  MT = 133,
  // 马绍尔群岛
  MH = 134,
  // 马提尼克
  MQ = 135,
  // 毛里塔尼亚
  MR = 136,
  // 毛里求斯
  MU = 137,
  // 马约特
  YT = 138,
  // 阿塞拜疆
  AZ = 139,
  // 密克罗尼西亚
  FM = 140,
  // 摩尔多瓦
  MD = 141,
  // 摩纳哥
  MC = 142,
  // 蒙古
  MN = 143,
  // 黑山
  ME = 144,
  // 蒙特塞拉特
  MS = 145,
  // 摩洛哥
  MA = 146,
  // 莫桑比克
  MZ = 147,
  // 缅甸
  MM = 148,
  // 纳米比亚
  NA = 149,
  // 瑙鲁
  NR = 150,
  // 尼泊尔
  NP = 151,
  // 荷兰
  NL = 152,
  // 荷属安的列斯
  AN = 153,
  // 新喀里多尼亚
  NC = 154,
  // 新西兰
  NZ = 155,
  // 尼加拉瓜
  NI = 156,
  // 尼日尔
  NE = 157,
  // 巴巴多斯
  BB = 158,
  // 纽埃
  NU = 159,
  // 诺福克岛
  NF = 160,
  // 北马里亚纳
  MP = 161,
  // 挪威
  NO = 162,
  // 阿曼
  OM = 163,
  // 巴基斯坦
  PK = 164,
  // 帕劳
  PW = 165,
  // 巴勒斯坦
  PS = 166,
  // 巴拿马
  PA = 167,
  // 巴布亚新几内亚
  PG = 168,
  // 巴拉圭
  PY = 169,
  // 秘鲁
  PE = 170,
  // 皮特凯恩
  PN = 171,
  // 波兰
  PL = 172,
  // 葡萄牙
  PT = 173,
  // 波多黎各
  PR = 174,
  // 卡塔尔
  QA = 175,
  // 留尼汪
  RE = 176,
  // 罗马尼亚
  RO = 177,
  // 卢旺达
  RW = 178,
  // 圣赫勒拿
  SH = 179,
  // 圣基茨和尼维斯
  KN = 180,
  // 圣卢西亚
  LC = 181,
  // 圣皮埃尔和密克隆
  PM = 182,
  // 圣文森特和格林纳丁斯
  VC = 183,
  // 萨摩亚
  WS = 184,
  // 圣马力诺
  SM = 185,
  // 圣多美和普林西比
  ST = 186,
  // 孟加拉国
  BD = 187,
  // 塞内加尔
  SN = 188,
  // 塞尔维亚
  RS = 189,
  // 塞舌尔
  SC = 190,
  // 塞拉利昂
  SL = 191,
  // 新加坡
  SG = 192,
  // 斯洛伐克
  SK = 193,
  // 斯洛文尼亚
  SI = 194,
  // 所罗门群岛
  SB = 195,
  // 索马里
  SO = 196,
  // 南非
  ZA = 197,
  // 南乔治亚岛和南桑德韦奇岛
  GS = 198,
  // 奥地利
  AT = 199,
  // 斯里兰卡
  LK = 200,
  // 苏丹
  SD = 201,
  // 苏里南
  SR = 202,
  // 斯瓦尔巴岛和扬马延岛
  SJ = 203,
  // 斯威士兰
  SZ = 204,
  // 瑞典
  SE = 205,
  // 瑞士
  CH = 206,
  // 叙利亚
  SY = 207,
  // 塔吉克斯坦
  TJ = 208,
  // 坦桑尼亚
  TZ = 209,
  // 泰国
  TH = 210,
  // 东帝汶
  TL = 211,
  // 多哥
  TG = 212,
  // 托克劳
  TK = 213,
  // 汤加
  TO = 214,
  // 特立尼达和多巴哥
  TT = 215,
  // 突尼斯
  TN = 216,
  // 巴林
  BH = 217,
  // 土库曼斯坦
  TM = 218,
  // 特克斯和凯科斯群岛
  TC = 219,
  // 图瓦卢
  TV = 220,
  // 乌干达
  UG = 221,
  // 乌克兰
  UA = 222,
  // 阿联酋
  AE = 223,
  // 安道尔
  AD = 224,
  // 美国本土外小岛屿
  UM = 225,
  // 乌拉圭
  UY = 226,
  // 乌兹别克斯坦
  UZ = 227,
  // 瓦努阿图
  VU = 228,
  // 委内瑞拉
  VE = 229,
  // 越南
  VN = 230,
  // 英属维尔京群岛
  VG = 231,
  // 美属维尔京群岛
  VI = 232,
  // 瓦利斯和富图纳
  WF = 233,
  // 西撒哈拉
  EH = 234,
  // 也门
  YE = 235,
  // 赞比亚
  ZM = 236,
  // 津巴布韦
  ZW = 237,
  // 菲律宾
  PH = 238,
  // 亚美尼亚
  AM = 239,
  // 安提瓜和巴布达
  AG = 240,
  // 阿富汗
  AF = 241,
  // 贝宁
  BJ = 242,
  // 伯利兹
  BZ = 243,
  // 奥兰群岛
  AX = 244,
}
