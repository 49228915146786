import { useState, type FC } from "react";
import { type ShareBill } from "@api/http_pms/bill/bill_type";
import { BillItemForm } from "./BillItemForm";

import { BillItemShow } from "./BIllItemShow";
import { checkIsAddId } from "@/utils/dealWithId";

interface ItemProps {
  item: ShareBill;
  onOk: (item: ShareBill, isRefundDeposit?: boolean) => Promise<void>;
  onRemove: () => Promise<void>;
  orderId: string;
}

export const BillItem: FC<ItemProps> = ({ item, onOk, onRemove, orderId }) => {
  const [isEdit, setIsEdit] = useState(() => checkIsAddId(item.shareId));

  return (
    <div className="mb-2 overflow-hidden transition-[height] duration-500">
      {isEdit ? (
        <BillItemForm
          orderId={orderId}
          shareBill={item}
          onCancel={() => {
            setIsEdit(false);
            if (checkIsAddId(item.shareId)) {
              void onRemove();
            }
          }}
          onOk={async (_item) => {
            await onOk(_item);
            setIsEdit(false);
          }}
        />
      ) : (
        <BillItemShow
          shareBill={item}
          onEdit={() => {
            setIsEdit(true);
          }}
          onRemove={onRemove}
          onOk={async (_item) => {
            await onOk(_item, true);
            setIsEdit(false);
          }}
        />
      )}
    </div>
  );
};
