import {
  CategoryKind as ApiCategoryKind,
  CategoryTagKind as ApiCategoryTagKind,
  ReviewerRole,
} from "@api/http_pms/ota_review/ota_review_type";
import { type ConstOptionMap } from "@/common/const/interface";

export const CategoryKind: ConstOptionMap<ApiCategoryKind> = {
  [ApiCategoryKind.UnknownCategoryKind]: {
    value: ApiCategoryKind.UnknownCategoryKind,
    label: "",
    hidden: true,
  },
  [ApiCategoryKind.Recommend]: {
    value: ApiCategoryKind.Recommend,
    label: "推荐",
  },
  [ApiCategoryKind.RespectHouseRules]: {
    value: ApiCategoryKind.RespectHouseRules,
    label: "遵守规则",
  },
  [ApiCategoryKind.Cleanliness]: {
    value: ApiCategoryKind.Cleanliness,
    label: "干净卫生",
  },
  [ApiCategoryKind.Communication]: {
    value: ApiCategoryKind.Communication,
    label: "沟通顺畅",
  },
  [ApiCategoryKind.Accuracy]: {
    value: ApiCategoryKind.Accuracy,
    label: "如实描述",
  },
  [ApiCategoryKind.Location]: {
    value: ApiCategoryKind.Location,
    label: "地段优越",
  },
  [ApiCategoryKind.Checkin]: {
    value: ApiCategoryKind.Checkin,
    label: "入住便捷",
  },
  [ApiCategoryKind.Value]: {
    value: ApiCategoryKind.Value,
    label: "高性价比",
  },
};

export const CategoryTagKind: ConstOptionMap<
  ApiCategoryTagKind,
  {
    reviewerRole?: ReviewerRole;
    categoryKind?: ApiCategoryKind;
    isOther?: boolean;
    isPositive?: boolean;
  }
> = {
  [ApiCategoryTagKind.UnknownCategoryTagKind]: {
    value: ApiCategoryTagKind.UnknownCategoryTagKind,
    label: "",
    hidden: true,
  },
  [ApiCategoryTagKind.HostReviewGuestRecommend]: {
    value: ApiCategoryTagKind.HostReviewGuestRecommend,
    label: "房东推荐",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Recommend,
  },
  [ApiCategoryTagKind.HostReviewGuestUnRecommend]: {
    value: ApiCategoryTagKind.HostReviewGuestUnRecommend,
    label: "房东不推荐",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Recommend,
  },

  // 房东评价房客积极性（清洁度）
  [ApiCategoryTagKind.HostReviewGuestPositiveNeatAndTidy]: {
    value: ApiCategoryTagKind.HostReviewGuestPositiveNeatAndTidy,
    label: "干净整洁",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },
  [ApiCategoryTagKind.HostReviewGuestPositiveKeptInGoodCondition]: {
    value: ApiCategoryTagKind.HostReviewGuestPositiveKeptInGoodCondition,
    label: "保持良好状态",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },
  [ApiCategoryTagKind.HostReviewGuestPositiveTookCareOfGarbage]: {
    value: ApiCategoryTagKind.HostReviewGuestPositiveTookCareOfGarbage,
    label: "认真处理垃圾",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },

  // 房东评价房客差评（清洁度）
  [ApiCategoryTagKind.HostReviewGuestNegativeIgnoredCheckoutDirections]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeIgnoredCheckoutDirections,
    label: "忽略退房指引",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeGarbage]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeGarbage,
    label: "垃圾过多",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeMessyKitchen]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeMessyKitchen,
    label: "厨房凌乱",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeDamage]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeDamage,
    label: "财产损坏",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeRuinedBedLinens]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeRuinedBedLinens,
    label: "床上用品损坏",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Cleanliness,
  },

  // 房东评价房客负面（尊重房屋规则）
  [ApiCategoryTagKind.HostReviewGuestNegativeArrivedEarly]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeArrivedEarly,
    label: "提前太早到达",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeStayedPastCheckout]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeStayedPastCheckout,
    label: "未按时退房",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedGuests]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedGuests,
    label: "未经批准接待其他访客",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedPet]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedPet,
    label: "未批准带宠物",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeDidNotRespectQuietHours]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeDidNotRespectQuietHours,
    label: "吵闹",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedFilming]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedFilming,
    label: "未批准拍摄",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedEvent]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeUnapprovedEvent,
    label: "未批准事件",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeSmoking]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeSmoking,
    label: "吸烟",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.RespectHouseRules,
  },

  // 房东评价房客积极（沟通）
  [ApiCategoryTagKind.HostReviewGuestPositiveHelpfulMessages]: {
    value: ApiCategoryTagKind.HostReviewGuestPositiveHelpfulMessages,
    label: "消息实用",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },
  [ApiCategoryTagKind.HostReviewGuestPositiveRespectful]: {
    value: ApiCategoryTagKind.HostReviewGuestPositiveRespectful,
    label: "尊重他人",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },
  [ApiCategoryTagKind.HostReviewGuestPositiveAlwaysResponded]: {
    value: ApiCategoryTagKind.HostReviewGuestPositiveAlwaysResponded,
    label: "回复及时",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },

  // 房东评价房客负面（沟通）
  [ApiCategoryTagKind.HostReviewGuestNegativeUnhelpfulMessages]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeUnhelpfulMessages,
    label: "回复无用",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeDisrespectful]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeDisrespectful,
    label: "不尊重他人",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeUnreachable]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeUnreachable,
    label: "联系不上",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.HostReviewGuestNegativeSlowResponses]: {
    value: ApiCategoryTagKind.HostReviewGuestNegativeSlowResponses,
    label: "回复慢",
    reviewerRole: ReviewerRole.Host,
    categoryKind: ApiCategoryKind.Communication,
  },

  // 客人点评 房东好评 (准确度)
  [ApiCategoryTagKind.GuestReviewHostPositiveLookedLikePhotos]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveLookedLikePhotos,
    label: "和照片一样",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveMatchedDescription]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveMatchedDescription,
    label: "描述一致",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveHadListedAmenitiesAndServices]: {
    value:
      ApiCategoryTagKind.GuestReviewHostPositiveHadListedAmenitiesAndServices,
    label: "设施和服务周全",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
    isPositive: true,
  },

  // 客人点评 房东差评 (准确度)
  [ApiCategoryTagKind.GuestReviewHostNegativeSmallerThanExpected]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeSmallerThanExpected,
    label: "差评低于预期",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeDidNotMatchPhotos]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeDidNotMatchPhotos,
    label: "和照片不一样",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeNeedsMaintenance]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeNeedsMaintenance,
    label: "需要维护",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeUnexpectedFees]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeUnexpectedFees,
    label: "意外费用",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeExcessiveRules]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeExcessiveRules,
    label: "过多规则",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeUnexpectedNoise]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeUnexpectedNoise,
    label: "噪音",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeInaccurateLocation]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeInaccurateLocation,
    label: "不准确位置",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeMissingAmenity]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeMissingAmenity,
    label: "缺少便利设施",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },

  // 客人点评 房东好评（入住）
  [ApiCategoryTagKind.GuestReviewHostPositiveResponsiveHost]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveResponsiveHost,
    label: "积极响应",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveClearInstructions]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveClearInstructions,
    label: "明确指示",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveEasyToFind]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveEasyToFind,
    label: "容易找到",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveEasyToGetInside]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveEasyToGetInside,
    label: "容易进入",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveFlexibleCheckIn]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveFlexibleCheckIn,
    label: "灵活入住",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveFeltAtHome]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveFeltAtHome,
    label: "像家",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
    isPositive: true,
  },

  // 客人点评 房东差评（入住）
  [ApiCategoryTagKind.GuestReviewHostNegativeHardToLocate]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeHardToLocate,
    label: "难以定位",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeUnclearInstructions]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeUnclearInstructions,
    label: "不清楚说明",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeTroubleWithLock]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeTroubleWithLock,
    label: "锁问题",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeUnresponsiveHost]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeUnresponsiveHost,
    label: "房东不响应",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeHadToWait]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeHadToWait,
    label: "不得不等待",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeHardToGetInside]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeHardToGetInside,
    label: "难以进入",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Checkin,
  },

  // 客人评价 房东好评（清洁度）
  [ApiCategoryTagKind.GuestReviewHostPositiveSpotlessFurnitureAndLinens]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveSpotlessFurnitureAndLinens,
    label: "家具和床上用品完善",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveFreeOfClutter]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveFreeOfClutter,
    label: "无杂乱",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveSqueakyCleanBathroom]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveSqueakyCleanBathroom,
    label: "干净卫生浴室",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositivePristineKitchen]: {
    value: ApiCategoryTagKind.GuestReviewHostPositivePristineKitchen,
    label: "精致厨房",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
    isPositive: true,
  },

  // 客人点评 房东差评（清洁度）
  [ApiCategoryTagKind.GuestReviewHostNegativeDirtyOrDusty]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeDirtyOrDusty,
    label: "肮脏或多尘",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeNoticeableSmell]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeNoticeableSmell,
    label: "明显气味",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeStains]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeStains,
    label: "污点",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeExcessiveClutter]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeExcessiveClutter,
    label: "过度混乱",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeMessyKitchen]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeMessyKitchen,
    label: "厨房乱",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeHairOrPetHair]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeHairOrPetHair,
    label: "有头发或宠物毛发",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeDirtyBathroom]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeDirtyBathroom,
    label: "肮脏的浴室",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeTrashLeftBehind]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeTrashLeftBehind,
    label: "垃圾遗留",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Cleanliness,
  },

  // 客人点评 房东差评 (准确度)
  [ApiCategoryTagKind.GuestReviewHostNegativeBrokenOrMissingLock]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeBrokenOrMissingLock,
    label: "损坏或丢失锁",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeUnexpectedGuests]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeUnexpectedGuests,
    label: "意外客人",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeIncorrectBathroom]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeIncorrectBathroom,
    label: "错误浴室",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Accuracy,
  },

  // 客人评价 房东积极（沟通）
  [ApiCategoryTagKind.GuestReviewHostPositiveAlwaysResponsive]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveAlwaysResponsive,
    label: "总是有回应",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveLocalRecommendations]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveLocalRecommendations,
    label: "积极当地推荐",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveProactive]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveProactive,
    label: "积极主动",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveHelpfulInstructions]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveHelpfulInstructions,
    label: "有帮助说明",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveConsiderate]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveConsiderate,
    label: "体贴",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
    isPositive: true,
  },

  // 客人点评 房东差评（沟通）
  [ApiCategoryTagKind.GuestReviewHostNegativeSlowToRespond]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeSlowToRespond,
    label: "回复缓慢",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeNotHelpful]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeNotHelpful,
    label: "无帮助",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeMissingHouseInstructions]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeMissingHouseInstructions,
    label: "缺少房屋说明",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeUnclearCheckoutTasks]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeUnclearCheckoutTasks,
    label: "不清楚结账",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeInconsiderate]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeInconsiderate,
    label: "不体谅",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeExcessiveCheckoutTasks]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeExcessiveCheckoutTasks,
    label: "过多结账",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Communication,
  },

  // 客人点评 房东好评（位置）
  [ApiCategoryTagKind.GuestReviewHostPositivePeaceful]: {
    value: ApiCategoryTagKind.GuestReviewHostPositivePeaceful,
    label: "积极平静",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveBeautifulSurroundings]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveBeautifulSurroundings,
    label: "环境美丽",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositivePrivate]: {
    value: ApiCategoryTagKind.GuestReviewHostPositivePrivate,
    label: "私密",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveGreatRestaurants]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveGreatRestaurants,
    label: "很棒餐厅",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveLotsToDo]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveLotsToDo,
    label: "做了很多事情",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
    isPositive: true,
  },
  [ApiCategoryTagKind.GuestReviewHostPositiveWalkable]: {
    value: ApiCategoryTagKind.GuestReviewHostPositiveWalkable,
    label: "步行",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
    isPositive: true,
  },

  // 客人点评 房东 差评（位置）
  [ApiCategoryTagKind.GuestReviewHostNegativeNoisy]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeNoisy,
    label: "吵闹",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeNotMuchToDo]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeNotMuchToDo,
    label: "没什么可做的",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeBlandSurroundings]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeBlandSurroundings,
    label: "乏味周边环境",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeNotPrivate]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeNotPrivate,
    label: "非私密",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
  },
  [ApiCategoryTagKind.GuestReviewHostNegativeInconvenientLocation]: {
    value: ApiCategoryTagKind.GuestReviewHostNegativeInconvenientLocation,
    label: "不方便位置",
    reviewerRole: ReviewerRole.Guest,
    categoryKind: ApiCategoryKind.Location,
  },

  // 其他类别
  [ApiCategoryTagKind.AccuracyOther]: {
    value: ApiCategoryTagKind.AccuracyOther,
    label: "其他",
    categoryKind: ApiCategoryKind.Accuracy,
    isOther: true,
  },
  [ApiCategoryTagKind.CheckInOther]: {
    value: ApiCategoryTagKind.CheckInOther,
    label: "其他",
    categoryKind: ApiCategoryKind.Checkin,
    isOther: true,
  },
  [ApiCategoryTagKind.CleanlinessOther]: {
    value: ApiCategoryTagKind.CleanlinessOther,
    label: "其他",
    categoryKind: ApiCategoryKind.Cleanliness,
    isOther: true,
  },
  [ApiCategoryTagKind.CommunicationOther]: {
    value: ApiCategoryTagKind.CommunicationOther,
    label: "其他",
    categoryKind: ApiCategoryKind.Communication,
    isOther: true,
  },
  [ApiCategoryTagKind.LocationOther]: {
    value: ApiCategoryTagKind.LocationOther,
    label: "其他",
    categoryKind: ApiCategoryKind.Location,
    isOther: true,
  },
  [ApiCategoryTagKind.RespectHouseRulesOther]: {
    value: ApiCategoryTagKind.RespectHouseRulesOther,
    label: "其他",
    categoryKind: ApiCategoryKind.RespectHouseRules,
    isOther: true,
  },
};
