import { type FC } from "react";
import { type Currency as ApiCurrency } from "@api/http_pms/const/const_enum";
import { type InputNumberProps } from "@arco-design/web-react";
import { Currency } from "../const";
import { MoneyInput } from "./MoneyInput";

export type MoneyInputWithCurrencyProps = Omit<
  InputNumberProps,
  "value" | "onChange"
> & {
  value?: number;
  onChange?: (v?: number) => void;
  currency?: ApiCurrency;
  hideDec?: boolean;
  appendPrefix?: string;
  appendSuffix?: string;
};

export const MoneyInputWithCurrency: FC<MoneyInputWithCurrencyProps> = ({
  value,
  onChange,
  currency,
  hideDec,
  appendPrefix,
  appendSuffix,
  ...props
}) => {
  const _value =
    value && currency && Currency[currency].hasDecimal && hideDec
      ? Math.round(value / 100)
      : value;
  const _onChange =
    onChange && currency && Currency[currency].hasDecimal && hideDec
      ? (v?: number) => onChange(v && v * 100)
      : onChange;

  const prefix = `${appendPrefix || ""} ${currency !== undefined ? Currency[currency].symbol : ""}`;
  const suffix = `${appendSuffix || ""} ${currency !== undefined ? Currency[currency].label : ""}`;

  return (
    <MoneyInput
      hasDec={
        !hideDec && currency !== undefined
          ? Currency[currency].hasDecimal
          : false
      }
      prefix={prefix}
      suffix={suffix}
      value={_value}
      onChange={_onChange}
      {...props}
    />
  );
};
