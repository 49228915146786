/** @format */

// This is code generated automatically by the proto2api, please do not modify

// 增删改查复用同一个结构的话,字段都必须是按查看的result去定义
export interface Role {
  // id
  id: string;
  // 角色名称
  name: string;
  permissions: Permission[];
}
export enum Permission {
  // 未知
  Unknown = 0,
  // 房间
  RoomView = 1,
  // [2] 修改房间信息
  RoomUpdate = 2,
  // [3] 创建房间与排序
  RoomCreateAndSort = 3,
  // [1] 查看房间详情
  RoomDetailView = 4,
  // [9] 删除房间
  RoomSuper = 9,
  // 房态
  RoomDayView = 11,
  // [2] 设置备注
  RoomDaySetRemark = 12,
  // [9] 设置价格、活动、房态
  RoomDaySuper = 19,
  // 订单
  OrderView = 21,
  // [2] 查看进阶订单信息
  OrderAdvancedView = 22,
  // [2] 设置备注、标签
  OrderSetRemarkAndTag = 23,
  // [3] 订单修改与录入(可删除本账号录入的订单)
  OrderUpdate = 24,
  // [4] 订单评价
  OrderReview = 28,
  // [9] 取消与删除
  OrderSuper = 29,
  // 财务（含账单、月报、钱包）
  BillView = 31,
  // [1] 查看钱包
  BillWalletView = 32,
  // [1] 账单修改与录入(可修改/删除自己的本账号创建并未被确认的账单)
  BillUpdate = 33,
  // [1] 查看订单中的帐单
  BillTabView = 35,
  // [1] 查看月报
  BillMonthlyView = 36,
  // [9] 财务配置/确认/删除
  BillSuper = 39,
  // 全局功能
  Operation = 41,
  // [1] 账号管理
  Account = 42,
  // [1] 系统管理
  System = 43,
  // [1] 创建房间分组与排序
  RoomGroupCreateAndSort = 44,
  // [1] ota账号管理
  OtaAccount = 45,
  // [1] 聊天
  Chat = 46,
  // 统计
  OccupancyRateView = 51,
  // 微店
  ReservationView = 61,
  // [9] 微店配置管理
  ReservationSuper = 69,
}
