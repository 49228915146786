import {
  Select,
  type SelectProps,
  type InputProps,
} from "@arco-design/web-react";
import { type FC } from "react";
import { type Currency as ApiCurrency } from "@api/http_pms/const/const_enum";
import { Currency } from "../const";
import { usePartialControllable } from "@/hooks/usePartialControllable";
import { useConstOption } from "../const/utils";
import { CompactGroup } from "@/common/CompactGroup";
import { type MoneyInputProps } from "./MoneyInput";
import { MoneyInputWithCurrency } from "./MoneyInputWithCurrency";

export interface MoneyValue {
  currency: ApiCurrency;
  amount: number;
}

export const MoneyCurrencyInput: FC<{
  value?: Partial<MoneyValue>;
  onChange?: (v?: Partial<MoneyValue>) => void;
  beforeInputProps?: Partial<MoneyInputProps>;
  afterInputProps?: SelectProps;
  disabled?: boolean;
  size?: InputProps["size"];
}> = ({ beforeInputProps, afterInputProps, disabled, size, ...props }) => {
  const currencyOptions = useConstOption(Currency);
  const { currentValue, handleCurrentValueChange } = usePartialControllable(
    props,
    ["currency", "amount"],
  );
  return (
    <CompactGroup>
      <MoneyInputWithCurrency
        css={`
          input.arco-input {
            padding-left: 4px;
          }
        `}
        onChange={(m) => handleCurrentValueChange("amount", m)}
        value={currentValue.amount}
        suffix={null}
        disabled={disabled}
        size={size}
        currency={currentValue.currency}
        className="w-1/2"
        {...beforeInputProps}
      />
      <Select
        className="w-1/2 flex-none"
        options={currencyOptions}
        onChange={(m: ApiCurrency) => handleCurrentValueChange("currency", m)}
        value={currentValue.currency}
        disabled={disabled}
        size={size}
        triggerProps={{
          containerScrollToClose: true,
        }}
        {...afterInputProps}
      />
    </CompactGroup>
  );
};
