import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { type ImMsg, ImEvent } from "@api/http_im/im_type";
import { type Dayjs } from "dayjs";
import { ChatService } from "./service.ts";

import {
  ChatConvKind,
  ChatStatus,
  type ChatConv,
  type ChatMsg,
} from "./interface.ts";
import {
  checkWaitReplay,
  deleteSendingMsgByTraceId,
  insertItemInOrder,
  newImConv,
  newImMsg,
  removeLteItem,
  updateWaitReplaySet,
} from "./utils.ts";

import { genTraceId } from "@/wasm/core";

interface ChatStore {
  currentConvId: string;
  convMapId: Record<string, ChatConv>;
  convEditingTextMapId: Record<string, string>;
  convIdsMapKind: Record<ChatConvKind, Set<string>>;
  convHasMoreMapKind: Record<ChatConvKind, boolean>;
  sendingTraceIds: Set<string>;
  imSSE?: EventSource;
  imStatus: ChatStatus;
  inActive: boolean;
  cmd: {
    setInActive: (active: boolean) => void;
    setCurrentConvId: (convId: string) => void;
    initSSE: (force?: boolean) => void;
    loadMoreMsgs: (convId: string, beforeSet?: () => void) => Promise<boolean>;
    loadMoreConvs: (kind: ChatConvKind, lastSentAt?: Dayjs) => Promise<boolean>;
    loadConv: (convId: string) => Promise<void>;
    sendMsg: (convId: string, msg: ChatMsg) => Promise<void>;
    setConvEditingText: (convId: string, text: string) => void;
    readMsg: (convId: string, msgId: string) => Promise<void>;
  };
  events: {
    updateConv: (convId: string) => void;
    newMsg: (convId: string, msg: ImMsg, traceId: string) => void;
    read: (convId: string, msgId: string) => void;
    mark: (convId: string, on: boolean) => void;
  };
}

export const useChatStore = create<ChatStore>()(
  immer((set, get) => ({
    inActive: true,
    currentConvId: "",
    convMapId: {},
    convEditingTextMapId: {},
    convIdsMapKind: {
      [ChatConvKind.All]: new Set(),
      [ChatConvKind.Mark]: new Set(),
      [ChatConvKind.WaitReplay]: new Set(),
    },
    convHasMoreMapKind: {
      [ChatConvKind.All]: true,
      [ChatConvKind.Mark]: true,
      [ChatConvKind.WaitReplay]: true,
    },
    sendingTraceIds: new Set(),
    imSSE: undefined,
    imStatus: ChatStatus.NotConnected,
    cmd: {
      setInActive(active) {
        set((s) => {
          s.inActive = active;
        });
      },

      setConvEditingText(convId, text) {
        set((s) => {
          s.convEditingTextMapId[convId] = text;
        });
      },

      setCurrentConvId(convId) {
        set((s) => {
          s.currentConvId = convId;
        });
      },

      async initSSE(force) {
        const _s = get();
        if (
          (_s.imStatus === ChatStatus.Connected ||
            _s.imStatus === ChatStatus.Connecting) &&
          _s.imSSE &&
          !force
        ) {
          return;
        }
        set((s) => {
          s.imSSE?.close();
          s.imStatus = ChatStatus.Connecting;
          // const initData = useChatStore.getInitialState();
          // s.convMapId = initData.convMapId;
          // s.convIdsMapKind = initData.convIdsMapKind;
          // s.convHasMoreMapKind = initData.convHasMoreMapKind;
          // s.sendingTraceIds = initData.sendingTraceIds;
        });
        console.info("ImService_Subscribe connecting...");
        const imSSE = await ChatService.Subscribe({});
        set((s) => {
          s.imSSE = imSSE;
        });
        imSSE.onerror = (err) => {
          console.error("ImService_Subscribe failed:", err);
          set((s) => {
            s.imStatus = ChatStatus.Error;
          });
        };
        imSSE.onopen = () => {
          console.info("ImService_Subscribe connected!");
          set((s) => {
            s.imStatus = ChatStatus.Connected;
          });
          void _s.cmd.loadMoreConvs(ChatConvKind.All);
        };
        imSSE.onmessage = (sseEvent) => {
          const imEvent: ImEvent = JSON.parse(sseEvent.data as string);
          switch (imEvent.kind) {
            case ImEvent.Kind.NewMsg:
            case ImEvent.Kind.UpdateMsg:
              get().events.newMsg(
                imEvent.convId!,
                imEvent.msg!,
                imEvent.traceId,
              );
              break;
            case ImEvent.Kind.Read:
              get().events.read(imEvent.convId!, imEvent.msgId!);
              break;
            case ImEvent.Kind.PmsMark:
              get().events.mark(imEvent.convId!, imEvent.isPmsMark!);
              break;
            case ImEvent.Kind.UpdateConv:
              get().events.updateConv(imEvent.convId!);
              break;
            default:
          }
        };
      },

      async loadMoreConvs(kind, lastSentAt) {
        const convHasMore = get().convHasMoreMapKind[kind];
        if (!convHasMore) {
          return false;
        }

        const { convs, markedConvIds, waitReplyConvIds, hasMore } =
          await ChatService.ListImConv({
            isMarked: kind === ChatConvKind.Mark ? true : undefined,
            isWaitReply: kind === ChatConvKind.WaitReplay ? true : undefined,
            lastSentAt: lastSentAt?.unixStr(),
            pageSize: 20,
          });

        set((s) => {
          const { convMapId, convIdsMapKind, convHasMoreMapKind } = s;
          convHasMoreMapKind[kind] = hasMore;
          convIdsMapKind[ChatConvKind.Mark] = new Set(markedConvIds);
          convIdsMapKind[ChatConvKind.WaitReplay] = new Set([
            ...convIdsMapKind[ChatConvKind.WaitReplay],
            ...waitReplyConvIds,
          ]);
          convs.forEach((conv) => {
            const convId = conv.id;
            const _imConv = newImConv(convId, conv);
            if (!convMapId[convId]) {
              convMapId[convId] = _imConv;
            } else {
              const _msgs = convMapId[convId].msgs;
              _imConv.msgs.forEach((m) =>
                insertItemInOrder(_msgs, m, (c) => c.id),
              );
              convMapId[convId] = {
                ..._imConv,
                msgs: convMapId[convId].msgs,
                hasMoreMsg: convMapId[convId].hasMoreMsg,
              };
              updateWaitReplaySet(
                convIdsMapKind[ChatConvKind.WaitReplay],
                convId,
                convMapId[convId].msgs,
              );
            }
          });
          if (!s.currentConvId) {
            s.currentConvId = convs[0]?.id || "";
          }
        });

        return hasMore;
      },

      async loadMoreMsgs(convId, beforeSet) {
        const conv = get().convMapId[convId];
        if (!conv?.hasMoreMsg) {
          beforeSet?.();
          return false;
        }

        const { msgs, hasMore } = await ChatService.ListImMsg({
          convId,
          lastMsgId: conv.msgs[0]?.id,
          pageSize: 20,
        });
        beforeSet?.();
        // 为了加载后，滚动位置保留
        set(({ convMapId, convIdsMapKind }) => {
          if (!convMapId[convId]) {
            return;
          }
          convMapId[convId].hasMoreMsg = hasMore;
          msgs.reverse();
          msgs.forEach((m) => {
            insertItemInOrder(
              convMapId[convId]!.msgs,
              newImMsg(m),
              (c) => c.id,
              true,
            );
          });
          updateWaitReplaySet(
            convIdsMapKind[ChatConvKind.WaitReplay],
            convId,
            convMapId[convId].msgs,
          );
        });

        return hasMore;
      },

      async loadConv(convId) {
        const { conv } = await ChatService.GetImConv({ convId });
        const _imConv = newImConv(convId, conv);
        set(({ convMapId, convIdsMapKind }) => {
          if (!convMapId[convId]) {
            convMapId[convId] = _imConv;
          } else {
            const _msgs = convMapId[convId].msgs;
            _imConv.msgs.forEach((m) =>
              insertItemInOrder(_msgs, m, (c) => c.id),
            );
            convMapId[convId] = {
              ..._imConv,
              msgs: convMapId[convId].msgs,
              hasMoreMsg: convMapId[convId].hasMoreMsg,
            };
            updateWaitReplaySet(
              convIdsMapKind[ChatConvKind.WaitReplay],
              convId,
              convMapId[convId].msgs,
            );
          }
        });
      },

      async sendMsg(convId, msg) {
        const traceId = genTraceId();
        set(({ convMapId, sendingTraceIds, cmd }) => {
          if (!convMapId[convId]) {
            convMapId[convId] = newImConv(convId);
            void cmd.loadConv(convId);
          }
          convMapId[convId].msgs.push({ ...msg, id: `_${traceId}` });
          sendingTraceIds.add(traceId);
        });
        await ChatService.Publish({
          event: {
            traceId,
            kind: ImEvent.Kind.NewMsg,
            convId,
            msg: {
              id: "",
              outId: "",
              content: msg.content,
              kind: msg.kind,
              sender: msg.sender,
              sentAt: msg.sentAt.unixStr(),
            },
          },
        });
      },

      async readMsg(convId, msgId) {
        let ok = false;
        set(({ convMapId }) => {
          if (!convMapId[convId]) {
            return;
          }
          ok = removeLteItem(convMapId[convId].unreadMsgIds, msgId);
        });
        if (ok) {
          await ChatService.Publish({
            event: {
              traceId: genTraceId(),
              kind: ImEvent.Kind.Read,
              convId,
              msgId,
            },
          });
        }
      },
    },
    events: {
      newMsg(convId, msg, traceId) {
        set(({ convMapId, convIdsMapKind, sendingTraceIds, cmd }) => {
          if (!convMapId[convId]) {
            // 先创建一个conv，装消息，然后异步拉取conv数据
            convMapId[convId] = newImConv(convId);
            void cmd.loadConv(convId);
          }
          const _msg = newImMsg(msg);
          insertItemInOrder(convMapId[convId].msgs, _msg, (c) => c.id);
          if (checkWaitReplay(_msg.sender)) {
            insertItemInOrder(convMapId[convId].unreadMsgIds, _msg.id);
          } else if (sendingTraceIds.has(traceId)) {
            if (deleteSendingMsgByTraceId(convMapId[convId].msgs, traceId)) {
              sendingTraceIds.delete(traceId);
            } else {
              console.error("核销消息失败", {
                convId,
                traceId,
                msgId: _msg.id,
              });
            }
          }
          updateWaitReplaySet(
            convIdsMapKind[ChatConvKind.WaitReplay],
            convId,
            convMapId[convId].msgs,
          );
        });
      },

      read(convId, msgId) {
        set(({ convMapId }) => {
          if (!convMapId[convId]) {
            return;
          }
          removeLteItem(convMapId[convId].unreadMsgIds, msgId);
        });
      },

      mark(convId, on) {
        set(({ convIdsMapKind }) => {
          if (on) {
            convIdsMapKind[ChatConvKind.Mark].add(convId);
          } else {
            convIdsMapKind[ChatConvKind.Mark].delete(convId);
          }
        });
      },

      updateConv(convId) {
        const s = get();
        if (!s.convMapId[convId]) {
          return;
        }
        void s.cmd.loadConv(convId);
      },
    },
  })),
);
