import { Icon } from "@arco-design/web-react";
import clsx from "clsx";
import { type SVGAttributes, type FC } from "react";

const OriginIconFont = Icon.addFromIconFontCn({
  src: "//at.alicdn.com/t/c/font_4428100_ug1ycrzccvr.js",
  // extraProps: { className: "icon-font" },
});

export const IconFont: FC<
  { type: string; className?: string } & SVGAttributes<any>
> = ({ className, ...otherProps }) => (
  // eslint-disable-next-line tailwindcss/no-custom-classname
  <OriginIconFont {...otherProps} className={clsx("icon-font", className)} />
);
