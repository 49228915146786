import clsx from "clsx";
import { type FC } from "react";

export const MoneyColorShow: FC<{
  moneyText: string;
  className?: string;
}> = ({ moneyText, className }) => (
  <span
    className={clsx(
      className,
      moneyText.startsWith("-")
        ? "text-color-danger-6"
        : "text-color-primary-6",
    )}
  >
    {moneyText}
  </span>
);
