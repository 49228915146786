import core_wasm from "./core.wasm?url";

const env = {
  get_current_time: () =>
    BigInt(Date.now() * 1000000 + Math.floor(Math.random() * 1000000)),
};

let wasm:
  | {
      memory: WebAssembly.Memory;
      gen_trace_id: () => number;
    }
  | undefined;

export const initWasm = async () => {
  const wasmIns = await WebAssembly.instantiateStreaming(fetch(core_wasm), {
    env,
  });

  wasm = wasmIns.instance.exports as any;
};

const decoder = new TextDecoder("utf8");

export const genTraceId = () => {
  if (!wasm) {
    throw new Error("wasm need init");
  }
  const traceIdPrt = wasm.gen_trace_id();
  const traceIdBuffer = wasm.memory.buffer.slice(traceIdPrt, traceIdPrt + 16);
  return decoder.decode(traceIdBuffer);
};
