import { useRef, type FC } from "react";

import {
  OtaReviewService,
  type ListByCondReq,
} from "@api/http_pms/ota_review/ota_review_srv";
import { Spin, Tabs } from "@arco-design/web-react";
import TabPane from "@arco-design/web-react/es/Tabs/tab-pane";
import { useInfiniteScroll, useSize } from "ahooks";
import { IconRefresh } from "@arco-design/web-react/icon";
import { isEmpty } from "lodash-es";
import { PageLayout } from "@/common/Layout/PageLayout";
import { Query } from "./Query";
import { useTableProps } from "@/hooks/useTableProps";
import { PublicCardList } from "./PublicCardList";
import { NotPublicCardList } from "./NotPublicCardList";
import { Loading } from "@/comps/Loading";

enum TabType {
  Public = "Public",
  NotPublic = "NotPublic",
}

const Review: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useSize(ref);
  const colNumber = (size?.width || 0) > 1400 ? 3 : 2;
  const queryCurrent = useRef(1);

  const { query, assignQuery } = useTableProps<ListByCondReq, {}>({
    defaultRequestQuery: {
      current: 1,
      pageSize: 18,
      isPublished: true,
      roomIds: [],
      publishedAt: {},
      overallRatings: [],
      ids: [],
    },
    defaultFilterQuery: {},
  });

  const { data, error, noMore, mutate, loadMore } = useInfiniteScroll(
    async (d) => {
      const res = await OtaReviewService.ListByCond({
        ...query.request,
        current: queryCurrent.current,
      });
      // 成功后自增
      queryCurrent.current++;
      return {
        list: res.reviews || [],
        isNoMore: res.total <= res.current * res.pageSize,
      };
    },
    {
      target: ref,
      isNoMore: (d) => d?.isNoMore,
      threshold: 0,
      reloadDeps: [
        query.request.createdAt,
        query.request.isPublished,
        query.request.roomIds,
        query.request.publishedAt,
        query.request.overallRatings,
      ],
    },
  );

  return (
    <PageLayout
      title={
        <Tabs
          activeTab={
            query.request.isPublished ? TabType.Public : TabType.NotPublic
          }
          onChange={(val) => {
            mutate(undefined);
            queryCurrent.current = 1;
            assignQuery.request({
              isPublished: val === TabType.Public,
            });
          }}
          css={`
            .arco-tabs-header-nav::before {
              display: none;
            }
          `}
        >
          <TabPane
            key={TabType.Public}
            title={<span className="text-lg">已公开</span>}
          />
          <TabPane
            key={TabType.NotPublic}
            title={<span className="text-lg">未公开</span>}
          />
        </Tabs>
      }
      topBar={
        <Query
          query={query.request}
          assignQuery={(_query) => {
            queryCurrent.current = 1;
            assignQuery.request(_query);
          }}
        />
      }
      childrenClassName="!overflow-hidden"
    >
      <Loading
        loading={data?.list === undefined}
        isEmpty={isEmpty(data?.list)}
        className="rounded-xl bg-white"
      >
        <div
          ref={ref}
          className="flex size-full flex-col justify-between overflow-y-auto overflow-x-hidden"
        >
          {query.request.isPublished ? (
            <div>
              <PublicCardList list={data?.list || []} colNumber={colNumber} />
            </div>
          ) : (
            <NotPublicCardList
              list={data?.list || []}
              colNumber={colNumber}
              mutateNewList={(list) => {
                mutate({
                  list,
                  isNoMore: data?.isNoMore,
                });
              }}
            />
          )}
          <div className="mt-4">
            {error ? (
              <div
                className="text-color-danger-6 flex cursor-pointer items-center justify-center"
                onClick={() => loadMore()}
              >
                <IconRefresh />
                <div className="ml-2 underline underline-offset-2">
                  加载失败，请重试
                </div>
              </div>
            ) : (
              <>
                {noMore && queryCurrent.current > 2 && (
                  <div className="text-color-text-3 text-center">
                    没有更多了~
                  </div>
                )}
                {!noMore && (
                  <div className="text-color-primary-6 flex items-center justify-center">
                    <Spin /> <div className="ml-2 leading-none">加载中</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Loading>
    </PageLayout>
  );
};

export default Review;
