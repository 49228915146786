import { Modal } from "@arco-design/web-react";
import clsx from "clsx";
import { useState, type ReactNode } from "react";
import { createNiceModal } from "@/utils/nicemodel";
import { BaseInfoForm } from "./BaseInfoForm";
import { EmailChangeForm } from "./EmailChangeForm";
import { PasswordManageForm } from "./PasswordManageForm";

export const openUserCenterModal = createNiceModal<
  {}, // 调用的入参参数
  {} // 调用的出参参数
>(({ _modal }) => {
  const [currentTab, setCurrentTab] = useState("1");
  return (
    <Modal
      title="个人中心"
      {..._modal.props}
      footer={null}
      className="w-[640px]"
      css={`
        .arco-modal-content {
          padding: 0 24px;
          height: 460px;
        }
      `}
    >
      <div className="flex h-full">
        <div className="w-40 border-r">
          <EasTab onChange={setCurrentTab} tabKey="1" current={currentTab}>
            基础信息
          </EasTab>
          <EasTab onChange={setCurrentTab} tabKey="2" current={currentTab}>
            绑定邮箱
          </EasTab>
          <EasTab onChange={setCurrentTab} tabKey="3" current={currentTab}>
            修改密码
          </EasTab>
        </div>
        <div className="flex-auto py-6 pl-6">
          {currentTab === "1" && <BaseInfoForm />}
          {currentTab === "2" && <EmailChangeForm />}
          {currentTab === "3" && <PasswordManageForm />}
        </div>
      </div>
    </Modal>
  );
});

const EasTab = ({
  tabKey,
  current,
  onChange,
  children,
}: {
  tabKey: string;
  current: string;
  onChange: (val: string) => void;
  children: ReactNode;
}) => (
  <div
    className={clsx("flex h-[52px] cursor-pointer items-center ", {
      "border-r-2 border-color-primary-6 text-color-primary-6":
        current === tabKey,
    })}
    onClick={() => onChange(tabKey)}
  >
    {children}
  </div>
);
