import { Input, type InputProps, Select } from "@arco-design/web-react";
import { type FC } from "react";
import { CompactGroup } from "@/common/CompactGroup";
import { CountryCode } from "../const";
import { usePartialControllable } from "@/hooks/usePartialControllable";
import { useConstOption } from "../const/utils";
import { toLowerCaseSearch } from "@/utils/toLowerCaseSearch";

export interface PhoneInputValue {
  countryCode: string;
  phoneNumber: string;
}

export const TelInput: FC<{
  value?: Partial<PhoneInputValue>;
  onChange?: (val?: Partial<PhoneInputValue>) => void;
  size?: InputProps["size"];
}> = ({ size, ...props }) => {
  const { currentValue, handleCurrentValueChange } = usePartialControllable(
    props,
    ["countryCode", "phoneNumber"],
  );

  const countryCodeOptions = useConstOption(CountryCode);

  return (
    <CompactGroup>
      <Select
        className="w-[90px] flex-none"
        options={countryCodeOptions}
        value={currentValue.countryCode || undefined}
        onChange={(val: string) => handleCurrentValueChange("countryCode", val)}
        allowClear={true}
        size={size}
        placeholder="区号"
        showSearch={true}
        filterOption={(inputValue, option) =>
          toLowerCaseSearch(String(option.props.children), inputValue)
        }
      />
      <Input
        value={currentValue.phoneNumber}
        onChange={(val) => handleCurrentValueChange("phoneNumber", val)}
        size={size}
        placeholder="请输入联系方式"
      />
    </CompactGroup>
  );
};
