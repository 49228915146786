import { Message, Progress, Upload, Image, Spin } from "@arco-design/web-react";
import {
  type UploadListProps,
  type UploadItem,
  type UploadProps,
} from "@arco-design/web-react/es/Upload";
import { IconPlus, IconRefresh } from "@arco-design/web-react/icon";
import { useEffect, useState, type FC } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import clsx from "clsx";
import {
  type PartitionKind,
  type Image as ApiImage,
  type EntityInfo,
} from "@api/http_pms/common/common_type";

import {
  type BatchSetImageRankReq,
  CommonService,
} from "@api/http_pms/common/common_srv";
import { isEmpty } from "lodash-es";
import { useCreation } from "ahooks";
import { fileUpload, makeImageUrl } from "@/utils/fileUpload";
import { IconFont } from "@/common/IconFont";
import { usePromiseDebounce } from "@/utils/usePromiseDebounce";
import { useUploadingStore } from "./FileUploadList";
import { genId } from "@/utils/dealWithId";

export type ImageUploadItem = UploadItem & {
  uri?: string;
  realName?: string;
};

export const ImageList: FC<
  {
    entityInfo: EntityInfo;
    partition: PartitionKind;
    disabled?: boolean;
    onChange?: (val: ApiImage[]) => void;
    value?: ApiImage[];
    onNumberChange?: (val: number) => void;
    className?: string;
    showMainImage?: boolean;
    mainImage?: string;
    setMainImage?: (val?: string) => void; // 设置有值，删除没有值
    canSortable?: boolean;
  } & Omit<UploadProps, "value" | "onChange">
> = ({
  entityInfo,
  partition,
  disabled,
  onChange,
  value,
  onNumberChange,
  className,
  showMainImage,
  mainImage,
  setMainImage,
  canSortable = true,
  ...props
}) => {
  const [pictureList, setPictureList] = useState<ImageUploadItem[]>([]); // 组件内维护的图片list
  const [uidPercent, setUidPercent] = useState<Record<string, number>>({}); // 上传进度维护

  const uploadKey = useCreation(genId, []);
  const { setUploadKeyState } = useUploadingStore();
  useEffect(() => () => setUploadKeyState(uploadKey, false), []);

  const _onChange = (val: ImageUploadItem[]) => {
    const changeFilesList = val.map((f) => {
      const existImage = value?.find((item) => item.name === f.uid);
      return existImage || (f.response as ApiImage);
    });
    onChange?.(changeFilesList);
  };

  useEffect(() => {
    if (isEmpty(pictureList)) {
      setPictureList(
        (value || []).map((i) => ({
          uid: i.name,
          name: i.realName,
          url: makeImageUrl(i.uri, 92, 92, "s"),
        })),
      );
    }
  }, [value]);

  const { run: runBatchSetImageRank } = usePromiseDebounce(
    async (req: BatchSetImageRankReq) =>
      await CommonService.BatchSetImageRank(req),
    "noWait",
  );

  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;
    const moveList = arrayMove(pictureList, oldIndex, newIndex);
    setPictureList?.(moveList);
    onNumberChange?.(moveList.length);
    runBatchSetImageRank({
      entityInfo,
      partition,
      names: moveList
        .filter((item) => item.status === "done" || !item.status)
        .map((item) => item.uid),
    });
    _onChange?.(moveList);
  };

  const deleteOne = async (item: UploadItem) => {
    if (item.status === "done") {
      await CommonService.BatchDelImage({
        entityInfo,
        partition,
        names: [item.uid],
      });
      Message.success("删除成功");
    }
    const finalPictureList = pictureList.filter(
      (picture) => picture.uid !== item.uid,
    );
    setPictureList(finalPictureList);
    onNumberChange?.(finalPictureList.length);
    _onChange?.(finalPictureList);
  };

  return (
    <div
      css={`
        .arco-upload-list {
          display: inline;
        }
        .image-delete {
          display: ${disabled ? "none" : "flex"};
        }
      `}
      className={className}
    >
      <Upload
        disabled={disabled}
        onProgress={(f) => {
          setUidPercent((percent) => ({ ...percent, [f.uid]: f.percent || 0 }));
        }}
        customRequest={({ onProgress, onError, onSuccess, file }) => {
          fileUpload(file, entityInfo, partition, onProgress)
            .then(onSuccess)
            .catch(onError);
        }}
        fileList={pictureList}
        listType="picture-card"
        renderUploadList={(filesList, _props) => (
          <SortableListContainer
            filesList={filesList}
            props={_props}
            axis="xy"
            onSortEnd={onSortEnd}
            uidPercent={uidPercent}
            deleteOne={deleteOne}
            shouldCancelStart={() => Boolean(disabled)}
            showMainImage={showMainImage}
            mainImage={mainImage}
            setMainImage={setMainImage}
            useDragHandle={true}
            canSortable={canSortable}
          />
        )}
        accept="image/*"
        multiple={true}
        onChange={(filesList, file) => {
          const doneFilesList = filesList.filter((f) => f.status === "done");
          // 初始、上传中、成功、失败都会触发onChange
          // 上传成功，修改value值
          if (file.status === "done") {
            const newFilesList = filesList.map((_file) => {
              const newFile: ImageUploadItem = { ..._file };
              const image = newFile?.response as ApiImage;
              if (image) {
                newFile.uid = image.name;
                newFile.url = makeImageUrl(image.uri, 92, 92, "s");
                newFile.uri = image.uri;
              }
              return newFile;
            });
            setPictureList(newFilesList);
            onNumberChange?.(doneFilesList.length);
            _onChange?.(doneFilesList);
          } else {
            // 修改组件内维护值
            setPictureList(filesList);
          }
          setUploadKeyState?.(
            uploadKey,
            doneFilesList.length !== filesList.length,
          );
        }}
        {...props}
      >
        <div className="arco-upload-trigger-picture text-color-primary-6 border-color-primary-5 bg-color-primary-1 mt-2 flex size-[92px] flex-col items-center justify-center rounded-md border border-dashed">
          <IconPlus className="mb-2 size-4" />
          上传
        </div>
      </Upload>
    </div>
  );
};

export const SortableListContainer = SortableContainer<{
  filesList: UploadItem[];
  props: UploadListProps;
  uidPercent: Record<string, number>;
  deleteOne: (item: UploadItem) => Promise<void>;
  mainImage?: string;
  showMainImage?: boolean;
  setMainImage?: (mainImage?: string) => void;
  canSortable: boolean;
}>(
  ({
    filesList,
    props,
    uidPercent,
    deleteOne,
    mainImage,
    showMainImage,
    setMainImage,
    canSortable,
  }: {
    filesList: UploadItem[];
    props: UploadListProps;
    uidPercent: Record<string, number>;
    deleteOne: (item: UploadItem) => Promise<void>;
    mainImage: string;
    showMainImage: boolean;
    setMainImage: (mainImage?: string) => void;
    canSortable: boolean;
  }) => (
    <div className="inline">
      {filesList.map((file, index) => {
        const url = file.url || URL.createObjectURL(file?.originFile as Blob);
        return file.status === "done" && canSortable ? (
          <SortableItem
            key={file.uid}
            file={file}
            url={url}
            index={index}
            mainImage={mainImage}
            showMainImage={showMainImage}
            setMainImage={setMainImage}
            onRemove={async (_file) => {
              await deleteOne(_file);
            }}
            percent={uidPercent[file.uid] || 0}
          />
        ) : (
          <ImageItem
            key={file.uid}
            file={file}
            url={url}
            mainImage={mainImage}
            onReupload={(_file) => {
              props?.onReupload?.(_file);
            }}
            percent={uidPercent[file.uid] || 0}
            onRemove={async (_file) => {
              await deleteOne(_file);
            }}
          />
        );
      })}
    </div>
  ),
);

const SortableItem = SortableElement<{
  file: UploadItem;
  url: string;
  mainImage: string;
  onRemove?: (file: UploadItem) => void;
  percent: number;
  showMainImage?: boolean;
  setMainImage?: (mainImage?: string) => void;
}>(
  ({
    file,
    url,
    mainImage,
    showMainImage,
    setMainImage,
    onRemove,
    percent,
  }: {
    file: UploadItem;
    url: string;
    mainImage: string;
    showMainImage?: boolean;
    setMainImage?: (mainImage?: string) => void;
    onRemove?: (file: UploadItem) => void;
    percent: number;
  }) => (
    <ImageItem
      file={file}
      url={url}
      mainImage={mainImage}
      onRemove={onRemove}
      percent={percent}
      showMainImage={showMainImage}
      setMainImage={setMainImage}
    />
  ),
);

export const ImageItem = ({
  file,
  url,
  mainImage,
  showMainImage,
  setMainImage,
  onReupload,
  onRemove,
  percent,
}: {
  file: UploadItem;
  url: string;
  mainImage: string;
  showMainImage?: boolean;
  setMainImage?: (mainImage?: string) => void;
  onReupload?: (file: UploadItem) => void;
  onRemove?: (file: UploadItem) => void;
  percent: number;
}) => (
  <div
    className={clsx(
      "picture-card-item group relative z-[1001] mr-[14px] mt-2 inline-block size-[92px]",
      { "cursor-move": file.status !== "error" },
    )}
  >
    {/* 主图 */}
    {file.uid === mainImage && showMainImage && (
      <div
        className="main-image absolute top-0 z-[1] flex h-6 w-full items-center justify-center rounded-t-xl text-white"
        css={`
          background-color: rgba(var(--primary-4), 0.4);
        `}
      >
        主图
      </div>
    )}
    {showMainImage && (
      <div
        className={clsx(
          `absolute top-0 z-[1] hidden h-6 w-full cursor-pointer rounded-t-xl text-center text-white`,
          { "group-hover:block": file.uid !== mainImage },
        )}
        css={`
          background-color: rgba(var(--primary-4), 0.4);
        `}
        onClick={() => {
          setMainImage?.(file.uid);
        }}
      >
        设为主图
      </div>
    )}

    {/* 进度条 */}
    {file.status !== "done" && (
      <Progress
        percent={percent}
        size="mini"
        className="absolute inset-0 m-auto flex flex-col justify-center"
        strokeWidth={2}
        trailColor="#CCCCCC"
        color="#fff"
        css={`
          .arco-progress-circle-wrapper {
            width: 24px !important;
            height: 24px !important;
          }
        `}
        status="success"
      />
    )}
    {/* 重新上传 */}
    {file.status === "error" && (
      <div
        className="absolute inset-0 m-auto flex cursor-pointer flex-col items-center justify-center text-white"
        onClick={() => {
          onReupload?.(file);
        }}
      >
        <IconRefresh className="mb-3 size-6" />
        <div>重新上传</div>
      </div>
    )}
    {/* 删除 */}
    <div
      className="image-delete bg-color-primary-6 absolute -right-2 -top-2 z-[1100] flex size-5 cursor-pointer items-center justify-center rounded-full hover:scale-110"
      onMouseDown={() => {
        onRemove?.(file);
        if (file.uid === mainImage && showMainImage) {
          setMainImage?.();
        }
      }}
    >
      <IconFont type="icon-right-top-minus" className="text-white" />
    </div>

    <DragHandle url={url} />
  </div>
);

const DragHandle = SortableHandle<{ url: string }>(
  ({ url }: { url: string }) => (
    <Image
      width={92}
      height={92}
      src={url}
      loader={
        <div className="flex size-full items-center justify-center bg-white">
          <Spin />
        </div>
      }
      className="inline-block size-full rounded-xl object-cover"
      preview={false}
    />
  ),
);
