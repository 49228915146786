/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type OtaPlatformKind, type Currency } from "../const/const_enum";
import { type Remark } from "../common/common_type";
import { type TenantSimple } from "../tenant/tenant_type";

export interface Order {
  id: string;
  // ota账号id
  otaAccountId: string;
  // ota平台
  otaPlatformKind: OtaPlatformKind;
  // ota的订单编号
  otaOutOrderId: string;
  // 冲突订单号
  conflictIds: string[];
  // 下单时间 @uint64
  bookAt: string;
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
  // 住了几天 @uint32
  stays: number;
  // 入住时间 @int32
  checkInTime: number;
  // 退房时间 @int32
  checkOutTime: number;
  // 房费 @uint32
  roomFee: number;
  // 房费币种
  roomFeeCurrency: Currency;
  // 房费钱包id
  roomFeeWalletId: string;
  // ota服务费 @int32
  otaFee: number;
  // ota钱包id
  otaFeeWalletId?: string;
  // 手续费 @int32
  handlingFee: number;
  // 佣金是否入帐 @bool
  otaFeeWalletExist: boolean;
  // 标签
  tagIds: string[];
  // 备注
  remark: Remark;
  // 房客人数 @uint32
  tenantCount: number;
  // 订单来源
  orderSourceId: string;
  // 订单来源key
  orderSourceKey?: string;
  roomId: string;
  // 取消时间 @uint64
  cancelledAt?: string;
  // 是否取消 @bool
  isCancelled: boolean;
  // 在线checkIn状态 0-生成页面 1-停止页面,2-重新打开页面
  checkInKind: CheckInKind;
  // orderId_sign
  signedId: string;
}

export interface OrderForList {
  roomId: string;
  roomName: string;
  roomCover: string;
  // 标签
  tagIds: string[];
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
  // 房费 @uint32
  roomFee: number;
  // 房费币种
  roomFeeCurrency: Currency;
  // 房客姓名
  tenant?: TenantSimple;
  // 订单id
  id: string;
  // ota的订单编号
  otaOutOrderId?: string;
  // ota账号id
  otaAccountId?: string;
  // ota平台
  otaPlatformKind?: OtaPlatformKind;
  // 预定时间 @uint64
  bookAt?: string;
  // ota服务费 @int32
  otaFee: number;
  // 手续费 @int32
  handlingFee: number;
  // 备注
  remark?: Remark;
  // 取消时间 @uint64
  cancelledAt?: string;
  // 是否取消 @bool
  isCancelled: boolean;
  // 订单来源
  orderSourceId: string;
  // 订单来源key
  orderSourceKey?: string;
  // 入住时间 @int32
  checkInTime: number;
  // 退房时间 @int32
  checkOutTime: number;
  // 房客人数 @uint32
  tenantCount: number;
  // 房间分组
  groupId?: string;
  // 冲突id
  conflictIds: string[];
}

export interface OrderCreate {
  // ota账号id
  otaAccountId?: string;
  // ota的订单编号
  otaOutOrderId?: string;
  // 房间
  roomId: string;
  // 下单时间 @uint64
  bookAt: string;
  // 开始日期
  dateStart: string;
  // 结束日期
  dateEnd: string;
  // 入住时间 @int32
  checkInTime: number;
  // 退房时间 @int32
  checkOutTime: number;
  // 房费 @uint32
  roomFee: number;
  // 房费币种
  roomFeeCurrency: Currency;
  // 房费钱包id
  roomFeeWalletId: string;
  // ota服务费 @int32
  otaFee: number;
  // ota钱包id 空不计入
  otaFeeWalletId?: string;
  // 手续费 @int32
  handlingFee: number;
  // 房客人数 @uint32
  tenantCount: number;
  // 标签
  tagIds: string[];
  // 订单来源
  orderSourceId: string;
  // 订单来源key
  orderSourceKey?: string;
  // 佣金是否入帐 @bool
  otaFeeWalletExist: boolean;
  // 在线checkIn状态
  checkInKind: CheckInKind;
}

// 订单信息
export interface OrderPureWithTenant {
  id: string;
  roomId: string;
  // 订单入住日期
  dateStart: string;
  // 订单退房日期
  dateEnd: string;
  // 住了几天 @uint32
  stays: number;
  //  @uint32
  roomFee: number;
  roomFeeCurrency: Currency;
  // ota服务费 @int32
  otaFee: number;
  // 手续费 @int32
  handlingFee: number;
  // 订单来源
  orderSourceId: string;
  // 订单来源key
  orderSourceKey: string;
  // 房客姓名
  tenant?: TenantSimple;
  // 房客人数 @uint32
  tenantCount: number;
  // 冲突id
  conflictIds: string[];
  // 取消时间 @uint64
  cancelledAt?: string;
  // 是否取消 @bool
  isCancelled: boolean;
}

export interface OrderUpdate {
  id: string;
  // ota账号id
  otaAccountId?: string;
  // ota平台
  otaPlatformKind?: OtaPlatformKind;
  // ota的订单编号
  otaOutOrderId?: string;
  // 下单时间 @uint64
  bookAt?: string;
  // 开始日期
  dateStart?: string;
  // 结束日期
  dateEnd?: string;
  // 住了几天 @uint32
  stays?: number;
  // 入住时间 @int32
  checkInTime?: number;
  // 退房时间 @int32
  checkOutTime?: number;
  // 房费 @uint32
  roomFee?: number;
  // 房费币种
  roomFeeCurrency?: Currency;
  // 房费钱包id
  roomFeeWalletId?: string;
  // ota服务费 @int32
  otaFee?: number;
  // ota钱包id
  otaFeeWalletId?: string;
  // 手续费 @int32
  handlingFee?: number;
  // 房客人数 @uint32
  tenantCount?: number;
  // 订单来源
  orderSourceId?: string;
  // 订单来源key
  orderSourceKey?: string;
  roomId?: string;
  // 佣金是否入帐 @bool
  otaFeeWalletExist?: boolean;
  // 在线checkIn状态
  checkInKind?: CheckInKind;
}

// 订单信息
export interface OrderPure {
  id: string;
  roomId: string;
  // 订单入住日期
  dateStart: string;
  // 订单退房日期
  dateEnd: string;
  // 住了几天 @uint32
  stays: number;
  roomFeeCurrency: Currency;
  //  @uint32
  roomFee: number;
  // ota服务费 @int32
  otaFee: number;
  // 手续费 @int32
  handlingFee: number;
  // 订单来源
  orderSourceId: string;
  // 订单来源key
  orderSourceKey: string;
}
export enum CheckInKind {
  CheckInDefault = 0,
  // 只有1的时候可以提交
  CheckInView = 1,
  CheckInSuper = 2,
}
