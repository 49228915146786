import { type ShareBill } from "@api/http_pms/bill/bill_type";
import { Button, Form, Message, Space } from "@arco-design/web-react";
import { useRef, type FC } from "react";
import { IsFileUploading } from "@/common/FileUploadList";
import { AsyncButton } from "@/comps/AsyncButton";
import { BillForm } from "@/pages/Bill/BillForm";
import { getShareBillSubmitData } from "@/pages/Bill/BillForm/utils";
import { checkIsAddId } from "@/utils/dealWithId";

interface ItemFormProps {
  onOk?: (item: ShareBill) => Promise<void>;
  onCancel?: () => void;
  shareBill: ShareBill;
  orderId: string;
}

export const BillItemForm: FC<ItemFormProps> = ({
  shareBill,
  onCancel,
  onOk,
  orderId,
}) => {
  const [form] = Form.useForm();

  const fileUploading = useRef(false);

  const onSubmit = async () => {
    if (fileUploading.current) {
      Message.error("附件未上传完成");
      return;
    }
    const submitBill = await getShareBillSubmitData(form);
    await onOk?.(submitBill);
  };

  return (
    <IsFileUploading onUploadingChange={(s) => (fileUploading.current = s)}>
      <div
        className="bg-color-fill-1 rounded-lg p-4"
        css={`
          .arco-select-disabled {
            border-color: var(--color-border-2);
            border-width: 1px;
            border-radius: 8px;
          }
          .arco-input-inner-wrapper-disabled {
            border-color: var(--color-border-2);
            border-width: 1px;
          }
          .arco-picker-disabled {
            border-color: var(--color-border-2);
            border-width: 1px;
          }
        `}
      >
        <BillForm
          form={form}
          initShareBill={
            checkIsAddId(shareBill.shareId) ? undefined : shareBill
          }
          orderId={orderId}
        />

        <Form.Item wrapperCol={{ offset: 6 }} className="!mb-0 mt-2">
          <Space>
            <AsyncButton
              onClick={async () => {
                await onSubmit();
              }}
              type="outline"
              status="success"
            >
              保存
            </AsyncButton>
            <Button
              onClick={() => {
                onCancel?.();
              }}
              type="outline"
            >
              取消
            </Button>
          </Space>
        </Form.Item>
      </div>
    </IsFileUploading>
  );
};
