import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { type ConstOptionMap } from "@/common/const/interface";

export enum PermissionGroupEnum {
  Unknown,
  Room,
  RoomDay,
  Order,
  Bill,
  System,
  Statistic,
  Reservation,
  Ota,
}

export const permissionGroupList = [
  PermissionGroupEnum.Room,
  PermissionGroupEnum.RoomDay,
  PermissionGroupEnum.Order,
  PermissionGroupEnum.Bill,
  PermissionGroupEnum.Statistic,
  PermissionGroupEnum.Reservation,
  PermissionGroupEnum.Ota,
  PermissionGroupEnum.System,
];

export const PermissionGroup: ConstOptionMap<
  PermissionGroupEnum,
  {
    name: string;
  }
> = {
  [PermissionGroupEnum.Unknown]: {
    value: PermissionGroupEnum.Unknown,
    label: "",
    name: "",
  },
  [PermissionGroupEnum.Room]: {
    value: PermissionGroupEnum.Room,
    label: "房间",
    name: "房间",
  },
  [PermissionGroupEnum.RoomDay]: {
    value: PermissionGroupEnum.RoomDay,
    label: "房态",
    name: "房态",
  },
  [PermissionGroupEnum.Order]: {
    value: PermissionGroupEnum.Order,
    label: "订单",
    name: "订单",
  },
  [PermissionGroupEnum.Bill]: {
    value: PermissionGroupEnum.Bill,
    label: "财务 (账单、月报、钱包)",
    name: "财务",
  },
  [PermissionGroupEnum.Statistic]: {
    value: PermissionGroupEnum.Statistic,
    label: "统计",
    name: "统计",
  },
  [PermissionGroupEnum.System]: {
    value: PermissionGroupEnum.System,
    label: "全局功能",
    name: "全局功能",
  },
  [PermissionGroupEnum.Reservation]: {
    value: PermissionGroupEnum.Reservation,
    label: "微店",
    name: "微店",
  },
  [PermissionGroupEnum.Ota]: {
    value: PermissionGroupEnum.Ota,
    label: "OTA直联",
    name: "OTA直联",
  },
};

// 权限
export const Permission: ConstOptionMap<
  ApiPermission,
  {
    level: number; // 权限等级
    group: PermissionGroupEnum;
    tip?: string;
  }
> = {
  [ApiPermission.Unknown]: {
    value: ApiPermission.Unknown,
    label: "",
    hidden: true,
    level: 0,
    group: PermissionGroupEnum.Unknown,
    rank: 0,
  },

  // 房间
  [ApiPermission.RoomView]: {
    value: ApiPermission.RoomView,
    label: "查看房间列表",
    level: 1,
    rank: 5,
    group: PermissionGroupEnum.Room,
  },
  [ApiPermission.RoomDetailView]: {
    value: ApiPermission.RoomDetailView,
    label: "查看房间详情",
    level: 2,
    rank: 4,
    group: PermissionGroupEnum.Room,
  },
  [ApiPermission.RoomUpdate]: {
    value: ApiPermission.RoomUpdate,
    label: "修改房间信息",
    level: 3,
    rank: 3,
    group: PermissionGroupEnum.Room,
  },
  [ApiPermission.RoomCreateAndSort]: {
    value: ApiPermission.RoomCreateAndSort,
    label: "创建房间/房间排序",
    level: 4,
    rank: 2,
    group: PermissionGroupEnum.Room,
  },
  [ApiPermission.RoomSuper]: {
    value: ApiPermission.RoomSuper,
    label: "删除",
    level: 5,
    rank: 1,
    group: PermissionGroupEnum.Room,
  },

  // 房态
  [ApiPermission.RoomDayView]: {
    value: ApiPermission.RoomDayView,
    label: "查看日历",
    level: 1,
    rank: 3,
    group: PermissionGroupEnum.RoomDay,
  },
  [ApiPermission.RoomDaySetRemark]: {
    value: ApiPermission.RoomDaySetRemark,
    label: "设置备注",
    level: 2,
    rank: 2,
    group: PermissionGroupEnum.RoomDay,
  },
  [ApiPermission.RoomDaySuper]: {
    value: ApiPermission.RoomDaySuper,
    label: "设置价格/活动/房态",
    level: 3,
    rank: 1,
    group: PermissionGroupEnum.RoomDay,
  },

  // 订单
  [ApiPermission.OrderView]: {
    value: ApiPermission.OrderView,
    label: "查看基础订单信息",
    level: 1,
    rank: 6,
    group: PermissionGroupEnum.Order,
  },
  [ApiPermission.OrderAdvancedView]: {
    value: ApiPermission.OrderAdvancedView,
    label: "查看进阶订单信息",
    level: 2,
    rank: 5,
    group: PermissionGroupEnum.Order,
    tip: "备注、房客支付、渠道佣金、手续费、标签、日志信息",
  },
  [ApiPermission.OrderSetRemarkAndTag]: {
    value: ApiPermission.OrderSetRemarkAndTag,
    label: "设置备注/标签",
    level: 3,
    rank: 4,
    group: PermissionGroupEnum.Order,
  },
  [ApiPermission.OrderUpdate]: {
    value: ApiPermission.OrderUpdate,
    label: "订单录入/修改",
    level: 4,
    rank: 3,
    group: PermissionGroupEnum.Order,
    // tip: "可删除本账号录入的订单",
  },
  [ApiPermission.OrderReview]: {
    value: ApiPermission.OrderReview,
    label: "订单评价",
    level: 5,
    rank: 2,
    group: PermissionGroupEnum.Order,
  },
  [ApiPermission.OrderSuper]: {
    value: ApiPermission.OrderSuper,
    label: "删除/取消",
    level: 6,
    rank: 1,
    group: PermissionGroupEnum.Order,
  },

  // 财务
  [ApiPermission.BillView]: {
    value: ApiPermission.BillView,
    label: "查看账单总表",
    level: 1,
    rank: 6,
    group: PermissionGroupEnum.Bill,
  },
  [ApiPermission.BillTabView]: {
    value: ApiPermission.BillTabView,
    label: "查看订单中的账单",
    level: 1,
    rank: 5,
    group: PermissionGroupEnum.Bill,
  },
  [ApiPermission.BillMonthlyView]: {
    value: ApiPermission.BillMonthlyView,
    label: "查看月报",
    level: 1,
    rank: 4,
    group: PermissionGroupEnum.Bill,
  },
  [ApiPermission.BillWalletView]: {
    value: ApiPermission.BillWalletView,
    label: "查看钱包",
    level: 1,
    rank: 3,
    group: PermissionGroupEnum.Bill,
  },
  [ApiPermission.BillUpdate]: {
    value: ApiPermission.BillUpdate,
    label: "帐单创建/编辑/删除",
    level: 1,
    rank: 2,
    group: PermissionGroupEnum.Bill,
    tip: "编辑所有未确认的帐单、删除本人创建的未确认的帐单",
  },
  [ApiPermission.BillSuper]: {
    value: ApiPermission.BillSuper,
    label: "财务编辑/确认/删除",
    level: 2,
    rank: 1,
    group: PermissionGroupEnum.Bill,
  },

  // 全局
  [ApiPermission.Operation]: {
    value: ApiPermission.Operation,
    label: "运营配置",
    level: 1,
    rank: 5,
    group: PermissionGroupEnum.System,
    tip: "标签管理、活动管理配置",
  },
  [ApiPermission.Account]: {
    value: ApiPermission.Account,
    label: "账号管理",
    level: 1,
    rank: 4,
    group: PermissionGroupEnum.System,
  },
  [ApiPermission.System]: {
    value: ApiPermission.System,
    label: "系统管理",
    level: 1,
    rank: 2,
    group: PermissionGroupEnum.System,
  },
  [ApiPermission.RoomGroupCreateAndSort]: {
    value: ApiPermission.RoomGroupCreateAndSort,
    label: "创建分组/分组排序",
    level: 1,
    rank: 1,
    group: PermissionGroupEnum.System,
  },

  // 统计
  [ApiPermission.OccupancyRateView]: {
    value: ApiPermission.OccupancyRateView,
    label: "查看入住率",
    level: 1,
    rank: 1,
    group: PermissionGroupEnum.Statistic,
  },

  // 微店
  [ApiPermission.ReservationView]: {
    value: ApiPermission.ReservationView,
    label: "微店数据查看",
    level: 1,
    rank: 2,
    group: PermissionGroupEnum.Reservation,
  },
  [ApiPermission.ReservationSuper]: {
    value: ApiPermission.ReservationSuper,
    label: "微店配置管理",
    level: 2,
    rank: 1,
    group: PermissionGroupEnum.Reservation,
  },

  // ota直联
  [ApiPermission.Chat]: {
    value: ApiPermission.Chat,
    label: "聊天",
    level: 1,
    rank: 10,
    group: PermissionGroupEnum.Ota,
  },
  [ApiPermission.OtaAccount]: {
    value: ApiPermission.OtaAccount,
    label: "OTA账号管理",
    level: 1,
    rank: 3,
    group: PermissionGroupEnum.Ota,
  },
};
