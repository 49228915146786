import { UserService } from "@api/http_pms/user/user_srv";
import { Button, Form, Input, Message } from "@arco-design/web-react";
import { Avatar } from "@/comps/Avatar";
import { IconFont } from "@/common/IconFont";
import { useUserInfo } from "@/store/useUserInfo";

export const BaseInfoForm = () => {
  const { userInfo, refresh } = useUserInfo();
  const [form] = Form.useForm();

  return (
    <>
      <div className="text-base font-medium">基础信息</div>
      <div className="mt-6 flex items-center">
        <Avatar className="size-[36px] text-base" name={userInfo.nickname} />
        <div className="ml-2 text-base font-medium">{userInfo.nickname}</div>
      </div>
      <div className="mt-4 text-color-text-2">ID：{userInfo.accountName}</div>
      <div className="mb-6 mt-4 flex items-center text-color-text-2">
        <IconFont type="icon-email" className="mr-2 size-4" />
        {userInfo.email}
      </div>

      <Form
        initialValues={userInfo}
        layout="vertical"
        form={form}
        onSubmit={async (values) => {
          await UserService.SetNickname({
            nickname: values.nickname,
          });
          await refresh();
          Message.success("修改成功");
        }}
      >
        <Form.Item field="nickname" label="员工姓名">
          <Input size="large" placeholder="请输入员工姓名" />
        </Form.Item>
        <div className="mt-2">
          <Button type="primary" htmlType="submit" className="mr-3">
            确定
          </Button>
        </div>
      </Form>
    </>
  );
};
