import { styled } from "styled-components";

export const CompactGroup = styled.span`
  display: flex;

  & > *:not(:last-child) {
    &.arco-select > .arco-select-view,
    &.arco-input,
    &.arco-picker,
    &.arco-input-group-wrapper .arco-input-inner-wrapper {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & > *:not(:first-child) {
    &.arco-select > .arco-select-view,
    &.arco-input,
    &.arco-picker,
    &.arco-input-group-wrapper .arco-input-inner-wrapper {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  & > *:not(:last-child):not(:hover):not(:focus) {
    &.arco-select > .arco-select-view,
    &.arco-input,
    &.arco-picker,
    &.arco-input-group-wrapper
      .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-focus) {
      border-right-color: transparent;
    }
  }
`;
