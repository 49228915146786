import { useState, type FC } from "react";
import { Form, Link } from "@arco-design/web-react";
import { type Currency } from "@api/http_pms/const/const_enum";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { MoneyCurrencyInput } from "@/common/Inputer/MoneyCurrencyInput";
import { MoneyInputWithCurrency } from "@/common/Inputer/MoneyInputWithCurrency";
import { MoneyWalletInput } from "./MoneyWalletInput";
import { usePartialControllable } from "@/hooks/usePartialControllable";
import { getMoneyText } from "@/utils/money";
import { useWallet } from "@/store/useWallet";
import { permissionHas } from "@/store/useUserInfo";

const getSumAmount = ({
  roomFeeAmount,
  otaFeeAmount,
  handlingFee,
}: Partial<OrderRoomFeeGroupValue>) =>
  (roomFeeAmount || 0) + (otaFeeAmount || 0) + (handlingFee || 0);

export interface OrderRoomFeeGroupValue {
  currency: Currency;

  roomFeeAmount: number;
  roomFeeWalletId: string;

  otaFeeAmount: number;
  otaFeeWalletId?: string;

  handlingFee: number;
}

export const OrderRoomFeeInput: FC<{
  value?: Partial<OrderRoomFeeGroupValue>;
  onChange?: (val?: Partial<OrderRoomFeeGroupValue>) => void;
  disabled?: boolean;
}> = ({ disabled, ...props }) => {
  const { walletMap } = useWallet();
  const [isEdit, setIsEdit] = useState(false);
  const { currentValue, handleCurrentValueChange, handleMultiValueAssign } =
    usePartialControllable(props, [
      "currency",
      "roomFeeAmount",
      "roomFeeWalletId",
      "otaFeeAmount",
      "handlingFee",
    ]);

  return (
    <div className="flex flex-col gap-y-3">
      <Form.Item
        className="!m-0"
        field="roomFeeGroup"
        rules={[
          {
            validator: (value: any, cb: (error?: string) => void) => {
              if (value.roomFeeAmount < 0) {
                const otherAmount: number =
                  (value.otaFeeAmount || 0) + (value.handlingFee || 0);
                const currency: Currency = value.currency;
                return cb(
                  `订单金额需要大于${getMoneyText(otherAmount, currency, {
                    hidePlusSign: true,
                    hideLabel: true,
                  })}`,
                );
              }
              return cb();
            },
          },
        ]}
      >
        <>
          <MoneyCurrencyInput
            disabled={disabled}
            value={{
              currency: currentValue.currency!,
              amount: getSumAmount(currentValue),
            }}
            beforeInputProps={{
              placeholder: getMoneyText(
                getSumAmount(currentValue),
                currentValue.currency!,
                {
                  hidePlusSign: true,
                  hideLabel: true,
                  hideSymbol: true,
                },
              ),
            }}
            onChange={(t) => {
              const roomFeeAmount =
                (t?.amount || 0) -
                (currentValue.otaFeeAmount || 0) -
                (currentValue.handlingFee || 0);
              handleMultiValueAssign({
                currency: t?.currency,
                roomFeeAmount,
                // roomFeeAmount: roomFeeAmount >= 0 ? roomFeeAmount : 0,
              });
            }}
          />
        </>
      </Form.Item>

      {isEdit ? (
        <>
          <MoneyWalletInput
            size="default"
            disabled={disabled}
            value={{
              amount:
                currentValue.roomFeeAmount! < 0
                  ? undefined!
                  : currentValue.roomFeeAmount!,
              walletId: currentValue.roomFeeWalletId!,
            }}
            currency={currentValue.currency}
            onChange={(t) => {
              handleMultiValueAssign({
                roomFeeAmount: t?.amount || 0,
                roomFeeWalletId: t?.walletId,
              });
            }}
            beforeInputProps={{
              placeholder: getMoneyText(
                currentValue.roomFeeAmount!,
                currentValue.currency!,
                {
                  hidePlusSign: true,
                  hideLabel: true,
                  hideSymbol: true,
                },
              ),
              appendPrefix: `房费 `,
            }}
          />
          <MoneyWalletInput
            size="default"
            disabled={disabled}
            value={{
              amount: currentValue.otaFeeAmount!,
              walletId: currentValue.otaFeeWalletId!,
            }}
            currency={currentValue.currency}
            onChange={(t) => {
              handleMultiValueAssign({
                otaFeeAmount: t?.amount || 0,
                otaFeeWalletId: t?.walletId,
              });
            }}
            beforeInputProps={{
              placeholder: getMoneyText(
                currentValue.otaFeeAmount!,
                currentValue.currency!,
                {
                  hidePlusSign: true,
                  hideLabel: true,
                  hideSymbol: true,
                },
              ),
              appendPrefix: `佣金 `,
            }}
            afterInputProps={{
              placeholder: "不入账",
              allowClear: true,
            }}
          />
          <MoneyInputWithCurrency
            size="default"
            css={`
              input.arco-input {
                padding-left: 4px;
              }
            `}
            appendPrefix={`手续费 `}
            suffix={null}
            disabled={disabled}
            value={currentValue.handlingFee}
            placeholder={getMoneyText(
              currentValue.handlingFee || 0,
              currentValue.currency!,
              {
                hidePlusSign: true,
                hideLabel: true,
                hideSymbol: true,
              },
            )}
            currency={currentValue.currency}
            onChange={(t) => {
              handleCurrentValueChange("handlingFee", t || 0);
            }}
          />
        </>
      ) : (
        <div className="text-color-text-2 flex flex-col gap-y-2">
          <div className="flex items-center justify-between">
            <div>
              房费：
              {currentValue.currency
                ? getMoneyText(
                    currentValue.roomFeeAmount || 0,
                    currentValue.currency,
                    {
                      hidePlusSign: true,
                      hideLabel: true,
                    },
                  )
                : null}
              <span className="ml-3">
                {currentValue.roomFeeWalletId !== undefined
                  ? walletMap[currentValue.roomFeeWalletId]?.name
                  : ""}
              </span>
            </div>
            {permissionHas(ApiPermission.OrderUpdate) && (
              <Link onClick={() => setIsEdit(true)}>明细</Link>
            )}
          </div>
          {Boolean(currentValue.otaFeeAmount) && (
            <div>
              佣金：
              {currentValue.currency
                ? getMoneyText(
                    currentValue.otaFeeAmount || 0,
                    currentValue.currency,
                    {
                      hidePlusSign: true,
                      hideLabel: true,
                    },
                  )
                : null}
              <span className="ml-3">
                {currentValue.otaFeeWalletId !== undefined
                  ? walletMap[currentValue.otaFeeWalletId]?.name
                  : ""}
              </span>
            </div>
          )}

          {Boolean(currentValue.handlingFee) && (
            <div>
              手续费：
              {currentValue.currency
                ? getMoneyText(
                    currentValue.handlingFee || 0,
                    currentValue.currency,
                    {
                      hidePlusSign: true,
                      hideLabel: true,
                    },
                  )
                : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
