import { type ReactElement, type FC, type HTMLAttributes } from "react";
import { type Color } from "@api/http_pms/const/const_enum";
import clsx from "clsx";
import { isNil } from "lodash-es";
import { BaseColor } from "@/common/const";

type EasTagProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "color" | "prefix" | "suffix" | "text"
> & {
  color?: Color;
  suffix?: ReactElement;
  prefix?: ReactElement;
  text: string;
};

export const EasTag: FC<EasTagProps> = ({
  color,
  suffix,
  className,
  prefix,
  text,
  style,
  ...props
}) => (
  <div
    className={clsx(
      "inline-block rounded-lg border px-2 text-xs leading-6",
      className,
    )}
    style={{
      borderColor: !isNil(color)
        ? BaseColor[color].border
        : "var(--color-border-2)",
      backgroundColor: !isNil(color) ? BaseColor[color].bg : "white",
      color: !isNil(color) ? BaseColor[color].code : "var(--color-text-2)",
      ...style,
    }}
    {...props}
  >
    <div className="flex items-center">
      {prefix}
      {text}
      {suffix}
    </div>
  </div>
);
