import { type ListByFilterReq } from "@api/http_pms/order/order_srv";
import dayjs, { type Dayjs } from "dayjs";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { type ListByFilter } from "./interface";
import { initialOrderColumns } from "./openColumnsManager";
import { getCurrentTimeZoneDate, OrderStatusEnum } from "@/utils/orderStatus";
import { permissionHas } from "@/store/useUserInfo";

export const finalQuery = (query: ListByFilter) => {
  const newQuery: ListByFilterReq = {
    ...query,
    keys: Array.from(
      new Set([
        ...query.keys
          .map(
            (key) =>
              initialOrderColumns.find((column) => column.key === key)
                ?.databaseKey || [],
          )
          .flat()
          .filter((key) =>
            permissionHas(ApiPermission.OrderAdvancedView)
              ? true
              : key !== "tagIds" && key !== "remark",
          ),
        "id",
        "orderSourceKey",
      ]),
    ),
    bookAt: query.bookAt
      ? {
          start: dayjs.dateStr(query.bookAt[0]!).unixStr(),
          end: dayjs.dateStr(query.bookAt[1]!).endOf("day").unixStr(),
        }
      : undefined,
    dateStart: query.dateStart
      ? {
          start: query.dateStart[0],
          end: query.dateStart[1],
        }
      : undefined,
    dateEnd: query.dateEnd
      ? {
          start: query.dateEnd[0],
          end: query.dateEnd[1],
        }
      : undefined,
    cancelledAt: undefined,
  };
  // newQuery.cancelledAt = { empty: true };
  if (query.orderStatus !== undefined) {
    const nowData = getCurrentTimeZoneDate();
    // 取消
    if (query.orderStatus === OrderStatusEnum.Cancelled) {
      if (query.cancelledAt) {
        newQuery.cancelledAt = {
          start: dayjs.dateStr(query.cancelledAt[0]!).unixStr(),
          end: dayjs.dateStr(query.cancelledAt[1]!).endOf("day").unixStr(),
        };
      } else {
        newQuery.cancelledAt = { whole: true };
      }
    }
    // 七天内入住
    if (query.orderStatus === OrderStatusEnum.SevenDayDuringCheckIn) {
      if (query.dateStart) {
        newQuery.dateStart = getDateIntersection(
          [nowData.dateStr(), query.dateStart[0]!],
          [nowData.add(6, "day").dateStr(), query.dateStart[1]!],
        );
      } else {
        newQuery.dateStart = {
          start: nowData.dateStr(),
          end: nowData.add(6, "day").dateStr(),
        };
      }
    }
    // 七天后入住
    if (query.orderStatus === OrderStatusEnum.SevenDayAfterCheckIn) {
      if (query.dateStart) {
        newQuery.dateStart = getDateIntersection(
          [nowData.add(7, "day").dateStr(), query.dateStart[0]!],
          [query.dateStart[1]!],
        );
      } else {
        newQuery.dateStart = { start: nowData.add(7, "day").dateStr() };
      }
    }
    // 今日入住
    if (query.orderStatus === OrderStatusEnum.CheckInToday) {
      if (query.dateStart) {
        newQuery.dateStart = getDateIntersection(
          [nowData.dateStr(), query.dateStart[0]!],
          [nowData.dateStr(), query.dateStart[1]!],
        );
      } else {
        newQuery.dateStart = {
          start: nowData.dateStr(),
          end: nowData.dateStr(),
        };
      }
    }
    // 入住中
    if (query.orderStatus === OrderStatusEnum.CheckIn) {
      if (query.dateStart || query.dateEnd) {
        if (query.dateStart) {
          newQuery.dateStart = getDateIntersection(
            [query.dateStart[0]!],
            [query.dateStart[1]!, nowData.dateStr()],
          );
        }
        if (query.dateEnd) {
          newQuery.dateEnd = getDateIntersection(
            [query.dateEnd[0]!, nowData.add(1, "day").dateStr()],
            [query.dateEnd[1]!],
          );
        }
      } else {
        newQuery.dateStart = { end: nowData.dateStr() };
        newQuery.dateEnd = { start: nowData.add(1, "day").dateStr() };
      }
    }
    // 今日退房
    if (query.orderStatus === OrderStatusEnum.CheckOutToday) {
      if (query.dateEnd) {
        newQuery.dateEnd = getDateIntersection(
          [query.dateEnd[0]!, nowData.dateStr()],
          [query.dateEnd[1]!, nowData.dateStr()],
        );
      } else {
        newQuery.dateEnd = { start: nowData.dateStr(), end: nowData.dateStr() };
      }
    }
    // 已退房
    if (query.orderStatus === OrderStatusEnum.CheckedOut) {
      if (query.dateEnd) {
        newQuery.dateEnd = getDateIntersection(
          [query.dateEnd[0]!],
          [query.dateEnd[1]!, nowData.subtract(1, "day").dateStr()],
        );
      } else {
        // 减去一天
        newQuery.dateEnd = { end: nowData.subtract(1, "day").dateStr() };
      }
    }
  }

  return newQuery;
};

export const getDateIntersection = (
  startDates: string[],
  endDates: string[],
) => {
  let startDate: Dayjs | undefined;
  let endDate: Dayjs | undefined;
  startDates.forEach((item) => {
    if (startDate) {
      const itemDate = dayjs.dateStr(item);
      if (itemDate.isAfter(startDate)) {
        startDate = itemDate;
      }
    } else {
      startDate = dayjs.dateStr(item);
    }
  });
  endDates.forEach((item) => {
    if (endDate) {
      const itemDate = dayjs.dateStr(item);
      if (itemDate.isBefore(endDate)) {
        endDate = itemDate;
      }
    } else {
      endDate = dayjs.dateStr(item);
    }
  });
  return startDate?.isAfter(endDate)
    ? { empty: true }
    : { start: startDate?.dateStr(), end: endDate?.dateStr() };
};

export const getSumAmount = ({
  roomFee,
  otaFee,
  handlingFee,
}: {
  roomFee?: number;
  otaFee?: number;
  handlingFee?: number;
}) => (roomFee || 0) + (otaFee || 0) + (handlingFee || 0);
