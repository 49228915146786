import { useEffect, useRef } from "react";

export const useInitialValue = <T>(
  value: T | undefined,
  onChange: ((v: T) => void) | undefined,
  initialValue: T | (() => T),
): T => {
  const initialValueRef = useRef<T>();
  if (value === undefined && initialValueRef.current === undefined) {
    initialValueRef.current =
      initialValue instanceof Function ? initialValue() : initialValue;
  }
  useEffect(() => {
    if (value === undefined && initialValueRef.current !== undefined) {
      onChange?.(initialValueRef.current);
    }
  }, []);

  return value === undefined ? initialValueRef.current! : value;
};
