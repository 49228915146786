import { OrderSourceConfigService } from "@api/http_pms/order_source_config/order_source_config_srv";
import { type OrderSourceConfig } from "@api/http_pms/order_source_config/order_source_config_type";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Message,
  Modal,
} from "@arco-design/web-react";
import { type OtaPlatformKind as ApiOtaPlatformKind } from "@api/http_pms/const/const_enum";
import { IconKind } from "@/common/const";
import { DeletePopconfirm } from "@/comps/DeletePopconfirm";
import { OtaPlatformSelector } from "@/common/Inputer/OtaPlatformSelector";
import { AsyncButton } from "@/comps/AsyncButton";
import { createNiceModal } from "@/utils/nicemodel";
import { required } from "@/utils/validator";

export const openOrderSourceCreator = createNiceModal<
  {
    config?: OrderSourceConfig;
    afterSubmit?: (config?: OrderSourceConfig) => Promise<any>; // config存在是新建或编辑，config不存在是删除
    limitOtaPlatformKinds?: Set<ApiOtaPlatformKind>;
  }, // 调用的入参参数
  {} // 调用的出参参数
>(({ _modal, afterSubmit, config, limitOtaPlatformKinds }) => {
  const isAdd = !config?.id;
  const [form] = Form.useForm();

  return (
    <Modal
      title={`${isAdd ? "新建" : "编辑"}自定义渠道`}
      {..._modal.props} // 需要解构给Modal组件
      className="w-[360px]"
      cancelText="删除"
      footer={
        <div className="flex w-full items-center justify-end">
          {!isAdd && (
            <DeletePopconfirm
              title={`确定删除${config.name}渠道吗？`}
              onOk={async () => {
                await OrderSourceConfigService.Del({ id: config.id });
                await afterSubmit?.();
                Message.success("删除成功");
                _modal.resolve({});
              }}
            >
              <Button type="outline" status="danger" className="mr-2">
                删除
              </Button>
            </DeletePopconfirm>
          )}
          <AsyncButton
            type="primary"
            onClick={async () => {
              const values = await form.validate();
              if (isAdd) {
                const res = await OrderSourceConfigService.Save({
                  config: {
                    id: "",
                    name: values.name,
                    otaPlatformKind: values.otaPlatformKind,
                    enableOrderSourceKeyCheck: values.enableOrderSourceKeyCheck,
                    rank: 0,
                  },
                });
                Message.success("新建成功");
                await afterSubmit?.(res.config);
              } else {
                const res = await OrderSourceConfigService.Save({
                  config: {
                    id: config.id,
                    name: values.name,
                    otaPlatformKind: values.otaPlatformKind,
                    enableOrderSourceKeyCheck: values.enableOrderSourceKeyCheck,
                    rank: config.rank,
                  },
                });
                Message.success("编辑成功");
                await afterSubmit?.(res.config);
              }
              _modal.resolve({});
            }}
          >
            保存
          </AsyncButton>
        </div>
      }
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 17 }}
        initialValues={config || { iconKind: IconKind[0].value }}
        size="large"
      >
        <Form.Item field="name" label="渠道名称" rules={[required("渠道名称")]}>
          <Input placeholder="请输入渠道名称" />
        </Form.Item>
        <Form.Item field="otaPlatformKind" label="OTA类型" className="!mb-0">
          <OtaPlatformSelector limitOtaPlatformKinds={limitOtaPlatformKinds} />
        </Form.Item>
        <Form.Item
          field="enableOrderSourceKeyCheck"
          wrapperCol={{ offset: 6 }}
          className="!mb-0"
          triggerPropName="checked"
        >
          <Checkbox>
            <span className="text-color-text-2">开启渠道编号漏填校验</span>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
