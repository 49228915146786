import { Divider, Link, Popconfirm, Tooltip } from "@arco-design/web-react";

import { useRef, useState, type FC } from "react";
import { OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import dayjs from "dayjs";
import { useSize } from "ahooks";
import { IconRight } from "@arco-design/web-react/icon";
import clsx from "clsx";
import { UserAddDelete } from "./LogCard/UserAddDelete";
import { UserChange } from "./LogCard/UserChange";
import { orderShowRule } from "./LogCard/showRule";
import { BillChange } from "./LogCard/BillChange";
import { InfoChange } from "./LogCard/InfoChange";
import { TagChange } from "./LogCard/TagChange";
import { Avatar } from "@/comps/Avatar";
import { getUserName } from "@/utils/getUserName";
import { getMessageContent } from "./LogList";

interface ItemShowProps {
  item: OperateLog;
  onEdit: () => void;
  onRemove: () => void;
}

const operateKindText = {
  [OperateLog.OperateKind.Create]: "添加",
  [OperateLog.OperateKind.Delete]: "移除",
  [OperateLog.OperateKind.Update]: "修改",
  [OperateLog.OperateKind.Cancel]: "取消",
};
const operateEntityText: Record<OperateLog.Kind, string | undefined> = {
  [OperateLog.Kind.Message]: "留言",
  [OperateLog.Kind.OrderLog]: "订单",
  [OperateLog.Kind.TenantLog]: "房客",
  [OperateLog.Kind.TagLog]: "标签",
  [OperateLog.Kind.BillLog]: "帐单",
};

export const LogItemShow: FC<ItemShowProps> = ({ item, onEdit, onRemove }) => {
  const elementRef = useRef(null);
  const size = useSize(elementRef);
  const [expanded, setExpanded] = useState(false);

  const creatorName = getUserName(item.creatorId, item.creatorName);

  if (item.kind === OperateLog.Kind.Message && item.revokeAt) {
    return (
      <div className="text-color-text-3 my-2 text-center">
        用户{creatorName} 在{dayjs.unixStr(item.revokeAt).dateStr(true)}
        撤回了一条留言信息
      </div>
    );
  }
  return (
    <div className="bg-color-fill-1 h-full justify-between rounded-xl px-4 pb-2 pt-3">
      <div className="flex w-full items-center justify-between">
        <div className="text-color-text-1 flex items-center">
          <Avatar className="size-5 text-xs" name={creatorName} />
          <div className="ml-2">{creatorName}</div>
          {item.editAt && (
            <Tooltip
              content={dayjs.unixStr(item.editAt).dateStr()}
              position="right"
            >
              <div className="text-color-text-3 ml-2 cursor-pointer">
                已编辑
              </div>
            </Tooltip>
          )}
          {item.kind !== OperateLog.Kind.Message && (
            <div className="text-color-text-3">
              &nbsp;·&nbsp;
              {operateKindText[item.operateKind]}
              {operateEntityText[item.kind]}
            </div>
          )}
        </div>
        {item.kind === OperateLog.Kind.Message &&
          dayjs().isSame(dayjs.unixStr(item.createdAt), "day") && (
            <div>
              <Link
                onClick={() => {
                  onEdit();
                }}
              >
                编辑
              </Link>
              <Popconfirm
                onOk={() => {
                  onRemove();
                }}
                title="确认撤回此留言？"
              >
                <Link>撤回</Link>
              </Popconfirm>
            </div>
          )}
      </div>

      <div
        className={clsx("overflow-hidden", {
          "max-h-56": !expanded,
          "max-h-none": expanded,
        })}
        ref={elementRef}
      >
        {/* 留言 */}
        {item.kind === OperateLog.Kind.Message && (
          <div className="mt-4">{getMessageContent(item.fieldContents)}</div>
        )}
        {/* 订单创建 */}
        {item.kind === OperateLog.Kind.OrderLog &&
          item.operateKind === OperateLog.OperateKind.Create && (
            <div className="mt-4">创建了一条新订单</div>
          )}
        {/* 订单取消 */}
        {item.kind === OperateLog.Kind.OrderLog &&
          item.operateKind === OperateLog.OperateKind.Cancel && (
            <div className="mt-4">取消了一条订单</div>
          )}
        {/* 订单基本信息修改 */}
        {item.kind === OperateLog.Kind.OrderLog &&
          item.operateKind === OperateLog.OperateKind.Update && (
            <div className="mt-4 flex flex-col gap-1">
              <InfoChange
                fieldContents={item.fieldContents}
                showRules={orderShowRule}
              />
            </div>
          )}
        {/* 系统日志-标签信息修改 */}
        {item.kind === OperateLog.Kind.TagLog && (
          <div className="mt-4 flex flex-col gap-1">
            <TagChange fieldContents={item.fieldContents} />
          </div>
        )}
        {/* 系统日志-房客信息新增删除 */}
        {item.kind === OperateLog.Kind.TenantLog &&
          (item.operateKind === OperateLog.OperateKind.Create ||
            item.operateKind === OperateLog.OperateKind.Delete) && (
            <div className="mt-4 flex flex-col gap-1">
              <UserAddDelete
                list={item.fieldContents}
                isDelete={item.operateKind === OperateLog.OperateKind.Delete}
              />
            </div>
          )}
        {/* 房客信息修改 */}
        {item.kind === OperateLog.Kind.TenantLog &&
          item.operateKind === OperateLog.OperateKind.Update && (
            <UserChange list={item.fieldContents} />
          )}
        {/* ##账单信息 */}
        {/* 账单创建 */}
        {item.kind === OperateLog.Kind.BillLog &&
          item.operateKind === OperateLog.OperateKind.Create && (
            <div className="mt-4">创建了一条新账单</div>
          )}
        {/* 账单修改 */}
        {item.kind === OperateLog.Kind.BillLog &&
          item.operateKind === OperateLog.OperateKind.Update && (
            <BillChange list={item.fieldContents} />
          )}
      </div>
      {(size?.height || 0) >= 224 && (
        <div
          className="text-color-primary-6 mt-3 flex w-full cursor-pointer items-center justify-center"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? "收起" : "展开"}
          <IconRight
            className={clsx("w-5 cursor-pointer", {
              "rotate-90": !expanded,
              "-rotate-90": expanded,
            })}
          />
        </div>
      )}

      <Divider className="mb-2 mt-3" />
      <div className="text-color-text-3 flex justify-between">
        <div>{dayjs.unixStr(item.createdAt).dateStr(true, true)}</div>
        <div>
          {item.location}・{item.device}
        </div>
      </div>
    </div>
  );
};
