import { useMemoizedFn } from "ahooks";
import { isNumber } from "lodash-es";
import { useCallback, useEffect, useState } from "react";

export const useInterval = <T extends unknown[]>(
  fn: (...args: T) => void,
  delay?: number,
  /** 使用此参数自动开始运行 */
  autoStartArgs?: T,
): { start: (...args: T) => void; end: () => void } => {
  const timerCallback = useMemoizedFn(fn);

  const [runArgs, setRunArgs] = useState<T | undefined>(autoStartArgs);

  useEffect(() => {
    if (runArgs && isNumber(delay) && delay > 0) {
      const cb = () => {
        timerCallback(...runArgs);
      };
      cb();
      const timer = setInterval(cb, delay);
      return () => {
        clearInterval(timer);
      };
    }
  }, [delay, runArgs]);

  const start = useCallback((...args: T) => {
    setRunArgs(args);
  }, []);
  const end = useCallback(() => {
    setRunArgs(undefined);
  }, []);

  return { start, end };
};
