/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import {
  type Area,
  type Currency,
  type Language,
  type OtaPlatformKind,
} from "../const/const_enum";

export namespace SystemService {
  // 获取系统信息
  export function GetInfo(req: GetInfoReq, config?: any) {
    return webapi.post<GetInfoResp>(
      "/api/v1/SystemService_GetInfo",
      req,
      config,
    );
  }
}
export interface GetInfoReq {}

// This the module of GetInfoResp
export namespace GetInfoResp {
  export interface Version {
    commit: string;
    time: string;
  }

  export interface AreaConfig {
    area: Area;
    currency: Currency;
    timeZone: string;
    language: Language;
    otaPlatforms: OtaPlatformKind[];
  }
}
export interface GetInfoResp {
  version: GetInfoResp.Version;
  areaConfigs: GetInfoResp.AreaConfig[];
}
