import { Grid, Progress, Rate } from "@arco-design/web-react";
import { IconDown } from "@arco-design/web-react/icon";
import dayjs from "dayjs";
import { useState, type FC } from "react";
import { type ReviewContent } from "@api/http_pms/ota_review/ota_review_type";
import { type OtaPlatformKind as ApiOtaPlatformKind } from "@api/http_pms/const/const_enum";
import { CategoryKind, CategoryTagKind } from "@/pages/Review/const";
import { OtaPlatformKind } from "@/common/const";
import { OverflowTooltip } from "@/comps/OverflowTooltip";

const Row = Grid.Row;
const Col = Grid.Col;

export const GuestReviewCard: FC<{
  review: ReviewContent;
  otaPlatformKind: ApiOtaPlatformKind;
}> = ({ review, otaPlatformKind }) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div className="bg-color-fill-1 mt-2 rounded-xl p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={OtaPlatformKind[otaPlatformKind].iconUrlSquare}
            className="size-6 rounded-full"
          />
          <span className="ml-2 font-medium">{review.reviewerName}</span>
          <span className="text-color-text-3 ml-3">
            {review.reviewedAt &&
              dayjs.unixStr(review.reviewedAt).dateStr(true)}
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-color-text-2 text-xs">综合评分</span>
          <span className="ml-1 font-medium leading-none">
            {review.overallRating}
          </span>
          <Rate
            className="ml-2 text-sm"
            value={review.overallRating}
            readonly={true}
            css={`
              .arco-icon {
                width: 15px;
                height: 15px;
              }
              .arco-rate-character:not(:last-child) {
                margin-right: 4px;
              }
              .arco-rate-inner {
                min-height: 0px;
              }
            `}
          />
        </div>
      </div>
      <div className="mb-3 mt-4 font-medium">指标打分</div>
      <div className="mt-2 w-full">
        <Row gutter={[18, 14]}>
          {review.categoryRatings.map((categoryRating, index) => (
            <Col key={index} span={12}>
              <div className="w-full">
                <div className="flex items-center">
                  <div className="text-color-text-2 shrink-0">
                    {CategoryKind[categoryRating.categoryKind].label}
                  </div>
                  <div className="mx-2 font-medium">
                    {categoryRating.rating}
                  </div>
                  <Progress
                    percent={(categoryRating.rating / 5) * 100}
                    showText={false}
                  />
                </div>
                {isExpand && (
                  <div className="bg-color-fill-2 mt-1 rounded-lg px-2 pb-1">
                    <OverflowTooltip line={2}>
                      {categoryRating.categoryTags.map((categoryTag) => (
                        <span
                          key={categoryTag}
                          css={`
                            &:before {
                              content: "";
                              width: 4px;
                              height: 4px;
                              border-radius: 50%;
                              background-color: rgb(var(--primary-6));
                              display: inline-block;
                              margin-bottom: 2px;
                              margin-right: 8px;
                            }
                          `}
                          className="mr-6 mt-1 inline-block"
                        >
                          {CategoryTagKind[categoryTag].label !== "其他"
                            ? CategoryTagKind[categoryTag].label
                            : categoryRating.comment}
                        </span>
                      ))}
                    </OverflowTooltip>
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {isExpand && (
        <>
          {review.publicContent && (
            <>
              <div className="mb-2 mt-4 font-medium">公开评价</div>
              <div className="text-color-text-2 whitespace-pre-line">
                {review.publicContent}
              </div>
            </>
          )}
          {review.respondContent && (
            <div className="bg-color-primary-1 mt-2 rounded-lg p-2">
              <span className="text-color-primary-6">房东：</span>
              {review.respondContent}
            </div>
          )}
          {review.privateContent && (
            <>
              <div className="mb-2 mt-4 font-medium">私信评价</div>
              <div className="text-color-text-2 whitespace-pre-line">
                {review.privateContent}
              </div>
            </>
          )}
        </>
      )}

      <div className="text-color-primary-6 mt-3 text-center">
        {isExpand ? (
          <span
            className="cursor-pointer"
            onClick={() => setIsExpand(!isExpand)}
          >
            收起 <IconDown className="ml-2 rotate-180" />
          </span>
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => setIsExpand(!isExpand)}
          >
            查看评价详情 <IconDown className="ml-2" />
          </span>
        )}
      </div>
    </div>
  );
};
