import { type Tenant } from "@api/http_pms/tenant/tenant_type";
import { Grid } from "@arco-design/web-react";
import { isEmpty } from "lodash-es";
import { type FC } from "react";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { PictureListShow } from "@/comps/PictureListShow";
import { Nationality } from "@/common/const/nationality";
import { Sex } from "@/common/const";
import { permissionHas } from "@/store/useUserInfo";

const { Row } = Grid;
const { Col } = Grid;

export const Displayer: FC<{
  value?: Tenant;
  index: number;
  onEdit: () => void;
}> = ({ value, index, onEdit }) => {
  const name = !value?.name?.trim() ? null : (
    <div className="mr-1 text-base font-medium text-gray-800">
      <span className="mr-1">{value.name.trim()}</span>
      <span>{Sex[value?.sex]?.label}</span>
    </div>
  );

  return (
    <>
      <div className="mb-1 flex items-center justify-between">
        <div>{`房客${index + 1} ${index === 0 ? "(代表人)" : ""}`}</div>
      </div>
      <div
        className="bg-color-fill-1 mb-3 flex cursor-pointer overflow-hidden rounded-lg"
        onClick={() => {
          if (permissionHas(ApiPermission.OrderUpdate)) {
            onEdit();
          }
        }}
      >
        <div className="bg-color-fill-1 hover:bg-color-fill-2 flex-auto select-text p-3">
          <div>{name}</div>
          <Row gutter={[0, 6]} className="!mt-1">
            {(value?.phoneNumber || value?.countryCode) && (
              <Col span={12} className="flex">
                联系方式：
                <div className="flex gap-1">
                  <span>{value?.countryCode}</span>
                  <span>{value?.phoneNumber}</span>
                </div>
              </Col>
            )}

            {value?.email && (
              <Col span={12}>
                邮箱地址：
                {value?.email}
              </Col>
            )}

            {Boolean(value?.nationality) && (
              <Col span={12}>
                国籍/地域：
                {Nationality[value!.nationality].label}
              </Col>
            )}

            {value?.birthday && (
              <Col span={12}>
                出生日期：
                {value?.birthday}
              </Col>
            )}

            {value?.profession && (
              <Col span={12}>
                工作/职业：
                {value?.profession}
              </Col>
            )}

            {value?.address?.trim() && (
              <Col span={12} className="flex">
                <div className="shrink-0">居住地址：</div>
                <div>{value.address.trim()}</div>
              </Col>
            )}

            {!isEmpty(value?.images) && (
              <Col span={24} className="flex">
                <div className="shrink-0">附件：</div>
                <PictureListShow
                  urls={value?.images.map((item) => item.uri) || []}
                />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};
