import { Button, type ButtonProps } from "@arco-design/web-react";
import { forwardRef, useState } from "react";
import clsx from "clsx";

export const AsyncButton = forwardRef(
  (
    {
      type,
      onClick,
      ...otherProps
    }: Omit<ButtonProps, "type"> & {
      type?: ButtonProps["type"] | "link";
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    const handleClick = async (e: Event) => {
      setLoading(true);
      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await onClick?.(e);
      } finally {
        setLoading(false);
      }
    };

    const btnType = type === "link" ? "text" : type;
    if (type === "link") {
      otherProps.size = otherProps.size || "small";
      otherProps.className = clsx(otherProps.className, "px-1");
    }

    return (
      <Button
        ref={ref}
        onClick={handleClick}
        loading={loading}
        type={btnType}
        {...otherProps}
      />
    );
  },
);
