import { type BillKind as ApiBillKind } from "@api/http_pms/bill/bill_type";
import { type Currency as ApiCurrency } from "@api/http_pms/const/const_enum";
import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import {
  type Nationality as ApiNationality,
  type Tenant,
} from "@api/http_pms/tenant/tenant_type";
import dayjs from "dayjs";
import { type CheckInKind as ApiCheckInKind } from "@api/http_pms/order/order_type";
import { getMoneyText } from "@/utils/money";
import { BillKind, CheckInKind, Currency, Sex } from "@/common/const";
import { genTimeOptions } from "@/common/Inputer/TimeSelector";
import { Nationality } from "@/common/const/nationality";
import { useOrderSource } from "@/store/useOrderSource";

export interface ShowRuleItem {
  key: string;
  title: string;
  render?: (
    col?: string,
    rows?: OperateLog.FieldContent[],
    type?: "before" | "after",
  ) => any;
}

const getOrderAmount = (
  col?: string,
  row?: OperateLog.FieldContent[],
  type?: "before" | "after",
) => {
  if (type) {
    const currencyItem = row?.find((item) => item.field === "RoomFeeCurrency");
    const currency = Number(
      currencyItem?.[type] || currencyItem?.current,
    ) as ApiCurrency;
    if (!currency) {
      return "【币种异常】";
    }
    return getMoneyText(Number(col), currency, {
      hidePlusSign: true,
    });
  }
};

export const orderShowRule: ShowRuleItem[] = [
  { key: "RoomId", title: "订单房间" },
  { key: "DateStart", title: "入住日期" },
  { key: "DateEnd", title: "退房日期" },
  {
    key: "CheckInTime",
    title: "入住时间",
    render: (col) =>
      genTimeOptions().find((option) => option.value === Number(col))?.label,
  },
  {
    key: "CheckOutTime",
    title: "退房时间",
    render: (col) =>
      genTimeOptions().find((option) => option.value === Number(col))?.label,
  },
  { key: "TenantCount", title: "房客人数" },
  {
    key: "OrderSourceId",
    title: "渠道类型",
    render: (col) =>
      col ? useOrderSource.getState().orderSourceMapId[col]?.name : "",
  },
  { key: "OrderSourceKey", title: "来源key" },
  {
    key: "BookAt",
    title: "订房日期",
    render: (col) => (col ? dayjs.unixStr(col).dateStr() : ""),
  },
  {
    key: "OrderAmount",
    title: "订单金额",
    render: (col, row, type) => getOrderAmount(col, row, type),
  },
  {
    key: "RoomFee",
    title: "房费",
    render: (col, row, type) => getOrderAmount(col, row, type),
  },
  {
    key: "RoomFeeWalletId",
    title: "房费钱包",
    render: (col) => col || "默认钱包",
  },
  {
    key: "OtaFee",
    title: "佣金",
    render: (col, row, type) => getOrderAmount(col, row, type),
  },
  {
    key: "OtaFeeWalletId",
    title: "佣金钱包",
    render: (col) => col || "默认钱包",
  },
  {
    key: "OtaFeeWalletExist",
    title: "佣金入账",
    render: (col) => (col === "true" ? "入账" : "不入账"),
  },
  {
    key: "HandlingFee",
    title: "手续费",
    render: (col, row, type) => getOrderAmount(col, row, type),
  },
  {
    key: "RoomFeeCurrency",
    title: "订单币种",
    render: (col) => Currency[Number(col) as ApiCurrency].label,
  },
  {
    key: "OtaFeeCurrency",
    title: "佣金币种",
    render: (col) => Currency[Number(col) as ApiCurrency].label,
  },
  {
    key: "Remark",
    title: "备注",
  },
  {
    key: "CheckInKind",
    title: "在线登记",
    render: (col) => CheckInKind[Number(col) as ApiCheckInKind].label,
  },
];

export const tagShowRule: ShowRuleItem[] = [
  {
    key: "AddTags",
    title: "新增标签",
  },
  {
    key: "DeleteTags",
    title: "删除标签",
  },
];

export const tenantShowRule: ShowRuleItem[] = [
  {
    key: "Name",
    title: "房客姓名",
  },
  {
    key: "Sex",
    title: "性别",
    render: (col) => Sex[Number(col) as Tenant.Sex].label,
  },
  {
    key: "CountryCode",
    title: "联系区号",
  },
  {
    key: "PhoneNumber",
    title: "联系电话",
  },
  {
    key: "Email",
    title: "邮箱地址",
  },
  {
    key: "Nationality",
    title: "国籍/地域",
    render: (col) => Nationality[Number(col) as ApiNationality]?.label,
  },
  {
    key: "Birthday",
    title: "出生日期",
  },
  {
    key: "Profession",
    title: "工作/职业",
  },

  {
    key: "Address",
    title: "居住地址",
  },
];

export const billShowRule: ShowRuleItem[] = [
  {
    key: "BillKind",
    title: "账单类型",
    render: (col) => BillKind[Number(col) as ApiBillKind]?.label,
  },
  {
    key: "Amount",
    title: "金额",
    render: (col, row, type) => {
      if (type) {
        const currencyItem = row?.find((item) => item.field === "Currency");
        return getMoneyText(
          Number(col),
          Number(currencyItem?.[type] || currencyItem?.current) as ApiCurrency,
          {
            hidePlusSign: true,
            hide00Dec: true,
          },
        );
      }
    },
  },
  {
    key: "Currency",
    title: "币种",
    render: (col) => Currency[Number(col) as ApiCurrency].label,
  },
  {
    key: "WalletId",
    title: "钱包",
    render: (col) => col || "默认钱包",
  },
  {
    key: "ConfirmAt",
    title: "确认时间",
    render: (col) => (col ? dayjs.unixStr(col).dateStr(true) : ""),
  },
  // {
  //   key: "SettleMonth",
  //   title: "所属月报",
  // },
  {
    key: "ReceiptDate",
    title: "发生日期",
    render: (col) => (col ? dayjs(col).dateStr(false) : ""),
  },
  {
    key: "PostingDate",
    title: "入账日期",
    render: (col) => (col ? dayjs(col).dateStr(false) : ""),
  },
  {
    key: "Remark",
    title: "备注",
  },
];
