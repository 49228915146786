import { Alert, Button, Checkbox, Link, Modal } from "@arco-design/web-react";
import { IconDragDotVertical } from "@arco-design/web-react/icon";
import { useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { styled } from "styled-components";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { type ListByFilter } from "./interface";
import { permissionHas } from "@/store/useUserInfo";
import { createNiceModal } from "@/utils/nicemodel";

export const initialOrderColumns: Column[] = [
  {
    key: "id",
    databaseKey: "id",
    label: "订单ID",
    isChecked: true,
    canChecked: false,
    canSortable: false,
  },
  {
    key: "roomName",
    databaseKey: "roomName",
    label: "房间",
    isChecked: true,
    canChecked: false,
    canSortable: false,
  },
  {
    key: "aliveStatus",
    databaseKey: "aliveStatus",
    label: "订单状态",
    isChecked: true,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "tagIds",
    databaseKey: "tagIds",
    label: "标签",
    isChecked: true,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "dateStart",
    databaseKey: ["dateStart", "dateEnd"],
    label: "入离日期",
    isChecked: true,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "orderAmount",
    databaseKey: "orderAmount",
    label: "订单金额",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "roomFee",
    databaseKey: "roomFee",
    label: "房费",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "otaFee",
    databaseKey: "otaFee",
    label: "佣金",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "handlingFee",
    databaseKey: "handlingFee",
    label: "手续费",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "tenant",
    databaseKey: "tenant",
    label: "房客姓名",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  // TODO 后面做到 OTA 能力的时候，再加上这个字段
  // {
  //   key: "otaPlatformKind",
  //   databaseKey: "otaPlatformKind",
  //   label: "OTA来源",
  //   isChecked: false,
  //   canChecked: true,
  //   canSortable: true,
  // },
  {
    key: "orderSourceId",
    databaseKey: "orderSourceId",
    label: "渠道来源",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  {
    key: "bookAt",
    databaseKey: "bookAt",
    label: "预定时间",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
  // {
  //   key: "actualReceivedFee",
  //   databaseKey: "actualReceivedFee",
  //   label: "实收金额",
  //   isChecked: false,
  //   canChecked: true,
  // },
  {
    key: "remark",
    databaseKey: "remark",
    label: "备注",
    isChecked: false,
    canChecked: true,
    canSortable: true,
  },
];

export const orderAdvancedViewUniqueFields = new Set([
  "tagIds",
  "remark",
  "orderAmount",
  "otaFee",
  "handlingFee",
]);

export interface Column {
  key: string;
  databaseKey: string | string[]; // 后端实际需要字段
  label: string;
  isChecked: boolean; // 是否显示
  canChecked: boolean; // 用于默认字段不能选择是否显示
  canSortable: boolean; // 是否可以拖动
}

const getColumnsData = (queryColumnKeys: string[]) => {
  // 选中的
  const sortColumns = queryColumnKeys
    .map((key) => ({
      ...initialOrderColumns.find((column) => column.key === key),
      isChecked: true,
    }))
    .filter((c) => c.key);
  // 未选中的
  const notSortColumns = initialOrderColumns
    .filter((column) => !queryColumnKeys.includes(column.key))
    .map((c) => ({ ...c, isChecked: false }));

  return ([...sortColumns, ...notSortColumns] as Column[]).filter((item) =>
    permissionHas(ApiPermission.OrderAdvancedView)
      ? true
      : !orderAdvancedViewUniqueFields.has(item.key),
  );
};

// url 默认带列参数，使用url，不带，使用本地的
// url 带列参数，url覆盖本地

export const openColumnsManager = createNiceModal<
  {
    queryColumnKeys: string[];
    assignQuery: (newQuery: Partial<ListByFilter>) => void;
  }, // 调用的入参参数
  {} // 调用的出参参数
>(({ _modal, queryColumnKeys, assignQuery }) => {
  const [columns, setColumns] = useState<Column[]>(() =>
    getColumnsData(queryColumnKeys),
  );

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setColumns(arrayMove(columns, oldIndex, newIndex));
  };

  const onChangeIndexItem = (val: Column, index: number) => {
    setColumns((_columns) => {
      const newColumns = [..._columns];
      newColumns.splice(index, 1, val);
      return newColumns;
    });
  };

  return (
    <Modal
      title="列表配置"
      {..._modal.props} // 需要解构给Modal组件
      onOk={() => {}}
      className="w-[740px] pt-2"
      css={`
        .arco-modal-content {
          padding-top: 16px;
        }
      `}
      footer={
        <div className="flex items-center justify-between">
          <Link
            onClick={() => {
              setColumns(initialOrderColumns);
            }}
          >
            恢复默认
          </Link>
          <div>
            <Button
              className="mr-4"
              type="outline"
              onClick={() => _modal.resolve({})}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                localStorage.setItem("orderColumns_2", JSON.stringify(columns));
                assignQuery({
                  keys: columns
                    .filter((column) => column.isChecked)
                    .map((column) => column.key),
                });
                _modal.resolve({});
              }}
            >
              确定
            </Button>
          </div>
        </div>
      }
    >
      <Alert content="选择后可拖动直接排序" className="mb-4" />
      <SortableListContainer
        axis="xy"
        onSortEnd={onSortEnd}
        useDragHandle={true}
        columns={columns}
        onChangeIndexItem={onChangeIndexItem}
        helperClass="dragging-helper-class"
      />
    </Modal>
  );
});

const SortableListContainer = SortableContainer<{
  columns: Column[];
  onChangeIndexItem: (val: Column, index: number) => void;
}>(
  ({
    columns,
    onChangeIndexItem,
  }: {
    columns: Column[];
    onChangeIndexItem: (val: Column, index: number) => void;
  }) => (
    <div className="mt-4 flex flex-wrap gap-4 overflow-y-auto">
      {columns.map((item, index) => {
        if (item.canSortable) {
          return (
            <SortableItem
              key={item.key}
              index={index}
              item={item}
              onChangeItem={(val) => onChangeIndexItem(val, index)}
            />
          );
        }
        return (
          <Item
            key={item.key}
            item={item}
            onChangeItem={(val) => onChangeIndexItem(val, index)}
          />
        );
      })}
    </div>
  ),
);

const SortableItem = SortableElement<{
  item: Column;
  onChangeItem: (val: Column) => void;
}>(
  ({
    item,
    onChangeItem,
  }: {
    item: Column;
    onChangeItem: (val: Column) => void;
  }) => <Item item={item} onChangeItem={onChangeItem} />,
);

const Item = ({
  item,
  onChangeItem,
}: {
  item: Column;
  onChangeItem: (val: Column) => void;
}) => (
  <StyledItem
    className="z-[2000] flex h-10 w-[163px] items-center justify-between rounded-lg border bg-white px-2"
    css={`
      &:hover {
        .drag-handle {
          display: block;
        }
      }
    `}
  >
    <Checkbox
      checked={item.isChecked}
      onChange={(val) => onChangeItem({ ...item, isChecked: val })}
      disabled={!item.canChecked}
      css={`
        .arco-checkbox-text {
          color: var(--color-text-1);
        }
      `}
    >
      {item.label}
    </Checkbox>
    {item.canSortable && <DragHandle />}
  </StyledItem>
);

const StyledItem = styled.div`
  &.dragging-helper-class {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    .drag-handle {
      display: block;
    }
  }
`;

const DragHandle = SortableHandle(() => (
  <IconDragDotVertical className="drag-handle hidden cursor-pointer" />
));
