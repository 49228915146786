import { Grid } from "@arco-design/web-react";

import { type OperateLog } from "@api/http_pms/operate_log/operate_log_type";
import clsx from "clsx";
import { useMemo } from "react";
import { type Tenant } from "@api/http_pms/tenant/tenant_type";
import { isEmpty } from "lodash-es";
import { Sex } from "@/common/const";
import { Nationality } from "@/common/const/nationality";
import { PictureListShow } from "@/comps/PictureListShow";

const { Row } = Grid;
const { Col } = Grid;

export const UserAddDelete = ({
  list,
  isDelete,
}: {
  list: OperateLog.FieldContent[];
  isDelete: boolean;
}) => {
  const object = useMemo(
    () => Object.fromEntries(list.map((li) => [li.field, li.current as any])),
    [list],
  );

  const addImages = useMemo(
    () =>
      // TODO 改造成 JSON String
      object?.AddImages?.split("、") || [],
    [],
  );

  const name = !object?.Name?.trim() ? null : (
    <div className="mr-1 text-base font-medium text-gray-800">
      <span className="mr-1">{object.Name.trim()}</span>
      <span>{Sex[object.Sex as unknown as Tenant.Sex]?.label}</span>
    </div>
  );

  return (
    <div className="flex-auto select-text text-gray-500">
      <div className={clsx({ "line-through": isDelete })}>{name}</div>
      <Row gutter={[0, 6]} className="!mt-1">
        {Boolean(object?.PhoneNumber || object?.CountryCode) && (
          <Col span={12} className={clsx("flex", { "line-through": isDelete })}>
            <div className="shrink-0">联系方式：</div>
            <div className={clsx("flex gap-1", { "line-through": isDelete })}>
              <span>{object.CountryCode}</span>
              <span>{object.PhoneNumber}</span>
            </div>
          </Col>
        )}

        {object?.Email && (
          <Col span={12} className={clsx({ "line-through": isDelete })}>
            邮箱地址：
            {object.Email}
          </Col>
        )}

        {Nationality[object.Nationality as keyof typeof Nationality]?.label && (
          <Col span={12} className={clsx({ "line-through": isDelete })}>
            国籍/地域：
            {Nationality[object.Nationality as keyof typeof Nationality].label}
          </Col>
        )}

        {object?.Birthday && (
          <Col span={12} className={clsx({ "line-through": isDelete })}>
            出生日期：
            {object.Birthday}
          </Col>
        )}

        {object?.Profession && (
          <Col span={12} className={clsx({ "line-through": isDelete })}>
            工作/职业：
            {object.Profession}
          </Col>
        )}

        {object?.Address?.trim() && (
          <Col span={12} className={clsx("flex", { "line-through": isDelete })}>
            <div className="shrink-0">居住地址：</div>
            <div>{object.Address.trim()}</div>
          </Col>
        )}

        {!isEmpty(addImages) && (
          <Col span={24} className="flex">
            <div className="shrink-0">附件：</div>
            <PictureListShow urls={addImages} />
          </Col>
        )}
      </Row>
    </div>
  );
};
