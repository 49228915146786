import {
  BillKind as ApiBillKind,
  type ShareBill,
} from "@api/http_pms/bill/bill_type";
import { ContentKind, EntityKind } from "@api/http_pms/const/const_enum";
import { type RoomSimple } from "@api/http_pms/room/room_type";
import {
  DatePicker,
  Form,
  Input,
  Select,
  type FormInstance,
} from "@arco-design/web-react";
import { useMemo, type FC } from "react";
import dayjs from "dayjs";
import { FileUploadList } from "@/common/FileUploadList";
import { RoomSelector } from "@/common/Inputer/RoomSelector";
import { BillKind } from "@/common/const";
import { useConstOption } from "@/common/const/utils";
import { useWallet } from "@/store/useWallet";
import { required } from "@/utils/validator";
import { MoneyForm } from "./MoneyForm";
import { newEmptySubBill } from "../utils";
import { useOrgInfo } from "@/store/useOrgInfo";
import { useRoomInfo } from "@/store/useRoomInfo";

const nonOrderBillKinds = new Set([
  ApiBillKind.Water,
  ApiBillKind.Electricity,
  ApiBillKind.Gas,
  ApiBillKind.Network,
  ApiBillKind.Clear,
  ApiBillKind.TransferAccounts,
  ApiBillKind.Extra,
  ApiBillKind.ExtraIncome,
]);

const orderBillKinds = new Set([
  ApiBillKind.Deposit,
  ApiBillKind.Claims,
  ApiBillKind.Clear,
  ApiBillKind.Extra,
  ApiBillKind.ExtraIncome,
  ApiBillKind.ExtraRoomFee,
  ApiBillKind.Refund,
  ApiBillKind.OtaRefund,
]);

export type BillFormData = ShareBill & {
  roomIds: string[];
  roomList: RoomSimple[];
};

export const BillForm: FC<{
  form: FormInstance;
  canEdit?: boolean;
  initShareBill?: ShareBill; // 留空为新建
  orderId?: string; // 是否为订单上的帐单
}> = ({ form, canEdit: _canEdit = true, initShareBill, orderId: _orderId }) => {
  const { roomMapId } = useRoomInfo();
  const subBillHasOrder = useMemo(
    () =>
      _orderId ? undefined : initShareBill?.subBills.find((b) => b.orderId),
    [],
  );
  const orderId = subBillHasOrder?.orderId || _orderId;
  const order = subBillHasOrder?.order;

  const billOptions = useConstOption(
    BillKind,
    initShareBill
      ? undefined
      : (k) => (orderId ? orderBillKinds : nonOrderBillKinds).has(k.value),
  );

  const { walletOptions } = useWallet();

  const canEdit = useMemo(() => {
    if (!_canEdit) {
      return false;
    }
    if (
      initShareBill?.billKind === ApiBillKind.RoomFee ||
      initShareBill?.billKind === ApiBillKind.OtaFee
    ) {
      return false;
    }
    if (initShareBill?.subBills.some((b) => b.settleMonth)) {
      return false;
    }
    return true;
  }, [_canEdit]);

  const initData = useMemo<BillFormData>(() => {
    if (!initShareBill) {
      return {
        shareId: "",
        subBills: [newEmptySubBill({ orderId })],
        billKind: billOptions[0]!.value,
        walletId: "",
        currency: useOrgInfo.getState().orgInfo.currency,
        receiptDate: dayjs().format("YYYY-MM-DD"),
        postingDate: dayjs().format("YYYY-MM-DD"),
        images: [],
        roomList: [],
        roomIds: [],
        remark: {
          contentKind: ContentKind.Text,
          content: "",
        },
      };
    }
    const rooms = initShareBill.subBills
      .map((b) => (b.roomId ? roomMapId[b.roomId] : undefined))
      .filter((b) => b !== undefined);
    return {
      ...initShareBill,
      roomList: rooms,
      roomIds: rooms.map((r) => r.id),
    };
  }, []);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      size="large"
      className="mt-2"
      disabled={!canEdit}
      initialValues={initData}
    >
      <Form.Item
        field="roomIds"
        label="所属房间"
        hidden={Boolean(_orderId)}
        disabled={!canEdit || Boolean(orderId)}
      >
        <RoomSelector mode="multiple" />
      </Form.Item>
      <Form.Item
        field="roomIds"
        label="所属订单"
        hidden={Boolean(_orderId) || !orderId}
        disabled={true}
        formatter={() =>
          order
            ? `${dayjs(order.dateStart).dateStr(false, true)} - ${dayjs(order.dateEnd).dateStr(false, true)}`
            : "-"
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        field="billKind"
        label="账单类型"
        rules={[required("账单类型")]}
        disabled={!canEdit || Boolean(initShareBill)}
      >
        <Select
          options={billOptions}
          placeholder="请选择账单类型"
          triggerProps={{
            containerScrollToClose: true,
          }}
        />
      </Form.Item>
      <MoneyForm
        form={form}
        initShareBill={initShareBill}
        orderId={orderId}
        canEdit={canEdit}
      />
      <Form.Item
        field="receiptDate"
        label="发生日期"
        hidden={Boolean(_orderId)}
        disabled={!canEdit || Boolean(orderId)}
      >
        <DatePicker className="w-full" format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item field="postingDate" label="入账日期" disabled={!_canEdit}>
        <DatePicker
          className="w-full"
          format="YYYY-MM-DD"
          triggerProps={{
            containerScrollToClose: true,
          }}
        />
      </Form.Item>
      <Form.Item field="walletId" label="钱包" disabled={!_canEdit}>
        <Select
          options={walletOptions}
          placeholder="请选择钱包"
          triggerProps={{
            containerScrollToClose: true,
          }}
        />
      </Form.Item>
      <Form.Item
        field="remark.content"
        label="备注"
        formatter={(val) => (!_canEdit && !val ? "-" : val)}
        disabled={!_canEdit}
      >
        <Input.TextArea
          placeholder="请输入备注"
          autoSize={{ minRows: 3, maxRows: 8 }}
        />
      </Form.Item>
      <Form.Item
        field="images"
        label="附件"
        className="!mb-0"
        labelCol={{ span: 6 }}
        disabled={!_canEdit}
      >
        <FileUploadList
          entityInfo={{
            entityKind: EntityKind.Bill,
            relationId: initShareBill?.shareId || "",
            relationSubId: "",
          }}
          partition={0}
          itemClassName="border"
        />
      </Form.Item>
    </Form>
  );
};
