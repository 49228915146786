/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type Area } from "../const/const_enum";
import { type OrgConfig } from "../../http_resv/org/org_type";

export interface Org {
  // 组织id
  id: string;
  // 组织名称
  name: string;
  // 组织别名
  alias: string;
  area: Area;
  // 组织拥有的能力
  capabilities: Capability[];
  // 最大房源数量 @uint32
  maxRoomCount: number;
  // 微店域名
  resvOrg?: OrgConfig;
  // 默认最早入住时间 @uint32
  checkInTime: number;
  // 默认最晚退房时间 @uint32
  checkOutTime: number;
  // 过期时间
  expiredDate: string;
  // 默认标签
  orderDefaultTagIds: string[];
}
// 组织能力枚举
export enum Capability {
  // 未知
  Unknown = 0,
  // 基础的OTA能力
  OtaBase = 1,
  // OTA消息
  OtaMessage = 2,
}
