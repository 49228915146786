import { Grid } from "@arco-design/web-react";

import { IconFont } from "@/common/IconFont";
import { OverflowTooltip } from "@/comps/OverflowTooltip";

const { Row } = Grid;
const { Col } = Grid;

export const InfoChangeItem = ({
  title,
  before,
  after,
}: {
  title: string;
  before?: string;
  after?: string;
}) => (
  <Row>
    <Col span={5} className="text-color-text-2 text-right">
      {title}：
    </Col>
    <Col span={9} className="pl-1 line-through">
      <OverflowTooltip>{before || "<空>"}</OverflowTooltip>
    </Col>
    <Col span={10} className="flex items-center justify-between">
      <IconFont
        type="icon-single-right-arrow"
        className="mr-1 size-4 shrink-0"
      />
      <OverflowTooltip>{after || "<空>"}</OverflowTooltip>
    </Col>
  </Row>
);
