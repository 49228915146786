import {
  Link,
  Popover,
  Space,
  type TableColumnProps,
} from "@arco-design/web-react";

import { useMemo } from "react";
import { isEmpty } from "lodash-es";
import dayjs from "dayjs";
import { type Currency, EntityKind } from "@api/http_pms/const/const_enum";
import { type OrderForList } from "@api/http_pms/order/order_type";
import { Permission as ApiPermission } from "@api/http_pms/role/role_type";
import { OverflowTooltip } from "@/comps/OverflowTooltip";
import { EasTag } from "@/common/EasTag";
import { useTag } from "@/store/useTag";
import { PointAndTextStatus } from "@/common/PointAndTextStatus";
import {
  OrderStatus,
  OrderStatusEnum,
  getCurrentTimeZoneDate,
  getOrderStatus,
} from "@/utils/orderStatus";
import { RoomNameColumnShow } from "@/common/RoomNameColumnShow";
import { IconFont } from "@/common/IconFont";
import { getMoneyText } from "@/utils/money";
import { permissionHas } from "@/store/useUserInfo";
import { getSumAmount } from "./utils";
import { useOrderSource } from "@/store/useOrderSource";
import { openOrderDrawer } from "./OrderDrawer";

export const useColumns = ({
  queryColumnKeys,
  refreshAsync,
}: {
  queryColumnKeys: string[];
  refreshAsync?: () => Promise<any>;
}) => {
  const { tagMap } = useTag(EntityKind.Order);
  const nowDate = getCurrentTimeZoneDate();
  const { orderSourceMapId } = useOrderSource();

  const columns = useMemo<TableColumnProps[]>(() => {
    const allTableColumn: TableColumnProps[] = [
      {
        title: "房间名称",
        dataIndex: "roomName",
        width: 250,
        render: (col, row) => (
          <RoomNameColumnShow
            roomName={col}
            groupId={row.groupId}
            imgUrl={row.roomCover}
          />
        ),
      },
      {
        title: "订单状态",
        dataIndex: "aliveStatus",
        width: 150,
        render: (_, row: OrderForList) => {
          const statusEnum = getOrderStatus(row);
          if (statusEnum !== undefined) {
            const currentStatus = OrderStatus[statusEnum];

            if (statusEnum === OrderStatusEnum.Reserving) {
              return <div className="text-color-danger-6">预定中</div>;
            }

            return (
              <PointAndTextStatus
                typeCustomizeColor={currentStatus.color}
                tipContent={
                  row?.cancelledAt
                    ? dayjs.unixStr(row.cancelledAt).dateStr(true)
                    : undefined
                }
              >
                {statusEnum === OrderStatusEnum.WaitingCheckIn
                  ? `${dayjs(row.dateStart).diff(nowDate.startOf("day"), "day")}天后入住`
                  : currentStatus.label}
              </PointAndTextStatus>
            );
          }
        },
      },
      {
        title: "订单标签",
        width: 300,
        dataIndex: "tagIds",
        render: (list) =>
          isEmpty(list) ? (
            "-"
          ) : (
            <OverflowTooltip
              tooltipContent={
                <div className="flex flex-wrap gap-x-2 gap-y-1">
                  {list.map((val: string) => (
                    <EasTag
                      key={val}
                      color={tagMap[val]?.color}
                      text={tagMap[val]?.name || ""}
                    />
                  ))}
                </div>
              }
            >
              {list.map((val: string) => (
                <EasTag
                  key={val}
                  className="mr-2"
                  color={tagMap[val]?.color}
                  text={tagMap[val]?.name || ""}
                />
              ))}
            </OverflowTooltip>
          ),
      },
      {
        title: "入离日期",
        width: 300,
        dataIndex: "dateStart",
        render: (_, row) => (
          <div className="flex items-center">
            {row.dateStart && row.dateEnd
              ? `${dayjs.dateStr(row.dateStart as string).dateStr(false, true)} - ${dayjs.dateStr(row.dateEnd as string).dateStr(false, true)}`
              : "-"}
            <span>
              (共
              {dayjs(row.dateEnd as string).diff(
                dayjs(row.dateStart as string),
                "days",
              )}
              晚)
            </span>
            {!isEmpty(row.conflictIds) && (
              <Popover content="当前订单存在时间冲突">
                <IconFont
                  type="icon-warn"
                  className="text-color-danger-6 ml-2 cursor-pointer text-sm"
                />
              </Popover>
            )}
          </div>
        ),
      },
      {
        title: "订单金额",
        dataIndex: "orderAmount",
        width: 200,
        render: (_, row: OrderForList) =>
          getMoneyText(getSumAmount(row), row.roomFeeCurrency, {
            hidePlusSign: true,
            hide00Dec: true,
          }),
      },
      {
        title: "房费",
        dataIndex: "roomFee",
        width: 200,
        render: (col: number, row) =>
          getMoneyText(col, row.roomFeeCurrency as Currency, {
            hidePlusSign: true,
          }),
      },
      {
        title: "佣金",
        dataIndex: "otaFee",
        width: 200,
        render: (col: number, row) =>
          getMoneyText(col, row.roomFeeCurrency as Currency, {
            hidePlusSign: true,
          }),
      },
      {
        title: "手续费",
        dataIndex: "handlingFee",
        width: 200,
        render: (col: number, row) =>
          getMoneyText(col, row.roomFeeCurrency as Currency, {
            hidePlusSign: true,
          }),
      },
      {
        title: "订单ID",
        dataIndex: "id",
        width: 150,
      },
      {
        title: "房客姓名",
        dataIndex: "tenant",
        width: 150,
        render: (col) => col?.name || "-",
      },
      // TODO 后面做到 OTA 能力的时候，再加上这个字段
      // {
      //   title: "OTA来源",
      //   dataIndex: "otaPlatformKind",
      //   width: 150,
      //   render: (col) => OtaPlatformKind[col as keyof typeof OtaPlatformKind]?.label,
      // },
      {
        title: "渠道来源",
        dataIndex: "orderSourceId",
        width: 200,
        render: (col, row) => (
          <OverflowTooltip>
            {[orderSourceMapId[col]?.name, row.orderSourceKey]
              .filter((item) => item)
              .join(" - ")}
          </OverflowTooltip>
        ),
      },
      {
        title: "预定时间",
        dataIndex: "bookAt",
        width: 180,
        render: (col: string) =>
          col ? dayjs.unixStr(col).dateStr(true, true) : "-",
      },
      {
        title: "备注",
        dataIndex: "remark",
        width: 190,
        render: (col) =>
          col?.content ? (
            <OverflowTooltip> {col?.content}</OverflowTooltip>
          ) : (
            "-"
          ),
        exportRender: (col: any) => (col ? col?.content : "-"),
      },
    ];

    return [
      ...queryColumnKeys
        .map((key) => allTableColumn.find((item) => key === item.dataIndex))
        .filter((item) =>
          permissionHas(ApiPermission.OrderAdvancedView)
            ? item
            : item &&
              !["tagIds", "remark", "orderAmount"].includes(item.dataIndex!),
        ),
      {
        title: "操作",
        dataIndex: "op",
        width: 120,
        fixed: "right",
        isNotExport: true,
        render: (_, row) => (
          <Space>
            <Link
              onClick={async () => {
                await openOrderDrawer({
                  afterSubmit: refreshAsync,
                  orderId: row.id,
                });
              }}
            >
              查看订单
            </Link>
          </Space>
        ),
      },
    ] as TableColumnProps[];
  }, [tagMap, queryColumnKeys]);

  return columns;
};
