import {
  type ImMsg,
  type ImConvExtra,
  type ImUser,
} from "@api/http_im/im_type";
import { type Dayjs } from "dayjs";

export interface ChatConv {
  id: string;
  extra: ImConvExtra;
  entrant: ImUser;
  unreadMsgIds: string[];
  msgs: ChatMsg[];
  hasMoreMsg: boolean;
  ready: boolean;
  createdAt: Dayjs;
}

export interface ChatMsg {
  // 临时 id 下划线开头
  id: string;
  kind: ImMsg.Kind;
  content: string;
  sender: ImUser;
  sentAt: Dayjs;
  // undefined 是发送中，null是送达失败，Dayjs就是送达成功，并且有具体时间
  deliverAt?: Dayjs | null;
}

export enum ChatConvKind {
  All,
  WaitReplay,
  Mark,
}

export enum ChatStatus {
  NotConnected,
  Connecting,
  Connected,
  Error,
}
