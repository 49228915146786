import {
  type BillKind as ApiBillKind,
  type SubBill,
} from "@api/http_pms/bill/bill_type";
import { Form, Input, Radio } from "@arco-design/web-react";
import { cloneDeep, max, min, sum } from "lodash-es";
import { useLayoutEffect, type FC } from "react";
import { type Currency } from "@api/http_pms/const/const_enum";
import { useUpdateEffect } from "ahooks";
import { type CurrencyAmount } from "@api/http_pms/common/common_type";
import { CompactGroup } from "@/common/CompactGroup";
import { newEmptySubBill } from "../../utils";
import { type MoneyFormProps } from ".";
import { gtZero } from "@/utils/validator";
import { BillKind } from "@/common/const";
import { MoneyCurrencyInput } from "@/common/Inputer/MoneyCurrencyInput";
import { MoneyInputWithCurrency } from "@/common/Inputer/MoneyInputWithCurrency";
import { useAmountTypeOptions } from "../utils";
import { useRoomInfo } from "@/store/useRoomInfo";

enum AllocType {
  Average = 1,
  Customize = 2,
}

interface RoomAmount {
  roomId: string;
  amount: number;
}

const calcAverage = (
  total: number,
  roomAmountList: RoomAmount[],
): RoomAmount[] => {
  const count = roomAmountList.length;
  let baseAmount = 0;
  let remainder = 0;
  baseAmount = Math.floor(total / count);
  remainder = total % count;

  return roomAmountList.map((li, i) => ({
    roomId: li.roomId,
    amount: i < remainder ? baseAmount + 1 : baseAmount,
  }));
};

const allocOptions = [
  { value: AllocType.Average, label: "平均分配" },
  { value: AllocType.Customize, label: "自定义分配" },
];

export interface MoneyFormSplitRoomData {
  amountType: 1 | -1;
  currencyAmount: CurrencyAmount;
  allocType: AllocType;
  roomAmountList: RoomAmount[];
}

export const MoneyFormSplitRoom: FC<MoneyFormProps> = ({
  form,
  initShareBill,
  canEdit,
  orderId,
}) => {
  const { roomMapId } = useRoomInfo();
  // 外部表单数据
  const roomIds: string[] = Form.useWatch("roomIds", form);

  const billKind: ApiBillKind = Form.useWatch("billKind", form);
  const finalAmountTypeOptions = useAmountTypeOptions(billKind, form);

  // 内部表单数据
  const subFormData: MoneyFormSplitRoomData = Form.useWatch(
    ["amountType", "currencyAmount", "allocType", "roomAmountList"],
    form,
  );

  // 初始化逻辑
  useLayoutEffect(() => {
    const currency: Currency = form.getFieldValue("currency");
    const _subBills: SubBill[] = form.getFieldValue("subBills");
    const subBills = roomIds.map(
      (id) =>
        _subBills.find((b) => b.roomId === id) ||
        newEmptySubBill({ roomId: id }),
    );

    const amountList = subBills.map((b) => b.amount);
    let amount = sum(amountList);
    let amountType: number;
    if (amount === 0) {
      amountType = BillKind[billKind].amountType || -1;
    } else if (amount < 0) {
      amount = -amount;
      amountType = -1;
    } else {
      amountType = 1;
    }

    const _allocType =
      (max(amountList) || 0) - (min(amountList) || 0) <= 1
        ? AllocType.Average
        : AllocType.Customize;

    const roomAmountList = subBills.map((b) => ({
      amount: b.amount * amountType,
      roomId: b.roomId!,
    }));

    form.setFieldsValue({
      amountType,
      currencyAmount: {
        currency,
        amount,
      },
      allocType: _allocType,
      roomAmountList,
    });
    return () => {
      form.resetFields([
        "amountType",
        "currencyAmount",
        "allocType",
        "roomAmountList",
      ]);
    };
  }, []);

  // 相关数据变化时，更新 外部数据
  useUpdateEffect(() => {
    if (!canEdit) {
      return;
    }
    let newRoomAmountList;
    if (subFormData.allocType === AllocType.Average) {
      newRoomAmountList = calcAverage(
        subFormData.currencyAmount.amount,
        roomIds.map((id) => ({ roomId: id, amount: 0 })),
      );
    } else {
      const roomAmountMap = Object.fromEntries(
        subFormData.roomAmountList.map((a) => [a.roomId, a]),
      );
      newRoomAmountList = roomIds.map((id) => ({
        roomId: id,
        amount: roomAmountMap[id]?.amount || 0,
      }));
    }

    const subBills = newRoomAmountList.map((r, ind) => {
      let subBill = initShareBill?.subBills[ind];
      if (subBill) {
        subBill = cloneDeep(subBill);
      } else {
        subBill = newEmptySubBill();
      }
      subBill.amount = r.amount * subFormData.amountType;
      subBill.roomId = r.roomId;
      return subBill;
    });

    form.setFieldsValue({
      roomAmountList: newRoomAmountList,
      currency: subFormData.currencyAmount.currency,
      subBills,
    });
  }, [roomIds, subFormData]);

  return (
    <>
      <Form.Item field="amountType" label="收支类型">
        <Radio.Group options={finalAmountTypeOptions} />
      </Form.Item>

      <Form.Item
        field="currencyAmount"
        label="金额"
        rules={[gtZero("金额", "amount")]}
      >
        <MoneyCurrencyInput />
      </Form.Item>

      <Form.Item
        label="金额分配"
        field="allocType"
        rules={[
          {
            validateTrigger: [],
            validator(_, cb: (error?: string) => void) {
              if (
                sum(subFormData.roomAmountList?.map((li) => li.amount)) !==
                subFormData.currencyAmount?.amount
              ) {
                return cb("分配设置总金额账单总金额不一致");
              }
              return cb();
            },
          },
        ]}
      >
        <Radio.Group options={allocOptions} />
      </Form.Item>

      <Form.Item label=" ">
        <Form.List field="roomAmountList">
          {(fields) => (
            <div className="flex flex-col gap-4">
              {fields.map((item, index) => (
                <div key={index}>
                  <CompactGroup>
                    <Form.Item
                      field={`${item.field}.roomId`}
                      noStyle={true}
                      formatter={(id) => roomMapId[id]?.name}
                    >
                      <Input disabled={true} />
                    </Form.Item>

                    <Form.Item field={`${item.field}.amount`} noStyle={true}>
                      <MoneyInputWithCurrency
                        disabled={subFormData.allocType === AllocType.Average}
                        currency={subFormData.currencyAmount?.currency}
                        suffix={null}
                      />
                    </Form.Item>
                  </CompactGroup>
                </div>
              ))}
            </div>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};
