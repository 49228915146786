/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type Image, type Remark } from "../common/common_type";
import { type Currency, type PriceFloatKind } from "../const/const_enum";
import {
  type OtaAccountPure,
  type OtaAccount,
} from "../ota_account/ota_account_type";

// This the module of Room
export namespace Room {
  // 房间的状态
  export enum OccupyStatus {
    // 空房
    Vacant = 0,
    // 入住中
    Occupied = 1,
    // 待清扫
    Uncleaned = 2,
  }
}
export interface Room {
  // id
  id: string;
  // 是否关闭 @bool
  isClosed: boolean;
  // 房间名称
  name: string;
  // 房间的状态
  occupyStatus: Room.OccupyStatus;
  // 排序（权重）降序 @uint32
  rank: number;
  // 房间首图
  image?: Image;
  // 币种
  currency: Currency;
  // 时区
  timeZone: string;
  // 最早入住时间 @int32
  checkInTime: number;
  // 最晚退房时间 @int32
  checkOutTime: number;
  // 基础价格 @uint32
  basePrice: number;
  // 周末价格 @uint32
  weekendPrice: number;
  // 未来最大开放天数 @uint32
  maxOpenDays?: number;
  // 价格规则
  priceRules: PriceRules;
  // 标签id列表
  tagIds: string[];
  // 分组id
  groupId: string;
  // 备注
  remark: Remark;
  // 开始运营日期
  operationDate: string;
}

// This the module of PriceRules
export namespace PriceRules {
  export interface Rule {
    // 规则类型
    kind: Kind;
    // 预先配置好的节假日 枚举类型
    holidayKind?: HolidayKind;
    // 为空 临期 @uint32
    daysStart?: number;
    // 为空 近期 @uint32
    daysEnd?: number;
    // start 5
    // end   10
    // 5天之后-10天之内
    floatKind: PriceFloatKind;
    // 浮动数值 @int32
    floatValue: number;
  }
} // 价格规则
export interface PriceRules {
  rules: PriceRules.Rule[];
}

export interface RoomSimple {
  // 房间id
  id: string;
  // 名称
  name: string;
  // 封面
  image: string;
  // 是否关闭 @bool
  isClosed: boolean;
  // 标签id列表
  tagIds: string[];
  // 分组id（为空就是默认分组）
  groupId: string;
  // 排序（权重）降序 这里的排序 只是组内排序 只应用于分组 @uint32
  rank: number;
  // 最早入住时间 @int32
  checkInTime: number;
  // 最晚退房时间 @int32
  checkOutTime: number;
  // 删除时间 不为空表示已经软删 @uint64
  deletedAt?: string;
  // 房间同步至微店 @bool
  isOnSale: boolean;
  // 房间关联的渠道来源ids
  otaAccounts: OtaAccountPure[];
}

// isClosed 需要跨服务
export interface RoomPure {
  // 房间id
  id: string;
  // 名称
  name: string;
  // 封面
  image: string;
  // 标签id列表
  tagIds: string[];
  // 分组id（为空就是默认分组）
  groupId: string;
  // 排序（权重）降序 这里的排序 只是组内排序 只应用于分组 @uint32
  rank: number;
}

export interface RoomForCalender {
  // 房间id
  id: string;
  // 名称
  name: string;
  // 封面
  image: string;
  // 是否关闭 @bool
  isClosed: boolean;
  // 分组id
  groupId: string;
  // 冲突订单编号
  conflictOrderIds: string[];
  // 房间状态
  occupyStatus: Room.OccupyStatus;
  // 币种
  currency: Currency;
  // 权重 降序 @uint32
  rank: number;
  // 最早入住时间 @int32
  checkInTime: number;
  // 最晚退房时间 @int32
  checkOutTime: number;
}

export interface RoomCreate {
  // 房间名称
  name: string;
  // 基础价格 @uint32
  basePrice: number;
}

export interface RoomGroup {
  // id
  id: string;
  // 分组名称
  name: string;
  // 权重 倒序 @uint32
  rank?: number;
  // 房间数 @uint32
  count?: number;
  // 删除时间 不为空表示已经软删 @uint64
  deletedAt?: string;
}

export interface OtaRoomAccountConfig {
  // otaAccountId 微店的id:reservation 开启微店才返回 排在第一位
  otaAccount: OtaAccount;
  // 房间id
  roomId: string;
  // 币种
  currency: Currency;
  // 标题
  title: string;
  // 基础浮动值 (比例 支持2位小数) @int32
  basicFloatValue: number;
  // 周租优惠 (比例 支持2位小数) @uint32
  weeklyFloatValue: number;
  // 月租优惠 (比例 支持2位小数) @uint32
  monthlyFloatValue: number;
  // 免费人头数 @uint32
  freePersonCount: number;
  // 超出人数 每人每晚价格 @uint32
  pricePerExtraPerson: number;
  // 清扫费 @uint32
  cleaningFee: number;
  // 起订间夜 @uint32
  minNights: number;
}

export interface OtaRoomAccountConfigUpdate {
  // otaAccountId
  otaAccountId: string;
  // 房间id
  roomId: string;
  // 标题
  title?: string;
  // 基础浮动值 (比例 支持2位小数) @int32
  basicFloatValue?: number;
  // 周租优惠 (比例 支持2位小数) @uint32
  weeklyFloatValue?: number;
  // 月租优惠 (比例 支持2位小数) @uint32
  monthlyFloatValue?: number;
  // 免费人头数 @uint32
  freePersonCount?: number;
  // 超出人数 每人每晚价格 @uint32
  pricePerExtraPerson?: number;
  // 清扫费 @uint32
  cleaningFee?: number;
  // 起订间夜 @uint32
  minNights?: number;
}
export enum Kind {
  UnknownKind = 0,
  // 节假日 未来的节假日具体日期也是不确定的 所以要动态算
  Holiday = 1,
  // 远期/临期
  RangeDays = 2,
}
export enum HolidayKind {
  UnknownHoliday = 0,
  ChineseHolidays = 1,
  JapaneseHolidays = 2,
}
// 日历顶部状态筛选项枚举
export enum QueryStatusForCalender {
  UnknownQueryStatus = 0,
  // 入住
  CheckIn = 1,
  // 入住中
  Staying = 2,
  // 退房
  CheckOut = 3,
  // 空房
  Vacant = 4,
  // 关闭
  Closed = 5,
  // 明日入住
  NextCheckIn = 6,
}
